import * as R from 'ramda';
import { createSelector as _createSelector } from 'reselect'
// import createSelector from 'selectorator';
import _ from 'lodash';


const globalSelector = (state) => state.global;
const propertySearchSelector = (state) => state.propertySearch;
const routerSelector = (state) => state.router;

const isAuthenticatecSelector = _createSelector(
  globalSelector,
  R.prop('isAuthenticated')
);

const sessionSelector = _createSelector(
  globalSelector,
  R.prop('session')
);


// createSelector({
//   isAuthenticated: 'global.isAuthenticated',
//   searchFilters: 
// })

const favoritesSelector = _createSelector(propertySearchSelector, (state) => state.favorites);
const searchFiltersSelector = _createSelector(propertySearchSelector, (state) => state.searchFilters);
const centerSelector = _createSelector(propertySearchSelector, (state) => state.center);
const zoomSelector = _createSelector(propertySearchSelector, (state) => state.zoom);
const propertySessionsSelector = _createSelector(propertySearchSelector, (state) => state.propertySessions);
const filteredPs = _createSelector(propertySearchSelector, (state) => state.filteredPs);
const rightSideItems = _createSelector(propertySearchSelector, (state) => state.rightSideItems);
const statusSelector = _createSelector(propertySearchSelector, (state) => _.pick(state, ['pending', 'success', 'error']));
const featuresSelector = _createSelector(propertySearchSelector, (state) => state.features);
const searchFilter = _createSelector(propertySearchSelector, (state) => state.searchFilter);
const placeSelector = _createSelector(propertySearchSelector, (state) => state.place);

export default _createSelector(
  routerSelector,
  isAuthenticatecSelector,
  sessionSelector,
  propertySearchSelector,
  propertySessionsSelector,
  favoritesSelector,
  statusSelector,
  centerSelector,
  zoomSelector,
  searchFiltersSelector,
  featuresSelector,
  searchFilter,
  filteredPs,
  rightSideItems,
  placeSelector,
  (route, isAuthenticated, session, propertySearch, propertySessions, favorites, status, center, zoom, searchFilters, features, searchFilter, filteredPs, rightSideItems, place) => ({
    route,
    isAuthenticated,
    session,
    place,
    propertySearch,
    propertySessions,
    favorites,
    status,
    center,
    rightSideItems,
    zoom,
    searchFilters,
    filteredPs,
    searchFilter,
    features
  })
);

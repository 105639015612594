import { bindActionCreators } from 'redux';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createSlice, createSelector } from 'redux-starter-kit';
import _ from 'lodash';

const getInitialState = () => ({
  searchPsPending: false,
  getFeatPending: false,
  addFavPending: false,
  setSrchFilterPending: false,
  setBndsPending: false,

  markers: [],
  features: [],
  favorites: [],

  openInfoWindowId: null,
  tBounds: [[0, 0], [0, 0]],
  bounds: [[0, 0], [0, 0]],

  hiddenMarkerIds: [],

  savedSearchFilters: [],
  searchFilter:localStorage.getItem("searchFilter") === null ? {
    searchText: '',
    name: '',
    available: -1,
    beds: -1,
    baths: -1,
    floors: -1,
    creditScore: -1,
    propertyType: [],
    amenities: [],
    pets: -1,
    lotSize: [200],
    price: -1,
    rentItNow: -1,
    eviction: -1,
    evictionPeriod: -1,
    bankruptcy: -1,
    bankruptcyChapter: -1,
    bankruptcyType: -1,
    bankruptcyPeriod: -1,
    customPrice: -1,
    minimumResidentScore: -1,
    zoom: 10
  } : JSON.parse(localStorage.getItem('searchFilter')),
});




const slice = createSlice({
  slice: 'maps',
  initialState: getInitialState(),
  reducers: {
    setPropertySessions: (state, action) => ({
      ...state,
      markers: _.uniqBy(_.map([...state.markers, ...action.payload], (el) => {
        return {
          ...el,
          lat: _.get(el, 'property.location.coordinates.1'),
          lng: _.get(el, 'property.location.coordinates.0'),
        }
      }), '_id')
    }),
    searchPropertySessions: (state) => ({
      ...state,
      searchPsPending: true,
    }),
    searchPropertySessionsPending: (state) => ({
      ...state,
      searchPsPending: true,
    }),
    searchPropertySessionsSuccess: (state, action) => ({
      ...state,
      searchPsPending: false,
      markers: _.uniqBy(_.map([...state.markers, ...action.payload], (el) => {
        return {
          ...el,
          lat: _.get(el, 'property.location.coordinates.1'),
          lng: _.get(el, 'property.location.coordinates.0'),
        }
      }), '_id')
    }),




    setBounds: (state, action) => {
      return {
        ...state,
        tbounds: action.payload,
      };
    },
    setBoundsPending: (state, action) => ({
      ...state,
      setBndsPending: true,
      tBounds: action.payload
    }),
    setBoundsSuccess: (state, action) => ({
      ...state,
      tBounds: [[0, 0], [0, 0]],
      setBndsPending: false,
      bounds: action.payload
    }),



    saveSearchFilter: (state, action) => ({
      ...state,
    }),
    saveSearchFilterPending: (state, action) => ({
      ...state,
      saveFilterPending: true,
    }),
    saveSearchFilterSuccess: (state, action) => ({
      ...state,
      savedSearchFilters: action.payload,
      saveFilterPending: false,
    }),
    deleteSearchFilter: (state, action) => ({
      ...state,
    }),
    deleteSearchFilterPending: (state, action) => ({
      ...state,
      deleteFilterPending: true,
    }),
    deleteSearchFilterSuccess: (state, action) => ({
      ...state,
      savedSearchFilters: action.payload,
      searchFilter: getInitialState().searchFilter,
      deleteFilterPending: false,
    }),

    setSavedSearchFilter: (state, action) => ({
      ...state,
      searchFilter: action.payload
    }),

    setSearchFilter: (state, action) => ({
      ...state,
      searchFilter: {
        ...getInitialState().searchFilter,
        ...action.payload
      }
    }),
    setSearchFilterPending: (state, action) => ({
      ...state,
      setSrchFilterPending: true
    }),
    setSearchFilterSuccess: (state, action) => ({
      ...state,
      setSrchFilterPending: false,
      hiddenMarkerIds: action.payload
    }),

    resetSearchFilter: (state, action) => ({
      ...state,
      searchFilter: getInitialState().searchFilter
    }),


    setInfoWindowOpen: (state, action) => ({
      ...state,
      openInfoWindowId: action.payload
    }),
    setInfoWindowClose: (state) => ({
      ...state,
      openInfoWindowId: null
    }),


    getSavedFilters: (state, action) => ({
      ...state,
    }),
    getSavedFiltersPending: (state, action) => ({
      ...state,
      getFiltersPending: true
    }),
    getSavedFiltersSuccess: (state, action) => ({
      ...state,
      getFiltersPending: false,
      savedSearchFilters: action.payload,
    }),




    getFavorites: (state, action) => ({ ...state, }),
    getFavoritesPending: (state, action) => ({ ...state, getFavsPending: true }),
    getFavoritesSuccess: (state, action) => ({ ...state, getFavsPending: false, favorites: action.payload }),

    getFeatures: (state, action) => ({ ...state, }),
    getFeaturesPending: (state, action) => ({
      ...state,
      getFeatPending: true
    }),
    getFeaturesSuccess: (state, action) => ({
      ...state,
      features: action.payload,
      getFeatPending: false
    }),

    reset: (state) => ({
      ...state,
      ...getInitialState()
    }),

    addFavorite: (state) => ({ ...state, }),
    addFavoritePending: (state) => ({
      ...state,
      addFavPending: true
    }),

    addFavoriteSuccess: (state, action) => ({
      ...state,
      markers: action.payload.markers,
      favorites: action.payload.favorites,
      addFavPending: false
    }),

    deleteFavorite: (state) => ({ ...state, }),
    deleteFavoritePending: (state) => ({
      ...state,
      addFavPending: true
    }),
    deleteFavoriteSuccess: (state, action) => ({
      ...state,
      markers: action.payload.markers,
      favorites: action.payload.favorites,
      addFavPending: false
    }),



    setSearchText: (state, action) => ({
      ...state,
      searchFilter: {
        ...state.searchFilter,
        searchText: action.payload
      }
    }),
    setSearchFilterName: (state, action) => ({
      ...state,
      searchFilter: {
        ...state.searchFilter,
        name: action.payload
      }
    }),


  }
})

window.slice = slice;



const selector = createSelector({
  searchPsPending: 'maps.searchPsPending',
  searchFilter: 'maps.searchFilter',
  searchText: 'maps.searchFilter.searchText',
  bounds: 'maps.bounds',
  tbounds: 'maps.tbounds',
  panToBounds: 'maps.searchFilter.bounds',
  panToCenter: 'maps.searchFilter.center',
  panToZoom: 'maps.searchFilter.zoom',
  favorites: 'maps.favorites',
  markers: 'maps.markers',
  hiddenMarkerIds: 'maps.hiddenMarkerIds',
  openInfoWindowId: 'maps.openInfoWindowId'
});

export const psSelector = createSelector({
  searchPsPending: 'maps.searchPsPending',
  searchFilter: 'maps.searchFilter',
  bounds: 'maps.bounds',
  panToBounds: 'maps.searchFilter.bounds',
  panToCenter: 'maps.searchFilter.center',
  panToZoom: 'maps.searchFilter.zoom',
  markers: 'maps.markers',
  hiddenMarkerIds: 'maps.hiddenMarkerIds',
  openInfoWindowId: 'maps.openInfoWindowId'
});



export const mapDispatchToProps = (dispatch) => bindActionCreators({
  ...slice.actions,
  setLayout: () => ({ type: 'SET_LAYOUT', value: { dashboardType: 'renter', sidebar: false } })
}, dispatch);





const composeContainer = R.pipe(
  connect(selector, mapDispatchToProps),
  withRouter
);
export default composeContainer;


export const composePsContainer = R.pipe(
  connect(createSelector({
    searchPsPending: 'maps.searchPsPending',
    searchFilter: 'maps.searchFilter',
    bounds: 'maps.bounds',
    panToBounds: 'maps.searchFilter.bounds',
    panToCenter: 'maps.searchFilter.center',
    panToZoom: 'maps.searchFilter.zoom',
    markers: 'maps.markers',
    hiddenMarkerIds: 'maps.hiddenMarkerIds',
    openInfoWindowId: 'maps.openInfoWindowId'
  }), mapDispatchToProps),
  withRouter
);

export const composeMoreFiltersContainer = R.pipe(
  connect(createSelector({
    isAuthenticated: 'global.isAuthenticated',
    filter: 'maps.searchFilter',
    amenities: 'maps.features',
    bounds: 'maps.bounds',
    searchFilter: 'maps.searchFilter',
    searchFilterName: 'maps.searchFilter.name',
    savedSearchFilters: 'maps.savedSearchFilters',
    panToBounds: 'maps.searchFilter.bounds',
    panToCenter: 'maps.searchFilter.center',
    panToZoom: 'maps.searchFilter.zoom',
    markers: 'maps.markers',

    setBndsPending: 'maps.setBndsPending',
    setSrchFilterPending: 'maps.setSrchFilterPending',

    hiddenMarkerIds: 'maps.hiddenMarkerIds',
    openInfoWindowId: 'maps.openInfoWindowId'
  }), mapDispatchToProps),
  withRouter
);

export const reducer = slice.reducer;

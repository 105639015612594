import React from 'react'
import { connect } from 'react-redux';
import LoadingOverlayComponent from 'react-loading-overlay';
import createSelector from 'selectorator';
import { ProgressBar } from 'react-bootstrap';

export const LoadingOverlay = ({ children, spinner = {}, ...props }) => {
  const spinnerOpt = (spinner.type !== 'progress') ? true :
    <div className='container w-100'>
      <div className='row w-100'>
        <div className='col w-100'>
          <ProgressBar 
            striped 
            animated
            variant="success"
            now={spinner.progress} 
            label={`${spinner.progress}%`} 
            className='w-100'
          /> 
        </div>
      </div>

    </div>

  return (
    <LoadingOverlayComponent
      styles={{ 
        wrapper: { top: 0, left: 0, right: 0, bottom: 0, position: 'absolute' },
        content: { width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }
      }}
      active={spinner.isActive}
      {...spinner}
      className='h-100'
      spinner={spinnerOpt}
    >
      {children}
    </LoadingOverlayComponent>
  )
};


const selector = createSelector({ spinner: 'spinner', });
export default connect(selector)(LoadingOverlay);
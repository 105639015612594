import React, { useState } from 'react';
import _ from 'lodash';
import { Formik, Field, Form, FieldArray } from "formik";
import {
  Card, CardBody, Row, Col, CardFooter, Button
} from 'reactstrap';

import customFormikField from './customField';
import schema from './schema/uploadDocs.schema';

import './style.scss';

const fileSection = (incomeInfo, setFieldValue, parentIndex, values, index, editReqDocs) => {
  return (
    <Row className={index !== 0 ? 'mt-3' : null} key={index}>
      <Col className='mt-auto mb-auto'>
        <Field
          label={incomeInfo.label}
          name={`reqDocs.${parentIndex}.subCategory.${index}.checked`}
          type="checkbox"
          onChange={e => {
            if (!e.target.checked) {
              setFieldValue(`reqDocs.${parentIndex}.subCategory.${index}.value`, "");
              setFieldValue(`reqDocs.${parentIndex}.subCategory.${index}.checked`, false);
            } else {
              setFieldValue(`reqDocs.${parentIndex}.subCategory.${index}.checked`, true);
            }
          }}
          component={customFormikField}
          checked={values.reqDocs[parentIndex].subCategory[index].checked}
          disabled={!editReqDocs}
        />
      </Col>

      <Col>
        <Field
          name={`reqDocs.${parentIndex}.subCategory.${index}.value`}
          type="select"
          component={customFormikField}
          disabled={!values.reqDocs[parentIndex].subCategory[index].checked || !editReqDocs}
        >
          <option value="">Select</option>
          {_.map(incomeInfo.options, (data, index) => {
            return (
              <option
                key={index}
                value={data.name}>
                {data.label}
              </option>
            );
          })}
        </Field>
      </Col>
    </Row>
  )
}

const renderSubCategories = (data, setFieldValue, parentIndex, values, identifier, editReqDocs) => {

  return (
    <>
      {data.length > 0 &&
        _.map(data, (incomeInfo, index) => {
          if (!incomeInfo.isSelfEmployed) return fileSection(incomeInfo, setFieldValue, parentIndex, values, index, editReqDocs)
        })
      }

      {
        identifier !== "fromResidenceHistory" && <>
          <div className='nestedSubHeading'>If Self Employed</div>
          <div className='selfEmployedContent'>
            {_.map(data, (incomeInfo, index) => {
              if (incomeInfo.isSelfEmployed) return fileSection(incomeInfo, setFieldValue, parentIndex, values, index, editReqDocs)
            })}
          </div>
        </>
      }

    </>
  )
}

const generateCards = (document, setFieldValue, index, values, editReqDocs) => {
  const { category, label } = document;

  if (category === "incomeVerification") {
    return (
      <React.Fragment key={index}>
        <FieldArray
          name="incomeVerification"
          render={() => (
            <Card>
              <div className='subHeaderStyling'>{label}</div>
              <CardBody>
                {renderSubCategories(document.subCategory, setFieldValue, index, values, null, editReqDocs)}
              </CardBody>
            </Card>
          )}
        />
        <br />
      </React.Fragment>
    )
  } else if (category === "validIdentification") {
    return (
      <React.Fragment key={index}>
        <div>
          <Card>
            <div className='subHeaderStyling'>
              <Field
                name={`reqDocs.${index}.checked`}
                label={<>{label} <span className='text-small'>(e.g. Drivers license, Passport etc.)</span></>}
                type="checkbox"
                component={customFormikField}
                checked={values.reqDocs[index].checked}
                disabled={!editReqDocs}
              />
            </div>
          </Card>
        </div>
        <br />
      </React.Fragment>
    )
  } else if (category === "residenceHistory") {
    return (
      <React.Fragment key={index}>
        <FieldArray
          name="residenceHistory"
          render={() => (
            <Card>
              <div className='subHeaderStyling'>{label}</div>
              <CardBody>
                {renderSubCategories(document.subCategory, setFieldValue, index, values, 'fromResidenceHistory', editReqDocs)}
              </CardBody>
            </Card>
          )}
        />
        <br />
      </React.Fragment>
    )
  } else if (category === "criminalOrCivil" || category === "rentersInsurance" || category === "petScreeningReport") {
    return (
      <React.Fragment key={index}>
        <div>
          <Card>
            <div className='subHeaderStyling'>
              <Field
                name={`reqDocs.${index}.checked`}
                label={<>{label} {category === "criminalOrCivil" && <span className='text-small'>(Require Court Records, if applicant has any criminal or civil filings).</span>}</>}
                type="checkbox"
                component={customFormikField}
                checked={values.reqDocs[index].checked}
                disabled={!editReqDocs}
              />
            </div>
          </Card>
        </div>
        <br />
      </React.Fragment>
    )
  } else {
    return (
      <div key={index}>
        <Card>
          <div className='subHeaderStyling'>
            <Field
              name={`reqDocs.${index}.checked`}
              label={label}
              type="checkbox"
              checked={document.checked}
              disabled={category === "others" ? true : false}
              component={customFormikField}
            />
          </div>
        </Card>
      </div>
    )
  }
}

const DocumentCards = ({ documentInfo, onPrevious, onSubmit, editMode }) => {
  const [editReqDocs, setEditReqDocs] = useState(false);

  const toggle = () => setEditReqDocs(!editReqDocs);

  const disableEdit = () => setEditReqDocs(false);

  return (
    <div>
      <Formik
        initialValues={{ reqDocs: documentInfo }}
        validationSchema={schema}
        enableReinitialize
        onSubmit={(values, formikBag) => editMode ? onSubmit(values.reqDocs, formikBag, disableEdit) : onSubmit('required-documents', values.reqDocs)}
        render={({ values, setFieldValue, isValid, errors }) => {
          return (
            <>
              <Form>
                <CardBody>
                  {_.map(values.reqDocs, (document, index) => generateCards(document, setFieldValue, index, values, editMode ? editReqDocs : true))}
                </CardBody>
                {
                  editMode ?
                    <CardFooter>
                      <Row>
                        <Col className="text-right">
                          <Button onClick={toggle} type='button' color='link'>{editReqDocs ? 'Cancel' : 'Edit'}</Button>
                          <Button disabled={!_.isEmpty(errors) || !isValid} color="success" type="submit" style={{ minWidth: '100px' }}>Save</Button>
                        </Col>
                      </Row>
                    </CardFooter>
                    :
                    <CardFooter>
                      <div className="card-footer-actions">
                        <Button
                          type='button'
                          color="primary"
                          className="mr-2"
                          onClick={onPrevious}
                        >
                          Previous
                        </Button>
                        <Button
                          type='submit'
                          color='primary'
                          disabled={!_.isEmpty(errors)}
                        >
                          Next
                        </Button>
                      </div>
                    </CardFooter>
                }
              </Form>
            </>
          )
        }}
      />
    </div >
  )
}

export default DocumentCards;
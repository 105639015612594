import React from 'react';
import _ from 'lodash';
import { Field } from 'formik';
import {
  Card,
  CardBody,
  CardHeader,
  Label,
  FormGroup,
  Input
} from 'reactstrap';
import { FormikCustomField } from 'components/Inputs';



const Checkbox = (props) => (
  <FormGroup check className="checkbox">
    <Input
      className="form-check-input"
      type="checkbox"
      id={props.name}
      name={props.name}
      value={props.value}
      checked={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
    />
    <Label
      check
      className="form-check-label"
      htmlFor={props.name}
    >
      {props.label}
    </Label>
  </FormGroup>
);




export const PermissionCheckboxes = (props) => {
  const {
    label = 'NO LABEL',
    permissions = {},
    fields = {}
  } = props;

  return (
    <Card className='h-100'>
      <CardHeader>{label}</CardHeader>
      <CardBody>
        <FormGroup>
          {
            _.chain(fields)
                .mapValues((val, key) => (
                  <Checkbox
                    key={`${key}_${val}`}
                    name={`permissions.${key}`}
                    label={val}
                    value={permissions[key] || false}
                    onChange={props.onChange}
                    disabled={props.readOnly}
                  />
                ))
                .values()
                .value()
          }
        </FormGroup>
      </CardBody>
    </Card>
  );
}



export const RoleInfo = (props) => (
  <Card>
    <CardHeader>
      {props.label}
    </CardHeader>
    <CardBody>

      <Field
        inline
        label="Name"
        name="name"
        className='form-control'
        component={FormikCustomField}
        value={props.name}
        disabled={props.readOnly}
      />

      <Field
        noValidate
        inline
        label="Description"
        name="description"
        className='form-control'
        component={FormikCustomField}
        value={props.name}
        disabled={props.readOnly}
      />
    </CardBody>
  </Card>
);


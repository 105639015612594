import React from 'react';


const Esign = () => {
  return (
    <div className='container mt-5'>
      <h1 className='text-center' >ESIGN CONSENT TO USE ELECTRONIC RECORDS AND SIGNATURES (this “ESIGN Consent”)</h1>


      <p>As part of your relationship with us, we want to ensure you have all of the information you need to
        effectively manage your Account. Our goal is to provide you with as many options as possible for receiving
        your Account documents. We are required by law to give you certain information “in writing” – which means
        you are entitled to receive it on paper. We may provide this information to you electronically, instead,
        with your prior consent. We also need your general consent to use electronic records and signatures in our
        relationship with you. So, before you use our Electronic Services you must review and consent to the terms
        outlined below.</p>
      <p>This ESIGN Consent applies to all Communications for those Rentivity Products (or other products),
        services (or other services) and Accounts (or other accounts) offered or accessible through Rentivity’s
        Electronic Service, or a mobile application or mobile website used to access the Electronic Service, that
        are not otherwise governed by the terms and conditions of an electronic disclosure and consent.</p>
      <p>Please note that by consenting to receive Communications pursuant to this ESIGN Consent you are giving
        your consent to electronic disclosures and the use of electronic signatures. If you do not want to receive
        the Communications electronically, or do not want to use electronic signatures in connections with your
        transactions with us, you should not agree to the terms of this ESIGN Consent. If you do not consent to
        receiving the Communications electronically, or do not consent to the use of electronic signatures in
        connection with your transactions with us, we will not be able to proceed with the acceptance and
        processing of your online transaction or online Account services.</p>
      <p>In this ESIGN Consent:</p>
      (a) The word “Account” means the account you have with us now or in the future, including ones which
      you open through the Electronic Service.
      <br />(b) The word “Communications” means each disclosure, notice, agreement, fee schedule, statement,
      record, document, transaction history, privacy policies including our Privacy Policy, and other information
      we provide to you, or that you sign or submit or agree to at our request and any amendments thereto.
      <br />(c) The words “Electronic Service” means each and every product and service we offer that you apply
      for, own, use, or access using the Internet, a website, email, messaging services (including text messaging)
      and/or software applications (including applications for mobile or handheld devices), either now or in the
      future. Rentivity has no liability to you with respect to third party products and/or services which you
      apply for, use, or access using the Electronic Service.
      <br />(d) The words “include” and “including,” when used at the beginning of a list of one or more items,
      indicates the list contains examples – the list is not exclusive or exhaustive, and the items in the list
      are only illustrations. They are not the only possible items that could appear in the list. Further, the
      words “include” and “including” shall mean including without limitation.
      <br />(e) “Rentivity Product” means each and every Account, product or service we offer that you apply for,
      own, use, or access, either now or in the future. Rentivity Product includes Electronic Service. However,
      Rentivity Product does not include third party products and/or services which you apply for, use, or access
      using the Electronic Service.
      <br />(f) The words “you”, “your”, “my” and “me” means the person giving this consent, and also each
      authorized signer, authorized representative, occupant, product user and/or service user identified with any
      Rentivity Product that you apply for, use or access.
      <br />(g) The words “we”, “us”, “our”, and “Rentivity” means Rental Technologies, LLC d/b/a Rentivity, and
      each and every current and future affiliate of Rentivity.
      <p>The definition of a word or words in this ESIGN Consent shall not define the same word or words in
        Rentivity’s Privacy Policy or Terms and Conditions, both of which have their own specific definitions and
        are separate and distinct documents from this ESIGN Consent. Likewise, any word or words which are not
        defined in this ESIGN Consent but are defined in Rentivity’s Privacy Policy and/or Terms and Conditions
        shall not be so defined herein. Further, references to Rentivity’s Privacy Policy and/or Terms and
        Conditions in this ESIGN Consent shall not be utilized to interpret any term or provision in either the
        Privacy Policy and/or the Terms and Conditions.</p>
      <p>1. Your Consent to Use Electronic Records and Signatures. Method of Providing Communications to You in
        Electronic Form. Choosing to Receive Communications Electronically or in Writing. Certain Information Must
        Still be Provided in Writing. In our sole discretion, the Communications we provide to you, or that you
        sign or agree to at our request, may be in electronic form (“Electronic Records”). We may also use
        electronic signatures and obtain them from you as part of our transactions with you.</p>
      <p>By consenting to the use of electronic signatures, you agree that Rentivity may use electronic signatures
        in connection with your transactions with us. You agree that we may provide you with any and all
        Communications in electronic format, and that we may discontinue sending paper Communications to you,
        unless and until you withdraw your consent as described below. Your consent to receive electronic
        Communications and transactions includes
        <br />
        (a) Applying for or opening an Account or obtaining a Rentivity
        Product.
        <br />
        (b) All legal and regulatory disclosures and Communications associated with the Account or the
        product or service available through the Electronic Service for your Account. As an example, Rentivity may
        send by email legally required notification regarding our separate Terms and Conditions related to your
        Account or the Electronic Service.
        <br />
        (c) Notices or disclosures about a change in the terms of your Account
        or associated payment feature and responses to notices or requests from you.
        <br />
        (d) our separate Privacy Policy and notices.
        <br /> (e) Account statements for your Account or such other such Communications we may
        include from time to time as part of the participation in the Rentivity Product(s) and/or program(s)
        including electronic notifications. Related documents may include marketing documentation and
        documentation required to be provided to you pursuant to regulatory rules, such as privacy notices and
        other important information regarding your Account.</p>
      <p>Electronic Records may be delivered to you in a variety of ways. All Communications that we provide to
        you in electronic form shall be provided by one or more of the following methods:
        <br />(a) via email.
        <br />(b) to the extent of permissible by law, by access to a website, including our mobile websites.
        <br />(c) via our mobile applications. or (d) by requesting you download a PDF file containing the
        Communication.</p>
      <p>We may always, in our sole discretion, provide you with any Communication in writing, even if you have
        chosen to receive it electronically.</p>
      <p>Sometimes the law, or our agreement with you, requires you to give us a written notice. You must still
        provide these notices to us on paper, unless we specifically tell you in another Communication how you may
        deliver that notice to us electronically.</p>
      <p>There may be certain Communications that by law we are not permitted to deliver to you electronically,
        even with your consent. So long as required by law, we will continue to deliver those Communications to
        you in writing. However, if the law changes in the future and permits any of those Communications to be
        delivered as Electronic Records, this consent shall automatically cover those Communications as well.</p>
      <p>2. <strong>Your Option to Receive Paper Copies.</strong> You should not expect to receive a paper copy of
        any Communication, unless you request it or we otherwise deem it appropriate to do so. You can obtain a
        paper copy of an electronic Communication by printing it yourself or by requesting that we mail you a
        paper copy, provided that such request it made within a reasonable time after we first provided the
        electronic Communication to you. To obtain a paper copy, log into the Electronic Service and print or save
        a copy online or you may mail us a letter at Department of Paper Copies, 3218 East Colonial Drive, Suite
        G, Orlando, Florida 32803 and request a paper version. Upon receipt of your letter, we will notify you of
        the fee to provide a paper copy, unless charging a fee is prohibited by applicable law. You must pay this
        fee in advance, if applicable, and then, we will mail you the paper copy. We reserve the right, but assume
        no obligation, to provide a paper (instead of electronic) copy of any Communication that you have
        authorized us to provide electronically.</p>
      <p>3. Your Consent Covers All Rentivity Products. Privacy Policy. Your consent covers all Communications
        relating to any Rentivity Product. Your consent remains in effect until you give us notice that you are
        withdrawing it.</p>
      <p>From time to time, you may seek to obtain a new Rentivity Product from us. When you do, we may remind you
        that you have already given us your consent to use Electronic Records and signatures. If you decide not to
        use Electronic Records and signatures in connection with the new product or service, your decision does
        not mean you have withdrawn this consent for any other Rentivity Product.</p>
      <p>You agree that we may satisfy our obligation to provide you with an annual copy of our separate Privacy
        Policy by keeping it available for review on https://rentivity.com, or, with prior notice to you, on
        another website where we offer Electronic Services.</p>
      <p>4. You May Withdraw Your Consent at any Time. Consequences of Withdrawing Consent. How to Give Notice of
        Withdrawal. You have the right to withdraw your consent at any time. Please be aware, however, that
        withdrawal of consent may result in the termination of
        <br />(a) your access to and use of our Electronic
        Services.
        <br /> (b) your ability to use certain Rentivity Products.</p>
      <p>Your withdrawal of consent shall become effective after we have had a reasonable opportunity to act upon
        it.</p>
      <p>Depending on the specific Rentivity Product, if you withdraw consent we may charge higher or additional
        fees for that product or for services related to it. Upon receipt of your withdrawal of consent as set
        forth below, we will notify you of the higher or additional fees for that product or for services related
        to it, unless charging a higher or additional fee is prohibited by applicable law. You must pay this
        higher or additional fee in advance, if applicable, for that product or for services related to it.</p>
      <p>To withdraw your consent you must contact us at withdrawconsent@rentivity.com.</p>
      <p>Withdrawing your consent does not change the legal effect, validity, or enforceability of prior
        electronic disclosures but it does prevent you from doing future business with us online.</p>
      <p>Please specify the information you wish to receive in paper form. Be sure to state that you are
        withdrawing your consent, requesting a copy of the disclosures, notices, or other specific item, and
        include your name and mailing address. Your request shall apply only to those specific items you
        designate. Upon receipt of your e-mail, we will notify you of the fee to provide a paper copy of the
        specified information, unless charging a fee is prohibited by applicable law. You must pay this fee in
        advance, if applicable, and then, we will mail you the paper copy.</p>
      <p>5. You Must Keep Your Email or Electronic Address Current with Us. It is your responsibility to provide
        us with true, accurate and complete email address or other electronic address, contact, and other
        information related to this ESIGN Consent and your Account, and to maintain and update promptly any
        changes in this information. You can update information (such as your email address) or if you have given
        us another type of electronic address, such as an electronic address or mobile phone number for SMS text
        messages, by going through the Electronic Service.</p>
      <p>6. Communications in Writing. All Communications in either electronic or paper format from us to you
        shall be considered “in writing”. You should print or download for your records a copy of this ESIGN
        Consent and any other Communication that is important to you. Questions regarding your Account can be
        addressed by sending an email to questions@rentivity.com.</p>
      <p>7. Hardware and Software You Will Need. To access, view, and retain electronic Communications that we
        provide or make available to you, you must have:
        <br />
        (a) a Current Version (defined below) of an Internet
        browser we support.
        <br />
        (b) a connection to the Internet capable of receiving, accessing, displaying, and
        either printing or storing Communications received from us in electronic form via email or by access to
        our website.
        <br />
        (c) a Current Version of a program that accurately reads and displays PDF files (such as
        Adobe Acrobat Reader).
        <br />
        (d) a computer or other device and an operating system capable of accessing and
       supporting all of the above. Your access to this page verifies that your system/device meets these
       requirements. You will also need a printer if you wish to print out and retain records on paper, and
       sufficient electronic storage capacity if you wish to retain records in electronic form on your computer’s
       hard drive or other long term data storage device or service, and the ability to download and store files,
       including PDF files, in order to retain past Communications from Rentivity and/or for future
        reference.</p>
      <p>You must also have an active valid email address (the email address designated by you).</p>
      <p>In some cases, you may also need a specific brand or type of device that can support a particular
        software application, including an application intended for particular mobile or handheld devices.</p>
      <p>By “Current Version,” we mean a version of the software that is currently being supported by its
        publisher.</p>
      <p>We reserve the right to discontinue support of a Current Version of software if, in our sole opinion, it
        suffers from a security flaw or other flaw that makes it unsuitable for use with Rentivity Products or
        services.</p>
      <p>8. Changes to Hardware or Software Requirements. If our hardware or software requirements change, and
        that change would create a material risk that you would not be able to access or retain your Electronic
        Records, we will give you notice of the revised hardware or software requirements. Continuing to use
        Electronic Services after receiving notice of the change is reaffirmation of your consent.</p>
      <p>9. Applicable Law. You acknowledge and agree that your consent to electronic Communications is being
        provided in connection with a transaction affecting interstate commerce that may be subject to the federal
        Electronic Signatures in Global and National Commerce Act (or the Uniform Electronic Transactions Act as
        adopted by the state in which you reside), and that you and we both intend that each of the foregoing Acts
        apply to the fullest extent possible to validate our ability to conduct business with you by electronic
        means. If your state is not governed by either of these Acts, then that state’s laws shall apply to the
        extent not preempted by federal law.</p>
      <p>10. Termination/Changes. We reserve the right, in our sole discretion, to discontinue the provision of
        your electronic Communications, or to terminate or change our separate Terms and Conditions on which we
        provide electronic Communications. We will provide you with notice of any such termination or change as
        required by law.</p>
      <p>11. Communications in Languages other than English. Please note, we may be unable to fulfill and service
        Rentivity Products in a language other than English. Future Communications may be in English only. If you
        are not fluent in English, you should consider obtaining the services of an interpreter or taking other
        steps to ensure you understand the transaction before entering into it and to explain any future
        Communications in English.</p>
      <p>12. Acceptance and Consent. By consenting to this ESIGN Consent, you agree to the following statements:
        <br />(a) I have read, understand and agree to be bound by the terms and conditions described herein and
        consent to receive electronic Communications according to the process described above.
        <br />(b) You and Rentivity and its affiliates agree to conduct transactions in electronic form.
        <br />(c) Certain documents may continue to be delivered to me via U.S. Mail and that in the future some or
        all of these documents may be made available for me to view electronically in accordance with this ESIGN
        Consent.
        <br />(d) My consent to view documents electronically does not automatically expire and is not limited as
        to duration.
        <br />(e) Rentivity and/or its agents may revoke my participation in the Rentivity Product(s) and/or
        program(s) at any time at its and/or their discretion.
        <br />(f) Any third party and/or its agents providing products and/or services to you may revoke my
        participation in its products and/or services at any time at its and/or their discretion.
        <br />(g) Neither Rentivity, its agents nor any third party and/or its agents shall be liable for any loss,
        liability, cost, expense, or claim for acting upon this authorization or arising from my use of the
        Rentivity Product and/or services or other third party product and/or services provided pursuant to this
        ESIGN Consent.
        <br /> (h) Documents that may be provided contain important information or disclosures
        concerning my Account and I agree to review such documents in a timely manner.</p>
      <p>By clicking “I AGREE”, you acknowledge that you have read and agree to the terms set forth in this ESIGN
        Consent and that you give your affirmative consent for us to provide you with electronic Communications,
        as described in this ESIGN Consent, for your Account. You are also confirming that:
        <br />(a) You have reviewed this ESIGN Consent.
        <br />(b) You have the hardware and software described above and have access to the
        necessary equipment and software, you are able to receive, open and view electronic records, and print or
        download a copy of any Communication for your records. By your consent you confirm that you are able to
        access and view records and documents in HTML format.
        <br />(c) You are able to receive and review electronic records.
        <br />(d) You have an active valid email account and the ability to access and view PDF files.
        <br />(e) You are able to print or save a copy of these Communications for your records as they may not be
        accessible online at a later date.</p>
      <p>You are also confirming that you are authorized to, and do, consent on behalf of all the other authorized
        signers, authorized representatives, occupants, product users and/or service users identified with any
        Rentivity Product.</p>
      <p>As of February 23, 2019</p>

    </div>
  );
}

export default Esign;

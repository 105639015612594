import * as Yup from 'yup'


const resetPasswordForm = () => Yup.object().shape({
  password: Yup.string()
    .min(8)
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])/,
      "password must contain one uppercase and one lowercase"
    )
    .required('Password is required'),
    confirmPassword: Yup.string()
    //  eslint-disable-next-line
    .test('equals-password', 'Passwords do not match', function (value) {
      return this.parent.password === value;
    })
    .required('Password confirmation is required')
});

export default resetPasswordForm;

export const initialValues = {
  password: '',
  confirmPassword:''
};


import * as Yup from 'yup';

/* eslint-disable no-template-curly-in-string */
const ContactSchema = () => Yup.object().shape({
  name: Yup.string()
    .required('Name  is required'),
  email: Yup.string()
    .email()
    .required('Email is required'),
  subject: Yup.string()
    .required('Subject is required'),
  message: Yup.string()
    .min(10, 'Too Short')
    .required('Message is required')
});

export default ContactSchema;

export const defaultValues = {
  name: '',
  email: '',
  subject: 'generalInquiry',
  message: ''
};

import createReducer from 'utils/createReducer'



const initialState = {
  fees: [{
    name: '',
    internalDescription: '',
    publicRemark: '',
    fee: 0
  }],
  deposits: [{
    name: '',
    internalDescription: '',
    publicRemark: '',
    type: '',
    deposit: 0
  }]
};

export default createReducer(initialState, {
  ADD_FEE: (state) => ({
    ...state,
    fees: [
      ...state.fees, {
        name: '',
        internalDescription: '',
        publicRemark: '',
        fee: 0
      }
    ]
  }),
  ADD_DEPOSIT: (state) => ({
    ...state,
    deposits: [
      ...state.deposits, {
        name: '',
        internalDescription: '',
        publicRemark: '',
        type: '',
        deposit: 0
      }
    ]
  })
})

import React, { Component } from 'react';
import * as Yup from 'yup';
import {
  Button, CardHeader,
  Row, Col, Card, CardBody,
  Table, Form
} from 'reactstrap';
import { Field, FieldArray, Formik } from 'formik';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _range from 'lodash/range';

import { FormikCustomField } from 'components/Inputs';
import { HeaderWithTooltip } from 'components/HeaderWithTooltip';

import './style.scss';

const initialValues = {
  upfrontPayments: [],
  fees: [],
  deposits: [],
  additionalMonthlyRents: [],
  rentalDueDate: 1
};

const schema = Yup.object().shape({
  upfrontPayments: Yup.array().of(Yup.object().shape({
    name: Yup.string().required('Fee Name is required'),
    internalDescription: Yup.string().required('Internal Description is required'),
    publicRemark: Yup.string().required('Public Remark is required'),
    amount: Yup.number().integer('Amount must be integer').required('Payment Amount is required').typeError('Amount must be number').positive('Amount must be positive'),
    appliesTo: Yup.string().required('Applies To is required')
  })),
  fees: Yup.array().of(Yup.object().shape({
    name: Yup.string().required('Fee Name is required'),
    internalDescription: Yup.string().required('Internal Description is required'),
    publicRemark: Yup.string().required('Public Remark is required'),
    fee: Yup.number().integer('Fee must be integer').required('Fee Amount is required').typeError('Fee must be number').positive('Fee must be positive')
  })),
  additionalMonthlyRents: Yup.array().of(Yup.object().shape({
    name: Yup.string().required('Name is required'),
    internalDescription: Yup.string().required('Internal Description is required'),
    publicRemark: Yup.string().required('Public Remark is required'),
    amount: Yup.number().integer('Amount must be integer').required('Amount Amount is required').typeError('Amount must be number').positive('Amount must be positive')
  })),
  deposits: Yup.array().of(Yup.object().shape({
    name: Yup.string().required('Deposit Name is required'),
    internalDescription: Yup.string().required('Internal Description is required'),
    publicRemark: Yup.string().required('Public Remark is required'),
    type: Yup.string(),
    deposit: Yup.number().integer('Deposit must be integer').required('Deposit amount is required').typeError('Deposit must be number').positive('Deposit must be positive')
  })),
  rentalDueDate: Yup.number().required('Rental Due Date is required')
});


/**
 * Do http request to backend to verify email address and then redirect to appropriate registration step
 */
export default class FeesAndDeposits extends Component {

  render() {
    const { onSubmit, onPrevious, pending } = this.props;

    return (
      <div>
        <Row >
          <Col>
            <Formik
              initialValues={{
                ...initialValues,
                ...this.props.initialValues,
              }}
              validationSchema={schema}
              onSubmit={values => {
                const fees = _map(values.fees, (val) => {
                  return {
                    ...val,
                    amount: parseInt(val.fee)
                  }
                });
                const additionalMonthlyRents = _map(values.additionalMonthlyRents, (val) => {
                  return {
                    ...val,
                    amount: parseInt(val.amount)
                  }
                });
                const deposits = _map(values.deposits, (val) => {
                  return {
                    ...val,
                    amountType: val.type,
                    amount: parseInt(val.deposit)
                  }
                });
                const upfrontPayments = _map(values.upfrontPayments, (val) => {
                  return {
                    ...val,
                    amount: parseInt(val.amount)
                  }
                });

                onSubmit('fees-and-deposits', { fees, deposits, upfrontPayments, rentalDueDate: values.rentalDueDate, additionalMonthlyRents });
              }}
            >
              {
                props => (
                  <Form onSubmit={props.handleSubmit}>
                    <>
                      <Card>
                        <FieldArray
                          name='upfrontPayments'
                        >
                          {
                            (arrayHelpers) => (
                              <>
                                <CardHeader>
                                  <h3>Upfront Payment</h3>
                                  <div className="card-header-actions">
                                    <Button
                                      color='success'
                                      size='sm'
                                      disabled={!_isEmpty(props.errors.upfrontPayments)}
                                      onClick={() => {
                                        arrayHelpers.push({
                                          name: '',
                                          internalDescription: '',
                                          publicRemark: '',
                                          amount: '',
                                          appliesTo: ''
                                        })
                                      }}
                                    >
                                      Add Payment
                                        </Button>
                                  </div>
                                </CardHeader>
                                <CardBody>
                                  <Table
                                    hover
                                    responsive
                                    bordered
                                    className="table-outline mb-0 d-none d-sm-table"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Payment Name</th>
                                        <th>Internal Description</th>
                                        <th>Public Remark</th>
                                        <th>Payment $</th>
                                        <th>Applies To</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        _map(props.values.upfrontPayments, (upfrontPayments, index) => (
                                          <tr key={`${index}_payment`}>
                                            <td>
                                              <Field
                                                name={`upfrontPayments[${index}].name`}
                                                component={FormikCustomField}
                                              />
                                            </td>
                                            <td>
                                              <Field
                                                name={`upfrontPayments[${index}].internalDescription`}
                                                component={FormikCustomField}
                                              />
                                            </td>
                                            <td>
                                              <Field
                                                name={`upfrontPayments[${index}].publicRemark`}
                                                component={FormikCustomField}
                                              />
                                            </td>
                                            <td>
                                              <Field
                                                name={`upfrontPayments[${index}].amount`}
                                                component={FormikCustomField}
                                                type='number'
                                              />
                                            </td>
                                            <td>
                                              <Field
                                                type='select'
                                                name={`upfrontPayments[${index}].appliesTo`}
                                                component={FormikCustomField}
                                              >
                                                <option value="">Select</option>
                                                <option value="FEE">Fees</option>
                                                <option value="RENT">Rents</option>
                                                <option value="DEPOSIT">Deposits</option>
                                              </Field>
                                            </td>
                                            <td>
                                              <Button
                                                color='danger'
                                                // disabled={props.values.upfrontPayments.length < 2}
                                                onClick={() => {
                                                  if (props.values.upfrontPayments.length > 0) {
                                                    arrayHelpers.remove(index)
                                                  }
                                                }}
                                              >
                                                <i className='fa fa-times'></i>
                                              </Button>
                                            </td>
                                          </tr>
                                        ))
                                      }
                                    </tbody>
                                  </Table>
                                  {
                                    props.values.upfrontPayments.length === 0 && <div className='no-rows'>No rows to show</div>
                                  }
                                  <button type='submit' className='d-none'></button>
                                </CardBody>
                              </>
                            )
                          }
                        </FieldArray>
                      </Card>

                      <Card>
                        <FieldArray
                          name='fees'
                        >
                          {
                            (arrayHelpers) => (
                              <>
                                <CardHeader>
                                  <h3>Fees</h3>
                                  <div className="card-header-actions">
                                    <Button
                                      color='success'
                                      size='sm'
                                      disabled={!_isEmpty(props.errors.fees)}
                                      onClick={() => {
                                        arrayHelpers.push({
                                          name: '',
                                          internalDescription: '',
                                          publicRemark: '',
                                          fee: ''
                                        })
                                      }}
                                    >
                                      Add Fee
                                        </Button>
                                  </div>
                                </CardHeader>
                                <CardBody>
                                  <Table
                                    hover
                                    responsive
                                    bordered
                                    className="table-outline mb-0 d-none d-sm-table"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Fee Name</th>
                                        <th>Internal Description</th>
                                        <th>Public Remark</th>
                                        <th>Fee $</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        _map(props.values.fees, (fee, index) => (
                                          <tr key={`${index}_fee`}>
                                            <td>
                                              <Field
                                                name={`fees[${index}].name`}
                                                component={FormikCustomField}
                                              />
                                            </td>
                                            <td>
                                              <Field
                                                name={`fees[${index}].internalDescription`}
                                                component={FormikCustomField}
                                              />
                                            </td>
                                            <td>
                                              <Field
                                                name={`fees[${index}].publicRemark`}
                                                component={FormikCustomField}
                                              />
                                            </td>
                                            <td>
                                              <Field
                                                name={`fees[${index}].fee`}
                                                component={FormikCustomField}
                                                type='number'
                                              />
                                            </td>
                                            <td>
                                              <Button
                                                color='danger'
                                                // disabled={props.values.fees.length < 2}
                                                onClick={() => {
                                                  if (props.values.fees.length > 0) {
                                                    arrayHelpers.remove(index)
                                                  }
                                                }}
                                              >
                                                <i className='fa fa-times'></i>
                                              </Button>
                                            </td>
                                          </tr>
                                        ))
                                      }
                                    </tbody>
                                  </Table>
                                  {
                                    props.values.fees.length === 0 && <div className='no-rows'>No rows to show</div>
                                  }
                                  <button type='submit' className='d-none'></button>
                                </CardBody>
                              </>
                            )
                          }
                        </FieldArray>
                      </Card>

                      <Card>
                        <FieldArray
                          name='additionalMonthlyRents'
                        >
                          {
                            arrayHelpers => (
                              <>
                                <CardHeader>
                                  <HeaderWithTooltip
                                    title={'Additional Monthly Rent'}
                                    tooltip={'For E.g Pet Rent, A/C Filter Etc...'}
                                  />
                                  <div className="card-header-actions">
                                    <Button
                                      color='success'
                                      size='sm'
                                      disabled={!_isEmpty(props.errors.additionalMonthlyRents)}
                                      onClick={() => {
                                        arrayHelpers.push({
                                          name: '',
                                          internalDescription: '',
                                          publicRemark: '',
                                          amount: ''
                                        })
                                      }}
                                    >
                                      Add Amount
                                    </Button>
                                  </div>
                                </CardHeader>
                                <CardBody>
                                  <Table
                                    hover
                                    responsive
                                    bordered
                                    className="table-outline mb-0 d-none d-sm-table"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Name</th>
                                        <th>Internal Description</th>
                                        <th>Public Remark</th>
                                        <th>Amount $</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        _map(props.values.additionalMonthlyRents, (fee, index) => (
                                          <tr key={`${index}_additionalMonthlyRents`}>
                                            <td>
                                              <Field
                                                name={`additionalMonthlyRents[${index}].name`}
                                                component={FormikCustomField}
                                              />
                                            </td>
                                            <td>
                                              <Field
                                                name={`additionalMonthlyRents[${index}].internalDescription`}
                                                component={FormikCustomField}
                                              />
                                            </td>
                                            <td>
                                              <Field
                                                name={`additionalMonthlyRents[${index}].publicRemark`}
                                                component={FormikCustomField}
                                              />
                                            </td>
                                            <td>
                                              <Field
                                                name={`additionalMonthlyRents[${index}].amount`}
                                                component={FormikCustomField}
                                                type='number'
                                              />
                                            </td>
                                            <td>
                                              <Button
                                                color='danger'
                                                onClick={() => {
                                                  if (props.values.additionalMonthlyRents.length > 0) {
                                                    arrayHelpers.remove(index)
                                                  }
                                                }}
                                              >
                                                <i className='fa fa-times'></i>
                                              </Button>
                                            </td>
                                          </tr>
                                        ))
                                      }
                                    </tbody>
                                  </Table>
                                  {
                                    props.values.additionalMonthlyRents.length === 0 && <div className='no-rows'>No rows to show</div>
                                  }
                                  <button type='submit' className='d-none'></button>
                                </CardBody>
                              </>
                            )
                          }
                        </FieldArray>
                      </Card>

                      <Card>
                        <FieldArray
                          name='deposits'
                        >
                          {
                            (arrayHelpers) => (
                              <>
                                <CardHeader>
                                  <h3>Deposits</h3>
                                  <div className="card-header-actions">
                                    <Button
                                      color='success'
                                      size='sm'
                                      disabled={!_isEmpty(props.errors.deposits)}
                                      onClick={() => {
                                        arrayHelpers.push({
                                          name: '',
                                          internalDescription: '',
                                          publicRemark: '',
                                          type: 'Fixed',
                                          deposit: ''
                                        })
                                      }}
                                    >
                                      Add Deposit
                                        </Button>
                                  </div>
                                </CardHeader>
                                <CardBody>
                                  <Table
                                    hover
                                    responsive
                                    bordered
                                    className="table-outline mb-0 d-none d-sm-table"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Deposit Name</th>
                                        <th>Internal Description</th>
                                        <th>Public Remark</th>
                                        <th>Type</th>
                                        <th>Deposit $</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        _map(props.values.deposits, (fee, index) => (
                                          <tr key={`${index}_fee`}>
                                            <td>
                                              <Field
                                                name={`deposits[${index}].name`}
                                                component={FormikCustomField}
                                              />
                                            </td>
                                            <td>
                                              <Field
                                                name={`deposits[${index}].internalDescription`}
                                                component={FormikCustomField}
                                              />
                                            </td>
                                            <td>
                                              <Field
                                                name={`deposits[${index}].publicRemark`}
                                                component={FormikCustomField}
                                              />
                                            </td>
                                            <td>
                                              <Field
                                                type='select'
                                                name={`deposits[${index}].type`}
                                                noValidate
                                                component={FormikCustomField}
                                              >
                                                <option value="Fixed">Fixed</option>
                                                <option value="% of Rent">% of Rent</option>
                                              </Field>
                                            </td>
                                            <td>
                                              <Field
                                                name={`deposits[${index}].deposit`}
                                                component={FormikCustomField}
                                                type='number'
                                              />
                                            </td>
                                            <td>
                                              <Button
                                                color='danger'
                                                // disabled={props.values.deposits.length < 2}
                                                onClick={() => {
                                                  arrayHelpers.remove(index)
                                                }}
                                              >
                                                <i className='fa fa-times'></i>
                                              </Button>
                                            </td>
                                          </tr>
                                        ))
                                      }
                                    </tbody>
                                  </Table>
                                  {
                                    props.values.deposits.length === 0 && <div className='no-rows'>No rows to show</div>
                                  }
                                  <button
                                    type='submit'
                                    className='d-none'
                                  ></button>
                                </CardBody>
                              </>
                            )
                          }
                        </FieldArray>
                      </Card>

                      <Card>
                        <CardHeader>
                          <HeaderWithTooltip
                            title={'Rent Due Date'}
                            tooltip={'This is the day of the month, every month after move-in, when rent will be due.'}
                          />
                        </CardHeader>
                        <CardBody>
                          <div className='due-date'>
                            Day
                            <Field
                              name='rentalDueDate'
                              component={FormikCustomField}
                              placeholder='Select Due Date'
                              type='select'
                              onChange={props.handleChange}
                              value={props.values.rentalDueDate}
                            >
                              {_range(1, 32).map((el) => <option value={el} key={el}>{el}</option>)}
                            </Field>
                            of every month.
                          </div>
                        </CardBody>
                      </Card>


                      <>
                        <Button
                          type='button'
                          color="primary"
                          className="mr-2"
                          onClick={onPrevious}
                        >
                          Previous
                        </Button>
                        <Button
                          type='submit'
                          color='primary'
                          disabled={!_isEmpty(props.errors.fees) || !_isEmpty(props.errors.deposits) || !_isEmpty(props.errors.upfrontPayments) || pending}
                        >
                          Next
                        </Button>
                      </>
                    </>
                  </Form>
                )
              }
            </Formik>
          </Col>
        </Row>

      </div>
    )
  }
}
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Loadable from 'react-loadable';
import io from 'socket.io-client';
import * as R from 'ramda';
import _ from 'lodash';

import { AuthContext, ToastContext, SocketContext } from '../../contexts';
import ProtectedRoute from '../../components/ProtectedRoute';
import Login from '../Common/Login';
import Partners from "../Common/MarketPlace";
import Registration from '../Common/Registration/index';
import RequestResetPassword from '../Common/ResetPassword/components/RequestResetPassword/index';
import SetPassword from '../Common/ResetPassword/components/SetPassword/index';
import PrivacyPolicy from '../Common/PrivacyPolicy';
import Esign from '../Common/Esign';
import TermsAndConditions from '../Common/TermsAndConditions';
import Contact from '../Common/Contact';
import FAQ from '../Common/FAQ';
import HowItWorks from '../Common/HowItWorks';
import TermsAndConditionsPage from '../Common/TermsAndConditionsPage';
import GlobalModal from './components/GlobalModal';
import LoadingOverlay from '../../components/LoadingOverlay';
import ErrorBoundary from '../../components/ErrorBoundaries';

import * as services from '../../utils/services'
import * as actions from './store/actions';
import selectors from './store/selectors';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';


const debug = require('debug')('app');
const socketDebug = debug.extend('socket.io')

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import('components/DefaultLayout'),
  loading
});

const initSocket = (jwt, user) => {
  if (window.socket) {
    return window.socket;
  }

  const socket = window.socket = io({ path: '/api/rentivity/websocket', transports: ['websocket'] }, { forceNew: true });
  return socket;
};

const createNamespacedSocket = () => async (namespace) => {
  debug(`connecting to: /modal-${this.props.user._id}`)
  return io(namespace, {
    path: '/api/rentivity/websocket',
    transports: ['websocket']
  },
    {
      forceNew: true
    });
};

class App extends Component {
  static defaultProps = {
    isLoading: false
  }

  state = {
    socket: null,
    nssocket: null,
    modal: {
      show: false
    },
    spinner: {
      active: false,
      text: 'Loading...',
      fadeSpeed: 1000,
      spinner: true
    }
  }

  toggleModalClose = () => this.setState({ modal: { show: false } })

  componentDidMount = async () => {
    const { isAuthenticated, userType, user, dispatch } = this.props;
    // For accessing inner socket scope
    window.current = this;
    if (isAuthenticated) {

      await dispatch(actions.checkSession());

      if (userType !== 'renter') {
        await dispatch({ type: 'SET_LAYOUT', value: { dashboardType: 'admin' } })
      }

      if (userType === 'renter' && !_.isNil(user.renterApplication)) {
        await dispatch(actions.fetchRenterApplication(user._id));
      }
    }
  }

  componentWillUnmount = async () => {
    const { socket } = this.state;

    if (socket) {
      // this.state.socket.removeAllListeners();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { userType, user, jwt, dispatch } = nextProps;

    if (!prevState.isAuthenticated && nextProps.isAuthenticated) {
      debug('init websocket')

      if (userType === 'renter' && !_.isNil(user.renterApplication)) {
        dispatch(actions.fetchRenterApplication(user._id))
      }

      if (!prevState.socket) {

        let socket = initSocket(jwt, user);
        socketDebug(`subscribing to ${user.userType}-${user._id}`);

        socket.on('disconnect', reason => {
          console.log('disconnected', reason);
          socket.connect();
          // window.current.setState({ socket: initSocket(jwt, user) });
        });
        socket.on(`${user.userType}-${user._id}`, (data = {}) => {
          socketDebug(`recieved: ${user.userType}-${user._id}`, data);
          if (data.type === 'update_session') {
            dispatch(actions.checkSession());
          }
        });

        return { ...nextProps, socket }
      }
    }

    if (prevState.isAuthenticated && !nextProps.isAuthenticated && prevState.socket) {
      prevState.socket.close();
      debug('closed socketio connection')
      return { ...nextProps, socket: null }
    }

    return nextProps;
  }

  componentWillUnmount = () => {
    const { socket } = this.state;
    if (socket) socket.close();
  }

  showSuccessToast = (msg = 'Success', options = {}) => this.props.dispatch(actions.showSuccessToast(msg, options));

  showErrorToast = (message = 'Error', options = {}) => {
    return this.props.dispatch(actions.showErrorToast(message, options))
  };

  showSpinner = (opts = {}) => {
    return this.props.dispatch({ type: 'showSpinner', payload: { ...opts } })
  }

  hideSpinner = (opts = {}) => {
    return this.props.dispatch({ type: 'hideSpinner', payload: { ...opts } })
  }

  get childContext() {
    const { nssocket, socket } = this.state;
    const { isAuthenticated, userType, dispatch, user } = this.props;

    return {
      isAuthenticated: isAuthenticated || false,
      nssocket,
      socket,

      userType: userType || 'renter',
      updateSession: () => {
        if (isAuthenticated) dispatch(actions.checkSession());
      },
      fetchRenterApplication: () => {
        return dispatch(actions.fetchRenterApplication(user._id));
      },
      user
    }
  }

  get toastChildContext() {
    return {
      showSuccessToast: this.showSuccessToast,
      showErrorToast: this.showErrorToast,
      showSpinner: this.showSpinner,
      hideSpinner: this.hideSpinner
    }
  }

  get socketContext() {
    const { socket } = this.state;
    const { jwt } = this.props;

    return {
      socket,
      createNamespacedSocket: createNamespacedSocket(jwt)
    }
  }

  doLogout = () => this.props.dispatch(actions.doLogout())

  handleTocAccept = () => services.updateTermsAndConditions()
    .then(() => this.props.dispatch(actions.checkSession()))

  get navConfig() {
    // remove routes for renter without lease
    const { userType, isAuthenticated, navConfig } = this.props;
    const routeTypeToLoad = ((userType === 'client' || userType === 'pm') && isAuthenticated) ? 'admin' : 'renter';

    if (routeTypeToLoad === 'renter' && !_.get(this.props, 'user.metadata.hasLease')) {
      const routesToRemove = ['/myrental', '/renter-profile'];
      const renterNavConfig = _.chain(navConfig.renter.items)
        .filter((el) => _.findIndex(routesToRemove, (rm) => el.url === rm) < 0)
        .value();

      return {
        ...navConfig,
        [routeTypeToLoad]: {
          ...navConfig[routeTypeToLoad],
          items: renterNavConfig
        }
      }
    }
    return navConfig;
  }

  // removejscssfile = (filename, filetype) => {
  //   var targetelement = (filetype == "js") ? "script" : (filetype == "css") ? "link" : "none" //determine element type to create nodelist from
  //   var targetattr = (filetype == "js") ? "src" : (filetype == "css") ? "href" : "none" //determine corresponding attribute to test for
  //   var allsuspects = document.getElementsByTagName(targetelement)
  //   for (var i = allsuspects.length; i >= 0; i--) { //search backwards within nodelist for matching elements to remove
  //     if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null && allsuspects[i].getAttribute(targetattr).indexOf(filename) != -1)
  //       allsuspects[i].parentNode.removeChild(allsuspects[i]) //remove element by calling parentNode.removeChild()
  //   }
  // }

  /**
  * @method enableChatbotScript conditional rendering of chatbot
  */
  enableChatbotScript = () => {
    const { isAuthenticated, user, } = this.props;
    
    if (isAuthenticated) {
      if (_.isEmpty(user.leases)) {
        const script = document.createElement("script")
        script.src = process.env.REACT_APP_HUB_SPOT_URL;
        script.async = true;
        document.body.appendChild(script);
        script.onload = () => {console.log('Live Chat Enabled')}
     
      }
    } else {
      const script = document.createElement("script")
      script.src = process.env.REACT_APP_HUB_SPOT_URL;
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => {console.log('Live Chat Enabled')}
   
    }
  }

  render() {
    const { userType, isAuthenticated, sessionPending, tosAccepted, location } = this.props;
    const { modal } = this.state;

    const containerStyle = { zIndex: 1999 };
    const routeTypeToLoad = ((userType === 'client' || userType === 'pm') && isAuthenticated) ? 'admin' : 'renter';
    const routes = (routeTypeToLoad === 'admin') ? this.props.routes.admin : this.props.routes.renter;
    const homePath = (routeTypeToLoad === 'admin') ? "/dashboard" : '/home';

    return (
      <ErrorBoundary>
        <AuthContext.Provider value={this.childContext}>
          <ToastContext.Provider value={this.toastChildContext}>
            <SocketContext.Provider value={this.socketContext}>
              {this.enableChatbotScript()}
              <ToastContainer
                position="top-right"
                autoClose={5000}
                style={containerStyle}
              />

              <LoadingOverlay>

                {
                  isAuthenticated && !sessionPending &&
                  <TermsAndConditions
                    isOpen={!tosAccepted}
                    onSubmit={this.handleTocAccept}
                    onLogout={this.doLogout}
                  />
                }

                <Router>
                  <DefaultLayout
                    {...this.props}
                    navigation={this.navConfig[routeTypeToLoad]}
                    routes={this.props.routes[routeTypeToLoad]}
                    onLogout={this.doLogout}
                  >

                    <GlobalModal
                      {...modal}
                      onHide={this.toggleModalClose}
                    />
                    <Switch>
                      <Route
                        path="/login"
                        name="Login"
                        render={Login}
                      />
                      <Route
                        path="/alliance"
                        name="Partners"
                        render={Partners}
                      />
                      <Route
                        path='/register'
                        render={Registration}
                      />
                      <Route
                        path='/reset-password'
                        component={RequestResetPassword}
                      />
                      <Route
                        path='/privacy-policy'
                        render={PrivacyPolicy}
                      />
                      <Route
                        path='/e-sign-agreement'
                        render={Esign}
                      />
                      <Route
                        path='/contact-us'
                        component={Contact}
                      />
                      <Route
                        path='/faq'
                        component={FAQ}
                      />
                      <Route
                        path='/how-it-works'
                        component={HowItWorks}
                      />
                      <Route
                        path='/terms-and-conditions'
                        render={TermsAndConditionsPage}
                      />
                      <Route
                        path='/set-password'
                        component={SetPassword}
                      />

                      {
                        _.map([...routes], (route, idx) => {
                          if (route.component) {
                            return (
                              <ProtectedRoute
                                {...route}
                                public={route.public}
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={(props) =>
                                  <route.component
                                    isAuthenticated={isAuthenticated}
                                    {...props}
                                  />
                                }
                              />
                            );
                          }
                          return null;
                        })
                      }

                      <Redirect
                        to={{
                          pathname: _.get(location, 'state.from', homePath)
                        }}
                      />

                    </Switch>
                  </DefaultLayout>
                </Router>
              </LoadingOverlay>
            </SocketContext.Provider>
          </ToastContext.Provider>
        </AuthContext.Provider>
      </ErrorBoundary>
    );
  }
}

const composeComponent = R.compose(
  connect(selectors),
);

export default composeComponent(App);

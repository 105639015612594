import _ from 'lodash';
import createReducer from 'utils/createReducer';

const applyFilters = (propertySessions = [], filtersToApply = {}, misc = {}) => {
  const { amenities = [], propertyType = [], lotSize = [] } = misc;
  let props = _.cloneDeep(propertySessions);

  // filter properties for the dropdown filters
  for (let key in filtersToApply) {
    if (key === 'beds' && filtersToApply[key] !== '-1' && filtersToApply[key] !== -1) {
      props = _.filter(props, ({ property }) => property.beds >= parseInt(filtersToApply[key]))
    }
    if (key === 'baths' && filtersToApply[key] !== '-1' && filtersToApply[key] !== -1) {
      props = _.filter(props, ({ property }) => property.baths >= parseInt(filtersToApply[key]))
    }

    // TODO: bid price
    if (key === 'price' && filtersToApply[key] !== '-1' && filtersToApply[key] !== -1) {
      if (filtersToApply[key] !== 'custom' && _.isArray(filtersToApply.customPrice) && filtersToApply.customPrice.length === 2) {
        const [min, max] = filtersToApply.customPrice;
        props = _.filter(props, ({ fixedPrice }) => fixedPrice >= parseInt(min) && fixedPrice < parseInt(max))
      }
      else {
        props = _.filter(props, ({ fixedPrice }) => fixedPrice < parseInt(filtersToApply[key]))
      }
    }

    if (key === 'eviction') {
      if (filtersToApply[key] === 'yes' || filtersToApply[key] === true) {
        props = _.filter(props, ({ eviction }) => eviction === true)
      }

      if (filtersToApply[key] === 'no' || filtersToApply[key] === false) {
        props = _.filter(props, ({ eviction }) => eviction === false)
      }
    }

    if (key === 'bankruptcy') {
      if (filtersToApply[key] === 'yes' || filtersToApply[key] === true) {
        props = _.filter(props, ({ bankruptcy }) => bankruptcy === true)
      }

      if (filtersToApply[key] === 'no' || filtersToApply[key] === false) {
        props = _.filter(props, ({ bankruptcy }) => bankruptcy === false)
      }
    }




    if (key === 'available' && filtersToApply[key] !== '-1' && filtersToApply[key] !== -1) {
      props = _.filter(props, ({ type }) => type === filtersToApply[key])
    }

    if (key === 'pets' && filtersToApply[key] !== '-1' && filtersToApply[key] !== -1) {
      const validValues = (filtersToApply[key] === 'all') ? ['cat', 'dog', 'catdog', 'all'] :
        (filtersToApply[key] === 'catdog') ? ['cat', 'dog', 'catdog'] : [filtersToApply[key]];

      props = _.filter(props, ({ property }) => {
        return _.findIndex(validValues, (val) => val === property.petPolicy) > -1
      })
    }



    if (key === 'minimumResidentScore') {
      if (!_.isNaN(parseInt(filtersToApply[key])) && parseInt(filtersToApply[key]) !== -1) {
        console.log('parsing mimumresidentscore', filtersToApply[key]);
        props = _.filter(props, ({ minimumResidentScore }) => {
          console.log('mim', minimumResidentScore);
          return minimumResidentScore <= parseInt(filtersToApply[key])
        });
      }
      if (filtersToApply[key] === '700plus') {
        props = _.filter(props, ({ minimumResidentScore }) => minimumResidentScore >= parseInt(700));
      }
    }
  }

  // filter properties more-filter amenities checkboxes
  if (amenities.length > 0) {
    props = _.filter(props, (prop) => {
      return _.difference(amenities, _.map(prop.property.features, 'name')).length === 0
    })
  }

  if (propertyType.length > 0) {
    props = _.filter(props, ({ property: { propertyType: pt } }) => {
      return _.findIndex(propertyType, (t) => t === pt) > -1;
    });
  }



  if (lotSize.length > 0) {
    if (lotSize.length === 1) {
      props = _.filter(props, ({ property }) => property.livingArea >= parseInt(lotSize[0]))
    }

    if (lotSize.length === 2) {
      const [min, max] = lotSize;
      // eslint-disable-next-line
      if (max == 6000) {
        props = _.filter(props, ({ property }) => {
          return property.livingArea >= parseInt(min);
        });
      }
      else {
        props = _.filter(props, ({ property }) => {
          return property.livingArea >= parseInt(min) && property.livingArea <= parseInt(max)
        });
      }

    }
  }


  const invisibles = _.chain(propertySessions)
    .differenceBy(props, '_id')
    .map((el) => _.set(el, 'visible', false))
    .value();

  return [..._.map(props, (el) => _.set(el, 'visible', true)), ...invisibles];
}

const initialState = {
  pending: false,
  zoom: 8,
  success: false,
  error: null,
  features: [],
  place: null,
  center: {
    "lat": 28.338741673497953,
    "lng": -81.92900052428526
  },
  favorites: [],
  searchFilters: [],
  rightSideItems: [],
  searchFilter: {
    searchText: '',
    available: -1,
    beds: -1,
    baths: -1,
    floors: -1,
    creditScore: -1,
    propertyType: [],
    amenities: [],
    pets: -1,
    lotSize: [200],
    price: -1,
    eviction: -1,
    bankruptcy: -1,
    customPrice: -1,
    minimumResidentScore: -1,
    zoom: 10
  },
  propertySessions: [],
  filteredPs: [],
};


export default createReducer(initialState, {

  _SET_SEARCH_FILTER: (state, action) => {

    // if morefilter changes selection to nothing
    if (_.isEmpty(action.value)) {
      return {
        ...state,
        searchFilter: {
          ...initialState.searchFilter
        },
        propertySessions: state.propertySessions,
        filteredPs: state.propertySessions,
        rightSideItems: state.propertySessions
      }
    }

    const { amenities = [], propertyType = [], lotSize = [], customPrice = 200, zoom = state.zoom, ...filter } = action.value;
    const { bounds } = action;

    const filtersToApply = _.chain(filter)
      .toPairs()
      .filter(([, val]) => val !== -1)
      .filter(([, val]) => _.isArray(val) ? !_.isEmpty(val) : true)
      .fromPairs()
      .value();

    let props = applyFilters(action.propertySessions || state.propertySessions, filtersToApply, action.value);

    // filter by bounds
    const { rightSideProps } = _.reduce(props, (acc, val) => {
      const { property: { location: { coordinates: [lng, lat] } } } = val;
      if (acc.rightSideProps.length >= 7) {
        return acc
      }
      return {
        rightSideProps: bounds.contains({ lat, lng }) ? [...acc.rightSideProps, val] : acc.rightSideProps
      };
    }, { rightSideProps: [] });


    return ({
      ...state,
      zoom,
      place: _.get(action, 'value.searchText', ''),
      searchFilter: {
        ...initialState.searchFilter,
        ...filtersToApply,
        lotSize,
        amenities,
        propertyType,
        _id: _.get(action.value, '_id', -1),
        customPrice,
        searchText: _.get(action, 'value.searchText', ''),
      },
      rightSideItems: rightSideProps.slice(0, 7),
      filteredPs: props
    })
  },

  _SET_SEARCH_TEXT: (state, action) => ({
    ...state,
    place: action.value,
    searchFilter: {
      ...state.searchFilter,
      searchText: action.value
    }
  }),
  _SET_ZOOM: (state, action) => ({
    ...state,
    zoom: action.value
  }),
  _SET_CENTER: (state, action) => ({
    ...state,
    zoom: action.value.zoom,
    center: {
      lat: action.value.lat,
      lng: action.value.lng
    }
  }),

  _SET_PLACE: (state, action) => ({
    ...state,
    place: _.get(action.value, 'formatted_address'),
    searchFilter: {
      searchText: _.get(action.value, 'formatted_address')
    }
  }),

  _APPLY_BOUNDS: (state, { bounds }) => {
    // const rightSideProps = _.filter(state.filteredPs, ({ property: { location: { coordinates: [lng, lat] } } }) => {
    //   return bounds.contains({ lat, lng });
    // });

    return {
      ...state,
      rightSideItems: state.filteredPs
    }
  },




  _DELETE_FAVORITE: (state, action) => ({
    ...state,
    pending: true,
    success: false,
    error: null,
  }),
  _DELETE_FAVORITE_SUCCESS: (state, action) => ({
    ...state,
    pending: false,
    success: true,
    filteredPs: _.map(state.filteredPs, (ps) => {
      return (ps._id === action.value) ? {
        ...ps,
        favorite: false,
      } : ps
    }),
    rightSideItems: _.map(state.rightSideItems, (ps) => {
      return (ps._id === action.value) ? {
        ...ps,
        favorite: false,
      } : ps
    }),
    propertySessions: _.map(state.propertySessions, (ps) => {
      return (ps._id === action.value) ? {
        ...ps,
        favorite: true,
      } : ps
    }),
    error: null,
  }),
  _DELETE_FAVORITE_ERROR: (state, action) => {
    return {
      ...state,
      pending: false,
      success: false,
      error: action.error,
    }
  },




  _ADD_FAVORITE: (state, action) => ({
    ...state,
    pending: true,
    success: false,
    error: null,
  }),
  _ADD_FAVORITE_SUCCESS: (state, action) => ({
    ...state,
    pending: false,
    success: true,
    filteredPs: _.map(state.filteredPs, (ps) => {
      return (ps._id === action.value) ? {
        ...ps,
        favorite: true,
      } : ps
    }),
    rightSideItems: _.map(state.rightSideItems, (ps) => {
      return (ps._id === action.value) ? {
        ...ps,
        favorite: true,
      } : ps
    }),
    propertySessions: _.map(state.propertySessions, (ps) => {
      return (ps._id === action.value) ? {
        ...ps,
        favorite: true,
      } : ps
    }),
    error: null,
  }),
  _ADD_FAVORITE_ERROR: (state, action) => {
    return {
      ...state,
      pending: false,
      success: false,
      error: action.error,
    }
  },



  _GET_FAVORITES: (state, action) => ({
    ...state,
    pending: true,
    success: false,
    error: null,
  }),
  _GET_FAVORITES_SUCCESS: (state, action) => ({
    ...state,
    pending: false,
    success: true,
    favorites: action.value,
    error: null,
  }),
  _GET_FAVORITES_ERROR: (state, action) => {
    return {
      ...state,
      pending: false,
      success: false,
      error: action.error,
    }
  },


  _SEARCH_PROPERTY_SESSION: (state, action) => ({
    ...state,
    query: action.query,
    select: action.select,
    pending: true,
    success: false,
    error: null,
  }),
  _SEARCH_PROPERTY_SESSION_SUCCESS: (state, action) => {
    const features = _.chain(action.value)
      .map('property.features')
      .flatten()
      .uniqBy('name')
      .value();
    const properties = _.chain(action.value)
      .map((el) => {
        return {
          ...el,
          location: {
            lat: _.get(el, 'property.location.coordinates.1'),
            lng: _.get(el, 'property.location.coordinates.0'),
          }
        }
      })
      .value()

    const ps = _.uniqBy([...state.propertySessions, ...properties], '_id');
    const filtered = applyFilters([...ps], state.searchFilter, state.searchFilter);

    return ({
      ...state,
      pending: false,
      success: true,
      propertySessions: properties,
      features,
      filteredPs: filtered,
      rightSideItems: _.filter(_.filter(filtered, { visible: true }), (el) => {
        return action.bounds.contains({ ...el.location });
      })
    })
  },
  _SEARCH_PROPERTY_SESSION_ERROR: (state, action) => {
    return {
      ...state,
      pending: false,
      success: false,
      error: action.error,
    }
  },



  _SAVE_SEARCH_FILTER: (state, action) => {
    console.log(action.value);;

    return {
      ...state,

      pending: true
    }
  },
  SAVE_SEARCH_FILTER_SUCCESS: (state, action) => {
    const index = _.findIndex(state.searchFilters, { _id: action.value._id });
    const searchFilters = (index < 0) ? [...state.searchFilters, action.value] :
      _.map(state.searchFilters, (el, i) => i !== index ? el : action.value);

    return {
      ...state,
      searchFilter: action.value,
      searchFilters,
      error: false,
      success: true,
      pending: false
    }
  },
  SAVE_SEARCH_FILTER_ERROR: (state, action) => {
    return {
      ...state,
      success: false,
      error: action.error,
      pending: false
    }
  },
  DELETE_SEARCH_FILTER: (state, action) => {
    return {
      ...state,
      pending: true
    }
  },
  DELETE_SEARCH_FILTER_SUCCESS: (state, action) => {
    return {
      ...state,
      searchFilter: initialState.searchFilter,
      searchFilters: _.filter(state.searchFilters, (filter) => filter._id !== action.value._id),
      pending: false,
      success: true
    }
  },
  DELETE_SEARCH_FILTER_ERROR: (state, action) => { },



  GET_SEARCH_FILTERS: (state) => {
    return {
      ...state,
      pending: true
    }
  },
  GET_SEARCH_FILTERS_SUCCESS: (state, action) => {
    return {
      ...state,
      pending: false,
      success: true,
      searchFilters: action.value
    }
  },
  GET_SEARCH_FILTERS_ERROR: (state, action) => {
    return {
      ...state,
      pending: false,
      success: false,
      error: action.error,
      searchFilters: action.value
    }
  },

  GET_FEATURES: (state) => {
    return {
      ...state,
      pending: true
    }
  },
  GET_FEATURES_SUCCESS: (state, action) => {
    return {
      ...state,
      pending: false,
      success: true,
      features: _.uniqBy([...action.value, ...state.features], 'name')
    }
  },
  GET_FEATURES_ERROR: (state, action) => {
    return {
      ...state,
      pending: false,
      success: false,
      error: action.error,
    }
  }
});

import React, { Component } from 'react';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';

import { Button, Row, Col, Card, CardBody, CardHeader, CardFooter, Modal, ModalHeader, ModalBody } from 'reactstrap';
import LeaseWrapupOrderer from './LeaseWrapupOrderer';
import { fetchLeaseWrapupOrdererSteps } from '../../../../utils/services';
import infoGif from '../../../../assets/img/gifs/lwSteps.gif';

import './style.scss';

/**
 * @class orderLeaseWrapUp is used to handle the lease wrapup steps re-arrangement if any.
 */
export default class OrderLeaseWrapUp extends Component {

  state = {
    steps: [],
    editLeaseSteps: false,
    modal: false
  }

  async componentWillMount() {
    const { initialValues } = this.props;

    if (initialValues) {
      this.setState({
        steps: _sortBy(initialValues, ['stepNumber'])
      })
    } else {
      const { data } = await fetchLeaseWrapupOrdererSteps();

      this.setState({
        steps: _sortBy(data, ['stepNumber'])
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.initialValues !== this.props.initialValues) {
      this.setState({
        steps: nextProps.initialValues
      })
    }
  }

  /**
   * @method handleSubmit is used to submit the steps while creating an org.
   */
  handleSubmit = async () => {
    await this.props.onSubmit('lease-wrapup-steps', this.state.steps);
    // await this.props.createOrg()
  }

  /**
   * @method rearrangeSteps is used to re-arrange the steps when re-ordered.
   */
  rearrangeSteps = steps => this.setState({ steps });

  /**
   * @method handleEditLeaseSteps is used to handle the editing status of the steps.
   * @param mode is to determine what needs to happen when either one of edit/cancel is clicked.
   */
  handleEditLeaseSteps = (mode) => {
    if (mode === 'cancel') {
      return this.setState({
        editLeaseSteps: false,
        steps: this.props.initialValues
      })
    }

    this.setState(prevState => ({ editLeaseSteps: !prevState.editLeaseSteps }));
  };

  /**
   * @method disableEdit is used to disable the edit status.
   */
  disableEdit = () => this.setState({ editLeaseSteps: false });

  /**
   * @method handleEditSubmit is used to handle the submission of steps while editing.
   */
  handleEditSubmit = async () => {
    await this.props.handleEditSubmit(this.state.steps, this.disableEdit);
  }

  toggle = () => this.setState({ modal: !this.state.modal })

  render() {
    const { steps, editLeaseSteps, modal } = this.state;
    const { onPrevious, editMode, initialValues } = this.props;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card>
              <CardHeader>Order Lease Wrapup Steps</CardHeader>
              <div className='help-icon-styling' onClick={() => this.setState({ modal: !modal })}><i className='fa fa-question fa-2x' /></div>
              <CardBody>
                <LeaseWrapupOrderer
                  {...this.props}
                  steps={steps}
                  rearrangeSteps={this.rearrangeSteps}
                  disableDrag={editMode ? !editLeaseSteps : false}
                />
              </CardBody>
              {
                editMode ?
                  <CardFooter>
                    <Row>
                      <Col className="text-right">
                        <Button onClick={() => this.handleEditLeaseSteps(editLeaseSteps ? 'cancel' : 'edit')} type='button' color='link'>{editLeaseSteps ? 'Cancel' : 'Edit'}</Button>
                        <Button onClick={this.handleEditSubmit} disabled={!editLeaseSteps || _isEqual(steps, initialValues)} color="success" type="submit" style={{ minWidth: '100px' }}>Save</Button>
                      </Col>
                    </Row>
                  </CardFooter>
                  :
                  <CardFooter className='d-flex justify-content-start'>
                    <Button color='primary' className='mr-2' onClick={onPrevious}>Previous</Button>
                    <Button color='primary' disabled={_isEmpty(steps)} onClick={this.handleSubmit}>Next</Button>
                  </CardFooter>
              }
            </Card>
          </Col>
        </Row>

        <Modal isOpen={modal} toggle={this.toggle} size={'xl'}>
          <ModalHeader toggle={this.toggle}>How To?</ModalHeader>
          <ModalBody>
            <img src={infoGif} class="img-fluid" alt="Responsive image" />
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

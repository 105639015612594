import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line
import _ from 'lodash';
import { Modal, Button } from 'react-bootstrap';

/**
 *
 */
class GlobalModal extends Component {
  render() {
    const {
      content = '',
      type,
      heading,
      title,
      pathname,
      ...props
    } = this.props;

    return (
      <Modal
        {...props}
        show={this.props.show}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {
            heading &&
            <h4>{heading}</h4>
          }
          {
            content &&
            <h4>{content}-{pathname}</h4>

          }
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              this.props.history.push(pathname);
              return this.props.onHide()
            }}
          >Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withRouter(GlobalModal);

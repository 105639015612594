import React from 'react';
import * as R from 'ramda';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setPropTypes } from 'recompose';
import { createSelector, createStructuredSelector } from 'reselect';
import _ from 'lodash';


const sessionSelector = createSelector(
  (state) => state.global,
  (globalState) => globalState.session
);



const renterAppSelector = createSelector(
  sessionSelector,
  R.pathOr(null, ['user', 'renterApplication'])
)

const selector = createStructuredSelector({
  isAuthenticated: createSelector(
    (state) => state.global,
    (globalState) => globalState.isAuthenticated
  ),
  loginUserType: createSelector(
    sessionSelector,
    R.pathOr(null, ['user', 'userType'])
  ),
  leaseWrapupInProgress: createSelector(
    sessionSelector,
    R.pathOr(false, ['user', 'leaseWrapupInProgress'])
  ),
  leaseWrapupId: createSelector(
    sessionSelector,
    (session) => _.get(session, 'user.leaseWrapup._id', _.get(session, 'user.leaseWrapup', null))
  ),

  organization: createSelector(
    sessionSelector,
    R.pathOr(false, ['user', 'organization'])
  ),
  token: createSelector(
    sessionSelector,
    R.pathOr(false, ['user', 'registration', 'token'])
  ),
  registrationInProgress: createSelector(
    sessionSelector,
    R.pathOr(false, ['user', 'registration', 'inProgress'])
  ),

  isOccupant: createSelector(
    sessionSelector,
    R.pathOr(false, ['user', 'registration', 'isOccupant'])
  ),

  renterApplication: renterAppSelector,
  transunionApplicationId: createSelector(
    renterAppSelector,
    R.pathOr(null, ['transunionApplicationId'])
  ),
  transunionReportGenerated: createSelector(
    renterAppSelector,
    R.pathOr(null, ['transunionReport', 'reportGenerated'])
  ),

  pending: createSelector(
    (state) => state.global,
    R.pathOr(false, ['pending'])
  )
})




const ProtectedRouteBase = ({
  component,

  location,
  match,
  history,

  pending,
  path,
  name,
  render, exact, userType, public: isPublic,
  loginUserType,
  isAuthenticated,
  isOccupant,
  renterApplication,
  transunionApplicationId, transunionReportGenerated,
  leaseWrapupInProgress,

  registrationInProgress,
  organization,
  token,

  leaseWrapupId,
  computedMatch,
  ...props
}) => {
  if (pending){
    return <></>
  }

  if (isAuthenticated) {



    if (loginUserType === 'pm'){

      // NOTE: force PM to PM registration flow if its in progress
      if (registrationInProgress && !_.isNil(token)){
        if (computedMatch.path !== '/register/:token/steps'){
          return <Redirect to={`/register/${token}/steps`} />
        }
        return <Route path={path} name={name} render={render || component} exact={exact || false} />
      }
      // NOTE: if pm registration is not in progress but user tries to navigate to it, send them to dashboard
      if (!registrationInProgress && computedMatch.path === '/register/:token/steps'){
        return <Redirect to="/dashboard" />
      }
    }


    // if (leaseWrapupInProgress === true && leaseWrapupId && loginUserType === 'renter' && !computedMatch.path.includes('/renter-application')){


    //   if (isOccupant) {
    //     if (computedMatch.path !== '/renter-application' && _.isEmpty(renterApplication)){
    //       return <Redirect to='/renter-application' />
    //     }

    //     if (computedMatch.path !== '/transunion-application' && (_.isNil(transunionApplicationId) || !transunionReportGenerated) && !_.isEmpty(renterApplication)){
    //       return <Redirect to="/transunion-application" />
    //     }

    //     if (computedMatch.path !== '/lease-wrapup' && computedMatch.path !== '/transunion-application' && computedMatch.path !== '/renter-application' && (!_.isNil(transunionApplicationId) && transunionReportGenerated) && !_.isEmpty(renterApplication)){
    //       return <Redirect to={`/lease-wrapup/${leaseWrapupId}`} />
    //     }
    //   }

    //   if (!isOccupant){
    //     if (!computedMatch.path.includes('/lease-wrapup')){
    //       return <Redirect to={`/lease-wrapup/${leaseWrapupId}`} />
    //     }
    //   }
    // }

    if (isPublic){
      return <Route path={path} name={name} render={render} exact={exact} />
    }

    if (_.isArray(userType)){
      const i = _.findIndex(userType, (el) => _.isEqual(loginUserType, el));
      if (i > -1){
        return <Route
          path={path}
          name={name}
          render={render}
          exact={exact}
        />
      }
    }

    if (userType === loginUserType || _.isNil(userType)) {
      return <Route
        path={path}
        name={name}
        render={render}
        exact={exact}
      />
    }
  }

  if (isPublic){
    return <Route
      path={path}
      name={name}
      render={render}
      exact={exact}
    />
  }

  return <Redirect to='/login' />
}

const buildProtectedRoute = R.compose(
  connect(selector),
  setPropTypes({ isAuthenticated: PropTypes.bool })
);

export default buildProtectedRoute(ProtectedRouteBase);

import React, { Component } from 'react';
import _ from 'lodash';

import './style.scss';
import { TabContent, TabPane, UncontrolledCollapse } from "reactstrap";


const QnA = [
  {
    _id: 'kwH4H0HgH8HZfVa',
    question: 'How does it work?',
    answer: 'Search for rental properties. Save your favorite properties and search preferences. Complete only one tenant screening application per occupant over eighteen and find properties that match your qualifications. Submit an offer to rent the property you want, and adjust if desired, within a specified window of time (“bidding session”). Or save even more time and get right to the point by choosing to Rent-it-Now.',
    order: 1
  },
  {
    _id: 'kwH4H0HgH8HZfVb',
    question: 'What is a Reserve?',
    answer: 'A reserve is the minimum monthly rental price that the party responsible (Owner/Manager) for renting the property is willing to accept. The reserve price is not displayed, and not required. Some properties may not have a reserve price set. If a reserve has been established and the highest current offer is below the reserve amount, the bidding window will display “Reserve not met”',
    order: 2
  },
  {
    _id: 'kwH4H0HgH8HZfVc',
    question: 'What if the highest offer did not meet the Reserve when the bidding session ends?',
    answer: 'The offer would typically be deemed too low and therefore, unacceptable to the Owner/Manager. The automated system would recognize the bidding session as unsuccessful. The Owner/Manager may or may not then choose to contact one or more of those who made offers. They may also choose to activate a subsequent offer window (bidding session). All decisions are up to the party responsible for renting the property.',
    order: 3
  },
  {
    _id: 'kwH4H0HgH8HZfVd',
    question: 'When does the opportunity to make an offer end?',
    answer: 'Properties can be at various stages in the bidding process. The amount of time remaining in the bidding session is displayed on the property details view. If you are a registered user, it will also be displayed on your dashboard in a table of properties you have saved.\n' +
      '\n' +
      'Example: Remaining Time to Bid: 1d 6h 42m (d=Days, h=hours, m=minutes)',
    order: 4
  },
  {
    _id: 'kwH4H0HgH8HZfVe',
    question: 'When is the property available?',
    answer: 'Properties are typically available when listed by the Owner/ Manager. However, some may not be available for move-in until a future date. See the property details page for important terms and conditions noted by the Owner/Manager.',
    order: 5
  },
  {
    _id: 'kwH4H0HgH8HZfVf',
    question: 'What are the bidding fees?',
    answer: 'There is no fee to submit an offer to rent a property. There is only an application / pre-screening fee. Once you and your co-occupants, if applicable, have completed the application process, you may view your results, search for properties whose qualifications appear to be a match and submit your offers.',
    order: 6
  },
  {
    _id: 'kwH4H0HgH8HZfVh',
    question: 'What happens if I don’t win the bid? Will the application fee(s) be refunded?',
    answer: 'Application fees are not refundable. However, your application will remain valid for 30 days. During this 30-day window you may share it with up to five different property owner/managers per day at no additional charge. Should you not get the property that you bid on, you can bid on any other eligible property on the site, as long as your qualifications meet that of the property (i.e. credit, income, background check).',
    order: 7
  },
  {
    _id: 'kwH4H0HgH8HZfVi',
    question: 'What if someone outbids me?',
    answer: 'You will receive a notification that you have been outbid, per the notification options you have chosen in your settings. If the bidding session is still open, you will be able to place a higher bid. If you do not want to submit a higher offer, you may choose to bid on another eligible property, as long as you meet the qualifications for that property.',
    order: 8
  },
  {
    _id: 'kwH4H0HgH8HZfVj',
    question: 'How much is the application fee?',
    answer: 'The application screening fee is specified on the site and applies to each applicant. Typically, each occupant and/or guarantor over the age of 18 is required to complete a tenant screening application. Each applicant must have an individual e-mail address (this is a requirement of the screening service).',
    order: 9
  },
  {
    _id: 'kwH4H0HgH8HZfVk',
    question: 'Can I be the highest bidder on more than one property at the same time',
    answer: 'No. You can only be the winning (highest) bidder on one property at a time.',
    order: 10
  },
  {
    _id: 'kwH4H0HgH8HZfVl',
    question: 'Can I visit the home before bidding?',
    answer: 'Visiting a home before making an offer to rent is highly encouraged. You can make inquiries and request a showing from the property page. Some may require a pre-qualification process prior to showing, use third-party services and/or smart lock technologies for unattended showings. Requirements are unique to the party responsible for renting the property.',
    order: 11
  },
  {
    _id: 'kwH4H0HgH8HZfVm',
    question: 'What happens if I win the bid and change my mind?',
    answer: 'You should not submit offers on properties that you are not willing to rent or do not appear to qualify for. Canceling your offer should be done no later than 24 hours prior to the end of the bidding session. However, if you have won the bid and no longer wish to proceed, you may be charged a fee and/or forfeit some or all of any fees/ deposits paid at the time of winning, subject to the policies and rights of the party responsible for renting the property. All disputes and distribution of fees or refunds are strictly between the applicant(s) and the party responsible for renting the property. \n Life happens and circumstances change, we understand. We want everyone to have a positive experience. To provide a fair and equal opportunity platform to all users, we need to take measures to insure the integrity of the system. Therefore, repeat offenses may result in a user’s access being permanently revoked.',
    order: 12
  },
  {
    _id: 'kwH4H0HgH8HZfVn',
    question: 'What if I need more help?',
    answer: 'For system/ technical support, use the contact page or chat feature. For needs associated with a property, such as a showing question or repair request, contact the party responsible for renting the property. You may contact them through the property details page, or from within your dashboard if you are a registered user.',
    order: 13
  },
  {
    _id: 'kwH4H0HgH8HZfVo',
    question: 'What is the application process?',
    answer: 'The application process is an easy one. The process begins with a short questionnaire addressing the most commonly asked qualification criteria, completed with a tenant background screening through TransUnion. Once each occupant over the age of 18, as well as any co-signors have completed the process, you may use the results to find properties with stated qualification that appear to be a match. Applications are valid for 30 days.',
    order: 14
  },
  {
    _id: 'kwH4H0HgH8HZfVp',
    question: 'Will my application results be shared with others?',
    answer: 'No, only you will get your results. It will be up to you with whom you share the information with. Most, if not all, property managers/owners will require a review of the entire application and screening results before they rent to you. By using the system you do agree to automatically share the completed application and results with the party responsible for renting the property, if you are the winning bidder.\n Note: This is a pre-screening. The party responsible for the property may require additional information and reserves the full right, at their sole discretion, to accept or deny an applicant.',
    order: 15
  },
  {
    _id: 'kwH4H0HgH8HZfVq',
    question: 'What if I have a problem with property manager/owner?',
    answer: 'Rentivity is a technology platform used by property managers/owners, renters and service providers. Rentivity is not a property manager and is not involved in renting, managing or maintaining. However if you are having problems with a user, or having a great experience, please send us a note via the Contact page. Your feedback is very important and it will help us continue to improve the system.',
    order: 16
  },
  {
    _id: 'kwH4H0HgH8HZfVr',
    question: 'Can I pay my rent through Rentivity?',
    answer: 'Yes, you will be able to pay your rent using Rentivity, as long as the property manager/owner is willing to accept payments using Rentivity. You can set up automatic reminders and payments.',
    order: 17
  }
];

const QnALandLord = [
  {
    _id: 'kwH4H0HgH8HZfVaa',
    question: 'What are some of the benefits of using Rentivity?',
    answer: 'Rentivity is designed to save all users time and money. Through integration and automation, Rentivity is being built to incorporate the entire leasing and management process into a single, centralized digital platform that supports all users. Renters, Owners and Property Managers save time and money via the real time screening and bidding process. Built-in features and functions eliminate the need for separate third-party, single aspect external services. Processes and procedures are set by the user and automated to reduce time, errors, and mitigate risks. All is captured in a digital audit trail. Currently, the digital leasing process is captured in an immutable Blockchain. Soon, the entire property lifecycle will be.',
    order: 1
  },
  {
    _id: 'kwH4H0HgH8HZfVab',
    question: 'How can I add my property to Rentivity?',
    answer: 'Register and set up your account. You can then set up all of your users and preferences, add properties and assign designated users.',
    order: 2
  },
  {
    _id: 'kwH4H0HgH8HZfVac',
    question: 'Can I use my own lease?',
    answer: 'Yes. While you are setting up your account you can upload your lease and accompanying documents and set place holders. When approving your occupant(s), the system will auto-populate your lease and addendums for you and/or your attorney to review and distribute for all parties to sign. Keep it fast and simple using the Rentivity E-Signature feature. The entire leasing process will be captured digitally in an immutable blockchain ledger.',
    order: 3
  },
  {
    _id: 'kwH4H0HgH8HZfVad',
    question: 'What is a bidding session?',
    answer: 'An offer window. It could be compared to a multiple offer window in a home purchase scenario. Rentivity is a first-of-its-kind digital screening and rental price bidding platform, designed to enhance fair housing, create equal opportunity, provide real-time market response, and save all users time and money. As an example, one might set an open offer window “bidding session” for seven days, in which you offer all prospective tenants an equal opportunity to digitally complete your pre-screening process and make offers within that timeframe.',
    order: 4
  },
  {
    _id: 'kwH4H0HgH8HZfVae',
    question: 'Can I cancel a bidding session?',
    answer: 'Please exercise caution and integrity. You can cancel/ withdraw a listing so long as you have not yet received any offers/bids on the listing. If prospective tenants have placed bids, then they have paid fees, completed a lengthy application process and are interested in your property. Keep in mind that most bidding activity typically takes place in the final few hours. Absent of extenuating circumstances, such as death of the owner, legal encumbrances or cancelation of agreement between owner and property manager, withdrawing an active listing that has bids would be quite unfair, and unethical. We recommend you set up your internal escalation process in the User Management section to prevent unwanted behavior. Rentivity may collect fees and/or temporarily or permanently restrict access to users who violate this policy.',
    order: 5
  },
  {
    _id: 'kwH4H0HgH8HZfVaf',
    question: 'Are potential tenants screened?',
    answer: 'Yes. Of course, the final decision to approve or deny any occupant is ultimately yours. You must establish a series of tenant screening qualifications when setting up your account, and provide qualification and required document criteria for listings. Prospective tenants must complete a pre-screening questionnaire followed by a screening report provided by TransUnion Smartmove. Qualifications are matched prior to making offers for rent. Personal identifying information remains anonymous during the bidding session. Upon close of the bidding session, all information is available for review and communication to complete the final steps in your process and approve or deny.',
    order: 6
  },
  {
    _id: 'kwH4H0HgH8HZfVag',
    question: 'Can I collect deposits, fees and rents through Rentivity?',
    answer: 'Yes. You can process payments electronically using Rentivity, be it incoming or outgoing (i.e. repairs, owner disbursements). When you set up your account, you will be given the opportunity to add in your banking information and direct deposits and rents to route accordingly. Rentivity currently supports ACH transfers and credit card payments, with additional electronic wallet formats coming soon.',
    order: 7
  },
];

export default class FAQ extends Component {
  state = {
    activeTab: 'renter'
  }

  toggle = tab => {
    if (tab !== this.state.activeTab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    const sortedQnA = _.sortBy(QnA, o => o.order);
    const sortedQnALandlord = _.sortBy(QnALandLord, o => o.order);
    return (
      <>
        <div className='container mt-5'>
          <h1 className='text-center mb-4'>Frequently Asked Questions (FAQ)</h1>
          <div className="btn-groups">
            <button type="button" className={`${this.state.activeTab === 'renter' ? 'active' : ''} btns mr-2`} onClick={() => this.toggle('renter')}>
              I&apos;m a Renter
            </button>
            <button type="button" className={`${this.state.activeTab === 'landlord' ? 'active' : ''} btns`} onClick={() => this.toggle('landlord')}>
              I&apos;m a Landlord
            </button>
          </div>
        </div>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId='renter'>
            <div className='animated fadeIn mt-2 container'>
              <h4>With Rentivity you can save hundreds of dollars by paying only one application fee*. View all of the properties that are a potential match, and experience an equal opportunity to compete for the desired property within a given window. And Rentivity is not a lead generating site, so all inquiries go direct to the party responsible for renting the property.</h4>
            </div>
            <div className='animated fadeIn mt-2 container'>
              {
                _.map(sortedQnA, qna => {
                  return (
                    <ul style={{paddingLeft: '0px'}} className='faq' key={qna._id}>
                      <li className='faq-list'>
                        <div id={qna._id} className='question-wrapper'>
                          <span className='question-decor'>Q.</span>
                          <span className='question'>{qna.question}</span>
                        </div>
                        <UncontrolledCollapse toggler={qna._id}>
                          <div className='answer-wrapper'>
                            <span className='answer-decor'>A.</span>
                            <span className='answer'>{qna.answer}</span>
                          </div>
                        </UncontrolledCollapse>
                      </li>
                    </ul>
                  )
                })
              }
            </div>
            <div className='animated fadeIn mt-2 container'>
              <p>*Per each occupant over the age of 18.</p>
            </div>
          </TabPane>
          <TabPane tabId="landlord">
            <div className='animated fadeIn mt-2 container'>
              {
                _.map(sortedQnALandlord, qna => {
                  return (
                    <ul style={{paddingLeft: '0px'}}  className='faq' key={qna._id}>
                      <li className='faq-list'>
                        <div id={qna._id} className='question-wrapper'>
                          <span className='question-decor'>Q.</span>
                          <span className='question'>{qna.question}</span>
                        </div>
                        <UncontrolledCollapse toggler={qna._id}>
                          <div className='answer-wrapper'>
                            <span className='answer-decor'>A.</span>
                            <span className='answer'>{qna.answer}</span>
                          </div>
                        </UncontrolledCollapse>
                      </li>
                    </ul>
                  )
                })
              }
            </div>
          </TabPane>
        </TabContent>
      </>
    )
  }
}

import * as Yup from 'yup';
import moment from 'moment';

const schema = () => Yup.object().shape({
    first_name: Yup.string()
        .matches(/^[A-Za-z]+$/, { message: 'Invalid characters in first name'})
        .required('First Name is required'),
    last_name: Yup.string()
        .matches(/^[A-Za-z]+$/, { message: 'Invalid characters in last name'})
        .required('Last Name is required'),
    relationship: Yup.object().shape({
        title: Yup.string()
            .required('Title is required'),
    })
    .required(),
    ssn_last_4: Yup.string()
        .min(4)
        .max(4)
        .matches(/\d{4}$/, { message: 'Does not match SSN pattern'})
        .transform((currVal, origVal) => currVal.length > 4 ? origVal: currVal)
        .required('Last 4 of SSN is required'),
    dob: Yup.date()
        .min(moment(new Date(1, 1, 1900)).format('MM/DD/YYYY'), 'DOB year must be higher than year 1899')
        .max(moment().subtract(18, 'years').toDate(), 'Age should be more than 18 years. Please enter a valid DOB')
        .required('DOB is required'),
    email: Yup.string()
        .email()
        .required('Email is required'),
    phone: Yup.string()
        .matches(/\d{10}$/, { message: 'Should be 10 numbers' })
        .required('Phone Number is required'),
    address: Yup.object().shape({
        line1: Yup.string()
            .required('Address line 1 is required'),
        line2: Yup.string(),
        city: Yup.string()
            .required('City is required'),
        state: Yup.string()
            .min(1, 'Too Short')
            .max(50, 'Too Long')
            .required('State is required'),
        postal_code: Yup.string()
            .matches(/^(\d{5})?$/, 'zip should be a 5 digit valid number')
            .required('Zip Code is required'),

    })
    .required()
});
export default schema;  
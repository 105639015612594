import EmailVerification from './component';
import { composeContainer } from './store';



/**
 * Do http request to backend to verify email address and then redirect to appropriate registration step
 */

export default composeContainer(EmailVerification);


import _ from 'lodash';
import createReducer from 'utils/createReducer';


const authData = JSON.parse(localStorage.getItem('auth'));
const initialState = {
  showSpinner: false,
  spinnerText: null,

  isLoading: false,
  pending: false,
  success: false,
  error: false,


  toast: {
    show: false,
    options: {}
  },

  isAuthenticated: !_.isNil(authData) && !_.isEmpty(authData.user),

  session: {
    user: _.isNil(authData) ? {} : authData.user,
    jwt: _.isNil(authData) ? null : authData.jwt
  },

  layout: {
    dashboardType: 'admin',
    sidebar: true,
    header: true
  }
};

export default createReducer(initialState, {
  SET_DASHBOARD_TYPE: (state, action) => ({
    ...state,
    layout: {
      ...state.layout,
      dashboardType: action.value
    }
  }),
  SHOW_SPINNER: (state, action) => ({
    ...state,
    showSpinner: true,
    spinnerText: action.value
  }),
  HIDE_SPINNER: (state) => ({
    ...state,
    showSpinner: false,
    spinnerText: null
  }),

  SET_SESSION: (state, action) => ({
    ...state,
    pending: false,
    success: true,
    error: false,
    session: action.value,
    isAuthenticated: true
  }),
  SET_SESSION_USER: (state, action) => ({
    ...state,
    pending: false,
    success: true,
    error: false,
    session: {
      ...state.session,
      user: action.value
    },
    isAuthenticated: true
  }),
  GET_RENTER_APP_SUCCESS: (state, action) => ({
    ...state,
    session: {
      ...state.session,
      user: {
        ...state.session.user,
        renterApplication: action.value
      }
    }
  }),
  GET_SESSION_PENDING: (state) => ({
    ...state,
    pending: true,
    success: false,
    error: false
  }),
  GET_SESSION_SUCCESS: (state, action) => ({
    ...state,
    pending: false,
    success: true,
    error: false,
    session: {
      ...state.session,
      user: {
        ...action.value.user
      },
      jwt: action.value.jwt
    },
    isAuthenticated: true
  }),

  SUBMIT_LOGIN_SUCCESS: (state, action) => ({
    ...state,
    pending: false,
    success: true,
    error: false,
    isAuthenticated: true,
    session: {
      ...state.session,
      user: {
        ...action.value.user
      },
      jwt: action.value.jwt
    }
  }),

  GET_SESSION_ERROR: (state, action) => {
    localStorage.removeItem('auth');
    return ({
      ...state,
      pending: false,
      success: false,
      isAuthenticated: false,
      session: { user: {} },
      error: action.error
    })
  },
  LOGOUT_ERROR: (state) => ({
    ...state,
    pending: false,
    success: false,
    isAuthenticated: false,
    session: { user: {} },
    error: false
  }),
  LOGOUT_SUCCESS: (state) => {
    localStorage.removeItem('auth');
    return ({
      ...state,
      pending: false,
      success: false,
      isAuthenticated: false,
      session: { user: {}, jwt: null },
      error: false
    })
  },
  

  MARK_TOS_AGREE: (state) => ({
    ...state,
    session: {
      ...state.session,
      user: {
        ...state.session.user,
        registration: {
          ...state.session.user,
          tosAccepted: true
        }
      }
    }
  })
});



const layoutInit = {
  dashboardType: 'renter',
  sidebar: true,
  header: true
};

export const layoutReducer = createReducer(layoutInit, {
  SET_LAYOUT: (state, action) => {
    return {
      ...state,
      ...action.value
    }
  }
})

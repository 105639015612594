export const searchPropertySessions = (query, select, bounds) => (dispatch) => dispatch({ type: 'SEARCH_PROPERTY_SESSION', query, select, bounds });

export const fetchSavedSearchFilters = () => ({ type: 'GET_SEARCH_FILTERS' });
export const saveSearchFilter = (filter) => ({ type: 'SAVE_SEARCH_FILTER', value: filter });
export const deleteSearchFilter = (filterId, bounds) => ({ type: 'DELETE_SEARCH_FILTER', value: filterId, bounds });

export const fetchFavorites = () => ({ type: 'GET_FAVORITES' });
export const deleteFavorite = (id) => ({ type: 'DELETE_FAVORITE', value: id });
export const addFavorite = (id) => ({ type: 'ADD_FAVORITE', value: id });
export const getSearchFilters = () => ({ type: 'GET_SEARCH_FILTERS' });
export const setSearchFilter = (searchFilter, bounds, markerRef, mapRef) => ({ type: 'SET_SEARCH_FILTER', value: searchFilter, bounds, markerRef, mapRef });

export const getFeatures = () => ({ type: 'GET_FEATURES' });
export const applyBounds = (bounds) => ({ type: 'APPLY_BOUNDS', bounds });


export const setPlace = (place) => ({ type: 'SET_PLACE', value: place });
// export const setSearchText= (txt) => ({ type: 'SET_SEARCH_TEXT', value: txt });
export const setCenter = (center) => ({ type: 'SET_CENTER', value: center });

export const setLayout = () => ({ type: 'SET_LAYOUT', value: { dashboardType: 'search' } })

/* eslint arrow-body-style: [0] */
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';
import Plaid from 'plaid-fe';

import * as R from 'ramda';
import {
  Modal, ModalFooter, ModalBody, ModalHeader,
  Container, Row, Col, Table,
  Button, Form,
  Card, CardBody, CardHeader, CardFooter
} from 'reactstrap';
import { Button as BSButton, OverlayTrigger, Tooltip } from 'react-bootstrap';


import { FormikCustomField } from 'components/Inputs';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import { setPropTypes, defaultProps } from 'recompose';
import { ToastContext } from '../../../../contexts';
import { toast } from 'react-toastify';
import { getAccountTypes, getAccountPurposes } from '../../../../utils/services';

const toastMessageSetting = {position: toast.POSITION.TOP_RIGHT}
const AddAccountModalBase = (props) => (
  <Modal
    size={props.size}
    isOpen={props.isOpen}
    toggle={props.toggle(false)}
    onEnter={props.resetForm}
    onExit={props.resetForm}
    onClosed={props.resetForm}
    backdrop='static'
  >
    <Form
      onSubmit={R.pipe(
        R.tap(R.invoker(0, 'persist')),
        R.tap(R.invoker(0, 'stopPropagation')),
        props.handleSubmit
      )}
    >
      <ModalHeader>Add Account</ModalHeader>
      <ModalBody>
        <Container>

          <Row form>
            <Col>
              <Field
                type='text'
                name='name'
                label='Name'
                component={FormikCustomField}
              />
            </Col>
          </Row>
          <Row form>
            <Col>
              <Field
                type='text'
                noValidate
                name='description'
                label='Description'
                component={FormikCustomField}
              />
            </Col>
          </Row>
          <Row form>
            <Col>
              <Field
                type='select'
                name='account_purpose'
                label='Account Purpose'
                noValidate
                component={FormikCustomField}
                className="custom-select"
                style={{maxWidth: 'none'}}
              >
                <option value=''>Select purpose...</option>
                {
                  _.chain(props.accPurposes)
                    .map((abbr, i) => {
                      const isDisabled = _.findIndex(props.accountTypesAdded, (acctType) => abbr._id === acctType) > -1
                      return (
                        <option
                          value={abbr._id}
                          disabled={isDisabled}
                          key={`state-abbr-${i}-${abbr._id}`}
                        >
                          {abbr.label}
                        </option>
                      )
                    })
                    .value()
                }
              </Field>
            </Col>
          </Row>
          <Row form>
            <Col>
              <Field
                type='select'
                name='account_type'
                label='Account Type'
                noValidate
                component={FormikCustomField}
                className="custom-select"
                style={{maxWidth: 'none'}}
              >
                <option value=''>Select a type...</option>
                {
                  _.chain(props.accTypes)
                    .map((abbr, i) => {
                      return (
                        <option
                          value={abbr._id}
                          key={`state-abbr-${i}-${abbr._id}`}
                        >
                          {abbr.label}
                        </option>
                      )
                    })
                    .value()
                }
              </Field>
            </Col>
          </Row>

        </Container>
      </ModalBody>

      <ModalFooter>
        <Button color="primary" onClick={props.toggle(false)} >Close</Button>
        <Button
          color="success"
          type='submit'
          disabled={!props.isValid}
        >
          Link Bank Account
        </Button>
      </ModalFooter>

    </Form>
  </Modal>
);

AddAccountModalBase.propTypes = {
  size: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired
};






const composeAddAccountModal = R.compose(
  withFormik({
    mapPropsToValues: () => ({ name: '', description: '' }),
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      description: Yup.string(),
      account_type: Yup.string()
        .required(),
      account_purpose: Yup.string().required(),
    })
    .required(),
    handleSubmit: (values, formikBag) => formikBag.props.onSubmit(values, formikBag),
    enableReinitialize: true
  }),
  setPropTypes({
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  }),
  defaultProps({
    size: 'xl',
    isOpen: false
  })
)



const AddAccountModal = composeAddAccountModal(AddAccountModalBase);


class Banking extends Component {
  state = {
    modalIsOpen: false,
    accounts: [],
    accTypes: [],
    accPurposes: []
  }


  static contextType = ToastContext;

  async componentDidMount() {
    this.context.showSpinner({ text: 'Please wait...' });
    try {
      const { data: accTypes } = await this.fetchAccTypes();
      const { data: accPurposes } = await this.fetchAccPurposes();
      await this.setState({
        accTypes,
        accPurposes
      })
      await this.context.hideSpinner();
    } catch(err) {
      this.context.hideSpinner();
      const msg = _.get(err, 'response.data.message', err.message);
      this.context.showErrorToast(msg,toastMessageSetting);
    }
  }

  fetchAccTypes = async () => {
    return await getAccountTypes();
  }

  fetchAccPurposes = async () => {
    return await getAccountPurposes();
  }

  toggleModal = (toState = false) => () => this.setState({ modalIsOpen: toState })

  handlePlaidSubmit = (values) => {
    Plaid.create({
      apiVersion: 'v2',
      clientName: 'Plaid Walkthrough Demo',
      env: process.env.REACT_APP_PLAID_ENV,
      product: ['auth', 'transactions'],
      key: process.env.REACT_APP_PLAID_PUBLIC_KEY,
      selectAccount: true,
      onSuccess: (public_token, metadata) => {
        // allow only 2 accounts one of each type
        const acctIndex = _.findIndex(this.props.accounts, { type: values.type });
        if (acctIndex > -1){
          this.context.showErrorToast(`You have already added a ${_.toLower(values.type)}`,toastMessageSetting);
          return this.setState({ modalIsOpen: false });
        }
        return this.setState({
          modalIsOpen: false,
          // accounts: [
          //   ...this.state.accounts, {
          //     public_token,
          //     metadata,
          //     primary: true,
          //     ...values
          //   }
          // ]
        }, () => {
          this.props.addBankAccount({
            public_token,
            metadata,
            primary: true,
            ...values
          })
        });
      },
      onExit: () => this.setState({ modalIsOpen: false })
    })
    .open()
  }

  removeAcct = (i) => () => {
    this.props.removeBankAccount(i);
  }


  get accountTypesAdded() {
    return _.map(this.props.accounts, 'account_purpose');
  }

  get addAccountDisabled(){
    return this.props.accounts.length >= 2;
  }

  get unsubmittedAccountTypesMsg(){
    const submittedTypes = _.map(this.props.accounts, 'account_purpose');
    const unsubmittedDiff = _.difference(_.map(this.state.accPurposes, item => item._id), submittedTypes);
    const unsubmitted = _.map(unsubmittedDiff, item => {
      const filtered =  _.filter(this.state.accPurposes, purpose => purpose._id === item);
      if (!_.isEmpty(filtered)) {
        return filtered[0].label;
      }
    });
    const joined = _.join(unsubmitted, ' and ')
    return joined ? `${joined} ${unsubmitted.length > 1 ? 'are' : 'is'} required` : '';
  }



  render() {
    return (
      <div className='animated fade-in'>
        <Row>
          <Col>

            <AddAccountModal
              size='md'
              isOpen={this.state.modalIsOpen}
              toggle={this.toggleModal}
              accountTypesAdded={this.accountTypesAdded}
              onSubmit={this.handlePlaidSubmit}
              accTypes={this.state.accTypes}
              accPurposes={this.state.accPurposes}
            />

            <Card>
              <CardHeader>
                <h3>Banking</h3>
                <div className="card-header-actions">
                  <Button
                    color='success'
                    size='sm'
                    onClick={this.toggleModal(true)}
                    disabled={this.addAccountDisabled}
                  >
                    Add Account
                  </Button>
                </div>
              </CardHeader>

              <CardBody>

                <Table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Purpose</th>
                      <th>Type</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      _.map(this.props.accounts, (acct, index) => {
                        const purpose = !_.isEmpty(this.state.accPurposes) ? _.filter(this.state.accPurposes, purpose => purpose._id === acct.account_purpose)[0].label : '';
                        const type = !_.isEmpty(this.state.accTypes) ? _.filter(this.state.accTypes, type => type._id === acct.account_type)[0].label : '';

                        return (
                          <tr key={`role_${index}`}>
                            <td>{acct.name}</td>
                            <td>{acct.description}</td>
                            <td>{purpose}</td>
                            <td>{type}</td>
                            <td className="text-center">
                              <Button color='danger' onClick={this.removeAcct(index)}>
                                <i className="fa fa-trash" style={{ fontSize: `${24}px` }}></i>
                              </Button>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
                {_.get(this.props, 'stepInfo.bankingInfo.error.message', '')}
              </CardBody>

              <CardFooter>
                <div className="card-footer-actions">
                  <Button
                    type='button'
                    color="primary"
                    className="mr-2"
                    onClick={this.props.onPrevious}
                  >
                    Previous
                  </Button>
                  {
                    (this.props.accounts.length < 2) &&
                      (this.unsubmittedAccountTypesMsg ?
                      <OverlayTrigger placement='right' overlay={<Tooltip id="tooltip-disabled">{this.unsubmittedAccountTypesMsg}</Tooltip>}>
                        <span>
                          <BSButton
                            disabled={this.props.accounts.length < 2}
                            variant={'primary'}
                            onClick={() => this.props.onSubmit('banking-info', this.props.accounts)}
                            style={{ pointerEvents: 'none' }}
                          >
                            Next
                        </BSButton>
                        </span>
                      </OverlayTrigger>
                      :
                      <BSButton
                        disabled={this.props.accounts.length < 2}
                        variant={'primary'}
                        onClick={() => this.props.onSubmit('banking-info', this.props.accounts)}
                        style={{ pointerEvents: 'none' }}
                      >
                        Next
                        </BSButton>)
                  }
                  {
                    this.props.accounts.length >= 2 &&
                      <BSButton
                        variant={'primary'}
                        onClick={() => this.props.onSubmit('banking-info', this.props.accounts)}
                      >
                        Next
                      </BSButton>
                  }
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Banking;

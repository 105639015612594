import * as R from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect'


const globalSelector = (state) => state.global;

const sessionSelector = createSelector(
  globalSelector,
  R.propOr({}, 'session')
);

const userSelector = createSelector(
  sessionSelector,
  R.propOr({}, 'user')
);

const registrationSelector = createSelector(
  userSelector,
  (user = {}) => user.registration
);

const stepNumberSelector = createSelector(
  registrationSelector,
  (registration = {}) => registration.step || 0
);

const regSelector = createSelector(
  (state = {}) => state.registration,
  (reg = {}) => reg.steps || {}
)

export default createStructuredSelector({
  user: userSelector,
  steps: regSelector,
  registration: registrationSelector,
  stepNumber: stepNumberSelector
});

module.exports = {
  permissions: [
    "viewApplications",
    "addProperties",
    "editProperties",
    "viewProperties",
    "approveBids",
    "closeListings",
    "createListings",
    "editListings",
    "viewListings",
    "createTasks",
    "editTasks",
    "viewTasks",
    "createAccounts",
    "editAccounts",
    "viewAccounts",
    "createInvoices",
    "makePayments",
    "addTeams",
    "editTeams",
    "viewTeams",
    "createRoles",
    "deleteRoles",
    "editRoles",
    "manageRoles",
    "viewRoles",
    "viewReports",
    "viewMembers",
    "editMembers",
    "addMembers"
  ],
  defaultRoles: [
    {
      "name": "Admin",
      "description": "Administrator Role (read only)",
      "permissions": {
        "viewApplications": true,
        "addProperties": true,
        "editProperties": true,
        "viewProperties": true,
        "approveBids": true,
        "closeListings": true,
        "createListings": true,
        "editListings": true,
        "viewListings": true,
        "createTasks": true,
        "editTasks": true,
        "viewTasks": true,
        "createAccounts": true,
        "editAccounts": true,
        "viewAccounts": true,
        "createInvoices": true,
        "makePayments": true,
        "addTeams": false,
        "editTeams": false,
        "viewTeams": false,
        "createRoles": true,
        "deleteRoles": true,
        "editRoles": true,
        "manageRoles": true,
        "viewRoles": true,
        "viewReports": true,
        "viewMembers": true,
        "editMembers": true,
        "addMembers": true
      },
      "_id": "5ccfcc62bde7077f52000001"
    },
    {
      "name": "Default",
      "description": "Default Role (read only)",
      "permissions": {
        "viewApplications": false,
        "addProperties": false,
        "editProperties": false,
        "viewProperties": false,
        "approveBids": false,
        "closeListings": false,
        "createListings": false,
        "editListings": false,
        "viewListings": false,
        "createTasks": false,
        "editTasks": false,
        "viewTasks": false,
        "createAccounts": false,
        "editAccounts": false,
        "viewAccounts": false,
        "createInvoices": false,
        "makePayments": false,
        "addTeams": false,
        "editTeams": false,
        "viewTeams": false,
        "createRoles": false,
        "deleteRoles": false,
        "editRoles": false,
        "manageRoles": false,
        "viewRoles": false,
        "viewReports": false,
        "viewMembers": false,
        "editMembers": false,
        "addMembers":false
      },
      "_id": "5ccfcc62bde7077f52000002"
    }
  ]
}

import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const HoverTooltip = ({ text, placement = 'right', ...props }) => (
  <OverlayTrigger
    placement={placement}
    overlay={<Tooltip id="tooltip-right">{text}</Tooltip>}
  >
    {props.children}
  </OverlayTrigger>
)


export const HeaderWithTooltip = (props) => (
  <h3>
    {props.title}
    <HoverTooltip text={props.tooltip} >
      <i className="pl-2 fa fa-question-circle" aria-hidden="true"></i>
    </HoverTooltip>
    {props.subText}
  </h3>
)

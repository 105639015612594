import React from 'react';
import * as R from 'ramda';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import LoginBase from './component';
import selectors from './store/selectors';
import * as actions from './store/actions';

class LoginContainer extends React.Component {
  componentWillMount() {
    this.props.setLayout();
  }

  render() {
    if (this.props.isAuthenticated) {
      const user = _.get(this.props.session, 'user');
      const userType = _.get(this.props.session, 'user.userType', false);      
      const from = _.get(this.props, 'location.state.from');
      // If user type is 'renter' then redirect myrental or lease-wrapup or home based on condition
      const homeToLoad = (userType === 'pm' || userType === 'client') ? '/dashboard' : ( from && from === '/search' && userType === 'renter' ? '/search' : ( from && from.startsWith("/listings/") && userType === 'renter'? from : (!_.isEmpty(user.leases) ?'/myrental': (user.leaseWrapup?`/lease-wrapup/${typeof user.leaseWrapup === "object" ? user.leaseWrapup._id : user.leaseWrapup}`:'/home'))));
      // This statement has been disabled for not redirect working renter having lease status
      //const redirectToLocation = _.get(this.props.location, 'state.from', homeToLoad);
      const redirectToLocation = homeToLoad;
      const searchState = redirectToLocation === '/search' ?JSON.parse(localStorage.getItem('searchState')):{};
      return (
        <Redirect
          to={{
            pathname: redirectToLocation === '/alliance' ? '/dashboard' : redirectToLocation,
            state: searchState,
            search: redirectToLocation === '/search' ? (!_.isEmpty(searchState.searchText)?`value=${encodeURIComponent(searchState.searchText)}`:'') : ''  
          }}
        />
      );
    }

    return (!this.props.sessionPending) ? <LoginBase {...this.props} submitLogin={this.props.submitLogin} /> : null;
  }
}



const composeComponent = R.compose(
  withRouter,
  connect(selectors, (dispatch) => ({
    submitLogin: ({ ...payload }, formikActions) => dispatch(actions.submitLogin({ ...payload }, formikActions)),
    setLayout: () => dispatch({ type: 'SET_LAYOUT', value: { dashboardType: 'renter', sidebar: false } })
  })),
);


export default composeComponent(LoginContainer);

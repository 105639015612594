import createReducer from 'utils/createReducer';


const initialState = {
  pending: false,
  success: false,
  error: null,
  transactions: {
    deposits: [],
    incomingTransactions: [],
    outgoingTransaction: [],
    expenses: []
  }
};


export default createReducer(initialState, {
  GET_ACCOUNTING: (state) => ({
    ...state,
    pending: true,
    success: false,
    error: null
  }),
  GET_ACCOUNTING_SUCCESS: (state, action) => ({
    ...state,
    pending: false,
    success: true,
    error: null,
    transactions: { ...action.value }
  }),
  GET_ACCOUNTING_ERROR: (state, action) => ({
    ...state,
    pending: false,
    success: false,
    error: action.error
  })
});


import axios from 'axios';


export const onSubmit = (payload) => async (dispatch) => {
  dispatch({ type: 'SET_FIELDS', value: payload });
  dispatch({ type: 'SUBMIT_REGISTRATION_PENDING' });
  try {
    dispatch({ type: 'showSpinner', payload: { text: 'Please wait...' } });
    const { data: { user: auth } } = await axios.post(`/api/rentivity/register`, payload);
    dispatch({ type: 'hideSpinner' });
    return dispatch({ type: 'SUBMIT_REGISTRATION_SUCCESS', value: auth });
    // return dispatch({ type: 'SET_SESSION', value: auth });
  } catch (err) {
    dispatch({ type: 'hideSpinner' });
    return dispatch({ type: 'SUBMIT_REGISTRATION_ERROR', error: err.response.data });
  }
};

export const setLayout = () => (dispatch) => dispatch({ type: 'SET_LAYOUT', value: { dashboardType: 'renter', sidebar: false } })

import createReducer from 'utils/createReducer'


const initialState = {
  pending: false,
  success: false,
  error: false
};


export default createReducer(initialState, {
  VERIFY_EMAIL_PENDING: (state) => ({
    ...state,
    success: false,
    error: false,
    pending: true
  }),
  VERIFY_EMAIL_SUCCESS: (state) => ({
    ...state,
    pending: false,
    error: false,
    success: true
  }),
  VERIFY_EMAIL_ERROR: (state, action) => ({
    ...state ,
    pending: false,
    success: false,
    error: action.error
  })
});


import React from 'react';
import { Col, Row } from 'reactstrap';

import './style.scss';

import pointCentral from '../../../assets/img/partners/pointCentral.png';
import lemonade from '../../../assets/img/partners/lemonade.png';
import renterSelect from '../../../assets/img/partners/renterSelect.png';
import lynx from '../../../assets/img/partners/lynx.png';
import petScreening from 'assets/img/partners/petScreening.png';

const Partners = ({ inLeaseWrapup }) => {
  return (
    <div className='parent'>
      <Row>
        <>
          <Col md={6} sm={12} className='text-center'>
            <div className='m-3 bg-logo shadow  p-5'>
              <a href="https://www.lemonade.com/l/rentivity" target="_blank" rel="noopener noreferrer">
                <img src={lemonade} alt="partner_1" height="250" width="250" className="img-fluid rounded" />
              </a>
            </div>
          </Col>

          <Col md={6} sm={12} className='text-center vertical-align'>
            <h6>
              Lemonade reverses the traditional insurance model. We treat the premiums you pay as
              if it's your money, not ours. With Lemonade, everything becomes simple and transparent. We take a
              flat fee, pay claims super fast, and give back what’s left to causes you care about.
          </h6>
          </Col>
          <Col md={6} sm={12} className='text-center'>
            <div className='m-3 bg-logo shadow  p-5'>
              <a href="https://renters.mypropertyprotect.com/landingPage/rentivity" target="_blank" rel="noopener noreferrer">
                <img src={renterSelect} alt="partner_1" height="120" width="120" className="img-fluid rounded" />
              </a>
            </div>
          </Col>
          <Col md={6} sm={12} className='text-center vertical-align'>
            <h6>Property Protect offer the flexibility and coverage you need to protect your belongings.</h6>
          </Col>
        </>
        {!inLeaseWrapup &&
          <>
            <Col md={6} sm={12} className='text-center'>
              <div className='m-3 bg-logo shadow  p-5'>
                <a href="http://www.virtualkey.co/" target="_blank" rel="noopener noreferrer">
                  <img src={lynx} alt="partner_1" height="250" width="250" className="img-fluid rounded" />
                </a>
              </div>
            </Col>
            <Col md={6} sm={12} className='text-center vertical-align'>
              <h6>Lynx is a Home Automation Platform for property managers and owner-operators.</h6>
              <h6>Please use promo code <b>RENTIVITY20</b> to get 20% discount on Lynx Automation Software.</h6>
            </Col>
            <Col md={6} sm={12} className='text-center'>
              <div className='m-3 bg-logo shadow  p-5'>
                <a href="http://www.pointcentral.com" target="_blank" rel="noopener noreferrer">
                  <img src={pointCentral} alt="partner_1" height="250" width="250" className="img-fluid rounded" />
                </a>
              </div>
            </Col>
            <Col md={6} sm={12} className='text-center vertical-align'>
              <h6>Point Central enable property managers to increase their operating income, improve their operations, and enhance their properties for greater resident appeal.</h6>
            </Col>
            <Col md={6} sm={12} className='text-center'>
            <div className='m-3 bg-logo shadow  p-5'>
              <a href="https://info.petscreening.com/rentivity" target="_blank" rel="noopener noreferrer">
                <img src={petScreening} alt="partner_1" height="250" width="250" className="img-fluid rounded" />
              </a>
            </div>
          </Col>

          <Col md={6} sm={12} className='text-center vertical-align'>
            <h6>
            Pet Screening make it easy to manage your pet and assistance animal records.
          </h6>
          </Col>
          </>
        }
      </Row>
    </div>
  )
}
export default Partners;
import _ from 'lodash';
import * as services from '../../../utils/services';
import { toast } from "react-toastify";


export const showSuccessToast = (msg = 'Success', opts = { position: toast.POSITION.TOP_RIGHT }) =>  (dispatch) => {
  dispatch({ type: 'SHOW_SUCCESS_TOAST', value: msg });
  return toast.success(msg, { position: toast.POSITION.TOP_RIGHT, ...opts });
};

export const showErrorToast = (message, opts = { position: toast.POSITION.TOP_RIGHT }) => {
  return (dispatch) => {
    dispatch({ type: 'SHOW_ERROR_TOAST', error: message });
    if (message instanceof Error) {
      const msg = _.get(message, 'response.data.message', message.message);
      return toast.error(msg, { position: toast.POSITION.TOP_RIGHT, ...opts });
    }
    return toast.error(message, { position: toast.POSITION.TOP_RIGHT, ...opts });
  }
};

export const checkSession = () => async (dispatch) => {
  await dispatch({ type: 'GET_SESSION' });
  await dispatch({ type: 'GET_SESSION_PENDING' });

  // try {
  //   const { data: session } = await services.getSession();
  //   localStorage.setItem('auth', JSON.stringify(session));
  //   return dispatch({
  //     type: 'GET_SESSION_SUCCESS',
  //     value: session
  //   });
  // } catch (err) {
  //   const error = _.get(err, 'response.data', err);
  //   return dispatch({
  //     type: 'GET_SESSION_ERROR',
  //     error
  //   });
  // }
};

export const fetchRenterApplication = (userId) => async (dispatch) => {
  try {
    const { data: ra } = await services.fetchRenterApplication(userId);
    return dispatch({
      type: 'GET_RENTER_APP_SUCCESS',
      value: ra
    });
  } catch(err){
    const error = _.get(err, 'response.data', err);
    return dispatch({
      type: 'GET_RENTER_APP_ERROR',
      error
    });
  }
};

export const doLogout = () => async (dispatch) => {
  dispatch({ type: 'LOGOUT_PENDING' });

  try {

    localStorage.removeItem('auth');
    const { data: logout } = await services.submitLogout();

    dispatch({
      type: 'LOGOUT_SUCCESS',
      value: logout
    });
  } catch (err) {
    dispatch({
      type: 'LOGOUT_ERROR',
      error: err.response.data
    });
  }
};


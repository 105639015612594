import createReducer from 'utils/createReducer';


const initialState = {
  pending: false,
  isAuthenticated: false,
  success: false,
  error: false,
  user: null,
  jwt: null
};


export default createReducer(initialState, {
  UPDATE_FIELD: (state, action) => ({
    ...state,
    fields: {
      ...state.fields,
      [action.field]: action.value
    }
  }),
  GET_SESSION_SUCCESS: (state, action) => {
    localStorage.setItem('auth', JSON.stringify(action.value));
    return ({
      ...state,
      pending: false,
      success: true,
      error: false,
      user: action.value.user,
      jwt: action.value.jwt,
      isAuthenticated: true
    })
  },
  SUBMIT_LOGIN_PENDING: (state) => ({
    ...state,
    pending: true,
    success: false,
    error: false
  }),
  SUBMIT_LOGIN_SUCCESS: (state, action) => {
    localStorage.setItem('auth', JSON.stringify(action.value));
    return ({
      ...state,
      pending: false,
      success: true,
      error: false,
      user: action.value.user,
      jwt: action.value.jwt,
      isAuthenticated: true
    })
  },
  SUBMIT_LOGIN_ERROR: (state, action) => ({
    ...state,
    pending: false,
    success: false,
    error: action.error
  })
});


import React from 'react';
import _ from 'lodash';
import { TextMask } from 'react-text-mask-hoc';




class InputMask extends React.Component {
  state = {};

  render() {
    const propsToKeep = ['Component', 'onChange', 'mask', 'guide', 'placeholderChar', 'keepCharPositions', 'pipe', 'showMask'];
    const fieldsForMask = _.pick(this.props, propsToKeep);
    const iFields = _.omit(this.props, propsToKeep);

    const className = `${(iFields.invalid ? 'is-invalid' : (iFields.valid) ? 'is-valid' : '')} ${iFields.className || ''}`;
    return (
      <TextMask
        {...fieldsForMask}
        {...iFields}
        valid={iFields.valid ? 'true' : 'false'}
        invalid={iFields.invalid ? 'true' : 'false'}
        className={className}
      />
    )
  }
}

export default InputMask;

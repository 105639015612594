import React from 'react';


const PrivacyPolicy = () => {
  return (
    <div className='container mt-5'>
      <h1 className='text-center'>Privacy Policy</h1>
      <p>Protecting your privacy is really important to us. With this in mind, we're providing this privacy policy
        (this “Privacy Policy”) to explain our practices regarding the collection, use and disclosure of
        information that we receive through our Electronic Service (as defined below) and/or Rentivity Product (as
        defined below). This Privacy Policy does not apply to any third-party websites, services or applications,
        even if they are accessible through our Electronic Service and/or Rentivity Product.</p>

      <p><strong>Definitions</strong></p>
      <p>In this Privacy Policy:</p>
      (a) The word “Account” means the account you have with us now or in the future, including ones which
      you open through the Electronic Service.
      <br />(b) The word “Communications” means each disclosure, notice, agreement, fee schedule, statement,
      record, document, transaction history, privacy policies including this Privacy Policy (and any revisions
      thereto), and other information we provide to you, or that you sign or submit or agree to at our request and
      any amendments thereto.
      <br />(c) The words “Electronic Service” means each and every product and service we offer that you apply
      for, own, use, or access using the Internet, a website, email, messaging services (including text messaging)
      and/or software applications (including applications for mobile or handheld devices), either now or in the
      future. Rentivity has no liability to you with respect to third party products and/or services which you
      apply for, use, or access using the Electronic Service.
      <br />(d) The words “include” and “including,” when used at the beginning of a list of one or more items,
      indicates the list contains examples – the list is not exclusive or exhaustive, and the items in the list
      are only illustrations. They are not the only possible items that could appear in the list. Further, the
      words “include” and "including" shall mean including without limitation.
      <br />(e) “Rentivity Product” means each and every Account, product or service we offer that you apply for,
      own, use, or access, either now or in the future. Rentivity Product includes Electronic Service. However,
      Rentivity Product does not include third party products and/or services which you apply for, use, or access
      using the Electronic Service.
      <br />(f) The words “you”, “your”, “my” and “me” means the person reviewing and/or agreeing to this Privacy
      Policy, and also each authorized signer, authorized representative, occupant, product user and/or service
      user identified with any Rentivity Product that you apply for, use or access.
      <br />(g) The words “we”, “us”, “our”, and “Rentivity” means Rental Technologies, LLC d/b/a Rentivity, and
      each and every current and future affiliate of Rentivity.

      <p>The definition of a word or words in this Privacy Policy shall not define the same word or words in
        Rentivity’s ESIGN Consent or Terms and Conditions, both of which have their own specific definitions and
        are separate and distinct documents from this Privacy Policy. Likewise, any word or words which are not
        defined in this Privacy Policy but are defined in Rentivity’s ESIGN Consent and/or Terms and Conditions
        shall not be so defined herein. Further, references to Rentivity’s ESIGN Consent and/or Terms and
        Conditions in this Privacy Policy shall not be utilized to interpret any term or provision in either the
        ESIGN Consent and/or the Terms and Conditions.</p>
      <p><strong>Revisions to this Privacy Policy</strong></p>
      <p>Information that is collected via our Electronic Service and/or Rentivity Product is covered by the
        Privacy Policy in effect at the time such information is collected. We may revise this Privacy Policy from
        time to time. If we make any material changes to this Privacy Policy, we will notify you of those changes
        by posting them on our Electronic Service or by sending you an email or other notification, and we will
        update the date at the end of this Privacy Policy to indicate when those changes become effective.</p>

      <p>You agree that we may satisfy our obligation to provide you with an annual copy of our Privacy Policy by
        keeping it available for review on https://rentivity.com, or, with prior notice to you, on another website
        where we offer Electronic Services.</p>

      <p><strong>Collection and Use of Information</strong></p>
      <p><strong>Information Collected or Received from You</strong></p>
      <p>Our primary goals in collecting information are to provide and improve our Electronic Service and/or
        Rentivity Product, to administer your use of the Electronic Service (including your Account, if you are an
        Account holder) and/or our Rentivity Product, and to enable you to enjoy and easily navigate our
        Electronic Service and/or Rentivity Product.</p>

      <p><u>Account Information.</u> If you create an Account, we will collect certain personal information that
        can be used to identify you, including your name, email address, postal address, phone number, demographic
        information including your preferences and interests, website usage data, other information about you
        relevant to other customers of Rentivity, other information pertaining to special offers and surveys to
        you, and date of birth (collectively your “Personal Information"). If you create an Account using your
        login credentials from one of your social network accounts (“SNS Accounts”), we will be able to access and
        collect your name and email address and other Personal Information that your privacy settings on the SNS
        Account permit us to access. If you create an Account through our Electronic Service or one of your SNS
        Accounts, we may also collect your gender, date of birth and other Personal Information as well as
        non-personal information relating to you. Non-personal information is information which cannot be used by
        itself to identify you.</p>

      <p><u>Financial Information.</u> In connection with a financial transaction via the Electronic Service
        and/or Rentivity Product through our third party payment services provider it will collect your
        credit/debit card information or other financial and payment information. We do not store, have access to
        or utilize your credit/debit card information or other financial and payment information, except for the
        amount of your lease deposit and, if you make monthly lease payments via the Electronic Service and/or a
        Rentivity Product, the amount and date of payment (collectively, the “Lease Payment History”). We will use
        your Lease Payment History information solely in connection with our business and will not share this
        information with third parties, except for owners and property managers associated with your rental, with
        your consent or to the extent necessary to comply with applicable law.</p>
      <p><u>Real Estate Information.</u> When you use the Electronic Service and/or Rentivity Product to list,
        delete, access, bid upon and/or lease a property and/or properties, we will present property related
        information with respect to that property, which we obtain either directly from the owner, property
        manager or from a third party source. In addition, we will analyze any Personal Information and/or
        non-personal information that you provide via the Electronic Service and/or Rentivity Product and make the
        results of such analysis available to other Users (as defined below) of the Electronic Service and/or
        Rentivity Product.</p>
      <p><u>Real Estate Transaction Information.</u> When you complete a lease of a property included on our
        Electronic Service and/or Rentivity Product, we will collect information regarding the completed lease
        transaction. We may use this information to improve and personalize our Electronic Service and/or
        Rentivity Product.</p>

      <p><u>Information Collected Using Cookies and other Web Technologies.</u> Like many website owners and
        operators, we use automated data collection tools such as Cookies, Web Beacons, unique identifiers, and
        Widgets to collect certain information.</p>

      <p>"Cookies" are small text files that are placed on your device or hard drive by a web server when you
        access our Electronic Service and/or Rentivity Product. We may use both session Cookies and persistent
        Cookies to identify that you have logged in to the Electronic Service and/or Rentivity Product and to tell
        us how and when you interact with our Electronic Service and/or Rentivity Product. We may also use Cookies
        to monitor aggregate usage and web traffic routing on our Electronic Service and/or Rentivity Product and
        to customize and improve our Electronic Service and/or Rentivity Product. Unlike persistent Cookies,
        session Cookies are deleted when you navigate away from or log off the Electronic Service and/or Rentivity
        Product and close your browser. Although most browsers automatically accept Cookies, you can change your
        browser options to stop automatically accepting Cookies or to prompt you before accepting Cookies. Please
        note, however, that if you do not accept Cookies, you may not be able to access all portions or features
        of the Electronic Service and/or Rentivity Product. Some third-party services providers that we engage
        (including third-party advertisers) may also place their own Cookies on your device or hard drive. Note
        that this Privacy Policy covers only our use of Cookies and does not include use of Cookies by such third
        parties.</p>

      <p>"Web Beacons" (also known as web bugs, pixel tags or clear graphics interchange formats) are tiny
        graphics with a unique identifier that may be included on our Electronic Service and/or Rentivity Product
        for several purposes, including to deliver or communicate with Cookies, to track and measure the
        performance of our Electronic Service and/or Rentivity Product, to monitor how many visitors view our
        Electronic Service and/or Rentivity Product, and to monitor the effectiveness of our advertising. Unlike
        Cookies, which are stored on the user's device or hard drive, Web Beacons are typically embedded invisibly
        on web pages (or in an e-mail).</p>

      <p>"Widgets" are small programs or applications that can be embedded in a web page. Widgets can provide
        real-time information, such as stock quotes or weather reports. Widgets are often provided by a third
        party, and may allow that third party to collect data about users viewing that page.</p>

      <p><u>Information Related to Use of the Electronic Service and/or Rentivity Product.</u> Our servers
        automatically record certain information about how a person uses our Electronic Service and/or Rentivity
        Product (we refer to this information as "Log Data"), including both Account holders and non-Account
        holders (either, a "User"). Log Data may include information such as a User's Internet Protocol (“IP”)
        address, browser type, operating system, the web page that a User was visiting before accessing our
        Electronic Service and/or Rentivity Product, the pages or features of our Electronic Service and/or
        Rentivity Product to which a User browsed and the time spent on those pages or features, search terms, the
        links on our Electronic Service and/or Rentivity Product that a User clicked on and other statistics. We
        use Log Data to administer the Electronic Service and/or Rentivity Product and we analyze (and may engage
        third parties to analyze) Log Data to improve, customize and enhance our Electronic Service and/or
        Rentivity Product by expanding their features and functionality and tailoring them to our Users' needs and
        preferences. We may use a person's IP address to generate aggregate, non-identifying information about how
        our Electronic Service and/or Rentivity Product are used.</p>

      <p><u>Information Sent by Your Mobile/Electronic Device.</u> We collect certain information that your
        mobile/electronic device sends when you use our Electronic Service and/or Rentivity Product, like a device
        identifier, user settings and the operating system of your device, as well as information about your use
        of our Electronic Service and/or Rentivity Product.</p>

      <p><u>Information. </u> When you use our Electronic Service, Rentivity Product and/or application, we may
        collect and store information about your location by converting your IP address into a rough geo-location
        or by accessing your mobile/electronic device's GPS coordinates or coarse location if you enable location
        services on your device. We may ask you to manually provide precise location information on our Electronic
        Service, Rentivity Product and/or application. We may use location information to improve and personalize
        our Electronic Service and/or Rentivity Product for you. If you do not want us to collect location
        information, you may disable that feature on your mobile/electronic device.</p>

      <p><strong>Information that We Share with Third Parties</strong></p>
      <p>We will not share any Personal Information that we have collected from or regarding you except as
        described in this Privacy Policy:</p>

      <p><u>Information Shared with Our Services Providers.</u> We may engage third-party services providers to
        work with us to administer and provide the Electronic Service and/or Rentivity Product.for example
        payment services providers. These third-party services providers have access to your Personal Information
        only for the purpose of performing services you requested and/or agreed to.</p>

      <p><u>Information Shared with Third Parties.</u> We may share aggregated information and non-identifying
        information with third parties for industry research and analysis, demographic profiling and other similar
        purposes.</p>

      <p>We may share Personal Information you provide to us via the Electronic Service and/or Rentivity Product,
        non-personal information and other information we have about you (not to include your Lease Payment
        History) with third-parties and the third parties may personalize the Third-Party Service (as defined
        below) or content that they then provide to you. Further, when you enter your credit/debit card and other
        financial and payment information with our Third-Party Service provider and authorize payment(s), if you
        are the winning bidder or agree to rent the property at the posted rental amount sought by the owner
        and/or property manager, you will also authorize your screening report to be shared with the third party
        responsible for leasing and/or managing the property.</p>
      <p>We will comply with applicable legal requirements to the extent the requirements require us to obtain
        your consent before sharing such information with third parties.</p>

      <p><u>Information Disclosed in Connection with Business Transactions.</u> Information that we collect from
        our Users, including Personal Information, is considered to be a business asset. Thus, if we are acquired
        by a third party as a result of a transaction such as a merger, acquisition or asset sale or if our assets
        are acquired by a third party in the event we go out of business or enter bankruptcy, some or all of our
        assets, including your Personal Information, may be disclosed or transferred to a third party acquirer in
        connection with the transaction.</p>

      <p><u>Information Disclosed for Our Protection and the Protection of Others.</u> We cooperate with
        government and law enforcement officials or private parties to enforce and comply with the law. We may
        disclose any information about you to government or law enforcement officials or private parties as we, in
        our sole discretion, believe necessary or appropriate:
        <br />(i) to respond to claims, legal process (including
        subpoenas).
        <br />(ii) to protect our property, rights and safety and the property, rights and safety of a third
        party or the public in general.and
        <br />(iii) to stop any activity that we consider illegal, unethical or
        legally actionable activity.</p>

      <p><strong>Your Choices</strong></p>
      <p>We offer you choices regarding the collection, use and sharing of your Personal Information and we will
        respect the choices you make. Please note that if you decide not to provide us with your Personal
        Information that we request, you may not be able to access all of the features of the Electronic Service
        and/or Rentivity Product.</p>

      <p>Opt-Out. We may periodically send you free newsletters, e-mails and/or text messages that directly
        promote our Electronic Service and/or Rentivity Product. When you receive such promotional Communications
        from us, you will have the opportunity to “opt-out" (either through your Account or by following the
        unsubscribe instructions provided in the e-mail you receive). We do need to send you certain
        Communications regarding the Electronic Service and/or Rentivity Product and you will not be able to opt
        out of those Communications including Communications regarding updates to our Terms and Conditions or this
        Privacy Policy or information about billing.</p>

      <p>Modifying/Deleting Your Information. You can access and modify your Personal Information associated with
        your Account by updating your profile and payment information online. If you want us to delete your
        Personal Information and your Account, please contact us at deletePI@rentivity.com with your request. We
        will take steps to delete your information as soon we can, but some information may remain in
        archived/backup copies for our records or as otherwise required by law.</p>


      <p>Responding to Do Not Track Signals</p>
      <p>Our Electronic Service and/or Rentivity Product does not have the capability to respond to “Do Not
        Track" signals received from various web browsers.</p>

      <p>The Security of Your Information</p>
      <p>We take reasonable administrative, physical and electronic measures designed to protect the information
        that we collect from or about you (including your Personal Information) from unauthorized access, use or
        disclosure. When you enter sensitive information on our forms, Electronic Service and/or Rentivity
        Product, we encrypt this data. Please be aware, however, that no method of transmitting information over
        the Internet or storing information is completely secure. Accordingly, we cannot guarantee the absolute
        security of any information.</p>

      <p>Links to Other Sites</p>
      <p>Our Electronic Service and/or Rentivity Product may contain links to websites and services that are
        owned or operated by third parties, including renter insurance services, credit and background screening
        services and payment processing (each, a “Third-Party Service"). Any information that you provide on or
        to a Third-Party Service or that is collected by a Third-Party Service is provided directly to the owner
        or operator of the Third-Party Service and is subject to the owner's or operator's privacy policy. We
        are not responsible for the content, privacy or security practices and policies of any Third-Party
        Service. To protect your information we recommend that you carefully review the privacy policies of all
        Third-Party Services that you access.</p>

      <p>International Transfer</p>
      <p>Your Personal Information may be transferred to, and maintained on, computers located outside of your
        state, province, country or other governmental jurisdiction where the privacy laws may not be as
        protective as those in your jurisdiction. If you are located outside the United States and choose to
        provide your Personal Information to us, we may transfer your Personal Information to the United States
        and process it there.</p>

      <p>Our Policy Toward Children</p>
      <p>Our Electronic Service and/or Rentivity Product are not directed to children under 18 and we do not
        knowingly collect Personal Information from children under 18 except if they are an occupant of the
        rental then their names and ages are required by the owner and/or property manager. If we learn that we
        have collected Personal Information of a child under 18 (except as stated in the prior sentence), we
        will take steps to delete such information from our files as soon as possible.</p>

      <p>Communications in languages other than English.</p>
      <p>Please note, we may be unable to fulfill and service your rights pursuant to this Privacy Policy in a
        language other than English. Future Communications may be in English only. If you are not fluent in
        English, you should consider obtaining the services of an interpreter or taking other steps to ensure
        you understand this Privacy Policy before agreeing to it and to explain any future Communications in
        English.</p>

      <p>Acceptance and Consent. By consenting to this Privacy Policy, you agree to the following statements:
        (a) I have read, understand and agree to be bound by the terms and conditions described herein
        <br /> (b) My
        consent to this Privacy Policy does not automatically expire and is not limited as to duration <br />
        (c)
        Withdrawing my consent does not change the legal effect, of validity or enforceability of my prior
        consent.
        <br />(d) Rentivity and/or its agents may revoke my participation in the Electronic Service and/or
        Rentivity Product(s) and/or program(s) at any time at its and/or their discretion.
        <br />(e) Any third party
        and/or its agents providing products and/or services to me may revoke my participation in its products
        and/or services at any time at its and/or their discretion.
        <br />(f) Neither Rentivity, its agents nor any
        third party and/or its agents shall be liable for any loss, liability, cost, expense, or claim for
        acting upon this authorization or arising from my use of the Electronic Service and/or Rentivity Product
        and/or services or other third party product and/or services provided pursuant to this Privacy Policy;
        <br /> (h) Documents that may be provided contain important information or disclosures concerning the
        Privacy Policy and I agree to review such documents in a timely manner.</p>
      <p>By clicking “I AGREE”, you acknowledge that you have read and agree to the terms set forth in this
        Privacy Policy and that you give your affirmative consent for us to provide you with the Electronic
        Service and/or Rentivity Product. You are also confirming that
        <br /> (a) By your consent you confirm that you
        are able to receive, access and view records and documents in PDF, HTML format and electronic records;
        <br /> (b) You are able to print or save a copy of this Privacy Policy for your records.</p>
      <p>You are also confirming that you are authorized to, and do, consent on behalf of all the other
        authorized signers, authorized representatives, occupants, product users and/or service users identified
        with the Electronic Service and/or any Rentivity Product.</p>
      <p>Questions?</p>
      <p>Please contact us at questions@rentivity.com if you have any questions about our Privacy Policy.</p>

      <p>As of February 23, 2019</p>
    </div>
  );
}

export default PrivacyPolicy;

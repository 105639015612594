import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { uploadImage } from '../utils/services';
import _ from 'lodash';
const debug = require('debug')('components:ImageDropzone');


const ImageDropzone = ({ 
  // image: pImage, 
  onFileUpload, 
  dropzoneMessage = 'Drop file here, or click to select file',
  ...props
}) => {
  const [image, setImage] = useState({});

  useEffect(() => {
    if (!_.isEqual(image, props.image) && !_.isEmpty(props.image)) {
      setImage(props.image);
    }
  }, [props.image, image]);

  const handleFileDrop = useCallback(async (imageFiles) => {
    const formData = new FormData();
    const imgFile = _.head(imageFiles);
    debug('handleFileDrop', imgFile);

    formData.append('images[]', imgFile);
    const { data: { images: uploaded } } = await uploadImage(formData);
    setImage(_.head(uploaded));
    onFileUpload(_.head(uploaded));
  }, [onFileUpload]);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    disabled: props.disabled,
    onDrop: handleFileDrop,
    accept: 'image/*'
  });

  const className = (_.isEmpty(image)) ? 
    'd-flex flex-row justify-content-center align-items-center text-center drop-container bd-highlight p-2 py-5' : 
    'd-flex flex-row justify-content-center align-items-center drop-container'

  return (
    <div className={className} {...getRootProps()}>
      <input {...getInputProps()} />
      {
        (!_.isEmpty(image) && image.src) ? 
          <img
            className='img-thumbnail mt-2 profile-image'
            alt='profile_img'
            src={image.src}
            width='200px'
            height='200px'
            draggable={false}
            {...props.imageProps}
          /> : 
          <h4 className="m-0">{dropzoneMessage}</h4>
      }
    </div>
  )
}

ImageDropzone.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string.isRequired
  }),
  onFileUpload: PropTypes.func.isRequired,
  dropzoneMessage: PropTypes.string,
}

ImageDropzone.defaultProps = {
  disabled : false
}

export default ImageDropzone;

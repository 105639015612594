import _ from 'lodash';

import markerIcon from 'assets/img/brand/brand-marker-icon.png';
import MarkerClusterer from "@google/markerclusterer";
import MarkerClustererPlus from '@google/markerclustererplus';

const debug = require('debug')('property-search:utils');

const google = window.google;

export const mongoGeoToBounds = (bounds) => {
  return new window.google.maps.LatLngBounds({ lat: bounds[0][1], lng: bounds[0][0] }, { lat: bounds[1][1], lng: bounds[1][0] })
};

export const mongoGeoToLiteralBounds = (bounds) => {
  return ({ south: bounds[0][1], west: bounds[0][0], north: bounds[1][1], east: bounds[1][0] })
};
export const boundsToMongo = (bounds) => {
  const log = debug.extend('boundsToMongo');
  if (!bounds.getSouthWest || !bounds.getNorthEast) {
    log('invalid bounds object passed');
    return null;
  }
  const sw = bounds.getSouthWest().toJSON();
  const ne = bounds.getNorthEast().toJSON();

  return [
    [sw.lng, sw.lat],
    [ne.lng, ne.lat],
  ]
};

export const isMongoGeo = (arr) => {
  if (!_.isArray(arr) || arr.length !== 2) {
    return false;
  }
  if (arr[0].length !== 2 || arr[1].length !== 2){
    return false;
  }
  if(!_.isNumber(arr[0][0]) || !_.isNumber(arr[0][1]) || !_.isNumber(arr[1][0]) || !_.isNumber(arr[1][1])){
    return false;
  }
  return true;
}




export const markerClustererFactory = (map, markers, usePlus = true) => {
  const mcOptions = {
    maxZoom: 15,
    gridSize: 40,
    enableRetinaIcons: false,
    averageCenter: true,
    // imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    imagePath: `${process.env.PUBLIC_URL}/assets/img/m`
  };
  
  try {
    return (usePlus) ? new MarkerClustererPlus(map, markers, mcOptions) : new MarkerClusterer(map, markers, mcOptions);
  } catch(err) {
    console.error(err);
  }
};



export const attachMapEventHandlers = (ctx, evtMap, props) => {
  const log = debug.extend('attachMapEventHandlers');
  if (!google && !window.google){
    log('no window.google defined');
    return;
  }
  const { map, /*markerclusterer*/ } = ctx;

  // only keep props that are keys in eventMap
  const validPropKeys = _.keys(evtMap);
  const evtHandlerProps = _.pick(props, validPropKeys);

  for (const key in evtHandlerProps){
    const val = evtHandlerProps[key];
    if (!val || !_.isFunction(val)){
      return;
    }
    log('adding listener', evtMap[key]);
    map.addListener(evtMap[key], () => val(ctx, props))
  }

  return map;
};


export const toMarker = (map, mc,  mspec, eventMap = {}) => {
  const log = debug.extend('toMarker');
  const { lat, lng, _id } = mspec;
  window.google.maps.Marker.prototype.isDraggable = () => false;
  const m = new window.google.maps.Marker({
    _id,
    map: map,
    position: { lat, lng },
    draggable: false,
    icon: markerIcon
  });

  for (const key in eventMap){
    if (_.isFunction(eventMap[key])){
      log(`adding marker event handler: ${key}`);
      window.google.maps.event.addListener(m, key, () => {
        eventMap[key]({ map: map, markerclusterer: mc }, m, mspec);
      });
    }
  }
  return m;
}

import { call, put, takeLatest } from 'redux-saga/effects';
import * as services from '../../../../utils/services';


export function * getAccounting(action){
  try {
    const { data: acccounting } = yield call(services.fetchOrganizationAccountingInfo, [action.id])
    yield put({ type: 'GET_ACCOUNTING_SUCCESS', value: acccounting });
  } catch (err) {
    yield put({ type: 'GET_ACCOUNTING_ERROR', error: err });
  }
}

// /**
//  * Root saga manages watcher lifecycle
//  */
export default function* () {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest('GET_ACCOUNTING', getAccounting);
}

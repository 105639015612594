import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _findLastIndex from 'lodash/findLastIndex';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _includes from 'lodash/includes';

import { Card, CardBody, CardHeader } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


export default class LeaseWrapupOrderer extends Component {

  constructor(props, ctx) {
    super(props, ctx);
    this.state = {
      leaseWrapupSteps: []
    }
  }

  static propTypes = {
    steps: PropTypes.array.isRequired,
    rearrangeSteps: PropTypes.func.isRequired
  }

  droppableIds = {
    selectedDroppable: 'leaseWrapupSteps',
  };

  componentWillReceiveProps(nextProps){
    // if (nextProps.leaseWrapupSteps !== this.props.leaseWrapupSteps){
      this.setState({
        leaseWrapupSteps: nextProps.steps || []
      })
    // }
  }

  /**
   * @method getItemStyle is used to style the draggable elements.
   * @param {Boolean} isDragging is the state of elements drag.
   * @param {Object} draggableStyle contains the default style of the draggable element.
   */
  getItemStyle = (isDragging, draggableStyle, disableDrag) => ({
    userSelect: 'none',
    padding: 12,
    margin: '0 0 8px 0',
    background: disableDrag ? '#dddddd' : isDragging ? '#80b346' : '',
    border: '1px solid #ddd',
    color: isDragging ? 'white' : 'black',
    ...draggableStyle
  });

  /**
   * @method getListStyle is used to style the outer part that contains total draggable elements.
   */
  getListStyle = () => ({
    padding: 8,
    width: '100%',
    height: '100%',
    border: '1px solid #ddd'
  });

  /**
   * @method reorder is used to reorder the existing steps.
   * @param {Array} list is the available total steps.
   * @param {Number} startIndex is the index of the currently dragged element.
   * @param {Number} endIndex is the index of the dropped location of dragged element.
   */
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    const swapped = _find(Array.from(list), (item, index) => index === endIndex);

    // Swap step numbers - START
    swapped.stepNumber = removed.stepNumber;
    removed.stepNumber = endIndex + 1;
    // Swap step numbers - END
    result.splice(endIndex, 0, removed);

    return result;
  }

  /**
   * @method onDragEnd is called when drag and drop happen.
   * @param {Object} result contains the details of source and destination of drag and drop elements.
   */
  onDragEnd = result => {
    const { source, destination } = result;
    const { steps, rearrangeSteps } = this.props;
    const { leaseWrapupSteps } = this.state;

    // If same source and destination do nothing
    if (source.index === destination.index) {
      return;
    }

    // If no destination then do nothing
    if (!destination) {
      return;
    }

    // If the drop location has the static element then do nothing
    const toBeReplacedElem = steps[destination.index];
    if(toBeReplacedElem.isStatic) {
      return;
    }

    // If the drop index of the source element is out of it's league then do nothing
    const currentDragged = _find(leaseWrapupSteps, item => item._id === result.draggableId);
    if (!_includes(currentDragged.possiblePos, destination.index)) {
      return;
    }

    const reorder = this.reorder(
      leaseWrapupSteps,
      source.index,
      destination.index
    );

    this.setState({
      leaseWrapupSteps: reorder
    }, () => rearrangeSteps(this.state.leaseWrapupSteps));
  }

  render() {
    const { leaseWrapupSteps } = this.state;
    const { disableDrag } = this.props;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
          {/* Lease Wrapup Steps */}
          <Card>
            {/* <CardHeader>Order</CardHeader> */}
            <CardBody>
              <Droppable droppableId='selectedDroppable'>
                {(provided) => (
                  <div style={this.getListStyle()} ref={provided.innerRef}>
                    {
                      leaseWrapupSteps.length === 0 ?
                        <div className='drop-zone'>
                          Drop Zone
                        </div>
                        :
                        _map(leaseWrapupSteps, (order, index) => {
                          const staticElement = order.isStatic;
                          return (
                            <Draggable
                              key={order._id}
                              draggableId={order._id}
                              index={index}
                              isDragDisabled={disableDrag ? true : staticElement}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                  style={this.getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                    disableDrag
                                  )}
                                >
                                  <div className='order'>
                                    <i className={staticElement ? 'fa fa-ban' : 'fa fa-bars'}></i>
                                    {order.label}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          )
                        })
                    }
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </CardBody>
          </Card>
      </DragDropContext>
    );
  }
}

import createReducer from 'utils/createReducer'



const initialState = {
  pending: false,
  success: false,
  error: false,
  fields: { acctType: 'renter', email: '', firstName: '', lastName: '', password: '', passwordConfirm:'' },
  registrationState: { step: -1 },
  organization: {}
};


export default createReducer(initialState, {
  SUBMIT_REGISTRATION_STEP_PENDING: (state) => ({
    ...state,
    pending: true
  }),
  SUBMIT_REGISTRATION_STEP_SUCCESS: (state, action) => ({
    ...state,
    success: true,
    pending: false,
    error: false,
    user: action.value
  }),
  SUBMIT_REGISTRATION_STEP_ERROR: (state, action) => {
    if (action.stepName !== 'roles-and-team'){
      return state;
    }

    return ({
      ...state ,
      pending: false,
      success: false,
      error: action.error
    })
  }
});


import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, CardGroup } from 'reactstrap';
import { fromRenderProps } from 'recompose';
import { ToastContext } from '../../../../contexts';



const PageWrap = ({ children }) => (
  <div className="app flex-row align-items-center email-verified text-center">
    <Container>
      <Row className="justify-content-center">
        <Col md="8">
          <CardGroup>
            <Card className="email-verified-content">
              <CardBody>
                {children}
              </CardBody>
            </Card>
          </CardGroup>
        </Col>
      </Row>
    </Container>
  </div>
);

/**
 * Do http request to backend to verify email address and then redirect to appropriate registration step
 */
class EmailVerification extends Component {
  static defaultProps = {
    verification: {
      pending: false,
      error: false,
      success: false
    }
  };


  componentDidMount = async () => {
    if (this.props.match.params.token) {
      await this.props.verifyEmail(this.props.match.params.token);
    }
  }

  componentWillUnmount() {
    this.props.resetVerifyEmailState();
  }

  render() {
    if (this.props.verification.success && !this.props.verification.pending) {
      // is set-password is pending then route to set-password page
      if (this.props.setPasswordPending) {
        this.props.showSuccessToast('Email successfully verified', { delay: 10000 });
        this.props.history.push({
          pathname: `/register/${this.props.match.params.token}/set-password`,
          state: {
            from: this.props.location.pathname,
            token: this.props.match.params.token
          }
        })
      }

      return (
        <PageWrap>
          <h3 className='text-white mb-3'>RENTING REINVENTED</h3>
          <div className="pb-2">
            <i className="fa fa-envelope-open fa-3x"></i>
          </div>
          <div className="pb-2 email-verified-msg">Email Verified Successfully!!!</div>
          <div className="pb-2 email-verified-msg-sub">
            <Link className="login-link" to={{ pathname: '/login', state: this.props.location.state }}>Please Click here to log in</Link>
          </div>
        </PageWrap>
      )
    }

    if (this.props.verification.error && !this.props.verification.pending) {
      return (
        <PageWrap>
          <div>{this.props.verification.error.message}</div>
        </PageWrap>
      )
    }

    return (
      <PageWrap>Verifying Email Address...</PageWrap>
    );
  }
}

const withContext = fromRenderProps(ToastContext.Consumer, (props) => props);

export default withContext(EmailVerification);
import React from 'react';
import { TabContent, TabPane } from 'reactstrap';

import img1 from 'assets/img/howItWorks/house-searcher.png';
import AccManagement from 'assets/img/howItWorks/account-management.png';
import Apply from 'assets/img/howItWorks/apply.png';
import Offer from 'assets/img/howItWorks/offer.png';
import Signature from 'assets/img/howItWorks/signature.png';
import Edit from 'assets/img/howItWorks/edit.png';

import './styles.scss';


export default class HowItWorks extends React.Component {

  state = {
    activeTab: 'landlord'
  }

  toggle = tab => {
    if (tab !== this.state.activeTab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  routeToContactUsPage = () => {
    this.props.history.push('/contact-us');
  }

  render() {
    return (
      <div className='container mt-5 how-it-works'>
        <h1 className="header-large text-darkgrey text-left mb-5" >How It Works</h1>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item text-darkgrey" ><a href="_" className="text-darkgrey">Home</a></li>
          </ol>
        </nav>
        <p className="text-center rest-desc">Rentivity is an equal opportunity rental home bidding, screening, and management platform designed to help <span>tenants</span> and <span>landlords</span> eliminate unnecessary costs, mitigate inherent risks, and save valuable time.</p>

        <div className="page-subtitle"><h2 className="text-darkgrey">Here&apos;s <span className="text-green">How It Works</span></h2></div>

        <div className="btn-groups">
          <button type="button" className={`${this.state.activeTab === 'renter' ? 'active' : ''} btns mr-2`} onClick={() => this.toggle('renter')}>
            I&apos;m a Renter
          </button>
          <button type="button" className={`${this.state.activeTab === 'landlord' ? 'active' : ''} btns`} onClick={() => this.toggle('landlord')}>
            I&apos;m a Landlord
          </button>
        </div>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId='renter'>
            <div className="flex-rows hiw-flow-grid">
              <div className="flex-col">
                <div className="grid-box">
                  <img src={img1} alt="Find A Home" />
                  <h4>FIND A HOME</h4>
                  <p>Browse current listings, save searches, get new listing notification, or schedule a property showing.</p>
                </div>
              </div>
              <div className="flex-col">
                <div className="grid-box">
                  <img src={Apply} alt="Apply" />
                  <h4>APPLY</h4>
                  <p>Save money. Pay a single application fee per occupant. View all eligible rentals, make offers using the same application for up to 30 days.</p>
                </div>
              </div>
              <div className="flex-col">
                <div className="grid-box">
                  <img src={Offer} alt="Make An Offer" />
                  <h4>MAKE OFFER</h4>
                  <p>Get the home you truly want through equal opportunity bidding or save time with the Rent it Now feature.</p>
                </div>
              </div>
              <div className="flex-col">
                <div className="grid-box">
                  <img src={Signature} alt="Sign Lease" />
                  <h4>SIGN LEASE</h4>
                  <p>Sign your lease online, upload, store, and view your documents.</p>
                </div>
              </div>
              <div className="flex-col">
                <div className="grid-box">
                  <img src={AccManagement} alt="Manage Account" />
                  <h4>MANAGE ACCOUNT</h4>
                  <p>Pay rent online, purchase rental insurance, request repairs, and more.</p>
                </div>
              </div>
            </div>

            <div className="features">
              <div className="flex-rows">
                <div className="flex-col">
                  <h2 className="text-darkgrey"><span className="text-green">Rentivity</span> Features</h2>
                  <ul>
                    <li>
                      <h4>Rental Marketplace</h4>
                      <p>Shop for Renter&apos;s Insurance and specialized savings programs on security deposits, lease guarantees, moving services and more.</p>
                    </li>
                    <li>
                      <h4>Leasing</h4>
                      <p>View the qualification requirements, lease and terms before you pick your home. The process is transparent and highly automated.</p>
                    </li>
                    <li>
                      <h4>User Portal</h4>
                      <p>Pay rent, view your history, request and schedule repairs (coming soon).</p>
                    </li>
                    <li>
                      <h4>Manage Your Information</h4>
                      <p>Thinking of buying in the future? Need to build credit? Choose to have your rental payments reported to a national credit bureau. (coming soon).</p>
                    </li>
                    <li>
                      <h4>User Experience</h4>
                      <p>View your qualifications, manage your offers and benefit from a leasing process that is fair and transparent.</p>
                    </li>
                  </ul>
                </div>
                <div className="flex-col videoContainer">
                  <div className="videoWrapper">
                    <iframe
                      title="Renting Made Easy"
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/fFtFoUorLP4"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    >
                    </iframe>
                  </div>
                  <button type="button" onClick={() => this.routeToContactUsPage()} className="btn-contact-us"><img src={Edit} alt="Edit" /> Contact Us</button>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="landlord">
            <div className="flex-rows hiw-flow-grid">
              <div className="flex-col">
                <div className="grid-box">
                  <img src={img1} alt="Find A Home" />
                  <h4>MARKETING</h4>
                  <p>Your rentals will automatically be syndicated to all the major sites (i.e. Zillow, Trulia, HotPads).</p>
                </div>
              </div>
              <div className="flex-col">
                <div className="grid-box">
                  <img src={Apply} alt="Apply" />
                  <h4>QUALIFYING</h4>
                  <p>Prospective tenants match their qualifications to yours, reducing offers from unqualified applicants.</p>
                </div>
              </div>
              <div className="flex-col">
                <div className="grid-box">
                  <img src={Offer} alt="Make An Offer" />
                  <h4>REAL-TIME BIDDING</h4>
                  <p>Create more buzz around your rentals with our real-time bidding process. This gives everyone a fair chance and provides real-time market active.</p>
                </div>
              </div>
              <div className="flex-col">
                <div className="grid-box">
                  <img src={Signature} alt="Sign Lease" />
                  <h4>LEASE WRAP-UP</h4>
                  <p>We’ve taken the leasing process digital. With our lease auto population and <span style={{whiteSpace: "pre"}}>E-signature,</span> you can truly take your office on-line.</p>
                </div>
              </div>
              <div className="flex-col">
                <div className="grid-box">
                  <img src={AccManagement} alt="Manage Account" />
                  <h4>ACCOUNT MANAGEMENT</h4>
                  <p>Manage all your accounts in one place. Collect deposits and fees during the leasing process, ongoing rent collections, and vendor payments (coming soon) all in one system.</p>
                </div>
              </div>
            </div>

            <div className="features">
              <div className="flex-rows">
                <div className="flex-col">
                  <h2 className="text-darkgrey"><span className="text-green">Rentivity</span> Features</h2>
                  <h5>Streamlined Digital Management. Save Time. Save Money. Minimize Risks</h5>
                  <ul>
                    <li>
                      <h4>Leasing</h4>
                      <p>Customize and secure your leasing process to insure consistency, every time. Capture the entire process digitally in an immutable Blockchain ledger.</p>
                    </li>
                    <li>
                      <h4>Save Time and Money</h4>
                      <p>Save time and money with one highly automated, integrated system to market, schedule, screen, lease and manage. List properties, pre-screen and schedule showings; Monitor multiple rental offers in real time from pre-screened applicants matched to your specified qualifications; Communicate; Auto-populate and electronically execute documents, all from within a single system.</p>
                    </li>
                    <li>
                      <h4>Fair and Equal Opportunity</h4>
                      <p>Create an open window of time that allows all prospective tenants an equal opportunity to complete a pre-screening application and submit offers to rent. Applicants must meet your specified minimum qualifications. Applicant ID(s) remain anonymous prior to acceptance.</p>
                    </li>
                  </ul>
                </div>
                <div className="flex-col videoContainer">
                  <div className="videoWrapper">
                    <iframe
                      title="Renting Made Easy"
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/fFtFoUorLP4"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    >
                    </iframe>
                  </div>
                  <button type="button" onClick={() => this.routeToContactUsPage()} className="btn-contact-us"><img src={Edit} alt="Edit" /> Contact Us</button>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </div>
    )
  }
}

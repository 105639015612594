import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'config/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import socketio from 'socket.io-client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { LicenseManager } from 'ag-grid-enterprise';
import 'ag-grid-enterprise';
import _ from 'lodash';

import App from './views/App';
import {
  adminRoutes, adminNav,
  renterRoutes, renterNav
} from './config/routes';
import * as serviceWorker from './config/serviceWorker';
import history from './utils/history';
import configureStore from './configureStore';
import './index.scss';

// required for AG grid pro features
LicenseManager.setLicenseKey('Merlion_Solutions,_LLC__Rentivity_1Devs20_December_2019__MTU3NjgwMDAwMDAwMA==1bc9177ef6d9609e5839703fe836fcb7');


window.io = socketio;
const authData = JSON.parse(localStorage.getItem('auth'));
const defData = {
  global: {
    showSpinner: false,
    spinnerText: null,

    isLoading: false,
    pending: false,
    success: false,
    error: false,
    isAuthenticated: !_.isNil(authData) && !_.isEmpty(authData.user),
    session: {
      user: _.isNil(authData) ? {} : authData.user,
      jwt: _.isNil(authData) ? null : authData.jwt
    },
    layout: {
      dashboardType: 'admin',
      sidebar: true,
      header: true
    }
  },
  accounting: {
    transactions: {}
  },
  lease: {},
  login: {}
};

const initData = defData;
const store = configureStore(initData, history);
window.store = store;

const RootApp = (props) => {
  // console.log('renterNav', renterNav);
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App
          {...props}
          navConfig={{ admin: adminNav, renter: renterNav }}
          routes={{ admin: adminRoutes, renter: renterRoutes }}
        />
      </ConnectedRouter>
    </Provider>
  )
};

ReactDOM.render(<RootApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// this is the root reducer file

/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers, bindActionCreators } from 'redux';
import { connectRouter } from 'connected-react-router';

import globalReducer, { layoutReducer } from './views/App/store/reducer';
import propertySearchReducer from './views/Renter/PropertySearch/store/reducer';
import { reducer as mapReducer } from './views/Renter/PropertySearch/components/GMaps/store';
import leaseReducer from './views/Admin/Lease/store/reducer';
import pmLeaseWrapupReducer from './views/Admin/LeaseWrapup/store/reducer';
import loginReducer from './views/Common/Login/store/reducer';
import registrationReducer from './views/Common/Registration/store/reducer';
import { reducer as pmRegistrationReducer } from './views/Admin/PMRegistration/store'
import accounting from './views/Admin/Accounting/store/reducer';
import history from './utils/history';
import { createSlice } from 'redux-starter-kit';

const spinnerSlice = createSlice({
  initialState: {
      isActive: false,
      text: '',
      type: 'spinner',
      progress: 0
  },
  reducers: {
      showProgress: (state, action) => ({
          ...state,
          type: 'progress',
          progress: 0,
          text: action.payload.text,
          isActive: true,
      }),
      updateProgress: (state, action) => ({
          ...state,
          progress: action.payload.progress,
          text: action.payload.text || state.text,
          isActive: true,
      }),
      hideProgress: (state, action) => ({
          ...state,
          text: '',
          progress: 0,
          isActive: false,
      }),

      hideSpinner: (state, action) => ({
          ...state,
          isActive: false,
      }),
      showSpinner: (state, action) => ({
          ...state,
          text: action.payload.text,
          type: 'spinner',
          isActive: true,
      })
  }
});

spinnerSlice.mapDispatchToProps = (dispatch) => bindActionCreators(spinnerSlice.actions, dispatch);



/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const mergeWithRouterState = connectRouter(history);

  const rootReducer = combineReducers({
    lease: leaseReducer,
    login: loginReducer,
    registration: registrationReducer,
    global: globalReducer,
    spinner: spinnerSlice.reducer,
    maps: mapReducer,
    router: mergeWithRouterState,
    propertySearch: propertySearchReducer,
    pmLeaseWrapup: pmLeaseWrapupReducer,
    pmRegistration: pmRegistrationReducer,
    layout: layoutReducer,
    accounting,
    ...injectedReducers
  });

  // Wrap the root reducer and return a new root reducer with router state
  return mergeWithRouterState(rootReducer);
}

/* eslint arrow-body-style: [0] */
import _ from 'lodash';
import React from 'react';

import {
  Input
} from 'reactstrap';



const FormikNoLabelField = ({
  field,
  form: { touched, errors },                 // { touched, errors }
  component : InputComponent = Input,
  noLabel,
  ...props

}) => {

  const inputProps = {
    ...props,
    valid: _.get(touched, field.name) && !_.get(errors, field.name),
    invalid: _.get(touched, field.name) && !!_.get(errors, field.name),
    ...field
  };

  return (
    <InputComponent {...inputProps}>
      {props.children}
    </InputComponent>
  )
};


export default FormikNoLabelField;

import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSlice, createSelector } from 'redux-starter-kit';

const slice = createSlice({
    slice: 'pm_registration',
    initialState: {
        pending: false,
        success: false,
        error: null,
        currentStep: 0,
        roles: [],
        submissions: {
            'company-info': {
                companyName: '',
                dba: '',
                street1: '',
                street2: '',
                city: '',
                state: '',
                zip: '',
                employerIdentificationNumber: '',
                phone: '',
                pocName: '',
                pocEmail: '',
                pocPhone: '',
                licenseNumber: '',
                isSuperAdmin: true,
                mlsId: '',
                adminName: '',
                adminPhone: '',
                adminEmail: '',
            },
            'roles-and-team': { 
                members: [], 
                roles: [] 
            },
            'banking-info': [],
            'fees-and-deposits': { 
                fees: [],
                deposits: []
            },
            'qualification-criteria': {},
            'legal-entity': {},
        }
    },
    reducers: {
        updateRole: (state, action) => ({
            ...state,
            submissions: {
                ...state.submissions,
                'roles-and-team': {
                    ...state.submissions['roles-and-team'],
                    roles: _.map(state.submissions['roles-and-team'].roles, (role) => {
                        if (role._id === action.payload._id){
                            return action.payload.value;
                        }
                        return role;
                    })
                }
            }
        }),
        deleteRole: (state, action) => ({
          ...state,
          submissions: {
            ...state.submissions,
            'roles-and-team': {
              ...state.submissions['roles-and-team'],
              roles: _.filter(state.submissions['roles-and-team'].roles, role => {
                return role._id !== action.payload._id;
              })
            }
          }
        }),

        addRole: (state, action) => ({
            ...state,
            submissions: {
                ...state.submissions,
                'roles-and-team': {
                    ...state.submissions['roles-and-team'],
                    roles: [
                        ...state.submissions['roles-and-team'].roles,
                        action.payload
                    ]
                }
            }
        }),

        getRoles: (state, action) => ({
            ...state,
            pending: true,
            success: false,
            error: null,
        }),
        getRoles_success: (state, action) => ({
            ...state,
            pending: false,
            success: true,
            error: null,
            roles: action.payload,
            submissions: {
                ...state.submissions,
                'roles-and-team': {
                    ...state.submissions['roles-and-team'],
                    roles: _.uniqBy([
                        ...state.submissions['roles-and-team'].roles,
                        ...action.payload
                    ], 'name')
                }
            }
        }),

        submitStep: (state, action) => ({
            ...state,
            pending: true,
            success: false,
            error: null
        }),
        submitStep_success: (state, action) => ({
            ...state,
            pending: false,
            success: true,
            error: null, 
            ...action.payload,
            submissions: {
                ...state.submissions,
                ...action.payload.submissions
            }
        }),

        getCurrentFormData: (state, action) => ({
            ...state,
            pending: true,
        }),
        getCurrentFormData_success: (state, action) => ({
            ...state,
            success: true,
            pending: false,
            ...action.payload,
            submissions: {
                ...state.submissions,
                ...action.payload.submissions
            }
        }),

        createOrganization: (state, action) => ({
            ...state,
            pending: true,
        }),

        createOrganization_success: (state, action) => ({
            ...state,
            pending: false,
            success: true
        }),

        error: (state, action) => ({
            ...state,
            success: false,
            pending: false,
            error: action.payload
        }),
    }
})

window.slice = slice;



const selector = createSelector({
    user: 'global.session.user',
    roles: 'pmRegistration.roles',
    currentStep: 'pmRegistration.currentStep',
    // currentStep: (state) => {
    //     const pmRegStep = _.get(state, 'pmRegistration.currentStep');
    //     const userRegStep = _.get(state, 'global.session.user.registration.step');
    //     const org = _.get(state, 'global.session.user.organization');
    //     if (!_.isEmpty(org)){
    //         return userRegStep;
    //     }
    //     return pmRegStep;
    // },
    pending: 'pmRegistration.pending',
    error: 'pmRegistration.error',
    success: 'pmRegistration.success',
    submissions: 'pmRegistration.submissions',
});


export const mapDispatchToProps = (dispatch) => bindActionCreators(slice.actions, dispatch);
const composeContainer = compose(
    connect(selector, mapDispatchToProps),
    connect(() => ({}))
);
export default composeContainer;
export const reducer = slice.reducer;
// export { default as reducer } from './reducer';

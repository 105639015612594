import React from 'react'
import _ from 'lodash';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input/basic-input';
import {
  Row, Col,
  Card,
  Form,
  Button
} from 'react-bootstrap';
import { Formik, Field } from "formik";
import usStates from 'assets/us-states';

import { FormikCustomField,FormikCustomDatePicker } from '../../../../components/Inputs';
import schema from './schema';
import moment from 'moment';
import './style.scss';
import 'react-datepicker/dist/react-datepicker.css';


const LegalEntity = (props) => {
  return (
    <div>
      <Row>
        <Col>
          <Card>

            <Formik
              validationSchema={schema()}
              enableReinitialize
              initialValues={{
                ...props.initialValues
              }}
              onSubmit={(payload) => {
               payload= {...payload, dob:moment(payload.dob).format('MM/DD/YYYY')}
               props.onSubmit('legal-entity', payload)
              }}
            >
              {
                ({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldTouched,
                  setFieldValue,
                  isValid
                }) => (

                    <Form onSubmit={handleSubmit}>
                      <Card.Header>Company Representative Information</Card.Header>

                      <Card.Body>

                        {/* First Name, Last Name */}
                        <Row form>
                          <Col>
                            <Field
                              type='text'
                              name='first_name'
                              label='First Name'
                              component={FormikCustomField}
                              required
                            />
                          </Col>
                          <Col>
                            <Field
                              type='text'
                              name='last_name'
                              label='Last Name'
                              component={FormikCustomField}
                              required
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col>
                            <Field
                              type='text'
                              name='relationship.title'
                              label='Title (ex. CEO)'
                              component={FormikCustomField}
                              required
                            />
                          </Col>
                          <Col>
                            <Field
                              type='text'
                              name='ssn_last_4'
                              label='Last 4 of SSN'
                              component={FormikCustomField}
                              required
                            />
                          </Col>
                        </Row>


                        <Row form>
                          <Col>
                          <Field
                        onChange={(date) => {
                          if (!date || date > moment().subtract(18, 'years').toDate()){
                            return;
                          }
                          setFieldValue('dob', date)
                        }}
                        name='dob'
                        selected={values.dob}
                        maxDate={moment().subtract(18, 'years').toDate()}
                        placeholder='MM/DD/YYYY'
                        component={FormikCustomDatePicker}
                        label='DOB'
                        required
                      />
                          </Col>
                          <Col>
                            <Field
                              type='email'
                              name='email'
                              label='Email'
                              component={FormikCustomField}
                              required
                            />
                          </Col>
                          <Col>
                            <Form.Group controlId='phone'>
                              <Form.Label className='font-weight-semibold d-block undefined'>
                                Phone
                                <span class="text-danger">*</span>
                              </Form.Label>

                              <PhoneInput
                                name='phone'
                                className={`form-control ${!_.get(touched, 'phone') ? '' : !_.get(errors, 'phone') ? 'is-valid' : 'is-invalid'}`}
                                country="US"
                                onBlur={() => {
                                  setFieldTouched('phone', true);
                                  handleBlur();
                                }}
                                value={_.get(values, 'phone', '')}
                                onChange={(value) => {
                                  setFieldTouched('phone', true);
                                  setFieldValue('phone', value.substr(0, 10))
                                }}
                              />
                              <Form.Control.Feedback type="invalid">{_.get(errors, 'phone')}</Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>

                        {/* street 1 */}
                        <Row form>
                          <Col>
                            <Field
                              type='text'
                              name='address.line1'
                              label='Street 1'
                              required
                              component={FormikCustomField}
                            />
                          </Col>
                        </Row>

                        {/* street 2 */}
                        <Row form>
                          <Col>
                            <Field
                              type='text'
                              name='address.line2'
                              label='Street 2'
                              noValidate
                              component={FormikCustomField}
                            />
                          </Col>
                        </Row>

                        {/* city, state, zip */}
                        <Row form>
                          <Col>
                            <Field
                              type='text'
                              name='address.city'
                              label='City'
                              component={FormikCustomField}
                              required
                            />
                          </Col>

                          <Col xs={2}>
                            <Field
                              type='select'
                              name='address.state'
                              label='State'
                              component={FormikCustomField}
                              required
                            >
                              {
                                _.chain(usStates)
                                  .keys()
                                  .map((abbr, i) => <option value={abbr} key={`state-abbr-${i}-${abbr}`}>{abbr}</option>)
                                  .value()
                              }
                            </Field>
                          </Col>

                          <Col>
                            <Field
                              type='text'
                              name='address.postal_code'
                              label='Zip Code'
                              component={FormikCustomField}
                              required
                            />
                          </Col>
                        </Row>


                      </Card.Body>

                      <Card.Footer>
                        <Button 
                          color='primary' 
                          className='mr-2' 
                          onClick={props.onPrevious}
                        >
                          Previous
                        </Button>
                        <Button 
                          color='primary' 
                          type='submit'
                          disabled={!_.isEmpty(props.errors) || props.pending}
                        >
                          Submit
                        </Button>
                      </Card.Footer>
                    </Form>
                  )
              }
            </Formik>
          </Card>
        </Col>
      </Row>

    </div>
  )
}

export default LegalEntity 

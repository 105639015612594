// /**
//  * Gets the repositories of the user from Github
//  */
import { spawn, call, put, takeLatest, cancelled } from 'redux-saga/effects';
import axios, { CancelToken } from 'axios'
// import { CANCEL, fork, spawn, call, put, select, takeLatest, cancel, cancelled } from 'redux-saga/effects';
import qs from 'qs';
import _ from 'lodash';
// const debug = require('debug')('property-search:sagas');

axios.defaults.paramsSerializer = (params) => qs.stringify(params);

export function request(config = {}) {
  return call(function* () {
    const source = CancelToken.source();
    try {
      return yield call(axios.request, { cancelToken: source.token, ...config });
    } finally {
      if (yield cancelled()) {
        source.cancel();
      }
    }
  });
}

function* verifyEmail(action) {
  try {
    yield put({ type: 'email_verification/verifyEmailPending', payload: {} });
    const { data: { user = {} } } = yield request({
      method: 'GET',
      url: `/api/rentivity/register/verify/${action.payload}`,
    });
    yield put({ type: 'email_verification/verifyEmailSuccess', payload: user })
  } catch (err) {
    const error = _.get(err, 'response.data', err);
    yield put({ type: 'email_verification/verifyEmailError', payload: error });
  }
}


/**
 * Root saga manages watcher lifecycle
 */
function* _rootSaga() {
  // By using `takeLatest` only the result of the latest API call is applied.
  // It will be cancelled automatically on component unmount
  yield takeLatest('email_verification/verifyEmail', verifyEmail);
}

export default function* rootSaga() {
  yield spawn(_rootSaga)
}

import { combineReducers } from 'redux';
import { reducer as verification } from '../EmailVerification/store'
import { reducer as common } from '../CommonRegistration/store'
import { reducer as companyInfo } from '../CompanyInfo/store'
import { reducer as rolesAndTeam } from '../RolesAndTeam/store'
import { reducer as feesAndDeposits } from '../FeesAndDeposits/store';


export default combineReducers({
  steps: combineReducers({
    verification,
    companyInfo,
    common,
    rolesAndTeam,
    feesAndDeposits
  })
});

import React, { Component } from 'react';
import { Col, Card, CardBody, CardHeader, Row, Button, Form } from 'reactstrap';
import { Formik, Field } from 'formik';
import queryString from 'query-string';
import { FormikCustomField } from 'components/Inputs';

import { ToastContext } from '../../../../../contexts';
import { toast } from 'react-toastify';
import schema, { initialValues } from '../schema/setPassword.schema';
import { setPassword } from '../../../../../utils/services';


const toastMessageSetting = {position: toast.POSITION.TOP_RIGHT};


const CardWrap = ({ children }) => (
  <Card className="mx-4 mt-4">
    <CardHeader>Set Password</CardHeader>
    <CardBody className="p-4">
      {children}
    </CardBody>
  </Card>
)

export default class SetPassword extends Component {

  state = { token: '' };


  componentDidMount() {
    const value = queryString.parse(this.props.location.search);
    this.setState({ token: value.resetToken })
  }

  static contextType = ToastContext;

  onSubmit = async (values) => {
    try {
      await setPassword(this.state.token, { password: values.password });
      this.context.showSuccessToast('Password successfully set',toastMessageSetting);
      return this.props.history.push({ pathname: '/login' });
    } catch (err) {
      return this.context.showErrorToast(err,toastMessageSetting);
    }
  };

  render() {

    return (
      <CardWrap >
        <Formik
          validationSchema={schema()}
          initialValues={initialValues}
          onSubmit={this.onSubmit}
        >
          {
            ({ isValid, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col sm={12} md={6}>
                    <Field
                      name='password'
                      type='password'
                      label='Password'
                      component={FormikCustomField}
                    >
                    </Field>
                  </Col>
                  <Col sm={12} md={6}>
                    <Field
                      name='confirmPassword'
                      type='password'
                      label='Confirm Password'
                      component={FormikCustomField}
                    >
                    </Field>
                  </Col>

                </Row>
                {
                  this.props.error && <p className='error-text'>{this.props.error.message}</p>
                }
                <Row>
                  <Col sm={12} md={12}>
                    <Button
                      color='success'
                      block
                      type='submit'
                      disabled={!isValid}
                    >
                      Submit
                        </Button>
                  </Col>
                </Row>
              </Form>
            )
          }
        </Formik>
      </CardWrap>
    );
  }

}


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Col, Card, CardBody, CardFooter, Row, Button, Form } from 'reactstrap';
import { Formik, Field } from 'formik';
import { FormikCustomField } from 'components/Inputs';
import schema from './schema';

// images
import background from "../../../../assets/img/backgrounds/jamie-street-94244-unsplash.jpg";


const CardWrap = ({ children }) => (
  <div className="app-body flex-row align-items-center">
    <Container>
      <Row className="justify-content-center">
        <Col xs="12" md="10" lg="6">
          <Card className="mx-4 no-border">
            <CardBody className="p-4">
              {children}
            </CardBody>
            <CardFooter className="bg-green">
              <p className="text-white text-center mb-0">Already have an account? <Link to="/login" className="font-weight-bold text-white">Login.</Link></p>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
);

/**
 * This form is shown to all registrants (renters/pm)
 */
class Register extends Component {


  static defaultProps = {
    pending: false,
    success: false,
    fields: {
      acctType: 'renter',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirm: ''
    },
    error: false
  }

  static propTypes = {
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object, PropTypes.string]),
    pending: PropTypes.bool,
    success: PropTypes.bool,
    fields: PropTypes.shape({
      acctType: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      password: PropTypes.string,
      passwordConfirm: PropTypes.string
    })
  };


  componentWillMount() {
    this.props.setLayout();
  }


  loading = () => (
    <div className="animated fadeIn pt-1 text-center">
      <div className="sk-spinner sk-spinner-pulse" />
    </div>
  );

  render() {
    if (this.props.success) {
      return (
        <CardWrap>
          <center>
            <h1>Success</h1>
            <p className="text-muted">Email verification has been sent to your email address</p>
            {
              process.env.REACT_APP_DEV_ENV === '1' && (
                <p>
                  In <code>development</code> mode: <Link to={{
                    pathname: `/register/${this.props.user.registration.token}/verify`,
                    state: this.props.location.state
                  }}>Click here to verify email</Link>
                </p>
              )
            }
          </center>
        </CardWrap>
      );
    }

    return (
      <div className="app bg-cover registration" style={{ backgroundImage: `url(${background})` }}>
        <CardWrap>
          <Formik
            validationSchema={schema()}
            initialValues={this.props.fields}
            onSubmit={this.props.onSubmit}
          >
            {
              ({ isValid, handleSubmit }) => (
                <Form onSubmit={handleSubmit} className="p-4">
                  <h2 className='text-primary text-center'>RENTING REINVENTED</h2>
                  <h3 className='text-center mb-0'>Register</h3>
                  <p className="text-muted text-center">Create your account</p>

                  <Field
                    name='acctType'
                    type='select'
                    label='Select Account Type'
                    className="custom-select"
                    component={FormikCustomField}
                  >
                    <option value="renter">Renter</option>
                    <option value="pm">Property Manager</option>
                    {/* <option value="owner">Owner</option> */}
                  </Field>

                  <Field
                    inline
                    name='firstName'
                    label='First Name'
                    component={FormikCustomField}
                  />

                  <Field
                    inline
                    name='lastName'
                    label='Last Name'
                    component={FormikCustomField}
                  />

                  <Field
                    inline
                    type='email'
                    name='email'
                    label='Email'
                    component={FormikCustomField}
                  />

                  <Field
                    inline
                    type='password'
                    name='password'
                    label='Password'
                    component={FormikCustomField}
                  />

                  <Field
                    inline
                    type='password'
                    name='passwordConfirm'
                    label='Confirm Password'
                    component={FormikCustomField}
                  />


                  {
                    this.props.error && <p className='error-text'>{this.props.error.message}</p>
                  }

                  <Button
                    color='success'
                    block
                    type='submit'
                    disabled={!isValid || this.props.pending}
                  >
                    Sign Up
                  </Button>
                </Form>
              )
            }
          </Formik>

        </CardWrap>
      </div>

    );
  }
}

export default Register;

import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';

axios.defaults.paramsSerializer = (params) => qs.stringify(params);
window.axios = axios;


export const getSession = () => axios.get('/api/rentivity/session', {
  params: {
    populate: ['renterApplication', 'leaseWrapup']
  }
});
export const submitLogin = ({ email, password }) => axios.post('/api/rentivity/login', { email, password });

export const submitLogout = () => axios.get('/api/rentivity/logout');
export const submitRegistration = (payload) => axios.post(`/api/rentivity/register`, payload);

export const verifyEmail = (token) => axios.get(`/api/rentivity/register/verify/${token}`);
export const submitStep = (stepName, payload) => axios.post(`/api/rentivity/register/steps/${stepName}`, payload);
export const fetchLeaseWrapupSteps = () => axios.get(`/api/rentivity/register/steps/order-lease-wrapup`);

// pm registration
export const createOrganization = (payload) => axios.post(`/api/rentivity/organization`, payload);
export const fetchOrganization = (orgId) => axios.get(`/api/rentivity/organization/${orgId}`);
export const fetchFilteredOrgUsers = () => axios.get(`/api/rentivity/organization/users`);
export const fetchOrganizationLeaseWrapupSteps = (orgId) => axios.get(`/api/rentivity/organization/${orgId}/leasewrapupsteps`);
export const checkEmailAvailable = (email) => axios.get(`/api/rentivity/register/check-valid-email`, { params: { email } })
export const fetchPetBreeds = () => axios.get('/api/rentivity/lookup/petbreeds?sub_type=dog');
export const getDocumentsToBeVerified = () => axios.get('/api/rentivity/lookup/documentVerification');
export const fetchLeaseWrapupOrdererSteps = () => axios.get('/api/rentivity/lookup/step');
export const fetchOrgUpfrontPayments = (orgId) => axios.get(`/api/rentivity/organization/${orgId}/upfront-payments`);
export const fetchOrgRequiredDocs = (orgId) => axios.get(`/api/rentivity/organization/${orgId}/required-documents`);
export const fetchOrgRentalDueDate = (orgId) => axios.get(`/api/rentivity/organization/${orgId}/rentalDueDate`);
export const fetchOrgLegalEntity = (orgId) => axios.get(`/api/rentivity/organization/${orgId}/legal-entity`);
export const fetchOrgPreShowingQuestions = (orgId) => axios.get(`/api/rentivity/organization/${orgId}/pre-showing-questions`);
export const fetchPreShowingQuestions = () => axios.get('/api/rentivity/lookup/preShowingQuestions');

// pm registration v2 - onboarding
export const getOrgCreateFormSession = () => axios.get('/api/rentivity/organization/registration-session');
export const updateOrgCreateFormSession = (payload) => axios.patch('/api/rentivity/organization/registration-session', payload);
export const getOrgRegistrationRoles = () => axios.get('/api/rentivity/organization/registration/roles');
export const getAccountTypes = () => axios.get('/api/rentivity/lookup/account_type?sub_type=organization');
export const getAccountPurposes = () => axios.get('/api/rentivity/lookup/account_purpose');

// organization brand image
export const updateOrganizationBrandImg = (orgId, imgId) => axios.patch(`/api/rentivity/organization/${orgId}/brand-image`, { imgId });


// organization banking info
export const fetchOrganizationBankingInfo = (orgId) => axios.get(`/api/rentivity/organization/${orgId}/banking-info`);
export const deleteOrganizationBankingInfo = (orgId, baId) => axios.delete(`/api/rentivity/organization/${orgId}/banking-info/${baId}`);
export const updateOrganizationBankingInfo = (orgId, baId, payload) => axios.patch(`/api/rentivity/organization/${orgId}/banking-info/${baId}`, payload);
export const addOrganizationBankingInfo = (orgId, payload) => axios.post(`/api/rentivity/organization/${orgId}/banking-info`, payload);

// organization accounting infor
export const fetchOrganizationAccountingInfo = (orgId) => axios.get(`/api/rentivity/organization/${orgId}/accounting`);

// organization qualification criteria info
export const fetchOrganizationQualificationCriteriaInfo = (orgId) => axios.get(`/api/rentivity/organization/${orgId}/qualification-criteria`);

// organization
export const updateOrganization = (organizationId, payload) => axios.patch(`/api/rentivity/organization/${organizationId}`, payload);
export const fetchOrganizationFees = organizationId => axios.get(`/api/rentivity/organization/${organizationId}/fees`);
export const fetchOrganizationAdditionalRents = organizationId => axios.get(`/api/rentivity/organization/${organizationId}/additional_monthly_rents`);
export const fetchOrganizationDeposits = organizationId => axios.get(`/api/rentivity/organization/${organizationId}/deposits`);
export const updateOrganizationFees = (organizationId, payload) => axios.patch(`/api/rentivity/organization/${organizationId}/fees`, payload);
export const updateOrgAdditionalMonthlyRents = (organizationId, payload) => axios.patch(`/api/rentivity/organization/${organizationId}/additional_monthly_rents`, payload);
export const updateOrganizationDeposits = (organizationId, payload) => axios.patch(`/api/rentivity/organization/${organizationId}/deposits`, payload);
export const updateOrganizationQualificationCriteria = (organizationId, payload) => axios.patch(`/api/rentivity/organization/${organizationId}/qualification-criteria`, payload);
export const updateOrganizationPreShowing = (organizationId, payload) => axios.patch(`/api/rentivity/organization/${organizationId}/pre-showing-questions`, payload);
export const addOrganizationPreShowing = (organizationId, payload) => axios.post(`/api/rentivity/organization/${organizationId}/pre-showing-questions`, payload);
export const updateOrganizationLegalEntity = (organizationId, payload) => axios.patch(`/api/rentivity/organization/${organizationId}/legal-entity`, payload);
export const addOrganizationLegalEntity = (organizationId, payload) => axios.post(`/api/rentivity/organization/${organizationId}/legal-entity`, payload);
export const updateOrgLeasewrapupSteps = (orgId, payload) => axios.patch(`/api/rentivity/organization/${orgId}/leasewrapupsteps`, payload);
export const updateOrgUpfrontPayments = (orgId, payload) => axios.patch(`/api/rentivity/organization/${orgId}/upfront-payments`, payload);
export const updateOrgRequiredDocs = (orgId, payload) => axios.patch(`/api/rentivity/organization/${orgId}/required-documents`, payload);
export const updateOrgRentalDueDate = (orgId, payload) => axios.patch(`/api/rentivity/organization/${orgId}/rentalDueDate`, payload);
export const fetchRenterPreShowingQues=(ordId)=>axios.get(`/api/rentivity/organization/${ordId}/pre-showing-questions`)
export const fetchRenterScheduledDate=(propertySessionId)=>axios.get(`/api/rentivity/scheduled-request/scheduled-times?property_session_id=${propertySessionId}`)

// properties page
export const fetchPropertySessionBids = (propertySessionId) => axios.get(`/api/rentivity/property-session/${propertySessionId}/bids`);
export const fetchPropertySessionStats = propertySessionId => axios.get(`/api/rentivity/property-session/${propertySessionId}/stats`);

export const fetchLeaseReviewPdf = (leaseWrapupId, payload) => axios.post(`/api/rentivity/lease-wrapup/${leaseWrapupId}/property-manager/lease-review`, payload, {
  responseType: "arrayBuffer"
});

export const fetchSavedFilters = () => axios.get('/api/rentivity/search-filter');
export const fetchSavedFilterById = (filterId) => axios.get(`/api/rentivity/search-filter/${filterId}`);
export const submitSavedFilter = (payload) => axios.post('/api/rentivity/search-filter', payload);
export const editSavedFilter = (filterId, payload) => axios.patch(`/api/rentivity/search-filter/${filterId}`, payload);
export const deleteSavedFilter = filterId => axios.delete(`/api/rentivity/search-filter/${filterId}`);

// property features
export const fetchFeatures = () => axios.get('/api/rentivity/feature');
export const fetchFeatureCategories = () => axios.get('/api/rentivity/feature/categories');
export const fetchProperties = () => axios.get('/api/rentivity/property', {
  params: {
    populate: ['images']
  }
});
export const fetchPromotionsList = () => axios.get('/api/rentivity/promotion');
export const updateEsignJwt = (esignDocId) => axios.put(`/api/rentivity/lease-wrapup/documents/${esignDocId}/jwt`);

export const fetchPropertySessions = (query = {}) => axios.get('/api/rentivity/property-session', {
  params: {
    query,
    populate: [{
      path: 'property',
      populate: [{
        path: 'images'
      }, {
        path: 'portfolio',
        populate: {
          path: 'client'
        }
      }, {
        path: 'assignedTo'
      }
      ]
    }, {
      path: 'leaseWrapup',
      populate: [{
        path: 'applications.renter'
      }, {
        path: 'applications.renterApplication'
      }, {
        path: 'lease'
      }
      ]
    }, {
      path: 'bid'
    }, {
      path: 'leaseTemplate'
    }
    ]
  }
});

export const deleteDocumentById = documentId => axios.delete(`/api/rentivity/lease-wrapup/property-manager/preview-doc/${documentId}`);

export const fetchLeaseWrapupDocs = (propertySessionId) => axios.get(`/api/rentivity/property-session/${propertySessionId}/lease-wrapup-docs`);
export const submitLeaseWrapupDocApprove = (leaseWrapupId, submissionId, docId, payload = {}) => axios.patch(`/api/rentivity/lease-wrapup/${leaseWrapupId}/submissions/${submissionId}/documents/${docId}/approve`, payload);
export const submitLeaseWrapupDocDeny = (leaseWrapupId, submissionId, docId, payload = {}) => axios.patch(`/api/rentivity/lease-wrapup/${leaseWrapupId}/submissions/${submissionId}/documents/${docId}/deny`, payload);
export const fetchLeasePdf = (esignDocId) => axios.get(`/api/rentivity/lease-wrapup/documents/${esignDocId}/pdf`, { responseType: 'blob' })

export const fetchPropertySessionById = (psId, params = {
  populate: [{
    path: 'property',
    populate: { path: 'images' }
  }, {
    path: 'bid'
  }]
}) => axios.get(`/api/rentivity/property-session/${psId}`, {
  params
});

export const submitPropertyContact = (propertySessionId, payload) => axios.post(`/api/rentivity/property-session/${propertySessionId}/contact`, payload);

export const fetchAuctionDetails = (propertyId) => axios.get(`/api/rentivity/auction/${propertyId}/active`);

export const fetchSessionStats = (propertyId) => axios.get(`/api/rentivity/property-session/${propertyId}/sessionStats`);

export const submitBid = (propertyId, payload) => axios.post(`/api/rentivity/auction/${propertyId}/bid`, payload);

export const checkProxyConfig = (propertySessionId) => axios.get(`/api/rentivity/check-proxy-config/${propertySessionId}`);

export const fetchPortfolios = clientId => axios.get(`/api/rentivity/client/${clientId}/portfolio`);

export const fetchClients = () => axios.get('/api/rentivity/client');

export const submitCreateAuction = (propertyId, payload) => axios.post(`/api/rentivity/auction/${propertyId}`, payload);

export const scheduledShowings = ()=>axios.get('/api/rentivity/scheduled-requests')
export const scheduledShowingsDetails = (propertySessionId)=>axios.get(`/api/rentivity/scheduled-requests?property_session_id=${propertySessionId}`)
export const searchProperty = (searchText) => axios.get(`/api/rentivity/property/search`, { params: { property_search: searchText } });
export const submitProperty = (payload) => axios.post('/api/rentivity/property', payload);
export const submitProperties = (payload) => axios.post('/api/rentivity/properties', payload);
export const editProperty = (propertyId, payload) => axios.patch(`/api/rentivity/property/${propertyId}`, payload);
export const isAddressValid = payload => axios.post('/api/rentivity/property/check-valid-address', payload);
export const isAddress2Valid = (payload,validAddress) => axios.post(`/api/rentivity/property/check-valid-address?exclude-address2=${validAddress}`,payload);


export const fetchRoles = () => axios.get('/api/rentivity/role');
export const fetchRole = (roleId) => axios.get(`/api/rentivity/role/${roleId}`);
export const updateRole = (roleId, payload) => axios.patch(`/api/rentivity/role/${roleId}`, payload);


export const createRole = (payload) => axios.post(`/api/rentivity/role`, payload);

export const submitRolesAndTeam = (payload) => axios.post(`/api/rentivity/register/steps/roles-and-team`, payload);

// vendor calls
export const fetchVendors = () => axios.get(`/api/rentivity/vendor`);
export const updateVendorById = (id, payload) => axios.patch(`/api/rentivity/vendor/${id}`, payload);
export const deleteVendorById = (id) => axios.delete(`/api/rentivity/vendor/${id}`);
export const submitVendor = (payload) => axios.post(`/api/rentivity/vendor`, payload);
// end vendor calls

// promotion calls
export const fetchPromotions = () => axios.get(`/api/rentivity/promotion`);
export const updatePromotionById = (id, payload) => axios.patch(`/api/rentivity/promotion/${id}`, payload);
export const deletePromotionById = (id) => axios.delete(`/api/rentivity/promotion/${id}`);
export const submitPromotion = (payload) => axios.post(`/api/rentivity/promotion`, payload);
// end promotion calls

// service offering calls
export const fetchServiceOfferings = () => axios.get(`/api/rentivity/service-offering`);
export const updateServiceOfferingById = (id, payload) => axios.patch(`/api/rentivity/service-offering/${id}`, payload);
export const deleteServiceOfferingById = (id) => axios.delete(`/api/rentivity/service-offering/${id}`);
export const submitServiceOffering = (payload) => axios.post(`/api/rentivity/service-offering`, payload);
// end service offering calls

// reset Password
export const resetPassword = (payload) => axios.post(`/api/rentivity/password-reset`, payload);
export const setPassword = (resetToken, payload) => axios.patch(`/api/rentivity/password-reset/${resetToken}`, payload);
export const setUserPassword = (token, payload) => axios.patch(`/api/rentivity/register/${token}/set-password`, payload);
// end reset Password


// Terms & Conditions
export const updateTermsAndConditions = (payload) => axios.patch(`/api/rentivity/register/tos-accept`, payload)
// End Terms & Conditions

// portfolios
export const renamePortFolio = (pId, payload) => axios.patch(`/api/rentivity/portfolio/${pId}`, payload);


// Templates
export const fetchLeaseTemplates = (query = {}) => axios.get(`/api/rentivity/template?${qs.stringify(query)}`);
export const fetchLeaseTemplateCustomFields = (id) => axios.get(`/api/rentivity/template/${id}/custom-fields`);
export const fetchLeaseTemplateById = (id) => axios.get(`/api/rentivity/template/${id}`);
export const fetchLeaseTemplatePdfById = (id, params = {}) => axios.get(`/api/rentivity/template/${id}/pdf`, {
  params,
  responseType: 'arraybuffer'
});
export const fetchLeaseTemplatePdfUrlById = (id, params = {}) => `/api/rentivity/template/${id}/pdf?${_.map(params, (key, value) => `${value}=${[key]}`)}`;

export const associateRidersToTemplates = (id, payload) => axios.patch(`/api/rentivity/template/${id}/associate-templates`, payload);
export const associateTemplateToRiders = (id, payload) => axios.patch(`/api/rentivity/template/${id}/associate-riders`, payload);
export const replaceTemplate = (id, payload,query = {}) => axios.patch(`/api/rentivity/template/${id}/replace-template?${qs.stringify(query)}`, payload);
export const replaceAndDeleteTemplate = (id, payload,query = {}) => axios.patch(`/api/rentivity/template/${id}/replace-and-delete-template?${qs.stringify(query)}`, payload);


// Esign PDF documents
export const fetchEsignPdf = (id, params = {}) => axios.get(`/api/rentivity/esign/${id}/pdf`, {
  params,
  responseType: 'arraybuffer'
})



/**
 * Renter
 */
export const searchPropertySessions = (query, select = {}) => axios.get('/api/rentivity/property-session/search', {
  params: {
    query,
    select
  }
})
export const getPropertySessionDetails = (propertySessionId, params) => axios.get(`/api/rentivity/property-session/${propertySessionId}/details`, { params });
export const getSettings = () => axios.get(`/api/rentivity/setting`);
export const updateSetting = (payload) => axios.patch(`/api/rentivity/setting`, payload);
export const getGeneralInfo = (renterId) => axios.get(`/api/rentivity/renter/${renterId}/lease`);

// Renter application
export const fetchRenterApplication = (userId, params = {}) => axios.get(`/api/rentivity/renter/${userId}/application`, { params });
export const fetchRenterApplicationOccupants = (userId, params = {}) => axios.get(`/api/rentivity/renter/${userId}/application/occupants`, { params });
export const submitRenterApplication = (userId, payload) => axios.post(`/api/rentivity/renter/${userId}/application`, payload);
export const updateRenterApplication = (userId, payload) => axios.patch(`/api/rentivity/renter/${userId}/application`, payload);

// Renter Transunion application
export const submitTransunionApplication = (payload) => axios.post(`/api/rentivity/integration/transunion/renter/application`, payload)
export const submitTUAppWithActualProperty = (payload, renterId, propertySessionId, renterEmail, leaseWrapupId) => axios.post(`/api/rentivity/integration/transunion/renter/${renterId}/application?property_session_id=${propertySessionId}&renter_email=${renterEmail}&leasewrapup_id=${leaseWrapupId}`, payload)
export const updateTransunionApplication = (payload) => axios.put(`/api/rentivity/integration/transunion/renter/application`, payload)
export const updateTUAppWithActualProperty = (payload, renterId, tuPropertyId) => axios.put(`/api/rentivity/integration/transunion/renter/${renterId}/application?property_id=${tuPropertyId}`, payload)
export const submitTransunionAcceptAgreement = () => axios.post(`/api/rentivity/integration/transunion/renter/application/accept`)
export const fetchTransunionProperty = (propertySessionId) => axios.get(`/api/rentivity/integration/transunion/landlord/actual_property?property_session_id=${propertySessionId}`);
export const fetchTransunionApplication = (tuAppId) => axios.get(`/api/rentivity/integration/transunion/landlord/application?application_id=${tuAppId}`);
export const fetchTransunionTerms = () => axios.get(`/api/rentivity/integration/transunion/renter/application/terms`, { responseType: 'blob' });
export const fetchTransunionExam = () => axios.get(`/api/rentivity/integration/transunion/renter/application/exam`);
export const fetchTransunionReport = () => axios.get(`/api/rentivity/integration/transunion/renter/application/report`);
export const fetchTransunionReportByRenterId = (userId) => axios.get(`/api/rentivity/integration/transunion/renter/${userId}/application/report`);
export const fetchTransunionApplicationStatus = () => axios.get(`/api/rentivity/integration/transunion/renter/application/status`);
export const submitGenerateTransunionReport = () => axios.put(`/api/rentivity/integration/transunion/renter/application/report`);
export const submitTransunionExam = (payload) => axios.post(`/api/rentivity/integration/transunion/renter/application/exam`, payload);
export const downloadTransunionPDFReport = async (payload) => await axios.post(`/api/rentivity/integration/transunion/renter/application/download_report`, payload, {
          responseType: 'blob', //Force to receive data in a Blob Format
        })
        .then(response => {
          let headerLine = response.headers['content-disposition'];
          let filename = headerLine.split('attachment; filename=')[1];
          // It is necessary to create a new blob object with mime-type explicitly set
          // otherwise only Chrome works like it should
          var newBlob = new Blob([response.data], {type: "application/pdf"})
          
          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
          } 
            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download= filename;
            link.click();
            setTimeout(function(){
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100);
        })
        .catch(error => {
           // console.log(error);
            throw error;
        }); 

export const submitTransunionPayment = (userId, applicationId, payload) => axios.post(`/api/rentivity/integration/transunion/renter/${userId}/application/${applicationId}/payment`, payload)
export const getTransunionFee = () => axios.get('/api/rentivity/config?name=TRANSUNION');

export const submitLWLeaseGen = (propertySessionId, payload) => axios.post(`/api/rentivity/lease-wrapup/${propertySessionId}/lease-agreement`, payload);

export const fetchLeaseWrapupRenterSteps = (leaseWrapupId) => axios.get(`/api/rentivity/lease-wrapup/${leaseWrapupId}`, {
  params: {
    populate: [{
      path: 'propertySession'
    }]
  }
});
export const linkPlaidBankAccount = (renterId, public_token, metadata) => axios.post(`/api/rentivity/renter/${renterId}/bank-account/link`, { public_token, metadata })

export const fetchLeaseWrapupSubmission = (leaseWrapupId, submissionId) => axios.get(`/api/rentivity/lease-wrapup/${leaseWrapupId}/submissions/${submissionId}`);

export const submitRentItNow = (propertySessionId) => axios.post('/api/rentivity/lease-wrapup/rent-it-now', { propertySessionId });

export const submitLeaseWrapupStep = (leaseWrapupId, submissionId, payload) => axios.post(`/api/rentivity/lease-wrapup/${leaseWrapupId}/submission/${submissionId}`, payload);

/**
 * Common - START
 */

// Contact US
export const submitContactDetails = (details) => axios.post('/api/rentivity/contact-us', details);

/**
 * Common - END
 */

// PM notification start
export const updateNotification = (id) => axios.patch(`/api/rentivity/notification/${id}`);
export const deleteNotification = (id) => axios.delete(`/api/rentivity/notification/${id}`);
export const getNotification = () => axios.get(`/api/rentivity/notification/user`);
export const acceptedShowingRequest =()=>axios.get(`/api/rentivity/scheduled-request`);
export const approveRequest = (id,payload) => axios.patch(`/api/rentivity/scheduled-request/${id}`,payload);
// PM notification end


// maintenance page - START
export const fetchMaintenanceRequests = () => axios.get('/api/rentivity/maintenance');
export const submitMaintenanceRequest = details => axios.post('/api/rentivity/maintenance', details);
export const fetchMaintenanceRequestsById = maintenanceId => axios.get(`/api/rentivity/maintenance/${maintenanceId}`, {
  params: {
    populate: ['images']
  }
});
export const submitInternalNotes = (maintenanceId, payload) => axios.post(`/api/rentivity/maintenance/${maintenanceId}/internalNote`, payload);
export const submitChatMessage = (maintenanceId, payload) => axios.post(`/api/rentivity/maintenance/${maintenanceId}/chat`, payload);

export const getWorkOrder = maintenanceId => axios.get(`/api/rentivity/work-order/maintenance/${maintenanceId}`);
export const deleteWorkOrder = workOrderId => axios.delete(`api/work-order/${workOrderId}`);
export const saveWorkOrder = (payload) => axios.post(`/api/rentivity/work-order`, payload);
export const updateWorkOrder = (workOrderId, payload) => axios.patch(`/api/rentivity/work-order/${workOrderId}`, payload);

export const updateMaintenanceRequest = (maintenanceId, payload) => axios.patch(`/api/rentivity/maintenance/${maintenanceId}`, payload);

export const uploadMaintenanceReqImages = (maintenanceId, imageId) => axios.post(`/api/rentivity/maintenance/${maintenanceId}/image/${imageId}`);

// maintenance page - END

// PM Profile page - start
export const getProfileInfo = () => axios.get('/api/rentivity/user/profile');
export const updateProfileInfo = (payload) => axios.patch('/api/rentivity/user/profile', payload);
// PM Profile page - end
// Landing - START
export const fetchLastMinuteOffers = () => axios.get('/api/rentivity/property-session/ending-soon');
export const fetchNewListings = () => axios.get('/api/rentivity/property-session/new-listings');
export const fetchCrimeReports=(propertySessionId) => axios.get(`/api/rentivity/property-session/${propertySessionId}/incidents?month=6&pageSize=3000&distance=6mi`)
export const fetchSimilarProperties=(propertySessionId,query) => axios.get(`/api/rentivity/property-session/${propertySessionId}/similar_search?${query}`)
export const fetchDealsAndPromotions = () => axios.get('/api/rentivity/property-session/deals-and-promotions');
export const fetchWalkScoreBySessionId = (propertySessionId) => axios.get(`/api/rentivity/property-session/${propertySessionId}/walkscore`);
export const fetchNearBySchools = (propertySessionId) => axios.get(`/api/rentivity/property-session/${propertySessionId}/schools`);
// Landing - END

// Renter Offers - start
export const getSessionOffers = () => axios.get('/api/rentivity/property-session/offers');
// Renter Offers - end

// Client - START
export const getClients = () => axios.get('/api/rentivity/client');
export const getClientById = (id) => axios.get(`/api/rentivity/client/${id}`);
export const getQualificationCriteria = (id) => axios.get(`/api/rentivity/qualification-criteria/${id}`);
export const updatePortfolio = (portfolioId, payload) => axios.patch(`/api/rentivity/portfolio/${portfolioId}`, payload);
export const updatePortfolioQualificationCriteria = (portfolioId, payload) => axios.patch(`/api/rentivity/portfolio/${portfolioId}/qualification-criteria`, payload);
export const createPortfolioQualificationCriteria = (portfolioId, payload) => axios.post(`/api/rentivity/portfolio/${portfolioId}/qualification-criteria`, payload);


// Client - END

// Renter Favorites - start
export const getFavoritesDetails = () => axios.get('/api/rentivity/favorite/details');
export const removeFavorite = psId => axios.delete(`/api/rentivity/favorite/${psId}`);
// Renter Favorites - end

// Renter Invite - start
export const submitInvite = payload => axios.post(`/api/rentivity/invite`, payload);
// Renter Invite - end

// PM Dashboard - start
export const fetchPropertiesStats = () => axios.get('/api/rentivity/property/stats');
export const fetchActivityStats = () => ({
  data: { listingViews: 120, listingFavorites: 51, bids: 204 }
});
// PM Dashboard - end
// favourites - Start
export const fetchFavourites = () => axios.get('/api/rentivity/favorite/details');
export const makeFavorite = (propertySessionId) => axios.post(`/api/rentivity/favorite/${propertySessionId}`);
export const deleteFavorite = (propertySessionId) => axios.delete(`/api/rentivity/favorite/${propertySessionId}`);
// favourites - End

// User management - START

export const fetchOrgRoles = orgId => axios.get(`/api/rentivity/organization/${orgId}/roles`);
export const addRolesToOrg = (orgId, payload) => axios.post(`/api/rentivity/organization/${orgId}/roles`, payload);
export const editOrgRole = (orgId, roleId, payload) => axios.patch(`/api/rentivity/organization/${orgId}/roles/${roleId}`, payload);
export const deleteOrgRole = (orgId, roleId) => axios.delete(`/api/rentivity/organization/${orgId}/roles/${roleId}`);
export const fetchOrgMembers = orgId => axios.get(`/api/rentivity/organization/${orgId}/members`, {
  params: {
    populate: [{
      path: 'role'
    }]
  }
});
export const addMemberToOrg = (orgId, payload) => axios.post(`/api/rentivity/organization/${orgId}/members`, payload);
export const deleteMemberFromOrg = (orgId, userId) => axios.delete(`/api/rentivity/organization/${orgId}/members/${userId}`);
export const editMemberOrg = (orgId, userId, payload) => axios.patch(`/api/rentivity/organization/${orgId}/members/${userId}`, payload);

// User management - END



export const uploadImage = (formData, options = {}) => axios.post('/api/rentivity/upload/image', formData, {
  onUploadProgress: options.onUploadProgress
});

export const getImage = (imageId) => axios.get(`/api/rentivity/image/${imageId}`);

// Renter LW Page
export const getLeaseWrapupDetails = (leaseWrapupId) => axios.get(`/api/rentivity/lease-wrapup/${leaseWrapupId}/details`);

export const getLwStatus = (leaseWrapupId, renterId) => axios.get(`/api/rentivity/lease-wrapup/${leaseWrapupId}/status`);
export const getRenterLwApp = (leaseWrapupId, renterId) => axios.get(`/api/rentivity/lease-wrapup/${leaseWrapupId}/applicants/${renterId}`);
export const getPotentialFeesAndDeposits = (leaseWrapupId) => axios.get(`/api/rentivity/lease-wrapup/${leaseWrapupId}/fees-and-deposits`);

export const cancelLwApplication = (leaseWrapupId) => axios.delete(`/api/rentivity/lease-wrapup/${leaseWrapupId}`);

// export const setSubmissionRenterLwDoc = (leaseWrapupId, renterId, payload) => axios.post(`/api/rentivity/lease-wrapup/${leaseWrapupId}/applicant/${renterId}/documents`, payload);


export const submitLwPmSubmission = (leaseWrapupId, payload) => axios.post(`/api/rentivity/lease-wrapup/${leaseWrapupId}/property-manager/lease-agreement`, payload)
export const submitLwLeasePayment = (lwId, leaseId, payload) => axios.post(`/api/rentivity/lease-wrapup/${lwId}/lease/${leaseId}/payment`, payload)

// Renter Chat
export const fetchRenterChatAsPM = (propertySessionId, renterId) => axios.get(`/api/rentivity/renter-chat/${propertySessionId}/${renterId}`);
export const fetchRenterChatAsRenter = queryParam => axios.get(`/api/rentivity/renter-chat?session_id=${queryParam}`);
export const submitRenterChatMessage = (payload) => axios.post('/api/rentivity/renter-chat', payload);


export const fetchLeaseById = (id) => axios.get(`/api/rentivity/lease/${id}`);

// make payment
export const paymentSendFrom = renterId => axios.get(`/api/rentivity/renter/${renterId}/bank-account`);
export const paymentReasons = () => axios.get('/api/rentivity/lookup/payment-reason?sub_type=renter');
export const makeRenterPayment = payload => axios.post('/api/rentivity/renter-payment', payload)

// Leasewrapup new flow
export const fetchLeaseWrapupFlow = lsId => axios.get(`/api/rentivity/lease-wrapup/${lsId}/steps`);
export const fetchCostSummary = (lsId, includeConvFee) => axios.get(`/api/rentivity/lease-wrapup/${lsId}/cost-summary${includeConvFee ? '?includeConvenienceFees=true' : ''}`);
export const confirmCostSummary = (lsId, payload) => axios.post(`/api/rentivity/lease-wrapup/${lsId}/pm/verify-details/confirm`, payload);
export const sendToRenter = lsId => axios.patch(`/api/rentivity/lease-wrapup/${lsId}/send-to-renter`);
export const upfrontManualPayment = (lsId, payload) => axios.patch(`/api/rentivity/lease-wrapup/${lsId}/pm/upfront-payment/manual`, payload);
export const downloadDocs = (lsId, renterId, docCategory, subCategory, option) => axios.get(`/api/rentivity/lease-wrapup/${lsId}/applicants/${renterId}/documents/download?documentCategory=${docCategory}&subCategory=${subCategory}&option=${option}`, { responseType: 'blob' })
export const approveAppReview = lsId => axios.patch(`/api/rentivity/lease-wrapup/${lsId}/pm/approve-application`);
export const generateLease = (lsId, payload) => axios.post(`/api/rentivity/lease-wrapup/${lsId}/pm/generate/lease`, payload);
export const sendToRenterFinalPayment = lsId => axios.patch(`/api/rentivity/lease-wrapup/${lsId}/pm/generate/lease/complete`);
export const finalManualPayment = (lsId, payload) => axios.patch(`/api/rentivity/lease-wrapup/${lsId}/pm/final-payment/manual`, payload);
export const autoFinalizeLease = lsId => axios.patch(`/api/rentivity/lease-wrapup/${lsId}/pm/auto-finalize-lease`);
export const finalizeLease = lsId => axios.patch(`/api/rentivity/lease-wrapup/${lsId}/pm/finalize-lease`);

// LW New Flow --- Renter
export const postSubmitToPm = (leaseWrapupId, renterId) => axios.post(`/api/rentivity/lease-wrapup/${leaseWrapupId}/applicants/${renterId}/submit`);
export const renterAgreeDisagree = (leaseWrapupId, action) => axios.patch(`/api/rentivity/lease-wrapup/${leaseWrapupId}/renter-agreement?renterAgreed=${action}`);
export const renterAcknowledge = leaseWrapupId => axios.patch(`/api/rentivity/lease-wrapup/${leaseWrapupId}/renter-acknowledgement`);
export const renterMakeUpfrontPayment = (leaseWrapupId, payload) => axios.post(`/api/rentivity/lease-wrapup/${leaseWrapupId}/renter/upfront/payment`, payload);
export const renterManualPayment = leaseWrapupId => axios.patch(`/api/rentivity/lease-wrapup/${leaseWrapupId}/renter/upfront-payment/manual`);
export const renterUPcomplete = leaseWrapupId => axios.patch(`/api/rentivity/lease-wrapup/${leaseWrapupId}/renter/upfront-payment/complete`);
export const submitLeaseWrapupDocs = formdata => axios.post('/api/rentivity/upload/document', formdata, {
  onUploadProgress(progressEvent) {
    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');

    const percentCompleted = Math.round((progressEvent.loaded * 100) / totalLength)
    console.log('upload percentCompleted', percentCompleted);
  }
})
export const getRenterLwDocs = (leaseWrapupId, renterId) => axios.get(`/api/rentivity/lease-wrapup/${leaseWrapupId}/applicants/${renterId}/documents`);
export const addRenterLwDocs = (leaseWrapupId, renterId, payload) => axios.post(`/api/rentivity/lease-wrapup/${leaseWrapupId}/applicants/${renterId}/documents`, payload);
export const deleteRenterLwDocs = (leaseWrapupId, renterId, category, subCategory, option) => axios.delete(`/api/rentivity/lease-wrapup/${leaseWrapupId}/applicants/${renterId}/documents?documentCategory=${category}${subCategory !== null ? `&subCategory=${subCategory}` : ''}${option !== null ? `&option=${option}` : ''}`);
export const downloadRenterLwDocs = (leaseWrapupId, renterId, category, subCategory, option) => axios.get(`/api/rentivity/lease-wrapup/${leaseWrapupId}/applicants/${renterId}/documents/download?documentCategory=${category}${subCategory !== null ? `&subCategory=${subCategory}` : ''}${option !== null ? `&option=${option}` : ''}`, { responseType: 'blob' });
export const submitLwApplication = (leaseWrapupId, renterId) => axios.post(`/api/rentivity/lease-wrapup/${leaseWrapupId}/applicants/${renterId}/documents/submit`);
export const renterMakeFinalPayment = (leaseWrapupId, payload) => axios.post(`/api/rentivity/lease-wrapup/${leaseWrapupId}/renter/final/payment`, payload);
export const renterManualFinalPayment = leaseWrapupId => axios.patch(`/api/rentivity/lease-wrapup/${leaseWrapupId}/renter/final-payment/manual`);
export const renterFPcomplete = leaseWrapupId => axios.patch(`/api/rentivity/lease-wrapup/${leaseWrapupId}/renter/final-payment/complete`);
export const getPaymentHistory = renterId => axios.get(`/api/rentivity/payments/${renterId}`)
export const getPmPaymentHistory = propertySessionId => axios.get(`/api/rentivity/session/${propertySessionId}/payments`)
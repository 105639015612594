import createReducer from 'utils/createReducer'



const initialState = {
  pending: false,
  success: false,
  error: false,
  fields: { acctType: 'renter', email: '', firstName: '', lastName: '', password: '', passwordConfirm:'' },
  registrationState: { step: -1 },
  organization: {}
};


export default createReducer(initialState, {
  CLEAR_STATUS: (state) => ({
    ...state,
    success: false,
    pending: false,
    error: false
  }),
  SET_FIELDS: (state, action) => ({
    ...state,
    fields: action.value
  }),
  SUBMIT_REGISTRATION_PENDING: (state) => ({
    ...state,
    pending: true
  }),
  SUBMIT_REGISTRATION_SUCCESS: (state, action) => ({
    ...state,
    success: true,
    pending: false,
    error: false,
    user: action.value
  }),
  SUBMIT_REGISTRATION_ERROR: (state, action) => ({
    ...state ,
    pending: false,
    success: false,
    error: action.error
  })
});


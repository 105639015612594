import React, { Suspense, PureComponent } from 'react';
import * as Yup from 'yup';
import {
  Card, CardBody, CardGroup,
  Button,
  Container, Row, Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  FormFeedback,
  InputGroupText
} from 'reactstrap';
import { connect } from 'react-redux';
// eslint-disable-next-line
import _ from 'lodash';
import { Formik } from 'formik';
import * as services from '../../../../utils/services';
import { ToastContext } from '../../../../contexts';
import { toast } from 'react-toastify';
// images
import background from '../../../../assets/img/backgrounds/joshua-ness-109299-unsplash.jpg';

const toastMessageSetting = {position: toast.POSITION.TOP_RIGHT}

const schema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required'),
  passwordConfirm: Yup.string()
    .test('equals-password', 'Passwords do not match', function validate(value) {
      return this.parent.password === value;
    })
    .required('Password confirmation is required')
})


const loading = () => (
  <div className="animated fadeIn pt-1 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);




class SetPassword extends PureComponent {

  state = { error: {}, pending: true };

  static contextType = ToastContext;


  componentDidMount = async () => {
    this.handleVerify();
    this.props.dispatch({ type: 'SET_LAYOUT', value: { dashboardType: 'renter' } })
  }

  handleVerify = async () => {
    try {
      const { data: { user } } = await services.verifyEmail(this.props.match.params.token);
      if (!user.registration.setPasswordPending){
        this.context.showErrorToast('Set password is not pending for this user', {...toastMessageSetting, autoClose: 2000 });
        return setTimeout(() => {
          this.props.history.push('/login');
        }, 2000);
      }
      return this.setState({ pending: false })
    } catch(err){
      this.context.showErrorToast(err,toastMessageSetting);
      return setTimeout(() => {
        this.props.history.push('/login');
      }, 2000);
    }
  }

  handleSubmit = async (values) => {
    try {
      await this.setState({ pending: true});
      const { data: user } = await services.setUserPassword(this.props.match.params.token, values)
      this.context.showSuccessToast('Password successfully set. Proceed to login', { autoClose: 2000 });
      setTimeout(() => {
        this.props.history.push('/login');
      }, 2000);
      return user;
    } catch (error) {
      return this.context.showErrorToast(error,toastMessageSetting);
    }
  };


  render() {
    return (
      <div className="app bg-cover login" style={{backgroundImage: `url(${background})`}}>
        <div className="app-body">
          <main className="main d-flex flex-row align-items-center">
            <Suspense fallback={loading()}>

              {/* <div className="flex-row align-items-center "> */}
              <Container>
                <Row className="justify-content-center">
                  <Col md="10" lg="8">
                    <CardGroup>
                      <Card className="p-4 no-border">
                        <CardBody>
                          <Formik
                            initialValues={{ password: '', passwordConfirm: '' }}
                            validationSchema={schema}
                            onSubmit={this.handleSubmit}
                          >
                            {
                              ({
                                values,
                                isValid,
                                handleSubmit,
                                handleBlur,
                                handleChange,
                                errors,
                                touched
                              }) => (
                                <Form onSubmit={handleSubmit}>
                                  <h1>Password</h1>
                                  <p className="text-muted">Set New Password</p>
                                  <InputGroup className="mb-3">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText><i className="icon-user" /></InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                      className="password"
                                      name="password"
                                      type="password"
                                      placeholder="Password"
                                      autoComplete='new-password'
                                      value={values.password}
                                      valid={touched.password && !errors.password}
                                      invalid={touched.password && errors.password}
                                      disabled={this.state.pending}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <FormFeedback>{errors.password}</FormFeedback>
                                  </InputGroup>
                                  <InputGroup className="mb-4">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="icon-lock" />
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                      className="password"
                                      name="passwordConfirm"
                                      type="password"
                                      placeholder="Password Confirm"
                                      autoComplete='password-confirm'
                                      value={values.passwordConfirm}
                                      valid={touched.passwordConfirm && !errors.passwordConfirm}
                                      invalid={touched.passwordConfirm && errors.passwordConfirm}
                                      disabled={this.state.pending}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <FormFeedback>{errors.passwordConfirm}</FormFeedback>
                                  </InputGroup>

                                  {
                                    this.state.error && <Row>
                                      <Col>
                                        <p>{this.state.error.message}</p>
                                      </Col>
                                    </Row>
                                  }

                                  <Row>
                                    <Col xs="6">
                                      <Button
                                        color="primary"
                                        className="submit-login px-4"
                                        type="submit"
                                        disabled={!isValid}
                                      >
                                        Submit
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form>
                              )
                            }
                          </Formik>
                        </CardBody>
                      </Card>
                    </CardGroup>
                  </Col>
                </Row>
              </Container>
              {/* </div> */}
            </Suspense>
          </main>
        </div>
      </div>
    )
  }
}





export default connect()(SetPassword);
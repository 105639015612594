import * as services from '../../../../utils/services';



export const submitRegistration = (payload) => async (dispatch) => {
  dispatch({ type: 'SET_FIELDS', value: payload });
  dispatch({ type: 'SUBMIT_REGISTRATION_PENDING' });
  try {
    const { data: auth } = await services.submitRegistration(payload)
    return dispatch({ type: 'SUBMIT_REGISTRATION_SUCCESS', value: auth });
  } catch (err) {
    return dispatch({ type: 'SUBMIT_REGISTRATION_ERROR', error: err.response.data });
  }
};

export const verifyEmail = (token) => async (dispatch) => {
  dispatch({ type: 'VERIFY_EMAIL_PENDING' });

  try {
    const { data: { user = {}, organization = {} } } = await services.verifyEmail(token);
    return dispatch({
      type: 'VERIFY_EMAIL_SUCCESS',
      value: {
        user,
        organization
      }
    });
  } catch (err) {
    return dispatch({
      type: 'VERIFY_EMAIL_ERROR',
      error: err.response.data
    });
  }
};


export const submitStep = (stepName, payload) => async (dispatch) => {
  dispatch({ type: 'SUBMIT_REGISTRATION_STEP_PENDING', stepName });


  try {
    const { data: { user = {} } } = await services.submitStep(stepName, payload);
    dispatch({
      type: 'SET_SESSION_USER',
      value: user
    });

    return dispatch({
      type: 'SUBMIT_REGISTRATION_STEP_SUCCESS',
      value: user,
      stepName
    });
  } catch (err) {
    return dispatch({
      type: 'SUBMIT_REGISTRATION_STEP_ERROR',
      error: err.response.data,
      stepName
    });
  }
};


import createReducer from 'utils/createReducer';


const initialState = {
  higlights: {},
  fields: []
};


export default createReducer(initialState, {
  ADD_HIGHLIGHT: (state, action) => ({
    ...state,
    highlights: {
      ...state.highlights,
      [action.section]: [
        ...(state.highlights[action.section] || []),
        action.value
      ]
    }
  })
});


// /**
//  * Gets the repositories of the user from Github
//  */

import { select, call, put, takeLatest } from 'redux-saga/effects';
import * as services from '../../../../utils/services';
import _ from 'lodash';



function* getLwStatus(action) {
  try {
    const { data: status } = yield call(services.getLwStatus, [action.value.leaseWrapup._id])
    yield put({ type: 'GET_LW_STATUS_SUCCESS', value: status });
  } catch (err) {
    yield put({ type: 'GET_LW_STATUS_ERROR', error: err });
  }
}

function* getLwDetails(action) {
  try {
    const { data: details } = yield call(services.getLeaseWrapupDetails, [action.value.leaseWrapup._id])
    yield put({ type: 'GET_LW_DETAILS_SUCCESS', value: details });
  } catch (err) {
    yield put({ type: 'GET_LW_DETAIL_ERROR', error: err });
  }
}

function* getLeaseTemplates(action) {
  try {
    const renters = yield select((state) => state.pmLeaseWrapup.renters);
    const { data: feesAndDeposits } = yield call(services.fetchLeaseTemplates, [{
      query: {
        type: 'lease',
        numSignees: { $gte: renters.length }
      }
    }])

    yield put({ type: 'GET_LEASE_TEMPLATES_SUCCESS', value: feesAndDeposits });
  } catch (err) {
    yield put({ type: 'GET_LEASE_TEMPLATES_ERROR', error: err });
  }
}


function* getFeesAndDeposits(action) {
  try {
    const { data: costSummary } = yield call(services.fetchCostSummary, [action.value.leaseWrapup._id]);
    // const { data: feesAndDeposits } = yield call(services.getPotentialFeesAndDeposits, [action.value.leaseWrapup._id]);
    yield put({ type: 'GET_FEES_AND_DEPOSITS_SUCCESS', value: costSummary });
  } catch (err) {
    yield put({ type: 'GET_FEES_AND_DEPOSITS_ERROR', error: err });
  }
}

function* getTuReport(action) {

  const appIds = async () => {
    return _.chain(action)
      .get('value.leaseWrapup.applications', [])
      .map(async (app) => {
        const { data } = await services.fetchTransunionReportByRenterId(app.renter._id);
        return {
          ...app,
          tuReport: data
        }
      })
      .thru((proms) => Promise.all(proms))
      .value();
  }
  yield put({ type: 'showSpinner', payload: { text: 'Please wait...' } });
  try {
    const applications = yield call(appIds);    
    yield put({ type: 'GET_LW_TU_REPORT_SUCCESS', value: applications });
    yield put({ type: 'hideSpinner' });
  } catch (err) {
    yield put({ type: 'hideSpinner' });
    yield put({ type: 'GET_LW_TU_REPORT_ERROR', error: err });
  }
}

function* getLeaseWrapupDetails(action) {
  yield put({ type: 'showSpinner', payload: { text: 'Please wait...' } });
  try {
    const { data: propertySession } = yield call(services.fetchPropertySessionById, action.propertySessionId, {
      populate: [
        {
          path: 'property',
          populate: { path: 'images' }
        }, {
          path: 'leaseWrapup',
          populate: [{
            path: 'applications.renter',
            populate:
            {
              path: 'renterApplication'
            }
          }, {
            path: 'applications.uploads.file'
          }, {
            path: 'applications.renterApplication',
            populate: { path: 'occupants' }
          }]
        }, {
          path: 'leaseTemplate',
          populate: {
            path: 'associatedRiders'
          }
        }, {
          path: 'promotions'
        }, {
          path: 'organization',
          populate:
            [
              {
                path: 'qualificationCriteria',
                populate: { path: 'restrictedPetBreeds' }
              }
            ]
        }]
    });

    yield put({ type: 'GET_LW_PROPERTY_SESSION_SUCCESS', value: propertySession });
    yield put({ type: 'hideSpinner' });
  } catch (err) {
    yield put({ type: 'hideSpinner' });
    yield put({ type: 'GET_LW_PROPERTY_SESSION_ERROR', error: err });
  }
}

function* getLeaseTemplateCustomFields(action) {
  try {
    const lt = yield select((state) => state.pmLeaseWrapup.leaseTemplate);
    const { data: fields } = yield call(services.fetchLeaseTemplateCustomFields, [lt._id])

    yield put({ type: 'GET_LEASE_TEMPLATE_CUSTOM_FIELDS_SUCCESS', value: fields });
  } catch (err) {
    yield put({ type: 'GET_LEASE_TEMPLATE_CUSTOM_FIELDS_ERROR', error: err });
  }
}

function* getLwFlow(action) {
  try {
    const { data: leaseWrapupFlow } = yield call(services.fetchLeaseWrapupFlow, [action.value.leaseWrapup._id]);
    yield put({ type: 'GET_LEASE_WRAPUP_FLOW_SUCCESS', value: leaseWrapupFlow });
    yield put({ type: 'hideSpinner' });
  } catch (err) {
    yield put({ type: 'hideSpinner' });
    yield put({ type: 'GET_LEASE_WRAPUP_FLOW_ERROR', error: err });
  }
}

// /**
//  * Root saga manages watcher lifecycle
//  */
export default function* leaseWrapupSaga() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest('GET_LW_PROPERTY_SESSION', getLeaseWrapupDetails);
  yield takeLatest('GET_LW_TU_REPORT', getTuReport);
  yield takeLatest('GET_LW_PROPERTY_SESSION_SUCCESS', getLeaseTemplates);
  yield takeLatest('GET_LW_PROPERTY_SESSION_SUCCESS', getLeaseTemplateCustomFields);
  yield takeLatest('GET_LW_PROPERTY_SESSION_SUCCESS', getFeesAndDeposits);
  yield takeLatest('GET_LW_PROPERTY_SESSION_SUCCESS', getLwDetails);
  // yield takeLatest('GET_LW_PROPERTY_SESSION_SUCCESS', getLwStatus);
  yield takeLatest('GET_LW_DETAILS_SUCCESS', getLwFlow);
}

import * as Yup from 'yup'


const CompanyInfoSchema = () => Yup.object().shape({
  companyName: Yup.string()
    .min(1, 'Too Short')
    .max(50, 'Too Long')
    .required('Company Name is required'),
  dba: Yup.string()
    .min(1, 'Too Short')
    .max(50, 'Too Long')
    .required('Name Publicly Displayed is required'),
  street1: Yup.string()
    .min(1, 'Too Short')
    .max(50, 'Too Long')
    .required('Street 1 is required'),
  street2: Yup.string(),
  city: Yup.string()
    .min(1, 'Too Short')
    .max(50, 'Too Long')
    .required('City is required'),
  state: Yup.string()
    .min(1, 'Too Short')
    .max(50, 'Too Long')
    .required('State is required'),
  zip: Yup.string()
    .matches(/^(\d{5})?$/, 'zip should be a 5 digit valid number')
    .required('Zip Code is required'),
  employerIdentificationNumber: Yup.string()
    .matches(/^[0-9]{2}\d?-\d{7}$/, { message: 'Invalid format. Ex: 12-2425678', excludeEmptyString: false })
    .required('Employer Identification Number is required'),
  phone: Yup.string()
    .matches(/\d{10}$/, { message: 'Should be 10 numbers' })
    .required('Phone is required'),
  pocName: Yup.string()
    .matches(/^[a-zA-Z\s]*$/)
    .min(1, 'Too Short')
    .max(50, 'Too Long')
    .required('POC Name is required'),
  pocEmail: Yup.string()
    .email('POC Email must be a valid email')
    .required('POC Email is required'),
  pocPhone: Yup.string()
    .min(1, 'Too Short')
    .max(50, 'Too Long')
    .matches(/\d{10}$/, { message: 'Should be 10 numbers' })
    .required('POC Phone is required'),
  licenseNumber: Yup.string()
    .min(1, 'Too Short')
    .max(50, 'Too Long')
    .required('Real Estate Brokers License Required'),
  isSuperAdmin: Yup.bool(),
  // adminName: Yup.string().when('isSuperAdmin', {
  //   is: false,
  //   then: Yup.string().required('Admin name is required')
  // }),
  firstName: Yup.string().when('isSuperAdmin', {
    is: false,
    then: Yup.string()
      .min(2, 'Too Short')
      .max(50, 'Maximum 50 characters')
      .matches(/^[a-zA-Z ]+$/, { message: 'Only letters allowed' })
      .required('First Name is required')
  }),
  lastName: Yup.string().when('isSuperAdmin', {
    is: false,
    then: Yup.string()
      .min(2, 'Too Short')
      .max(50, 'Maximum 50 characters')
      .matches(/^[a-zA-Z ]+$/, { message: 'Only letters allowed' })
      .required('Last Name is required')
  }),
  adminPhone: Yup.string().when('isSuperAdmin', {
    is: false,
    then: Yup.string().matches(/\d{10}$/, { message: 'Should be 10 numbers' }).required('Admin Phone is required')
  }),
  adminEmail: Yup.string().when('isSuperAdmin', {
    is: false,
    then: Yup.string().email('Admin Email must be a valid email').required('Admin Email is required')
  }),
  mlsId: Yup.string()
    .min(5, 'Too short')
    .required('MLS ID is Required'),
  brandImg: Yup.string().required(),
});

export default CompanyInfoSchema;

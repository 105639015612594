import * as Yup from 'yup'


const resetPasswordForm = () => Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Email is required')

});

export default resetPasswordForm;

export const defaultValues = {
  email: ''
}


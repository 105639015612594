/* eslint arrow-body-style: [0] */
import React, { Component } from 'react';
import _ from 'lodash';
import { Formik, Field } from "formik";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input/basic-input';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Button,
  Col,
  CustomInput,
  Input,
  Row,
  Form,
  FormGroup,
  Label,
  FormFeedback
} from 'reactstrap';
import { InputAdapter } from 'react-text-mask-hoc';

import { FormikCustomField, InputMask } from 'components/Inputs';
import ImageDropzone from 'components/ImageDropzone';
import usStates from 'assets/us-states';
import CompanyInfoSchema from './schema';
import { getImage } from '../../../../utils/services';


class CompanyInfo extends Component {
  state = { isInitialValid: undefined, image: {} }
  componentWillMount() {
    try {
      CompanyInfoSchema().validateSync(this.props.initialValues);
      this.setState({ isInitialValid: true })
    } catch (err) {
      this.setState({ isInitialValid: false })
    }
  }

  async componentDidMount() {
    const { initialValues } = this.props;
    if (initialValues.brandImg) {
      try {
        const { data } = await getImage(initialValues.brandImg);
        this.setState({
          image: data
        }); 
      } catch(err) {
        console.log(err, 'error');
      }
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (this.props.initialValues.brandImg !== nextProps.initialValues.brandImg) {
      if (nextProps.initialValues.brandImg) {
        try {
          const { data } = await getImage(nextProps.initialValues.brandImg);
          this.setState({
            image: data
          }); 
        } catch(err) {
          console.log(err, 'error');
        }
      } 
    }
  }

  render() {
    const { image } = this.state;

    return (
      <Card>
        <Formik
          validationSchema={CompanyInfoSchema()}
          enableReinitialize
          isInitialValid={this.state.isInitialValid}
          initialValues={{
            companyName: '',
            dba: '',
            street1: '',
            street2: '',
            city: '',
            state: '',
            zip: '',
            employerIdentificationNumber: '',
            phone: '',
            pocName: '',
            pocEmail: '',
            pocPhone: '',
            licenseNumber: '',
            isSuperAdmin: true,
            mlsId: '',
            firstName: '',
            lastName: '',
            adminPhone: '',
            adminEmail: '',
            brandImg: '',
            ...this.props.initialValues
          }}
          onSubmit={(payload) => {
            delete payload.adminName;

            this.props.onSubmit('company-info', payload)
          }}
        >
          {
            ({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldTouched,
              setFieldValue,
              isValid
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <CardHeader>Company Information</CardHeader>

                  <CardBody>


                    <Row>
                      <Col xs={2}>
                        <ImageDropzone
                          image={image}
                          onFileUpload={(img) => setFieldValue('brandImg', img._id)}
                          dropzoneMessage={<div>Click to upload a brand image<span className='text-danger'>*</span></div>}
                          imageProps={{ width: '100%', height: 'auto' }}
                        />
                      </Col>
                      <Col xs={10}>
                        {/* companyName */}
                        <Row form>
                          <Col>
                            <Field
                              type='text'
                              name='companyName'
                              label='Company Name'
                              component={FormikCustomField}
                              required
                            />
                          </Col>
                          <Col>
                            <Field
                              type='text'
                              name='dba'
                              label='Name Publicly Displayed'
                              component={FormikCustomField}
                              required
                            />
                          </Col>
                        </Row>

                        {/* street 1 */}
                        <Row form>
                          <Col>
                            <Field
                              type='text'
                              name='street1'
                              label='Street 1'
                              required
                              component={FormikCustomField}
                            />
                          </Col>
                        </Row>

                        {/* street 2 */}
                        <Row form>
                          <Col>
                            <Field
                              type='text'
                              name='street2'
                              label='Street 2'
                              noValidate
                              component={FormikCustomField}
                            />
                          </Col>
                        </Row>

                        {/* city, state, zip */}
                        <Row form>
                          <Col>
                            <Field
                              type='text'
                              name='city'
                              label='City'
                              component={FormikCustomField}
                              required
                            />
                          </Col>
                          <Col xs={2}>
                            <Field
                              type='select'
                              name='state'
                              label='State'
                              component={FormikCustomField}
                              required
                            >
                              <option value={null} />
                              {
                                _.chain(usStates)
                                  .keys()
                                  .map((abbr, i) => <option value={abbr} key={`state-abbr-${i}-${abbr}`}>{abbr}</option>)
                                  .value()
                              }
                            </Field>
                          </Col>
                          <Col>
                            <Field
                              type='text'
                              name='zip'
                              label='Zip Code'
                              component={FormikCustomField}
                              required
                            />
                          </Col>
                        </Row>


                        {/* employeeIdentificationNumber */}
                        <Row form>
                          <Col>
                            <FormGroup>
                              <Label htmlFor="employerIdentificationNumber" className="d-block font-weight-semibold">
                                Employer Identification Number
                                <span className='text-danger'>*</span>
                              </Label>
                              <InputMask
                                mask={[/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                Component={InputAdapter}
                                className="form-control"
                                name='employerIdentificationNumber'
                                value={_.get(values, 'employerIdentificationNumber')}
                                required
                                valid={_.get(touched, 'employerIdentificationNumber') && !_.get(errors, 'employerIdentificationNumber')}
                                invalid={_.get(touched, 'employerIdentificationNumber') && !!_.get(errors, 'employerIdentificationNumber')}
                                onBlur={handleBlur}
                                onChange={(event, nextState) => {
                                  setFieldValue('employerIdentificationNumber', nextState.value.replace(/_/g, ''));
                                }}
                                guide={true}
                              />
                              <FormFeedback>{_.get(errors, 'employerIdentificationNumber')}</FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label for="phone">Phone<span className='text-danger'>*</span></Label>
                              <PhoneInput
                                name='phone'
                                className={`form-control ${!touched.phone ? '' : !_.get(errors, 'phone') ? 'is-valid' : 'is-invalid'}`}
                                country="US"
                                onBlur={() => {
                                  setFieldTouched('phone', true);
                                  handleBlur();
                                }}
                                value={_.get(values, 'phone')}
                                onChange={(value) => {
                                  setFieldTouched('phone', true);
                                  setFieldValue('phone', value.substr(0, 10))
                                }}
                              />
                              <FormFeedback>{errors.phone}</FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>

                        {/* POC Name */}
                        <Row form>
                          <Col>
                            <Field
                              type='text'
                              name='pocName'
                              label='POC Name'
                              component={FormikCustomField}
                              required
                            />
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label for="pocPhone">POC Phone<span className='text-danger'>*</span></Label>
                              <PhoneInput
                                name='pocPhone'
                                className={`form-control ${!touched.pocPhone ? '' : !_.get(errors, 'pocPhone') ? 'is-valid' : 'is-invalid'}`}
                                country="US"
                                onBlur={() => {
                                  setFieldTouched('pocPhone', true);
                                  handleBlur();
                                }}
                                value={_.get(values, 'pocPhone')}
                                onChange={(value) => {
                                  setFieldTouched('pocPhone', true);
                                  setFieldValue('pocPhone', value.substr(0, 10))
                                }}
                              />
                              <FormFeedback>{errors.pocPhone}</FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col>
                            <Field
                              type='email'
                              name='pocEmail'
                              label='POC Email'
                              component={FormikCustomField}
                              required
                            />
                          </Col>
                        </Row>

                        {/* POC email, POC Phone */}
                        <Row form>
                          <Col>
                            <Field
                              type='text'
                              name='licenseNumber'
                              label='Office Real Estate Brokers License #'
                              component={FormikCustomField}
                              required
                            />
                          </Col>
                          <Col>
                            <Field
                              type='text'
                              name='mlsId'
                              label='MLS Office ID'
                              component={FormikCustomField}
                              required
                            />
                          </Col>
                        </Row>

                        {/* Will be admin? */}
                        <Row form className="mb-3">
                          <Col>
                            <FormGroup>
                              <CustomInput
                                type="checkbox"
                                id="isSuperAdmin"
                                label="I will be an super administrator of the organization"
                                checked={values.isSuperAdmin}
                                value={values.isSuperAdmin}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <FormFeedback>{errors.isSuperAdmin}</FormFeedback>
                              </CustomInput>
                            </FormGroup>
                          </Col>
                        </Row>

                        {
                          !values.isSuperAdmin && (
                            <Row form className="mb-3">
                              <Col>
                                {/* <FormGroup>
                                  <Label for="adminName">Admin Name</Label>
                                  <Input
                                    type="text"
                                    name="adminName"
                                    value={values.adminName}
                                    required
                                    valid={touched.adminName && !errors.adminName}
                                    invalid={touched.adminName && !!errors.adminName}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                  <FormFeedback>{errors.adminName}</FormFeedback>
                                </FormGroup> */}
                                <FormGroup>
                                  <Label for="firstName">First Name<span className='text-danger'>*</span></Label>
                                  <Input
                                    type="text"
                                    name="firstName"
                                    value={values.firstName}
                                    required
                                    valid={touched.firstName && !errors.firstName}
                                    invalid={touched.firstName && !!errors.firstName}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                  <FormFeedback>{errors.firstName}</FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label for="lastName">Last Name<span className='text-danger'>*</span></Label>
                                  <Input
                                    type="text"
                                    name="lastName"
                                    value={values.lastName}
                                    required
                                    valid={touched.lastName && !errors.lastName}
                                    invalid={touched.lastName && !!errors.lastName}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                  <FormFeedback>{errors.lastName}</FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label for="adminPhone">Admin Phone<span className='text-danger'>*</span></Label>
                                  <PhoneInput
                                    name='adminPhone'
                                    className={`form-control ${!touched.adminPhone ? '' : !_.get(errors, 'adminPhone') ? 'is-valid' : 'is-invalid'}`}
                                    country="US"
                                    value={_.get(values, 'adminPhone')}
                                    onChange={(value) => {
                                      setFieldTouched('adminPhone', true);
                                      setFieldValue('adminPhone', value.substr(0, 10))
                                    }}
                                    onBlur={() => {
                                      setFieldTouched('adminPhone', true);
                                      handleBlur();
                                    }}
                                  />
                                  <FormFeedback>{errors.adminPhone}</FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label for="adminEmail">Admin Email<span className='text-danger'>*</span></Label>
                                  <Input
                                    type="text"
                                    name="adminEmail"
                                    value={values.adminEmail}
                                    required
                                    valid={touched.adminEmail && !errors.adminEmail}
                                    invalid={touched.adminEmail && !!errors.adminEmail}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                  <FormFeedback>{errors.adminEmail}</FormFeedback>
                                </FormGroup>
                              </Col>
                            </Row>
                          )
                        }

                      </Col>
                    </Row>

                  </CardBody>

                  <CardFooter>
                    <Button
                      type='submit'
                      color="primary"
                      className="px-4"
                      disabled={!_.isEmpty(errors)}
                    >
                      Next
                    </Button>
                  </CardFooter>
                </Form>
              )
            }
          }
        </Formik>
      </Card >
    )
  }
}

export default CompanyInfo;

import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import {
  Input, FormFeedback,
  CustomInput,
} from 'reactstrap';
import { getIn } from 'formik';

const customFormikField = ({ field, form: { touched, errors }, icon, ...props }) => {
  const touchedValue = getIn(touched, field.name);
  const errorValue = getIn(errors, field.name);

  if (props.type === "checkbox") {
    return (
      <>
        <CustomInput
          id={field.name}
          type="checkbox"
          inline
          {...field}
          {...props}
        />
        {touched[field.name] && errors[field.name] && <FormFeedback className='ml-5'>{errors[field.name]}</FormFeedback>}
      </>
    )
  } else {
    return (
      <>
        <Input
          invalid={touchedValue && !!errorValue}
          valid={_isEmpty(errorValue) && touchedValue ? true : false}
          {...field}
          {...props} />
        {touchedValue && errorValue && <FormFeedback>{errorValue}</FormFeedback>}
      </>
    );
  }
}

export default customFormikField;
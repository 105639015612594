import _ from 'lodash';
const debug = require('debug')('property-search:utils');


class CacheMap extends Map {
  get(_key){
      try{
          const key = JSON.parse(_key);
          return _.find(Array.from(this.keys()), (el) => _.isEqual(JSON.parse(el), key))
      } catch(err){
          return super.get(_key.toString())
      }
  }
  has(_key){
      try{
          const key = JSON.parse(_key);
          return !!_.find(Array.from(this.keys()), (el) => _.isEqual(JSON.parse(el), key));
      } catch(err){
          return super.has(_key.toString());
      }
  }
}

_.memoize.Cache = CacheMap;


const memoizeWithObjHash = (func) => func
const memoizeWithObjId = (func) => func
// const memoizeWithObjHash = (func) => _.memoize(func, createObjHash);
// const memoizeWithObjId = (func) => _.memoize(func, (obj) => obj._id);


const __applyFilter = (filtersToApply = {}) => memoizeWithObjId((ps) => {
  const { amenities = [], propertyType = [], lotSize = [] } = filtersToApply;

  // filter properties for the dropdown filters
  for (let key in filtersToApply){
    if (key === 'beds' && filtersToApply[key] !== '-1' && filtersToApply[key] !== -1) {
      if (ps.property.beds < parseInt(filtersToApply[key])){
        return false;
      }
      // props = _.filter(props, ({ property }) => property.beds >= parseInt(filtersToApply[key]))

    }
    if (key === 'baths' && filtersToApply[key] !== '-1' && filtersToApply[key] !== -1) {
      // props = _.filter(props, ({ property }) => property.baths >= parseInt(filtersToApply[key]))
      if (ps.property.baths < parseInt(filtersToApply[key])){
        return false;
      }
    }
  
    const { 
      fixedPrice, 
      eviction, 
      bankruptcy, 
      type, 
      minimumResidentScore,
      currentBidPrice,
      startPrice,
      rentItNow,
      organization 
    } = ps;
   // TODO: bid price

    if (key === 'price' && filtersToApply[key] !== '-1' && filtersToApply[key] !== -1) {

      if (filtersToApply[key] !== 'custom' && _.isArray(filtersToApply.customPrice) && filtersToApply.customPrice.length === 2 ){
        const [min, max] = filtersToApply.customPrice;
        if (fixedPrice < parseInt(min) || fixedPrice > parseInt(max)){
          return false;
        }
      }
      else {
        const currPrice = (type === 'BID') ? (currentBidPrice || startPrice) : fixedPrice;
        if (currPrice < parseInt(filtersToApply[key])){
          return false;
        }
      }
    }

     // TODO: Rent it now price

     if (key === 'rentItNow' && filtersToApply[key] !== '-1' && filtersToApply[key] !== -1) {
      const currPrice = (type === 'BID') ? rentItNow : fixedPrice;
      if (currPrice > parseInt(filtersToApply[key])){
        return false;
      }
     
    }

    if (key === 'eviction'){
      if (filtersToApply[key] === 'yes' || filtersToApply[key] === true){
        if(eviction === true) {
          if(_.get(organization,'qualificationCriteria.decline.eviction.monthsWithin') >= parseInt(filtersToApply['evictionPeriod']))
          {
            return false;
          } 
        }
      }

      if (filtersToApply[key] === 'no' || filtersToApply[key] === false){
        // No need any filter when renter not having any eviction
        // if (eviction === true){
        //   return false;
        // }
      }
    }

    if (key === 'bankruptcy'){
      if (filtersToApply[key] === 'yes' || filtersToApply[key] === true){
         
        /*if (bankruptcy === true){ 
          if(_.get(organization,'qualificationCriteria.decline.bankruptcy_discharged.monthsWithin') >= parseInt(filtersToApply['bankruptcyPeriod']))
          {
            return false;
          } 
        } */

        // Check bankruptcy_discharged enforce
        if(filtersToApply['bankruptcyType'] === 'bankruptcy_discharged') {
          if(_.get(organization,'qualificationCriteria.decline.bankruptcy_discharged.enforce'))
          {
            if(_.get(organization,'qualificationCriteria.decline.bankruptcy_discharged.monthsWithin') >= parseInt(filtersToApply['bankruptcyPeriod']))
            {
              return false;
            } 
          }  
        }

        if(filtersToApply['bankruptcyType'] === 'bankruptcy_open') {
          // Chapter 7 enforce check
          if(filtersToApply['bankruptcyChapter'] === 'bankruptcy_ch7') {
            if(_.get(organization,'qualificationCriteria.decline.bankruptcy_open.enforce'))
            {
              return false;
            } 
          }  
           // Chapter 13 enforce check
          if(filtersToApply['bankruptcyChapter'] === 'bankruptcy_ch13') {
            if(_.get(organization,'qualificationCriteria.decline.bankruptcy_ch13.enforce'))
            {
              return false;
            } 
          } 
        }        
      }

      if (filtersToApply[key] === 'no' || filtersToApply[key] === false){
        // No need any filter when renter not having any bankrupcy
        // if (bankruptcy === true){
        //   return false;
        // }
      }
    }




    if (key === 'available' && filtersToApply[key] !== '-1' && filtersToApply[key] !== -1) {
      if (type !== filtersToApply[key]) {
        return false;
      }
    }

    if (key === 'pets' && filtersToApply[key] !== '-1' && filtersToApply[key] !== -1 ){
      const validValues = (filtersToApply[key] === 'all') ? ['cat', 'dog', 'catdog', 'all'] :
        (filtersToApply[key] === 'catdog') ? ['cat', 'dog', 'catdog'] : [filtersToApply[key]];

      if (_.difference(validValues, [ps.property.petPolicy]).length === validValues.length){
        return false;
      }
    }



    if (key === 'minimumResidentScore'){

      if (!_.isNaN(parseInt(filtersToApply[key])) && parseInt(filtersToApply[key]) !== -1){
        if (minimumResidentScore > parseInt(filtersToApply[key])){
          return false;
        }
      }
      // minimumResidentScore input changed as textbox, so the above statements not required
      // if (filtersToApply[key] === '700plus'){
      //   if (minimumResidentScore < 700){
      //     return false;
      //   }
      // }
    }

    // TODO: need min ps creditscore stored in db
    if (key === 'creditScore'){
      // if (!_.isNaN(parseInt(filtersToApply[key])) && parseInt(filtersToApply[key]) !== -1){
      //   if (creditScore > parseInt(filtersToApply[key])){
      //     return false;
      //   }
      // }
      // if (filtersToApply[key] === '700plus'){
      //   if (creditScore < 700){
      //     return false;
      //   }
      // }
    }
  }

  // filter properties more-filter amenities checkboxes
  if (amenities.length > 0){
    if (_.difference(amenities, _.map(ps.property.features, 'name')).length !== 0){
      return false;
    }
  }

  if (propertyType.length > 0){
    if (_.findIndex(propertyType, (pt) => ps.property.propertyType === pt) === -1){
      return false;
    }
  }



  if (lotSize.length > 0){
    if (lotSize.length === 1){
      if (ps.property.livingArea < parseInt(lotSize[0])){
        return false;
      }
    }

    if (lotSize.length === 2){
      const [min, max] = lotSize;
      // eslint-disable-next-line
      if (max == 6000){
        if (ps.property.livingArea < parseInt(min)){
          return false;
        }
      }
      else {
        if (ps.property.livingArea < parseInt(min) || ps.property.livingArea > parseInt(max)){
          return false;
        }
      }

    }
  }

  return true;
});


const _applyFilter = memoizeWithObjHash(__applyFilter);
export const applyFilters = (filter, arr) => {
  const toKeep = _.map(_.filter(arr, _applyFilter(filter)), '_id');

  const toRemove = _.chain(arr)
    .groupBy('_id')
    .mapValues((val) => _.head(val))
    .omit([...toKeep])
    .map('_id')
    .value();
  return toRemove;
}

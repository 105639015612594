import React, { Component } from 'react';
import { Field, Formik } from 'formik';
import { Form, Row, Col, Button, FormGroup, Card, CardHeader } from 'reactstrap';
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from 'react-toastify';
import _ from 'lodash';

import { submitContactDetails } from 'utils/services';
import { FormikCustomField } from 'components/Inputs';
import subject from 'assets/contact-us-subject';
import ContactSchema, { defaultValues } from './schema/contact-schema';

import 'react-toastify/dist/ReactToastify.css';
import '../_cards.scss';

export default class Contact extends Component {
  handleCaptcha = value => console.log(value, 'captcha value');

  error = (message) =>
    // add type: 'error' to options
    toast.error(message)
    ;

  success = (message) =>
    // add type: 'success' to options
    // positioning: https://github.com/fkhadra/react-toastify#positioning-toast
    toast.success(message, {
      position: toast.POSITION.BOTTOM_RIGHT
    })
    ;

  render() {
    const recaptchaRef = React.createRef();
    const containerStyle = {
      zIndex: 1999
    };

    return (
      <div>
        {/*<ToastContainer position="top-center" autoClose={5000} style={containerStyle} />*/}
      
      <div className='animated fadeIn p-2'>
        <Card>
          <CardHeader className='align-items-center'><i className="fa fa-envelope font-2xl cutout" aria-hidden="true"></i>Contact Us </CardHeader>
          <Row className='p-3'>
            <Col lg={{ offset: 3, size: 6 }} md={{ offset: 3, size: 6 }} sm={12} xs={12}>
              <Formik
                validationSchema={ContactSchema}
                initialValues={defaultValues}
                onSubmit={values => {
                  if (!recaptchaRef.current.getValue()) {
                    this.error('Please verify you are a Human!!!');
                  } else {
                    console.log('hello');
                    submitContactDetails(values)
                      .then(() => this.success('Successfully submitted your details. Will get back to you.'))
                      .catch(() => this.error('Please try again, Something went wrong!!!'));
                  }
                }}
                render={props => {
                  const { handleChange, handleSubmit, values } = props;

                  return (
                    <Form onSubmit={handleSubmit}>
                      {/* Name */}
                      <Field
                        type='text'
                        name='name'
                        label='Name'
                        placeholder='Your Full Name'
                        required
                        inline
                        onChange={handleChange}
                        component={FormikCustomField}
                        value={values.name || ''}
                      />

                      {/* Email */}
                      <Field
                        type='text'
                        name='email'
                        label='Email'
                        placeholder='Your Email Address'
                        required
                        inline
                        onChange={handleChange}
                        component={FormikCustomField}
                        value={values.email || ''}
                      />

                      {/* Subject */}
                      <Field
                        type='select'
                        name='subject'
                        label='Subject'
                        component={FormikCustomField}
                        required
                        inline
                      >
                        {
                          _.chain(subject)
                            .keys()
                            .map((abbr, i) => (
                              <option value={subject[abbr]} key={`subject-${i}-${abbr}`}>{abbr}</option>
                            ))
                            .value()
                        }
                      </Field>

                      {/* Message */}
                      <Field
                        type='textarea'
                        rows='4'
                        name='message'
                        label='Message'
                        placeholder='Your Message'
                        required
                        inline
                        component={FormikCustomField}
                        value={values.message || ''}
                      />

                      {/* ReCaptcha */}
                      <FormGroup>
                        <Col md={{ size: 6, offset: 3 }} className='p-0 captcha-wrap'>
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            // TODO: Change this key to a proper one. Below is just a test key
                            sitekey="6Ldvt5oUAAAAAF-CNp8PRU_HbcKxvuVyucENy5Qj"
                            onChange={this.handleCaptcha}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className='text-center'>
                        <Col>
                          <Button
                            style={{ width: '50%' }}
                            type='submit'
                            color='primary'                          
                          >
                            Submit
                            </Button>
                        </Col>
                      </FormGroup>
                    </Form>
                  )
                }}
              />
            </Col>
          </Row>
        </Card>
      </div >
      </div> 
    )
  }
}

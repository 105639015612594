import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect'


import * as actions from './actions';


// selectors
const regStepSelector = createSelector(
  (state) => state.registration,
  (registration) => registration.steps
);

export const mapStateToProps = createSelector(
  regStepSelector,
  (steps) => steps.feesAndDeposits
);

export const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);




export const composeContainer = connect(mapStateToProps, mapDispatchToProps);

export { default as reducer } from './reducer';

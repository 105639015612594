import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';


import * as actions from './actions';
import selectors from './selectors';


export const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

const composeContainer = R.pipe(
  connect(selectors, mapDispatchToProps),
  withRouter
);

export default composeContainer;

export { default as reducer } from './reducer';
export { default as sagas } from './sagas';

import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button, Form, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';

import { setPropTypes, withProps, defaultProps } from 'recompose';
import { Formik } from 'formik';
import { PermissionCheckboxes, RoleInfo } from './RoleModalComponents'


const SizedCol = withProps({ xs: 12, md: 4, className: 'mt-4' })(Col);


const schema = Yup.object().shape({
  name: Yup.string()
    .min(1, 'Too Short')
    .max(50, 'Too Long')
    .required('Role name is required'),
  description: Yup.string()
    .min(1, 'Too Short')
});

const ModifyRolesModalBase = (props) => (
  <Modal
    isOpen={props.showModal}
    toggle={props.closeModal}
    size='xl'
  >
    <Formik
      initialValues={props.role}
      validationSchema={schema}
      onSubmit={(...args) => R.pipeP(props.create ? props.addRole : props.editRole, props.closeModal)(...args)}
    >
      {
        ({
          values,
          handleSubmit,
          handleChange,
          handleBlur
        }) => (
          <Form
            onSubmit={(evt) => {
              evt.stopPropagation();
              return handleSubmit(evt);
            }}
          >
            <ModalHeader toggle={props.closeModal}>{props.create ? 'Create Role' : `Editing Role: ${props.role.name}`}</ModalHeader>

            <ModalBody>
              <Container fluid className='content-row"'>
                <Row>
                  <Col>
                    <RoleInfo
                      label='Name + Description'
                      readOnly={props.readOnly}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={values.name}
                      description={values.description}
                    />
                  </Col>
                </Row>
                <Row>

                  <SizedCol>
                    <PermissionCheckboxes
                      label='Applications'
                      fields={{ viewApplications: 'View Applications' }}
                      readOnly={props.readOnly}
                      permissions={values.permissions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </SizedCol>

                  <SizedCol>
                    <PermissionCheckboxes
                      label='Properties'
                      fields={{
                        'addProperties': 'Add Properties',
                        'viewProperties': 'View Properties',
                        'editProperties': 'Edit Properties'
                      }}
                      readOnly={props.readOnly}
                      permissions={values.permissions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </SizedCol>

                  <SizedCol>
                    <PermissionCheckboxes
                      label='Listings'
                      fields={{
                        'approveBids': 'Approve Bids',
                        'closeListings': 'Close Listings',
                        'createListings': 'Create Listings',
                        'editListings': 'Edit Listings',
                        'viewListings': 'View Listings'
                      }}
                      readOnly={props.readOnly}
                      permissions={values.permissions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </SizedCol>

                  <SizedCol>
                    <PermissionCheckboxes
                      label='Tasks'
                      fields={{
                        'createTasks': 'Create Tasks',
                        'editTasks': 'Edit Tasks',
                        'viewTasks': 'View Tasks'
                      }}
                      readOnly={props.readOnly}
                      permissions={values.permissions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </SizedCol>

                  <SizedCol>
                    <PermissionCheckboxes
                      label='Accounts'
                      fields={{
                        'createAccounts': 'Create Tasks',
                        'createInvoices': 'Create Invoices',
                        'makePayments': 'Make Payments',
                        'viewAccounts': 'View Accounts',
                        'editAccounts': 'Edit Accounts'
                      }}
                      readOnly={props.readOnly}
                      permissions={values.permissions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </SizedCol>

                  <SizedCol>
                    <PermissionCheckboxes
                      label='Teams'
                      fields={{
                        'addMembers': 'Add Members',
                        'createRoles': 'Create Roles',
                        'deleteRoles': 'Delete Roles',
                        'editMembers': 'Edit Members',
                        'editRoles': 'Edit Roles',
                        'manageRoles': 'Manage Roles',
                        'viewMembers': 'View Members',
                        'viewRoles': 'View Roles'
                      }}
                      readOnly={props.readOnly}
                      permissions={values.permissions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </SizedCol>
                </Row>

                <Row>
                  <Col xs={12} className='mt-5'>
                    <PermissionCheckboxes
                      label='Reports'
                      fields={{ 'viewReports': 'View Reports' }}
                      readOnly={props.readOnly}
                      permissions={values.permissions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>
              </Container>
            </ModalBody>

            <ModalFooter>
              { !props.readOnly && <Button color="primary" type='submit'>Save</Button> }
              <Button color="primary" onClick={props.closeModal}>Close</Button>
            </ModalFooter>
          </Form>
        )
      }
    </Formik>

  </Modal>
);





const composeComponent = R.compose(
  setPropTypes({
    updateRole: PropTypes.func,
    closeModal: PropTypes.func,
    name: PropTypes.string,
    showModal: PropTypes.bool,
    create: PropTypes.bool,
    readOnly: PropTypes.bool
  }),
  defaultProps({
    showModal: false,
    create: false,
    readOnly: false
  })
);

export default composeComponent(ModifyRolesModalBase);

// this is the root saga file
import { all, spawn } from 'redux-saga/effects';

// import all sagas here:
import loginRoot from './views/Common/Login/store/sagas';
import globalSaga from './views/App/store/sagas';
// import propertySearchRoot from './views/Renter/PropertySearch/store/sagas';
import { sagas as mapRoot } from './views/Renter/PropertySearch/store';
import adminLeaseWrapupRoot from './views/Admin/LeaseWrapup/store/sagas';
import accountingSagaRoot from './views/Admin/Accounting/store/sagas';
import pmRegistrationRoot from './views/Admin/PMRegistration/store/sagas';
import { sagas as registrationRoot } from './views/Common/Registration/store';


// register all sagas here:
export default function* rootSaga() {
  /*
        'fork' is used to create attached forks.
        'spawn' is used to create detached forks.
        - The problem with forking is that if any of the root saga fails, then the root saga will be killed, and the
        other sub sagas will also be killed because their parent got killed. In practice, this means that your whole
        app may become unusable (if it relies heavily on sagas) just because of a minor saga error, so it's not really
        good to use fork.
        - Using spawn, detached forks live in their own execution context. A parent doesn't wait for detached forks to
        terminate. Uncaught errors from spawned tasks are not bubbled up to the parent. And cancelling a parent doesn't
        automatically cancel detached forks (you need to cancel them explicitly).
    */
  yield all([
    spawn(loginRoot),
    spawn(adminLeaseWrapupRoot),
    spawn(globalSaga),
    spawn(mapRoot),
    // spawn(propertySearchRoot),
    spawn(registrationRoot),
    spawn(pmRegistrationRoot),
    spawn(accountingSagaRoot)
  ]);
}

// /**
//  * Gets the repositories of the user from Github
//  */

import { call, put, fork, take, takeLatest } from 'redux-saga/effects';
// eslint-disable-next-line
import _ from 'lodash';
import * as services from '../../../utils/services';

const debug = require('debug')('app:store:sagas');

function* takexSaga(pattern) {
  let action;
  while (true) {
    action = yield take('*');
    debug('takex', action.type);
    if (pattern.test(action.type)) {
      debug('takex:match', action.type);
      break;
    }
    debug('takex:ignore', action.type);
  }
  return action;
}

function takex(pattern) {
  return call(takexSaga, pattern);
}


function* handleActions() {
  while (true) {
    const action = yield takex(/^SET_LAYOUT_/);
    put(action);
  }
}



function* getSession() {
  try {
    yield put({ type: 'GET_SESSION_PENDING' });
    const { data: session } = yield call(services.getSession)
    localStorage.setItem('auth', JSON.stringify(session));

    yield put({ type: 'GET_SESSION_SUCCESS', value: session });
  } catch (err) {
    yield put({ type: 'GET_SESSION_ERROR', error: err });
  }
}




export default function* rootSaga() {
  yield fork(handleActions);
  yield takeLatest('GET_SESSION', getSession);
}

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Row, Col, Card, CardHeader
} from 'reactstrap';

import { getDocumentsToBeVerified } from '../../../../utils/services';
import DocumentCards from './documentCards';

import './style.scss';

const UploadDocument = ({ onSubmit, onPrevious, documentInfo, editMode = false, onEditSubmit, initialValues }) => {
  const [documentsData, setDocumentsData] = useState([]);


  useEffect(() => {
    if (editMode) {
      setDocumentsData(documentInfo)
    } else {
      if (_.isEmpty(initialValues)) {
        async function fetchData() {
          const { data } = await getDocumentsToBeVerified();
          setDocumentsData(data)
        }
        fetchData();
      } else {
        setDocumentsData(initialValues);
      }
    }    
  }, [documentInfo, initialValues])

  return (
    <div className='animated fade-in'>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <h3>Documents</h3>
            </CardHeader>
            {!_.isEmpty(documentsData) &&
              <DocumentCards
                onPrevious={onPrevious}
                onSubmit={editMode ? onEditSubmit : onSubmit}
                documentInfo={documentsData}
                editMode={editMode}
              />
            }
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default UploadDocument;
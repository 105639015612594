import { call, put, takeLatest } from 'redux-saga/effects';
import * as services from '../../../../utils/services'


export function * submitLogin(action){
  try {
    yield put({ type: 'showSpinner', payload: { text: 'Loading...' } });
    yield put({ type: 'SUBMIT_LOGIN_PENDING' });
    const { data: resp } = yield call(services.submitLogin, action.value)
    yield put({ type: 'SUBMIT_LOGIN_SUCCESS', value: resp });
    yield put({ type: 'hideSpinner' });
  } catch(err){
    yield put({ type: 'hideSpinner' });
    action.formikActions.setSubmitting(false);
    yield put({ type: 'SUBMIT_LOGIN_ERROR', error: err.response.data });
  }
}

// /**
//  * Root saga manages watcher lifecycle
//  */
export default function* () {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest('SUBMIT_LOGIN', submitLogin);
}

import * as Yup from 'yup'
import { checkEmailAvailable } from '../../../../utils/services';

const commonRegistrationForm = () => Yup.object().shape({
  acctType: Yup.string()
    .oneOf(['renter', 'pm', 'owner', 'serviceVender'])
    .required('Account type is required'),
  firstName: Yup.string()
    .min(2, 'Minimum 2 characters')
    .max(50, 'Maximum 50 characters')
    .matches(/^[a-zA-Z ]+$/, { message: 'Only letters allowed', excludeEmptyString: true })
    .required('First name is required'),
  lastName: Yup.string()
    .min(2, 'Minimum 2 characters')
    .max(50, 'Maximum 50 characters')
    .matches(/^[a-zA-Z ]+$/, { message: 'Only letters allowed', excludeEmptyString: true })
    .required('Last name is required'),
  email: Yup.string()
    .email()
    .test(
      'email-available',
      'Email is already in use',
      async (email) => {
        if (email) {
          var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (regex.test(String(email).toLowerCase())) {
            try {
              const { data } = await checkEmailAvailable(email)
              return data.valid;
            } catch (err) {
              return false;
            }
          }
          else {
            // Return true because it will be check in required step
            return true;
          }  
        }
      })
    .required('Email is required'),
  password: Yup.string()
    .min(8)
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])/,
      "password must contain one uppercase and one lowercase"
    )
    .required('Password is required'),
  passwordConfirm: Yup.string()
    //  eslint-disable-next-line
    .test('equals-password', 'Passwords do not match', function (value) {
      return this.parent.password === value;
    })
    .required('Password confirmation is required')
});


export default commonRegistrationForm;

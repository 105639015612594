/* eslint arrow-body-style: [0] */
import React from 'react';
import _get from 'lodash/get';
import _assign from 'lodash/assign';
import {
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  FormFeedback,
  Col
} from 'reactstrap';

const FormikCompleteCustomField = ({
  field,
  form: { touched, errors },
  label,
  component: InputComponent = Input,
  formGroupProps = {},
  inline = false,
  noLabel = false,
  addonPrepend = null,
  addonAppend = null,
  onChange,
  ...props
}) => {

  const inputProps = (props.noValidate) ? { ...props, ...field } : {
    ...props,
    valid: _get(touched, field.name) && !_get(errors, field.name),
    invalid: _get(touched, field.name) && !!_get(errors, field.name),
    ...field,
    //  onChange // Enabled for on change event not fired while using this components
  };

  if (onChange) Object.assign(inputProps, { onChange })

  const errMessage = _get(errors, field.name);
  return (!inline) ? (
    <FormGroup {...formGroupProps}>
      {
        !noLabel && <Label for={field.name} className={`font-weight-semibold d-block ${props.labelclassname || props.labelClassName}`}>
          {label}
          {inputProps.required ? <span className="text-danger">*</span> : null}
        </Label>
      }
      {
        !addonPrepend && !addonAppend ? (
          <React.Fragment>
            <InputComponent {...inputProps}>{props.children}</InputComponent>
            {!props.noValidate && <FormFeedback>{errMessage || ' '}</FormFeedback>}
          </React.Fragment>
        ) :
          (
            <InputGroup>
              {addonPrepend && <InputGroupAddon addonType="prepend"><InputGroupText className="bg-blue text-white blue-border">{addonPrepend}</InputGroupText></InputGroupAddon>}
              <InputComponent {...inputProps} />
              {addonAppend && <InputGroupAddon addonType="append"><InputGroupText className="bg-blue text-white blue-border">{addonAppend}</InputGroupText></InputGroupAddon>}
              {!props.noValidate && <FormFeedback>{errMessage || ' '}</FormFeedback>}
            </InputGroup>
          )

      }
    </FormGroup>
  ) :
    !addonPrepend && !addonAppend ? (
      <FormGroup row className='mb-3'>
        <Col md="3">
          <Label htmlFor={field.name} className={`font-weight-semibold ${props.labelclassname || props.labelClassName}`}>{label}{inputProps.required ? <span className="text-danger">*</span> : null}</Label>
        </Col>
        <Col xs="12" md="9">
          <InputComponent {...inputProps}>
            {props.children}
          </InputComponent>
          {!props.noValidate && <FormFeedback>{errMessage}</FormFeedback>}
        </Col>
      </FormGroup>
    ) : (
        <FormGroup row className='mb-3'>
          <Col md="3">
            <Label htmlFor={field.name} className={`font-weight-semibold ${props.labelclassname || props.labelClassName}`}>{label}{inputProps.required ? <span className="text-danger">*</span> : null}</Label>
          </Col>
          <Col xs="12" md="9">
            <InputGroup>
              {addonPrepend && <InputGroupAddon addonType="prepend"><InputGroupText className="bg-white">{addonPrepend}</InputGroupText></InputGroupAddon>}
              <InputComponent {...inputProps}>
                {props.children}
              </InputComponent>
              {!props.noValidate && <FormFeedback>{errMessage}</FormFeedback>}
            </InputGroup>
          </Col>
        </FormGroup>
      )
};


export default FormikCompleteCustomField;


import axios from 'axios';


export const onSubmit = (stepName, payload) => async (dispatch) => {
  dispatch({ type: 'SUBMIT_REGISTRATION_STEP_PENDING', stepName });

  try {
    const { data: { user = {} } } = await axios.post(`/api/rentivity/register/steps/${stepName}`, payload);
    dispatch({
      type: 'SET_SESSION_USER',
      value: user
    });
    return dispatch({
      type: 'SUBMIT_REGISTRATION_STEP_SUCCESS',
      stepName,
      value: user
    });
  } catch (err) {
    return dispatch({
      type: 'SUBMIT_REGISTRATION_STEP_ERROR',
      stepName,
      error: err.error
    });
  }
};


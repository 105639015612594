const subject = {
  'General Inquiry': 'generalInquiry',
  'Site Error / Issue': 'siteErrIssue',
  'Site Suggestion': 'siteSuggestion',
  'Sales': 'sales',
  'Support': 'support',
  'Investor Inquiry': 'investorInquiry',
  'Media Inquiry': 'mediaInquiry'
};

module.exports = subject;

import React, { Component } from 'react';
import _ from 'lodash';
import * as R from 'ramda';
import { withFormik, Field } from 'formik';
import {
  Button, Form,
  Row, Col,
  Card, CardBody, CardHeader, CardFooter,
  FormGroup, Label, Input, FormFeedback,InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import { withProps } from 'recompose';
import { Typeahead } from 'react-bootstrap-typeahead';

import { FormikCustomField } from '../../../../components/Inputs';
import schema, { defaultValues } from './schema';
import { fetchPetBreeds } from '../../../../utils/services';

import 'react-bootstrap-typeahead/css/Typeahead.css';
const customInputForm = ({ field, form: { touched, errors } = null, icon, ...props }) => (
  <FormGroup>
    <Label for={field.name} className={`font-weight-semibold d-block ${props.labelclassname || props.labelClassName}`}>
      {props.label}
      {props.required ? <span className="text-danger">*</span> : null}
    </Label>
    <InputGroup className="mb-3">
      <Input
        invalid={!!(touched[field.name] && errors[field.name])}
        valid={_.isEmpty(errors[field.name]) && touched[field.name] ? true : false}
        {...field}
        {...props}
      />
      {touched[field.name] && errors[field.name] && <FormFeedback className='ml-5'>{errors[field.name]}</FormFeedback>}
    </InputGroup>
  </FormGroup>
);
class QualificationCriteria extends Component {

  state = {
    petBreeds: [],
    selected: []
  }

  componentDidMount() {
    this.fetchPetBreeds();
  }
  
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.initialValues && !_.isEmpty(nextProps.initialValues.restrictedPetBreeds)) {
      if (!_.isEqual(this.state.petBreeds, nextState.petBreeds) && !_.isEmpty(nextState.petBreeds)) {
        this.setState({
          selected: _.filter(nextState.petBreeds, item => {
            return _.includes(nextProps.initialValues.restrictedPetBreeds, item._id);
          })
        });

        return true;
      }
    }

    return true;
  }
  

  async fetchPetBreeds() {
    try {
      const { data } = await fetchPetBreeds();
      this.setState({
        petBreeds: data
      });
    } catch (err) {
      console.log(err);
    }
  }

  handleBreedsChange = (selected, setFieldValue) => {
    this.setState({ selected });
    setFieldValue('restrictedPetBreeds', _.map(selected, item => item._id));
  }
  render() {
    const { props } = this;
    const FormikMultiSelect = props => {
      const { options, label, labelKey, form: { touched, errors }, field } = props;
      const valid = _.get(touched, field.name) && !_.get(errors, field.name);
      const invalid = _.get(touched, field.name) && !!_.get(errors, field.name);

      return (
        <FormGroup className='mb-3'>
          <Label htmlFor={props.id} className={`font-weight-semibold ${props.labelclassname || props.labelClassName}`}>{label}{props.required ? <span className="text-danger">*</span> : null}</Label>
          <Typeahead
            options={options}
            multiple
            onChange={field.onChange}
            selected={field.selected}
            flip
            inputProps={{ name: field.name }}
            id={props.id}
            isInvalid={invalid}
            isValid={valid}
            labelKey={labelKey}
            selectHintOnEnter
            {..._.omit(field, 'onBlur')}
            {...props}
          />
          {touched[field.name] && errors[field.name] && <FormFeedback style={{ display: 'block' }}>{errors[field.name]}</FormFeedback>}
        </FormGroup>
      )
    }

    return (
      <div className="app">
        <Row>
          <Col>
            <Form onSubmit={props.handleSubmit}>

              <Card>
                <CardHeader>Qualification Criteria</CardHeader>

                <CardBody>
                  {/* Gross income to rent ratio */}
                  <Row form>
                    <Col md={5} xs={6}>
                     {/* <Field
                        step='any'
                        type='number'
                        name='grossIncomeToRentRatio'
                        label='Gross Income to Rent Ratio'
                        component={FormikCustomField}
                        min={0.1}
                        max={99.9}
                     /> */}
                     <Field
                          min={0.5}
                          max={99.5}
                          type='number'
                          step='0.5'
                          name='grossIncomeToRentRatio'
                          required
                          label='Gross Income to Rent Ratio'
                          component={FormikCustomField}
                        />
                    </Col>
                    <Col md={2} className='text-center font-weight-bold ratioWrapColon'>:</Col>
                    <Col md={5} xs={6}>
                      <Field
                        type='number'
                        name='ratio'
                        className='ratioWrap'
                        component={FormikCustomField}
                        disabled
                      />
                    </Col>
                  </Row>

                  {/* Minimum resident score */}
                  <Row form>
                    <Col>
                      <Field
                        type='number'
                        name='minimumResidentScore'
                        label='Minimum Resident Score'
                        component={FormikCustomField}
                        min={0}
                      />
                    </Col>
                  </Row>

                  {/* Renters Insurance Required */}
                  <Row form>
                        <Col>
                        <Field
                        type='select'
                        name='rentersInsuranceRequired'
                        label='Renters Insurance Required?'
                        component={customInputForm}
                        onChange={event => {
                          props.setFieldValue('rentersInsuranceRequired', event.target.value === 'false' ? false : true);
                        }}    
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </Field>
                       
                      </Col>

                  </Row>

                  {/* Restricted Dog Breeds */}
                  <Row form>
                    <Col>
                      <Field 
                        name='restrictedPetBreeds'
                        component={FormikMultiSelect}
                        options={this.state.petBreeds}
                        label='Restricted Dog Breeds'
                        selected={this.state.selected}
                        onChange={values => this.handleBreedsChange(values, props.setFieldValue)}
                        id='restrictedPetBreeds'
                        labelKey='value'
                      />
                    </Col>
                  </Row>

                    {/* Pet Policy Descrption */}
                    <Row form>
                    <Col>
                        <Field
                          type='textarea'
                          name='petPolicyDescription'
                          label='Pet Policy Description'
                          placeholder='Enter Pet Policy Description'
                          component={FormikCustomField}
                        />
                      </Col>
                   </Row>

                  {/* Bankruptcy discharged */}
                  <Row form>
                    <Col>
                      <Field
                        type='select'
                        name='decline.bankruptcy_discharged.enforce'
                        label='Decline for Bankruptcy (Discharged)?'
                        component={customInputForm}
                        onChange={event => {
                          props.setFieldValue('decline.bankruptcy_discharged.enforce', event.target.value === 'false' ? false : true);
                          props.setFieldValue('decline.bankruptcy_discharged.monthsWithin', 24);
                        }}    
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </Field>
                    </Col>
                    {props.values.decline.bankruptcy_discharged.enforce === true ?
                      <Col>
                        <Field
                          type='number'
                          min={1}
                          name='decline.bankruptcy_discharged.monthsWithin'
                          label='within X months'
                          component={FormikCustomField}
                        />
                      </Col>
                      : null
                    }

                  </Row>

                  {/* Bankruptcy open */}
                  <Row form>
                    <Col xs={12} md={12}>
                      <Field
                        type='select'
                        name='decline.bankruptcy_open.enforce'
                        label='Decline for Bankruptcy (Open Chapter 7)?'
                        component={customInputForm}
                        onChange={event => {
                          props.setFieldValue('decline.bankruptcy_open.enforce', event.target.value === 'false' ? false : true);
                        }}  
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </Field>
                    </Col>
                  </Row>

                  {/* Bankruptcy ch13 */}
                  <Row form>
                    <Col xs={12} md={12}>
                      <Field
                        type='select'
                        name='decline.bankruptcy_ch13.enforce'
                        label='Decline for Bankruptcy (Open Chapter 13)?'
                        component={customInputForm}
                        onChange={event => {
                          props.setFieldValue('decline.bankruptcy_ch13.enforce', event.target.value === 'false' ? false : true);
                        }}  
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </Field>
                    </Col>
                  </Row>

                  {/* foreclosure */}
                  <Row form>
                    <Col>
                      <Field
                        type='select'
                        name='decline.foreclosure.enforce'
                        label='Decline for Foreclosure?'
                        component={customInputForm}
                        onChange={event => {
                          props.setFieldValue('decline.foreclosure.enforce', event.target.value === 'false' ? false : true);
                          props.setFieldValue('decline.foreclosure.monthsWithin', 24);
                        }}    
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </Field>
                    </Col>

                    {props.values.decline.foreclosure.enforce === true  ?
                      <Col>
                        <Field
                          type='number'
                          min={1}
                          name='decline.foreclosure.monthsWithin'
                          label='within X months'
                          component={FormikCustomField}
                          required
                        />
                      </Col>
                      : null
                    }
                  </Row>

                  {/* collections */}
                  <Row form>
                    <Col>
                      <Field
                        type='select'
                        name='decline.collections.enforce'
                        label='Decline for Collections?'
                        component={customInputForm}
                        onChange={event => {
                          props.setFieldValue('decline.collections.enforce', event.target.value === 'false' ? false : true);
                          props.setFieldValue('decline.collections.monthsWithin', 24);
                        }}    
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </Field>
                    </Col>

                    {props.values.decline.collections.enforce === true ?
                      <Col>
                        <Field
                          type='number'
                          min={1}
                          name='decline.collections.monthsWithin'
                          label='within X months'
                          component={FormikCustomField}
                          required
                        />
                      </Col>
                      : null
                    }
                  </Row>

                  {/* eviction */}
                  <Row form>
                    <Col>
                      <Field
                        type='select'
                        name='decline.eviction.enforce'
                        label='Decline for Eviction?'
                        component={customInputForm}
                        onChange={event => {
                          props.setFieldValue('decline.eviction.enforce', event.target.value === 'false' ? false : true);
                          props.setFieldValue('decline.eviction.monthsWithin', 24);
                        }}    
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </Field>
                    </Col>

                    {props.values.decline.eviction.enforce === true  ?
                      <Col>
                        <Field
                          type='number'
                          min={1}
                          name='decline.eviction.monthsWithin'
                          label='within X months'
                          component={FormikCustomField}
                          required
                        />
                      </Col>
                      : null
                    }
                  </Row>


                  <h4>Convictions</h4>
                  <Row form>
                    <Col>
                      <Field
                        type='select'
                        name='decline.convictions.sexualOffense.enforce'
                        label='Decline for Sexual Offense?'
                        component={customInputForm}
                        onChange={event => {
                          props.setFieldValue('decline.convictions.sexualOffense.enforce', event.target.value === 'false' ? false : true);
                          props.setFieldValue('decline.convictions.sexualOffense.allTime', false);
                          props.setFieldValue('decline.convictions.sexualOffense.monthsWithin', 24);
                        }}    
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </Field>
                    </Col>
                    {props.values.decline.convictions.sexualOffense.enforce === true?
                    <Col>
                      <Field
                        type='number'
                        min={1}
                        name='decline.convictions.sexualOffense.monthsWithin'
                        label='within X months'
                        disabled={_.get(props, 'values.decline.convictions.sexualOffense.allTime')}
                        component={FormikCustomField}
                        required = {props.values.decline.convictions.sexualOffense.enforce && !props.values.decline.convictions.sexualOffense.allTime}
                      />
                    </Col>
                     : null
                    }
                    {props.values.decline.convictions.sexualOffense.enforce === true ?
                  
                    <Col>
                      <FormGroup check className="checkbox">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          name='decline.convictions.sexualOffense.allTime'
                          value={props.values['decline.convictions.sexualOffense.allTime']}
                          checked={props.values['decline.convictions.sexualOffense.allTime']}
                          onChange={props.handleChange}
                        />
                        <Label
                          check
                          className="form-check-label"
                          htmlFor="decline.convictions.sexualOffense.allTime"
                        >
                          Check here for all time
                    </Label>
                      </FormGroup>
                    </Col> : null
                    }
                  </Row>

                  <Row form>
                    <Col>
                      <Field
                        type='select'
                        name='decline.convictions.convictedLast7Years'
                        label='Require further review for Felony within the last 7 years?'
                        component={customInputForm}
                        onChange={event => {
                          props.setFieldValue('decline.convictions.convictedLast7Years', event.target.value === 'false' ? false : true);
                        }}    
                      >
                        <option value={true}>Yes, Supporting Documents required.</option>
                        <option value={false}>No</option>
                      </Field>
                    </Col>
                  </Row>


                  <Row form>
                    <Col>
                      <Field
                        type='select'
                        name='decline.convictions.convictedOfViolentOrDrugRelated.value'
                        label='Decline for conviction of a Violent or Drug related crime?'
                        component={FormikCustomField}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                        <option value="review_docs_required">Further review docs required.</option>
                      </Field>
                    </Col>
                    {props.values.decline.convictions.convictedOfViolentOrDrugRelated.value === "yes" ?
                      <Col>
                        <Field
                          type='number'
                          min={1}
                          name='decline.convictions.convictedOfViolentOrDrugRelated.monthsWithin'
                          label='within X months'
                          component={FormikCustomField}
                          required
                        />
                      </Col>
                      : null
                    }
                  </Row>
                  <h4>Additional Information</h4>
                  <Row form>
                  <Col>
                        <Field
                          type='textarea'
                          name='additionalInformation.description'
                          placeholder='Enter Description (You can include URL)'
                          component={FormikCustomField}
                        />
                      </Col>
                   </Row>

                </CardBody>

                <CardFooter>
                  <div className="card-footer-actions">
                    <Button
                      type='button'
                      color="primary"
                      className="mr-2"
                      onClick={props.onPrevious}
                    >
                      Previous
                </Button>
                    <Button
                      type='submit'
                      color='primary'
                      disabled={!_.isEmpty(props.errors)}
                    >
                      Next
                </Button>
                  </div>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>

      </div >
    )
  }
}

const composeComponent = R.compose(
  withProps(props => {
    try {
      schema.validateSync(props.iniitalValue);
      return {
        isInitialValid: true
      }
    } catch (err) {
      return {
        isInitialValid: undefined
      }
    }
  }),
  withFormik({
    mapPropsToValues: (props) => {
      props.initialValues.ratio = 1;

      return {
        ...defaultValues,
        ...props.initialValues,
      }
    },
    enableReinitialize: true,
    isInitialValid: (props) => {
      return props.isInitialValid;
    },
    validationSchema: schema,
    handleSubmit: (values, formikBag) => {
      delete values.ratio;

      formikBag.props.onSubmit('qualification-criteria', values);
    }
  }),
)
export default composeComponent(QualificationCriteria);

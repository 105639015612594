import * as R from 'ramda';
import { createSelector } from 'reselect'


const globalSelector = (state) => state.global;
const routerSelector = (state) => state.router;

const isAuthenticatecSelector = createSelector(
  globalSelector,
  R.prop('isAuthenticated')
);

const sessionSelector = createSelector(
  globalSelector,
  R.prop('session')
);
const sessionPendingSelector = createSelector(
  globalSelector,
  R.prop('pending')
);



const errorSelector = createSelector(
  globalSelector,
  R.prop('error')
);

const isLoadingSelector = createSelector(
  globalSelector,
  R.prop('isLoading')
);
const spinnerTextSelector = createSelector(
  globalSelector,
  R.prop('spinnerText')
);

const showSpinnerSelector = createSelector(
  globalSelector,
  R.prop('showSpinner')
);

const jwtSelector = createSelector(
  sessionSelector,
  R.propOr('', 'jwt')
)
const userSelector = createSelector(
  sessionSelector,
  R.propOr({}, 'user')
);

const registrationSelector = createSelector(
  userSelector,
  R.propOr({}, 'registration')
);

const userTypeSelector = createSelector(
  userSelector,
  R.propOr({}, 'userType')
)


const tosAcceptedSelector = createSelector(
  registrationSelector,
  R.propOr(false, 'tosAccepted')
)

export default createSelector(
  sessionPendingSelector,
  routerSelector,
  errorSelector,
  isLoadingSelector,
  showSpinnerSelector,
  userSelector,
  isAuthenticatecSelector,
  userTypeSelector,
  tosAcceptedSelector,
  jwtSelector,
  spinnerTextSelector,
  (sessionPending, router, error, isLoading, showSpinner, user, isAuthenticated, userType, tosAccepted, jwt, spinnerText) =>
    ({ sessionPending, router, error, isLoading, showSpinner, user, isAuthenticated, userType, tosAccepted, jwt, spinnerText })
);

/* eslint arrow-body-style: [0] */
import React, { Component } from 'react';

import _ from 'lodash';
import { Table, Button, Card, CardBody, CardHeader } from 'reactstrap';
import ModifyRoleModal from './ModifyRoleModal';
import metadata from './metadata';


const initPermissions = () => ({
  name: '',
  description: '',
  permissions: _.reduce(metadata.permissions, (acc, key) => ({ ...acc, [key]: false }), {})
});

class Roles extends Component {

  constructor(props, ctx){
    super(props, ctx);
    this.state = {
      roleInfo: {},
      createRole: true,
      modalIsOpen: false
    }
  }


  toggleModal = (toggleTo = false) => async () => this.setState({ modalIsOpen: toggleTo })


  handleAddRoleClick = () => this.setState({
    roleInfo: initPermissions(),
    modalIsOpen: true,
    modalIsReadOnly: false,
    createRole: true
  })

  handleShowInfoClick = (roleId) => () => {
    const items = [...this.props.roles];
    const roleInfo = _.find(items, { _id: roleId });

    this.setState({
      roleInfo,
      modalIsOpen: true,
      modalIsReadOnly: true,
      createRole: false
    });
  }

  handleEditRoleClick = (roleId) => () => {
    const items = [...this.props.roles];
    const roleInfo = _.find(items, { _id: roleId });

    this.setState({
      roleInfo,
      modalIsOpen: true,
      modalIsReadOnly: false,
      createRole: false
    });
  }

  handleDeleteRoleClick = roleId => this.props.deleteRole(roleId);

  render() {
    return (
      <>
        <ModifyRoleModal
          role={this.state.roleInfo}
          create={!!this.state.createRole}
          readOnly={this.state.modalIsReadOnly}
          closeModal={this.toggleModal(false)}
          showModal={this.state.modalIsOpen}
          editRole={(payload) => this.props.editRole(payload._id, payload)}
          addRole={this.props.addRole}
        />

        <Card>
          <CardHeader>
            <h3>Roles</h3>
            <div className="card-header-actions">
              <Button
                color='success'
                size='sm'
                onClick={this.handleAddRoleClick}
              >
                Add Role
              </Button>
            </div>
          </CardHeader>


          <CardBody>
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  _.map(this.props.roles, (role, index) => {
                    return (
                      <tr key={`role_${index}`}>
                        <td>{role.name}</td>
                        <td>{role.description}</td>
                        <td className="text-center">
                          <Button onClick={this.handleShowInfoClick(role._id)}>
                            <i className="fa fa-search" style={{ fontSize: `${24}px` }}></i>
                          </Button>
                          <Button 
                            onClick={this.handleEditRoleClick(role._id)}
                            disabled={role.isDefault}
                          >
                            <i className="fa fa-edit" style={{ fontSize: `${24}px` }}></i>
                          </Button>
                          <Button 
                            onClick={() => this.handleDeleteRoleClick(role._id)}
                            disabled={role.isDefault}
                          >
                            <i className="fa fa-trash" style={{ fontSize: `${24}px` }}></i>
                          </Button>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default Roles;

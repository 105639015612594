import _ from 'lodash';

const getChecked = (vals = []) => _.filter(vals, { checked: true });

const markChecked = (vals = [], id, isChecked = false, identifier) => _.map(vals, val => {
  let commonValues;

  if (val._id === id) {
    commonValues = {
      ...val,
      checked: isChecked,
      quantity: !isChecked ? 1 : val.quantity
    }
  } else commonValues = { ...val }

  // if (identifier === 'inAdditionalMonthlyRent') {
  //   if (val._id === id) {
  //     return {
  //       ...commonValues,
  //       amount: !isChecked ? val.baseAmount : val.amount ? val.amount : val.baseAmount
  //     }
  //   } else return { ...commonValues }
  // } else return commonValues;
  return commonValues;
})

const recomputeDeposits = (deposits, totalFees, rentPrice) => _.map(deposits, (dep) => {

  if (dep.type === '% of Rent') {
    return {
      ...dep,
      // changed from rentprice to dep.amount due to quantity field addition.
      amount: (dep.amount) * (dep.deposit / 100),
    }
  }
  return {
    ...dep,
    amount: dep.amount
  }
});

const initState = {
  request: {
    pending: false,
    success: false,
    error: null,
    isLoaded: false
  },
  status: '',
  fees: [],
  additionalMonthlyRents: [],
  deposits: [],
  riders: [],
  propertySession: {},
  property: {},
  leaseTemplate: null,
  leaseWrapup: {},
  lease: null,
  applications: [],
  renters: [],
  primaryRenter: null,
  totalDeposits: 0,
  totalAdditionalMonthlyRents: 0,
  totalFees: 0,
  rentPrice: 0,
  monthlyRent: 0,
  upfrontPayments: [],
  leaseWrapupFlow: [],
  firstMonthRent: 0,
  totalUpfrontAmount: 0,
  totalFinalPaymentAmount: 0,
  upfrontFees: 0,
  balanceFeesToBePaid: 0,
  upfrontDeposits: 0,
  balanceDepositsToBePaid: 0,
  proratedRent: 0,
  upfrontRents: 0,
  balanceRentsToBePaid: 0,
  totalMoveInCost: 0,
  allSubmittedRA: false,
  currentStep: 1,
  leaseStartDate: null,
  leaseEndDate: null,
  isEditable: true,
  totalMonthlyRent: 0
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'GET_LW_STATUS':
      return {
        ...state,
        request: {
          ...state.request,
          pending: true,
          success: false,
          error: null
        }
      }
    case 'GET_LW_STATUS_SUCCESS':
      return {
        ...state,
        status: action.value,
        request: {
          ...state.request,
          pending: true,
          success: false,
          error: null
        }
      }
    case 'GET_LW_STATUS_ERROR':
      return {
        ...state,
        request: {
          ...state.request,
          pending: false,
          success: false,
          error: action.error
        }
      }
    case 'GET_LW_DETAILS':
      return {
        ...state,
        request: {
          ...state.request,
          pending: true,
          success: false,
          error: null
        }
      }
    case 'GET_LW_DETAILS_SUCCESS': {
      return {
        ...state,
        request: {
          ...state.request,
          pending: true,
          success: false,
          error: null,
        },
        allSubmittedRA: action.value.allSubmittedRA,
        currentStep: action.value.currentStep,
        status: action.value.status
      }
    }
    case 'GET_LW_DETAILS_ERROR':
      return {
        ...state,
        request: {
          ...state.request,
          pending: false,
          success: false,
          error: action.error
        }
      }
    case 'GET_LEASE_TEMPLATES':
      return {
        ...state,
        request: {
          ...state.request,
          pending: true,
          success: false,
          error: null
        }
      }
    case 'GET_LEASE_TEMPLATES_SUCCESS':
      return {
        ...state,
        leaseTemplates: action.value,
        request: {
          ...state.request,
          pending: false,
          success: true,
          error: null
        }
      }
    case 'GET_LEASE_TEMPLATES_ERROR':
      return {
        ...state,
        leaseTemplates: action.value,
        request: {
          ...state.request,
          pending: false,
          success: false,
          error: action.error
        }
      }
    case 'GET_LEASE_TEMPLATE_CUSTOM_FIELDS':
      return {
        ...state,
        request: {
          ...state.request,
          pending: true,
          success: false,
          error: null
        }
      }
    case 'GET_LEASE_TEMPLATE_CUSTOM_FIELDS_SUCCESS':
      return {
        ...state,
        leaseTemplateCustomFields: action.value,
        request: {
          ...state.request,
          pending: false,
          success: true,
          error: null
        }
      }

    case 'GET_LEASE_TEMPLATE_CUSTOM_FIELDS_ERROR':
      return {
        ...state,
        leaseTemplateCustomFields: action.value,
        request: {
          ...state.request,
          pending: false,
          success: false,
          error: action.error
        }
      }
    // Get renter TU report
    case 'GET_LW_TU_REPORT':
      return {
        ...state,
        request: {
          ...state.request,
          pending: true,
          success: false,
          error: null
        }
      }
    case 'GET_LW_TU_REPORT_SUCCESS':
      return {
        ...state,
        applications: action.value,
        request: {
          ...state.request,
          pending: false,
          success: true,
          error: null
        }
      }
    case 'GET_LW_TU_REPORT_ERROR':
      return {
        ...state,
        request: {
          ...state.request,
          pending: false,
          success: false,
          error: action.error
        }
      }
    case 'GET_LW_PROPERTY_SESSION':
      return {
        ...state,
        request: {
          ...state.request,
          pending: true,
          success: false,
          error: null,
          isLoaded: false
        }
      }
    case 'GET_LW_PROPERTY_SESSION_SUCCESS':
      const { leaseTemplate, leaseWrapup = {}, property, ...propertySession } = action.value;

      const riders = (!_.isNil(leaseWrapup.lease)) ? _.map(leaseTemplate.associatedRiders, (el) => {
        const i = _.findIndex(leaseWrapup.lease.riders, { _id: el._id });
        if (i > -1) {
          return {
            ...el,
            checked: true
          }
        }
        return {
          ...el,
          checked: false
        }
      }) : _.map(leaseTemplate.associatedRiders, (el) => ({ ...el, checked: false }))

      return {
        ...state,
        propertySession,
        leaseTemplate,
        riders: riders,
        leaseWrapup,
        lease: leaseWrapup.lease,
        property,
        applications: leaseWrapup.applications,
        renters: _.map(leaseWrapup.applications, 'renter'),
        primaryRenter: _.find(leaseWrapup.applications, (app) => app.isPrimary),


        request: {
          ...state.request,
          pending: false,
          success: true,
          error: null
        }
      }
    case 'GET_LW_PROPERTY_SESSION_ERROR':
      return {
        ...state,
        propertySession: {},
        request: {
          ...state.request,
          pending: false,
          success: false,
          error: action.error
        }
      }
    case 'GET_FEES_AND_DEPOSITS':
      return {
        ...state,
        request: {
          ...state.request,
          pending: true,
          success: false,
          error: null
        }
      }
    case 'GET_FEES_AND_DEPOSITS_SUCCESS': {
      if (state.lease) {
        const totalFees = _.sumBy(state.lease.fees, 'amount');
        const totalDeposits = _.sumBy(state.lease.deposits, 'amount');

        return {
          ...state,
          fees: state.lease.fees,
          deposits: state.lease.deposits,
          monthlyRent: action.value.monthlyRent,
          rentPrice: action.value.rentPrice,
          totalFees,
          totalDeposits,

          request: {
            ...state.request,
            pending: false,
            success: true,
            error: null
          }
        }
      }

      // const deposits = _.map(recomputeDeposits(action.value.deposits, 0, action.value.monthlyRent), deposit => {
      const deposits = _.map(action.value.deposits, deposit => {
        return {
          ...deposit,
          totalAmount: deposit.amount * (deposit.quantity || 1)
        }
      });
      const fees = _.map(action.value.fees, fee => {
        return {
          ...fee,
          totalAmount: fee.amount * (fee.quantity || 1)
        }
      });
      const upfrontPayments = action.value.upfrontPayments;
      const additionalMonthlyRents = _.map(action.value.additionalMonthlyRents, amr => {
        return {
          ...amr,
          totalAmount: amr.amount * (amr.quantity || 1)
        }
      });

      return {
        ...state,
        fees,
        deposits,
        additionalMonthlyRents,
        monthlyRent: action.value.monthlyRent,
        rentPrice: action.value.rentPrice,
        totalFees: action.value.totalFees,
        totalDeposits: action.value.totalDeposits,
        totalMonthlyRent: action.value.monthlyRent + _.sumBy(getChecked(additionalMonthlyRents), 'totalAmount'),
        totalAdditionalMonthlyRents: action.value.totalAdditionalMonthlyRents,
        upfrontPayments,
        firstMonthRent: action.value.firstMonthRent,
        totalUpfrontAmount: action.value.totalUpfrontAmount,
        totalFinalPaymentAmount: action.value.totalFinalPaymentAmount,
        upfrontFees: action.value.upfrontFees,
        balanceFeesToBePaid: action.value.balanceFeesToBePaid,
        upfrontDeposits: action.value.upfrontDeposits,
        balanceDepositsToBePaid: action.value.balanceDepositsToBePaid,
        proratedRent: action.value.proratedRent,
        upfrontRents: action.value.upfrontRents,
        balanceRentsToBePaid: action.value.balanceRentsToBePaid,
        totalMoveInCost: action.value.totalMoveInCost,
        leaseStartDate: action.value.leaseStartDate ? action.value.leaseStartDate : null,
        leaseEndDate: action.value.leaseEndDate ? action.value.leaseEndDate : null,
        isEditable: action.value.isEditable,

        request: {
          ...state.request,
          pending: false,
          success: true,
          error: null
        }
      }
    }
    case 'GET_FEES_AND_DEPOSITS_ERROR':
      return {
        ...state,
        fees: [],
        deposits: [],
        upfrontPayments: [],
        request: {
          ...state.request,
          pending: false,
          success: false,
          error: action.error
        }
      }
    case 'ADD_FEE': {
      const baseFees = markChecked(state.fees, action.value._id, true);
      const fees = _.map(baseFees, baseFee => {
        if (baseFee._id === action.value._id) {
          return {
            ...baseFee,
            totalAmount: baseFee.amount * 1,
            quantity: 1,
          }
        } else return { ...baseFee }
      })

      const totalFees = _.sumBy(getChecked(fees), 'totalAmount');
      let balanceFeesToBePaid = totalFees - state.upfrontFees;
      if (balanceFeesToBePaid < 0) {
        balanceFeesToBePaid = 0;
      }
      const totalFinalPaymentAmount = balanceFeesToBePaid + state.balanceDepositsToBePaid + state.balanceRentsToBePaid + _.sumBy(getChecked(state.additionalMonthlyRents), 'totalAmount');
      const totalMoveInCost = totalFinalPaymentAmount + state.totalUpfrontAmount;

      return {
        ...state,
        fees,
        totalFees,
        balanceFeesToBePaid,
        totalFinalPaymentAmount,
        totalMoveInCost,
      }
    }
    case 'REMOVE_FEE': {
      const fees = markChecked(state.fees, action.value._id, false);
      const totalFees = _.sum(_.map(getChecked(fees), 'totalAmount'));
      let balanceFeesToBePaid = totalFees - state.upfrontFees;
      if (balanceFeesToBePaid < 0) {
        balanceFeesToBePaid = 0;
      }
      const totalFinalPaymentAmount = balanceFeesToBePaid + state.balanceDepositsToBePaid + state.balanceRentsToBePaid + _.sumBy(getChecked(state.additionalMonthlyRents), 'totalAmount');
      const totalMoveInCost = totalFinalPaymentAmount + state.totalUpfrontAmount;

      return {
        ...state,
        fees,
        totalFees,
        balanceFeesToBePaid,
        totalFinalPaymentAmount,
        totalMoveInCost,
      }
    }
    case 'ADD_FEE_QUANTITY': {
      const fees = _.map(state.fees, value => {
        if (value._id === action.value.data._id) {
          return {
            ...value,
            totalAmount: value.amount * Number(action.value.quantity),
            quantity: Number(action.value.quantity),
          }
        } else return { ...value }
      })

      const totalFees = _.sumBy(getChecked(fees), 'totalAmount');
      let balanceFeesToBePaid = totalFees - state.upfrontFees;
      if (balanceFeesToBePaid < 0) {
        balanceFeesToBePaid = 0;
      }
      const totalFinalPaymentAmount = balanceFeesToBePaid + state.balanceDepositsToBePaid + state.balanceRentsToBePaid + _.sumBy(getChecked(state.additionalMonthlyRents), 'totalAmount');
      const totalMoveInCost = totalFinalPaymentAmount + state.totalUpfrontAmount;

      return {
        ...state,
        fees,
        totalFees,
        balanceFeesToBePaid,
        totalFinalPaymentAmount,
        totalMoveInCost
      }
    }
    case 'ADD_ADDITIONAL_MONTHLY_RENTS': {
      const baseAdditionalMonthlyRents = markChecked(state.additionalMonthlyRents, action.value._id, true);
      const additionalMonthlyRents = _.map(baseAdditionalMonthlyRents, amr => {
        if (amr._id === action.value._id) {
          return {
            ...amr,
            totalAmount: amr.amount * 1,
            quantity: 1
          }
        } else return { ...amr }
      })

      const totalAdditionalMonthlyRents = _.sumBy(getChecked(additionalMonthlyRents), 'totalAmount');
      const totalFinalPaymentAmount = totalAdditionalMonthlyRents + state.balanceFeesToBePaid + state.balanceRentsToBePaid + state.balanceDepositsToBePaid;
      const totalMoveInCost = totalFinalPaymentAmount + state.totalUpfrontAmount;
      const totalMonthlyRent = state.monthlyRent + totalAdditionalMonthlyRents;

      return {
        ...state,
        additionalMonthlyRents,
        totalAdditionalMonthlyRents,
        totalFinalPaymentAmount,
        totalMoveInCost,
        totalMonthlyRent
      }
    }
    case 'REMOVE_ADDITIONAL_MONTHLY_RENTS': {
      const additionalMonthlyRents = markChecked(state.additionalMonthlyRents, action.value._id, false);
      const totalAdditionalMonthlyRents = _.sum(_.map(getChecked(additionalMonthlyRents), 'totalAmount'));
      const totalFinalPaymentAmount = totalAdditionalMonthlyRents + state.balanceFeesToBePaid + state.balanceRentsToBePaid + state.balanceDepositsToBePaid;
      const totalMoveInCost = totalFinalPaymentAmount + state.totalUpfrontAmount;
      const totalMonthlyRent = state.monthlyRent + totalAdditionalMonthlyRents;

      return {
        ...state,
        additionalMonthlyRents,
        totalAdditionalMonthlyRents,
        totalFinalPaymentAmount,
        totalMoveInCost,
        totalMonthlyRent
      }
    }
    case 'ADDITIOINAL_MONTHLY_RENTS_QUANTITY': {
      const additionalMonthlyRents = _.map(state.additionalMonthlyRents, (value, index) => {
        if (value._id === action.value.data._id) {
          return {
            ...value,
            totalAmount: value.amount * Number(action.value.quantity),
            quantity: Number(action.value.quantity)
          }
        } else return { ...value }
      })

      const totalAdditionalMonthlyRents = _.sumBy(getChecked(additionalMonthlyRents), 'totalAmount');
      const totalFinalPaymentAmount = totalAdditionalMonthlyRents + state.balanceFeesToBePaid + state.balanceDepositsToBePaid + state.balanceRentsToBePaid;
      const totalMoveInCost = totalFinalPaymentAmount + state.totalUpfrontAmount;
      const totalMonthlyRent = state.monthlyRent + totalAdditionalMonthlyRents;

      return {
        ...state,
        additionalMonthlyRents,
        totalAdditionalMonthlyRents,
        totalFinalPaymentAmount,
        totalMoveInCost,
        totalMonthlyRent
      }
    }
    case 'ADD_DEPOSIT': {
      const baseDeposits = markChecked(state.deposits, action.value._id, true);
      const deposits = _.map(baseDeposits, baseDeposit => {
        if (baseDeposit._id === action.value._id) {
          return {
            ...baseDeposit,
            totalAmount: baseDeposit.amount * 1,
            quantity: 1,
          }
        } else return { ...baseDeposit }
      })

      const totalDeposits = _.sumBy(getChecked(deposits), 'totalAmount');
      let balanceDepositsToBePaid = totalDeposits - state.upfrontDeposits;
      if (balanceDepositsToBePaid < 0) {
        balanceDepositsToBePaid = 0;
      }

      const totalFinalPaymentAmount = balanceDepositsToBePaid + state.balanceFeesToBePaid + state.balanceRentsToBePaid + _.sumBy(getChecked(state.additionalMonthlyRents), 'totalAmount');
      const totalMoveInCost = totalFinalPaymentAmount + state.totalUpfrontAmount;

      return {
        ...state,
        deposits,
        totalDeposits,
        balanceDepositsToBePaid,
        totalFinalPaymentAmount,
        totalMoveInCost
      }
    }
    case 'REMOVE_DEPOSIT': {
      const deposits = markChecked(state.deposits, action.value._id, false);
      const totalDeposits = _.sum(_.map(getChecked(deposits), 'totalAmount'));
      let balanceDepositsToBePaid = totalDeposits - state.upfrontDeposits;
      if (balanceDepositsToBePaid < 0) {
        balanceDepositsToBePaid = 0;
      }
      const totalFinalPaymentAmount = balanceDepositsToBePaid + state.balanceFeesToBePaid + state.balanceRentsToBePaid + _.sumBy(getChecked(state.additionalMonthlyRents), 'totalAmount');
      const totalMoveInCost = totalFinalPaymentAmount + state.totalUpfrontAmount;

      return {
        ...state,
        deposits,
        totalDeposits,
        balanceDepositsToBePaid,
        totalFinalPaymentAmount,
        totalMoveInCost
      }
    }
    case 'ADD_DEPOSIT_QUANTITY': {
      const deposits = _.map(state.deposits, value => {
        if (value._id === action.value.data._id) {
          return {
            ...value,
            totalAmount: value.amount * Number(action.value.quantity),
            quantity: Number(action.value.quantity),
          }
        } else return { ...value }
      })
      const totalDeposits = _.sumBy(getChecked(deposits), 'totalAmount');
      let balanceDepositsToBePaid = totalDeposits - state.upfrontDeposits;
      if (balanceDepositsToBePaid < 0) {
        balanceDepositsToBePaid = 0;
      }
      const totalFinalPaymentAmount = balanceDepositsToBePaid + state.balanceFeesToBePaid + state.balanceRentsToBePaid + _.sumBy(getChecked(state.additionalMonthlyRents), 'amount');
      const totalMoveInCost = totalFinalPaymentAmount + state.totalUpfrontAmount;

      return {
        ...state,
        deposits,
        totalDeposits,
        balanceDepositsToBePaid,
        totalFinalPaymentAmount,
        totalMoveInCost
      }
    }
    case 'ADD_RIDER': {
      return {
        ...state,
        riders: _.map(state.riders, (el) => {
          if (el._id === action.value._id) {
            return {
              ...el,
              checked: true
            }
          }
          return {
            ...el
          }
        })
      }
    }
    case 'REMOVE_RIDER': {
      const riders = markChecked(state.riders, action.value._id, false);
      return {
        ...state,
        riders
      }
    }
    case 'GET_LEASE_WRAPUP_FLOW':
      return {
        ...state,
        request: {
          ...state.request,
          pending: true,
          success: false,
          error: null
        }
      }
    case 'GET_LEASE_WRAPUP_FLOW_SUCCESS': {
      const leaseWrapupFlow = action.value;

      return {
        ...state,
        leaseWrapupFlow,
        request: {
          ...state.request,
          isLoaded: true
        }
      }
    }
    case 'GET_LEASE_WRAPUP_FLOW_ERROR': {
      return {
        ...state,
        leaseWrapupFlow: [],
        request: {
          ...state.request,
          pending: false,
          success: false,
          error: action.error
        }
      }
    }
    case 'MODIFY_PRORATED_RENT': {
      const proratedRent = action.value ? action.value : 0;
      const totalRents = state.firstMonthRent + proratedRent;

      const balanceRentsToBePaid = totalRents - state.upfrontRents;
      const totalFinalPaymentAmount = state.balanceDepositsToBePaid + state.balanceFeesToBePaid + balanceRentsToBePaid + _.sumBy(getChecked(state.additionalMonthlyRents), 'totalAmount');
      const totalMoveInCost = totalFinalPaymentAmount + state.totalUpfrontAmount;

      return {
        ...state,
        proratedRent,
        balanceRentsToBePaid,
        totalFinalPaymentAmount,
        totalMoveInCost,
      }
    }
    case 'MODIFY_FIRST_MONTH_RENT': {
      const firstMonthRent = action.value ? action.value : 0;
      const totalRents = firstMonthRent + state.proratedRent;

      let balanceRentsToBePaid = totalRents - state.upfrontRents;
      if (balanceRentsToBePaid < 0) {
        balanceRentsToBePaid = 0;
      }

      const totalFinalPaymentAmount = state.balanceDepositsToBePaid + state.balanceFeesToBePaid + balanceRentsToBePaid;
      const totalMoveInCost = totalFinalPaymentAmount + state.totalUpfrontAmount;

      return {
        ...state,
        firstMonthRent,
        balanceRentsToBePaid,
        totalFinalPaymentAmount,
        totalMoveInCost,
      }
    }
    default:
      return state;
  }
}

export default reducer;
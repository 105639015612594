import React, { Component, Suspense } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { Container, Col, Card, CardBody, Row, Button, Form,
  InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Formik, Field } from 'formik';
import { FormikCustomField } from 'components/Inputs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import background from "assets/img/backgrounds/timothy-buck-309898-unsplash.jpg";
import { ToastContext } from '../../../../../contexts';

import schema, { defaultValues } from '../schema/requestResetPassword.schema';
import { resetPassword } from '../../../../../utils/services';


const CardWrap = ({ children }) => (
  <Container>
    <Row className="justify-content-center">
      <Col xs="12" md="10" lg="6">
        {children}
      </Col>
    </Row>
  </Container>
);


const loading = () => (
  <div className="animated fadeIn pt-1 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

class RequestResetPassword extends Component {
  state = {
    submitted: false
  };

  static contextType = ToastContext;

  error = (message) => toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });

  success = (message) => toast.success(message, { position: toast.POSITION.BOTTOM_RIGHT });

  onSubmit = (data, actions) => {
    const payload = _.pickBy({
      email: data.email || null
    }, _.identity);

    const _this = this;
    this.context.showSpinner({ text: 'Please wait...' });
    resetPassword(payload)
      .then(result => {
        if (result.status === 200) {
          this.context.hideSpinner();
          _this.success(result.data.message);
          setTimeout(() => {
            this.props.history.push({ pathname: '/dashborad' });
          }, 5000);
        } else {
          this.context.hideSpinner();
          _this.warn(result.data.message);
        }
      })
      .catch(err => {
        this.context.hideSpinner();
        actions.setSubmitting(false);
        _this.error('User Email Not Found');
      });

  };

  render() {

    return (
      <div className="app bg-cover" style={{backgroundImage: `url(${background})`}}>
        <div className="app-body">
          <main className="main d-flex flex-row align-items-center">
            <Suspense fallback={loading()}>
              <CardWrap>
                <Card className="p-2 p-md-4 no-border">
                  <CardBody>
                    {/*<ToastContainer position="top-right" autoClose={5000} style={{ zIndex: 1999 }} />*/}
                    <Formik
                      validationSchema={schema()}
                      initialValues={defaultValues}
                      onSubmit={this.onSubmit}
                    >
                      {
                        ({ isValid, handleSubmit, isSubmitting }) => (
                          <Form onSubmit={handleSubmit}>
                            <h2 className='text-primary text-center'>RENTING REINVENTED</h2>
                            <h5 className='text-center'>Reset Password</h5>
                            <p className="text-muted">Forgot your password? Insert the email address tied to your account below and we'll send you an email to reset your password.</p>
                            <InputGroup className="mb-4">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="icons icon-user" aria-hidden="true"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Field
                                name='email'
                                type='text'
                                noLabel
                                formGroupProps={{style:{marginBottom: 0, flex: '1 0 auto'}}}
                                placeholder="Email"
                                component={FormikCustomField}
                              >
                              </Field>
                            </InputGroup>
                            {
                              this.props.error && <p className='error-text'>{this.props.error.message}</p>
                            }
                            <Row className="justify-content-between">
                              <Col sm="6">
                                <Button
                                  color='primary'
                                  type='submit'
                                  disabled={!isValid || isSubmitting}
                                >
                                  Submit
                                </Button>
                              </Col>
                              <Col sm="6" className="text-sm-right">
                                <Link
                                  className="btn btn-link px-0"
                                  to="/login"
                                >
                                  Remember your password?
                                </Link>
                              </Col>
                            </Row>
                          </Form>
                        )
                      }
                    </Formik>
                  </CardBody>
                </Card>
              </CardWrap>
            </Suspense>
          </main>
        </div>
      </div>

    );
  }

}

export default RequestResetPassword;

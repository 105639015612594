import React, { Suspense } from 'react';
import {
  Card, CardBody, CardGroup,
  Button,
  Container, Row, Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon, FormFeedback, FormGroup,
  InputGroupText
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

// images
import background from '../../../assets/img/backgrounds/joshua-ness-109299-unsplash.jpg';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Password has to be longer than 6 characters!')
    .required('Password is required')
})

/**
 * 
 * @method customInputForm - used to generate input field with validation
 * @param field - returns field properties.
 * @param form - returns form actions.
 * @param icon - returns inputGroup icon
 */
export const customInputForm = ({ field, form: { touched, errors }, icon, ...props }) => (
  <FormGroup>
    <InputGroup className="mb-3">
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <i className={icon} />
        </InputGroupText>
      </InputGroupAddon>
      <Input
        invalid={!!(touched[field.name] && errors[field.name])}
        valid={_.isEmpty(errors[field.name]) && touched[field.name] ? true : false}
        {...field}
        {...props} />
      {touched[field.name] && errors[field.name] && <FormFeedback className='ml-5'>{errors[field.name]}</FormFeedback>}
    </InputGroup>
  </FormGroup>
);

const loading = () => (
  <div className="animated fadeIn pt-1 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

const LoginBase = ({ loginError = false, submitLogin }) => (
  <div className="app bg-cover login" style={{ backgroundImage: `url(${background})` }}>
    <div className="app-body">
      <main className="main d-flex flex-row align-items-center">
        <Suspense fallback={loading()}>

          {/* <div className="flex-row align-items-center "> */}
          <Container>
            <Row className="justify-content-center">
              <Col md="10" lg="8">
                <CardGroup className='mb-4'>
                  <Card className="p-4 no-border">
                    <CardBody>
                      <Formik
                        initialValues={{ email: '', password: '' }}
                        validationSchema={loginSchema}
                        onSubmit={submitLogin}
                      >
                        {
                          ({ handleSubmit, values, isSubmitting }) => (
                            <Form onSubmit={handleSubmit}>
                              <h1>Login</h1>
                              <p className="text-muted">Sign In to your account</p>

                              <Field
                                className='email'
                                name="email"
                                type="text"
                                placeholder="Email"
                                icon='icon-user'
                                component={customInputForm}
                              />

                              <Field
                                className='password'
                                name="password"
                                type="password"
                                icon="icon-lock"
                                placeholder="Password"
                                component={customInputForm}
                              />
                              {
                                loginError && <Row>
                                  <Col>
                                    <p className='text-danger p-1'><i className='fa fa-exclamation-circle mr-1' /> {loginError.message ? loginError.message : 'Something went wrong! Please try again later'}</p>
                                  </Col>
                                </Row>
                              }
                              <Row>
                                <Col xs="6">
                                  <Button
                                    color="primary"
                                    className="submit-login px-4"
                                    type="submit"
                                    disabled={!values.email || !values.password || isSubmitting}
                                  >
                                    Login
                                  </Button>
                                </Col>
                                <Col xs="6" className="text-right">
                                  <Link className="px-0 btn btn-link" to="/reset-password">
                                    Forgot password?
                                  </Link>
                                </Col>
                              </Row>
                            </Form>
                          )
                        }
                      </Formik>
                    </CardBody>
                  </Card>
                  <Card className="text-white bg-primary py-5 no-border registration-pane">
                    <CardBody className="text-center">
                      <div>
                        <h2>Sign Up</h2>
                        <p>
                          Are you a renter or property manager?
                          <br /> Register with Rentivity today!
                        </p>
                        <Link to="/register" onClick={() => this.context.history.push('/register')}>
                          <Button
                            color="primary"
                            className="mt-3"
                            tabIndex={-1}
                            style={{
                              backgroundColor: '#1c375a',
                              borderColor: '#193150'
                            }}
                          >
                            Register Now!
                          </Button>
                        </Link>
                        <h5 className='mt-4 mb-0'>RENTING REINVENTED</h5>
                      </div>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
          {/* </div> */}
        </Suspense>
      </main>
    </div>
  </div >
)

export default LoginBase;

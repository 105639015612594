import * as Yup from 'yup'

const UploadDocsSchema = () => Yup.object().shape({
  reqDocs: Yup.array().of(
    Yup.object().shape({
      subCategory: Yup.array().of(
        Yup.object().shape({
          checked: Yup.bool(),
          value: Yup.string().when('checked', {
            is: true,
            then: Yup.string().required('Value is required')
          })
        })
      )
    })
  )
})

export default UploadDocsSchema;
import React from 'react';
import { 
  FormGroup,
  FormFeedback, Input, InputGroup, Label
} from 'reactstrap';
import _ from "lodash";

const CustomInputForm  = ({ field, form: { touched, errors } = null, icon, ...props }) => (
  <FormGroup>
    <Label for={field.name} className={`font-weight-semibold d-block ${props.labelclassname || props.labelClassName}`}>
      {props.label}
      {props.required ? <span className="text-danger">*</span> : null}
    </Label>
    <InputGroup className="mb-3">
      <Input
        invalid={!!(touched[field.name] && errors[field.name])}
        valid={_.isEmpty(errors[field.name]) && touched[field.name] ? true : false}
        {...field}
        {...props}
      />
      {touched[field.name] && errors[field.name] && <FormFeedback className='ml-5'>{errors[field.name]}</FormFeedback>}
    </InputGroup>
  </FormGroup>
);


export default CustomInputForm;
import React from 'react';
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import _get from 'lodash/get';
import DatePicker from 'react-datepicker';

const FormikCustomDatePicker = ({
  label,
  field,
  form: { touched, errors },
  className,
  required,
  ...props
}) => {
  const valid = _get(touched, field.name) && !_get(errors, field.name);
  const inValid = _get(touched, field.name) && !!_get(errors, field.name);

  return (
    <FormGroup>
      {label && <Label className='font-weight-semibold d-block'>{label}{required && <span className='text-danger'>*</span>}</Label>}
      <DatePicker
        className={`form-control ${className} ${inValid ? 'is-invalid' : valid ? 'is-valid' : ''}`}
        {...field}
        {...props}
      />
      {touched[field.name] && errors[field.name] && <FormFeedback style={{ display: 'block' }}>{errors[field.name] || ' '}</FormFeedback>}
    </FormGroup>
  )
};

export default FormikCustomDatePicker;
import React, { Component } from 'react';
import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import BottomScrollListener from 'react-bottom-scroll-listener';

import './style.scss';

class TermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = { disableAccept: true }
    this.tocRef = React.createRef()
  }

  handleBottom = () => this.setState({ disableAccept: false })


  render() {
    return (
      <Modal
        size="lg"
        isOpen={this.props.isOpen}
      >
        <ModalHeader>
          Terms & Condition | ESIGN Consent | Privacy Policy
        </ModalHeader>
        <ModalBody>
          <BottomScrollListener
            onBottom={this.handleBottom}
            offset={100}
          >
            {
              (scrollRef) => (
                <div
                  className="content"
                  ref={scrollRef}
                >
                  <h3>ESIGN CONSENT TO USE ELECTRONIC RECORDS AND SIGNATURES (this “ESIGN Consent”)</h3>


                  <p>As part of your relationship with us, we want to ensure you have all of the information you need to
                    effectively manage your Account. Our goal is to provide you with as many options as possible for receiving
                    your Account documents. We are required by law to give you certain information “in writing” – which means
                    you are entitled to receive it on paper. We may provide this information to you electronically, instead,
                    with your prior consent. We also need your general consent to use electronic records and signatures in our
                    relationship with you. So, before you use our Electronic Services you must review and consent to the terms
                    outlined below.</p>
                  <p>This ESIGN Consent applies to all Communications for those Rentivity Products (or other products),
                    services (or other services) and Accounts (or other accounts) offered or accessible through Rentivity’s
                    Electronic Service, or a mobile application or mobile website used to access the Electronic Service, that
                    are not otherwise governed by the terms and conditions of an electronic disclosure and consent.</p>
                  <p>Please note that by consenting to receive Communications pursuant to this ESIGN Consent you are giving
                    your consent to electronic disclosures and the use of electronic signatures. If you do not want to receive
                    the Communications electronically, or do not want to use electronic signatures in connections with your
                    transactions with us, you should not agree to the terms of this ESIGN Consent. If you do not consent to
                    receiving the Communications electronically, or do not consent to the use of electronic signatures in
                    connection with your transactions with us, we will not be able to proceed with the acceptance and
                    processing of your online transaction or online Account services.</p>
                  <p>In this ESIGN Consent:</p>
                  (a) The word “Account” means the account you have with us now or in the future, including ones which
                  you open through the Electronic Service.
                  <br />(b) The word “Communications” means each disclosure, notice, agreement, fee schedule, statement,
                  record, document, transaction history, privacy policies including our Privacy Policy, and other information
                  we provide to you, or that you sign or submit or agree to at our request and any amendments thereto.
                  <br />(c) The words “Electronic Service” means each and every product and service we offer that you apply
                  for, own, use, or access using the Internet, a website, email, messaging services (including text messaging)
                  and/or software applications (including applications for mobile or handheld devices), either now or in the
                  future. Rentivity has no liability to you with respect to third party products and/or services which you
                  apply for, use, or access using the Electronic Service.
                  <br />(d) The words “include” and “including,” when used at the beginning of a list of one or more items,
                  indicates the list contains examples – the list is not exclusive or exhaustive, and the items in the list
                  are only illustrations. They are not the only possible items that could appear in the list. Further, the
                  words “include” and “including” shall mean including without limitation.
                  <br />(e) “Rentivity Product” means each and every Account, product or service we offer that you apply for,
                  own, use, or access, either now or in the future. Rentivity Product includes Electronic Service. However,
                  Rentivity Product does not include third party products and/or services which you apply for, use, or access
                  using the Electronic Service.
                  <br />(f) The words “you”, “your”, “my” and “me” means the person giving this consent, and also each
                  authorized signer, authorized representative, occupant, product user and/or service user identified with any
                  Rentivity Product that you apply for, use or access.
                  <br />(g) The words “we”, “us”, “our”, and “Rentivity” means Rental Technologies, LLC d/b/a Rentivity, and
                  each and every current and future affiliate of Rentivity.
                  <p>The definition of a word or words in this ESIGN Consent shall not define the same word or words in
                    Rentivity’s Privacy Policy or Terms and Conditions, both of which have their own specific definitions and
                    are separate and distinct documents from this ESIGN Consent. Likewise, any word or words which are not
                    defined in this ESIGN Consent but are defined in Rentivity’s Privacy Policy and/or Terms and Conditions
                    shall not be so defined herein. Further, references to Rentivity’s Privacy Policy and/or Terms and
                    Conditions in this ESIGN Consent shall not be utilized to interpret any term or provision in either the
                    Privacy Policy and/or the Terms and Conditions.</p>
                  <p>1. Your Consent to Use Electronic Records and Signatures. Method of Providing Communications to You in
                    Electronic Form. Choosing to Receive Communications Electronically or in Writing. Certain Information Must
                    Still be Provided in Writing. In our sole discretion, the Communications we provide to you, or that you
                    sign or agree to at our request, may be in electronic form (“Electronic Records”). We may also use
                    electronic signatures and obtain them from you as part of our transactions with you.</p>
                  <p>By consenting to the use of electronic signatures, you agree that Rentivity may use electronic signatures
                    in connection with your transactions with us. You agree that we may provide you with any and all
                    Communications in electronic format, and that we may discontinue sending paper Communications to you,
                    unless and until you withdraw your consent as described below. Your consent to receive electronic
                    Communications and transactions includes:
                    <br />
                    (a) Applying for or opening an Account or obtaining a Rentivity
                   Product.
                   <br />
                    (b) All legal and regulatory disclosures and Communications associated with the Account or the
                     product or service available through the Electronic Service for your Account. As an example, Rentivity may
                     send by email legally required notification regarding our separate Terms and Conditions related to your
                     Account or the Electronic Service.
                    <br />
                    (c) Notices or disclosures about a change in the terms of your Account
                    or associated payment feature and responses to notices or requests from you.
                    <br />
                    (d) our separate Privacy
                    Policy and notices.
                    <br />
                    (e) Account statements for your Account or such other such Communications we may
                   include from time to time as part of the participation in the Rentivity Product(s) and/or program(s)
                   including electronic notifications. Related documents may include marketing documentation and
                   documentation required to be provided to you pursuant to regulatory rules, such as privacy notices and
                    other important information regarding your Account.</p>
                  <p>Electronic Records may be delivered to you in a variety of ways. All Communications that we provide to
                    you in electronic form shall be provided by one or more of the following methods:
                    <br />(a) via email.
                    <br />(b) to the extent of permissible by law, by access to a website, including our mobile websites.
                    <br />(c) via our mobile applications.
                    <br /> (d) by requesting you download a PDF file containing the
                    Communication.</p>
                  <p>We may always, in our sole discretion, provide you with any Communication in writing, even if you have
                    chosen to receive it electronically.</p>
                  <p>Sometimes the law, or our agreement with you, requires you to give us a written notice. You must still
                    provide these notices to us on paper, unless we specifically tell you in another Communication how you may
                    deliver that notice to us electronically.</p>
                  <p>There may be certain Communications that by law we are not permitted to deliver to you electronically,
                    even with your consent. So long as required by law, we will continue to deliver those Communications to
                    you in writing. However, if the law changes in the future and permits any of those Communications to be
                    delivered as Electronic Records, this consent shall automatically cover those Communications as well.</p>
                  <p>2. <strong>Your Option to Receive Paper Copies.</strong> You should not expect to receive a paper copy of
                    any Communication, unless you request it or we otherwise deem it appropriate to do so. You can obtain a
                    paper copy of an electronic Communication by printing it yourself or by requesting that we mail you a
                    paper copy, provided that such request it made within a reasonable time after we first provided the
                    electronic Communication to you. To obtain a paper copy, log into the Electronic Service and print or save
                    a copy online or you may mail us a letter at Department of Paper Copies, 3218 East Colonial Drive, Suite
                    G, Orlando, Florida 32803 and request a paper version. Upon receipt of your letter, we will notify you of
                    the fee to provide a paper copy, unless charging a fee is prohibited by applicable law. You must pay this
                    fee in advance, if applicable, and then, we will mail you the paper copy. We reserve the right, but assume
                    no obligation, to provide a paper (instead of electronic) copy of any Communication that you have
                    authorized us to provide electronically.</p>
                  <p>3. Your Consent Covers All Rentivity Products. Privacy Policy. Your consent covers all Communications
                    relating to any Rentivity Product. Your consent remains in effect until you give us notice that you are
                    withdrawing it.</p>
                  <p>From time to time, you may seek to obtain a new Rentivity Product from us. When you do, we may remind you
                    that you have already given us your consent to use Electronic Records and signatures. If you decide not to
                    use Electronic Records and signatures in connection with the new product or service, your decision does
                    not mean you have withdrawn this consent for any other Rentivity Product.</p>
                  <p>You agree that we may satisfy our obligation to provide you with an annual copy of our separate Privacy
                    Policy by keeping it available for review on https://rentivity.com, or, with prior notice to you, on
                    another website where we offer Electronic Services.</p>
                  <p>4. You May Withdraw Your Consent at any Time. Consequences of Withdrawing Consent. How to Give Notice of
                    Withdrawal. You have the right to withdraw your consent at any time. Please be aware, however, that
                    withdrawal of consent may result in the termination of
                    <br /> (a) your access to and use of our Electronic
                    Services.
                    <br />
                    (b) your ability to use certain Rentivity Products.</p>
                  <p>Your withdrawal of consent shall become effective after we have had a reasonable opportunity to act upon
                    it.</p>
                  <p>Depending on the specific Rentivity Product, if you withdraw consent we may charge higher or additional
                    fees for that product or for services related to it. Upon receipt of your withdrawal of consent as set
                    forth below, we will notify you of the higher or additional fees for that product or for services related
                    to it, unless charging a higher or additional fee is prohibited by applicable law. You must pay this
                    higher or additional fee in advance, if applicable, for that product or for services related to it.</p>
                  <p>To withdraw your consent you must contact us at withdrawconsent@rentivity.com.</p>
                  <p>Withdrawing your consent does not change the legal effect, validity, or enforceability of prior
                    electronic disclosures but it does prevent you from doing future business with us online.</p>
                  <p>Please specify the information you wish to receive in paper form. Be sure to state that you are
                    withdrawing your consent, requesting a copy of the disclosures, notices, or other specific item, and
                    include your name and mailing address. Your request shall apply only to those specific items you
                    designate. Upon receipt of your e-mail, we will notify you of the fee to provide a paper copy of the
                    specified information, unless charging a fee is prohibited by applicable law. You must pay this fee in
                    advance, if applicable, and then, we will mail you the paper copy.</p>
                  <p>5. You Must Keep Your Email or Electronic Address Current with Us. It is your responsibility to provide
                    us with true, accurate and complete email address or other electronic address, contact, and other
                    information related to this ESIGN Consent and your Account, and to maintain and update promptly any
                    changes in this information. You can update information (such as your email address) or if you have given
                    us another type of electronic address, such as an electronic address or mobile phone number for SMS text
                    messages, by going through the Electronic Service.</p>
                  <p>6. Communications in Writing. All Communications in either electronic or paper format from us to you
                    shall be considered “in writing”. You should print or download for your records a copy of this ESIGN
                    Consent and any other Communication that is important to you. Questions regarding your Account can be
                    addressed by sending an email to questions@rentivity.com.</p>
                  <p>7. Hardware and Software You Will Need. To access, view, and retain electronic Communications that we
                    provide or make available to you, you must have:
                   <br />
                    (a) a Current Version (defined below) of an Internet
                    browser we support.
                    <br />
                    (b) a connection to the Internet capable of receiving, accessing, displaying, and
                    either printing or storing Communications received from us in electronic form via email or by access to
                    our website.
                    <br />
                    (c) a Current Version of a program that accurately reads and displays PDF files (such as
                    Adobe Acrobat Reader).
                     <br />
                    (d) a computer or other device and an operating system capable of accessing and
                   supporting all of the above. Your access to this page verifies that your system/device meets these
                   requirements. You will also need a printer if you wish to print out and retain records on paper, and
                   sufficient electronic storage capacity if you wish to retain records in electronic form on your computer’s
                   hard drive or other long term data storage device or service, and the ability to download and store files,
                   including PDF files, in order to retain past Communications from Rentivity and/or for future
                    reference.</p>
                  <p>You must also have an active valid email address (the email address designated by you).</p>
                  <p>In some cases, you may also need a specific brand or type of device that can support a particular
                    software application, including an application intended for particular mobile or handheld devices.</p>
                  <p>By “Current Version,” we mean a version of the software that is currently being supported by its
                    publisher.</p>
                  <p>We reserve the right to discontinue support of a Current Version of software if, in our sole opinion, it
                    suffers from a security flaw or other flaw that makes it unsuitable for use with Rentivity Products or
                    services.</p>
                  <p>8. Changes to Hardware or Software Requirements. If our hardware or software requirements change, and
                    that change would create a material risk that you would not be able to access or retain your Electronic
                    Records, we will give you notice of the revised hardware or software requirements. Continuing to use
                    Electronic Services after receiving notice of the change is reaffirmation of your consent.</p>
                  <p>9. Applicable Law. You acknowledge and agree that your consent to electronic Communications is being
                    provided in connection with a transaction affecting interstate commerce that may be subject to the federal
                    Electronic Signatures in Global and National Commerce Act (or the Uniform Electronic Transactions Act as
                    adopted by the state in which you reside), and that you and we both intend that each of the foregoing Acts
                    apply to the fullest extent possible to validate our ability to conduct business with you by electronic
                    means. If your state is not governed by either of these Acts, then that state’s laws shall apply to the
                    extent not preempted by federal law.</p>
                  <p>10. Termination/Changes. We reserve the right, in our sole discretion, to discontinue the provision of
                    your electronic Communications, or to terminate or change our separate Terms and Conditions on which we
                    provide electronic Communications. We will provide you with notice of any such termination or change as
                    required by law.</p>
                  <p>11. Communications in Languages other than English. Please note, we may be unable to fulfill and service
                    Rentivity Products in a language other than English. Future Communications may be in English only. If you
                    are not fluent in English, you should consider obtaining the services of an interpreter or taking other
                    steps to ensure you understand the transaction before entering into it and to explain any future
                    Communications in English.</p>
                  <p>12. Acceptance and Consent. By consenting to this ESIGN Consent, you agree to the following statements:
                    <br />(a) I have read, understand and agree to be bound by the terms and conditions described herein and
                    consent to receive electronic Communications according to the process described above.
                    <br />(b) You and Rentivity and its affiliates agree to conduct transactions in electronic form.
                    <br />(c) Certain documents may continue to be delivered to me via U.S. Mail and that in the future some or
                    all of these documents may be made available for me to view electronically in accordance with this ESIGN
                    Consent.
                    <br />(d) My consent to view documents electronically does not automatically expire and is not limited as
                    to duration.
                    <br />(e) Rentivity and/or its agents may revoke my participation in the Rentivity Product(s) and/or
                    program(s) at any time at its and/or their discretion.
                    <br />(f) Any third party and/or its agents providing products and/or services to you may revoke my
                    participation in its products and/or services at any time at its and/or their discretion.
                    <br />(g) Neither Rentivity, its agents nor any third party and/or its agents shall be liable for any loss,
                    liability, cost, expense, or claim for acting upon this authorization or arising from my use of the
                    Rentivity Product and/or services or other third party product and/or services provided pursuant to this
                    ESIGN Consent.
                    <br />
                    (h) Documents that may be provided contain important information or disclosures
                    concerning my Account and I agree to review such documents in a timely manner.</p>
                  <p>By clicking “I AGREE”, you acknowledge that you have read and agree to the terms set forth in this ESIGN
                    Consent and that you give your affirmative consent for us to provide you with electronic Communications,
                    as described in this ESIGN Consent, for your Account. You are also confirming that:
                    <br />(a) You have reviewed this ESIGN Consent.
                    <br />(b) You have the hardware and software described above and have access to the
                    necessary equipment and software, you are able to receive, open and view electronic records, and print or
                    download a copy of any Communication for your records. By your consent you confirm that you are able to
                    access and view records and documents in HTML format.
                    <br />(c) You are able to receive and review electronic records.
                    <br />(d) You have an active valid email account and the ability to access and view PDF files.
                    <br />(e) You are able to print or save a copy of these Communications for your records as they may not be
                    accessible online at a later date.</p>
                  <p>You are also confirming that you are authorized to, and do, consent on behalf of all the other authorized
                    signers, authorized representatives, occupants, product users and/or service users identified with any
                    Rentivity Product.</p>
                  <p>As of February 23, 2019</p>

                  <h3>Privacy Policy</h3>
                  <p>Protecting your privacy is really important to us. With this in mind, we're providing this privacy policy
                    (this “Privacy Policy”) to explain our practices regarding the collection, use and disclosure of
                    information that we receive through our Electronic Service (as defined below) and/or Rentivity Product (as
                    defined below). This Privacy Policy does not apply to any third-party websites, services or applications,
                    even if they are accessible through our Electronic Service and/or Rentivity Product.</p>

                  <p><strong>Definitions</strong></p>
                  <p>In this Privacy Policy:</p>
                  (a) The word “Account” means the account you have with us now or in the future, including ones which
                  you open through the Electronic Service.
                  <br />(b) The word “Communications” means each disclosure, notice, agreement, fee schedule, statement,
                  record, document, transaction history, privacy policies including this Privacy Policy (and any revisions
                  thereto), and other information we provide to you, or that you sign or submit or agree to at our request and
                  any amendments thereto.
                  <br />(c) The words “Electronic Service” means each and every product and service we offer that you apply
                  for, own, use, or access using the Internet, a website, email, messaging services (including text messaging)
                  and/or software applications (including applications for mobile or handheld devices), either now or in the
                  future. Rentivity has no liability to you with respect to third party products and/or services which you
                  apply for, use, or access using the Electronic Service.
                  <br />(d) The words “include” and “including,” when used at the beginning of a list of one or more items,
                  indicates the list contains examples – the list is not exclusive or exhaustive, and the items in the list
                  are only illustrations. They are not the only possible items that could appear in the list. Further, the
                  words “include” and "including" shall mean including without limitation.
                  <br />(e) “Rentivity Product” means each and every Account, product or service we offer that you apply for,
                  own, use, or access, either now or in the future. Rentivity Product includes Electronic Service. However,
                  Rentivity Product does not include third party products and/or services which you apply for, use, or access
                  using the Electronic Service.
                  <br />(f) The words “you”, “your”, “my” and “me” means the person reviewing and/or agreeing to this Privacy
                  Policy, and also each authorized signer, authorized representative, occupant, product user and/or service
                  user identified with any Rentivity Product that you apply for, use or access.
                  <br />(g) The words “we”, “us”, “our”, and “Rentivity” means Rental Technologies, LLC d/b/a Rentivity, and
                  each and every current and future affiliate of Rentivity.

                  <p>The definition of a word or words in this Privacy Policy shall not define the same word or words in
                    Rentivity’s ESIGN Consent or Terms and Conditions, both of which have their own specific definitions and
                    are separate and distinct documents from this Privacy Policy. Likewise, any word or words which are not
                    defined in this Privacy Policy but are defined in Rentivity’s ESIGN Consent and/or Terms and Conditions
                    shall not be so defined herein. Further, references to Rentivity’s ESIGN Consent and/or Terms and
                    Conditions in this Privacy Policy shall not be utilized to interpret any term or provision in either the
                    ESIGN Consent and/or the Terms and Conditions.</p>
                  <p><strong>Revisions to this Privacy Policy</strong></p>
                  <p>Information that is collected via our Electronic Service and/or Rentivity Product is covered by the
                    Privacy Policy in effect at the time such information is collected. We may revise this Privacy Policy from
                    time to time. If we make any material changes to this Privacy Policy, we will notify you of those changes
                    by posting them on our Electronic Service or by sending you an email or other notification, and we will
                    update the date at the end of this Privacy Policy to indicate when those changes become effective.</p>

                  <p>You agree that we may satisfy our obligation to provide you with an annual copy of our Privacy Policy by
                    keeping it available for review on https://rentivity.com, or, with prior notice to you, on another website
                    where we offer Electronic Services.</p>

                  <p><strong>Collection and Use of Information</strong></p>
                  <p><strong>Information Collected or Received from You</strong></p>
                  <p>Our primary goals in collecting information are to provide and improve our Electronic Service and/or
                    Rentivity Product, to administer your use of the Electronic Service (including your Account, if you are an
                    Account holder) and/or our Rentivity Product, and to enable you to enjoy and easily navigate our
                    Electronic Service and/or Rentivity Product.</p>

                  <p><u>Account Information.</u> If you create an Account, we will collect certain personal information that
                    can be used to identify you, including your name, email address, postal address, phone number, demographic
                    information including your preferences and interests, website usage data, other information about you
                    relevant to other customers of Rentivity, other information pertaining to special offers and surveys to
                    you, and date of birth (collectively your “Personal Information"). If you create an Account using your
                    login credentials from one of your social network accounts (“SNS Accounts”), we will be able to access and
                    collect your name and email address and other Personal Information that your privacy settings on the SNS
                    Account permit us to access. If you create an Account through our Electronic Service or one of your SNS
                    Accounts, we may also collect your gender, date of birth and other Personal Information as well as
                    non-personal information relating to you. Non-personal information is information which cannot be used by
                    itself to identify you.</p>

                  <p><u>Financial Information.</u> In connection with a financial transaction via the Electronic Service
                    and/or Rentivity Product through our third party payment services provider it will collect your
                    credit/debit card information or other financial and payment information. We do not store, have access to
                    or utilize your credit/debit card information or other financial and payment information, except for the
                    amount of your lease deposit and, if you make monthly lease payments via the Electronic Service and/or a
                    Rentivity Product, the amount and date of payment (collectively, the “Lease Payment History”). We will use
                    your Lease Payment History information solely in connection with our business and will not share this
                    information with third parties, except for owners and property managers associated with your rental, with
                    your consent or to the extent necessary to comply with applicable law.</p>
                  <p><u>Real Estate Information.</u> When you use the Electronic Service and/or Rentivity Product to list,
                    delete, access, bid upon and/or lease a property and/or properties, we will present property related
                    information with respect to that property, which we obtain either directly from the owner, property
                    manager or from a third party source. In addition, we will analyze any Personal Information and/or
                    non-personal information that you provide via the Electronic Service and/or Rentivity Product and make the
                    results of such analysis available to other Users (as defined below) of the Electronic Service and/or
                    Rentivity Product.</p>
                  <p><u>Real Estate Transaction Information.</u> When you complete a lease of a property included on our
                    Electronic Service and/or Rentivity Product, we will collect information regarding the completed lease
                    transaction. We may use this information to improve and personalize our Electronic Service and/or
                    Rentivity Product.</p>

                  <p><u>Information Collected Using Cookies and other Web Technologies.</u> Like many website owners and
                    operators, we use automated data collection tools such as Cookies, Web Beacons, unique identifiers, and
                    Widgets to collect certain information.</p>

                  <p>"Cookies" are small text files that are placed on your device or hard drive by a web server when you
                    access our Electronic Service and/or Rentivity Product. We may use both session Cookies and persistent
                    Cookies to identify that you have logged in to the Electronic Service and/or Rentivity Product and to tell
                    us how and when you interact with our Electronic Service and/or Rentivity Product. We may also use Cookies
                    to monitor aggregate usage and web traffic routing on our Electronic Service and/or Rentivity Product and
                    to customize and improve our Electronic Service and/or Rentivity Product. Unlike persistent Cookies,
                    session Cookies are deleted when you navigate away from or log off the Electronic Service and/or Rentivity
                    Product and close your browser. Although most browsers automatically accept Cookies, you can change your
                    browser options to stop automatically accepting Cookies or to prompt you before accepting Cookies. Please
                    note, however, that if you do not accept Cookies, you may not be able to access all portions or features
                    of the Electronic Service and/or Rentivity Product. Some third-party services providers that we engage
                    (including third-party advertisers) may also place their own Cookies on your device or hard drive. Note
                    that this Privacy Policy covers only our use of Cookies and does not include use of Cookies by such third
                    parties.</p>

                  <p>"Web Beacons" (also known as web bugs, pixel tags or clear graphics interchange formats) are tiny
                    graphics with a unique identifier that may be included on our Electronic Service and/or Rentivity Product
                    for several purposes, including to deliver or communicate with Cookies, to track and measure the
                    performance of our Electronic Service and/or Rentivity Product, to monitor how many visitors view our
                    Electronic Service and/or Rentivity Product, and to monitor the effectiveness of our advertising. Unlike
                    Cookies, which are stored on the user's device or hard drive, Web Beacons are typically embedded invisibly
                    on web pages (or in an e-mail).</p>

                  <p>"Widgets" are small programs or applications that can be embedded in a web page. Widgets can provide
                    real-time information, such as stock quotes or weather reports. Widgets are often provided by a third
                    party, and may allow that third party to collect data about users viewing that page.</p>

                  <p><u>Information Related to Use of the Electronic Service and/or Rentivity Product.</u> Our servers
                    automatically record certain information about how a person uses our Electronic Service and/or Rentivity
                    Product (we refer to this information as "Log Data"), including both Account holders and non-Account
                    holders (either, a "User"). Log Data may include information such as a User's Internet Protocol (“IP”)
                    address, browser type, operating system, the web page that a User was visiting before accessing our
                    Electronic Service and/or Rentivity Product, the pages or features of our Electronic Service and/or
                    Rentivity Product to which a User browsed and the time spent on those pages or features, search terms, the
                    links on our Electronic Service and/or Rentivity Product that a User clicked on and other statistics. We
                    use Log Data to administer the Electronic Service and/or Rentivity Product and we analyze (and may engage
                    third parties to analyze) Log Data to improve, customize and enhance our Electronic Service and/or
                    Rentivity Product by expanding their features and functionality and tailoring them to our Users' needs and
                    preferences. We may use a person's IP address to generate aggregate, non-identifying information about how
                    our Electronic Service and/or Rentivity Product are used.</p>

                  <p><u>Information Sent by Your Mobile/Electronic Device.</u> We collect certain information that your
                    mobile/electronic device sends when you use our Electronic Service and/or Rentivity Product, like a device
                    identifier, user settings and the operating system of your device, as well as information about your use
                    of our Electronic Service and/or Rentivity Product.</p>

                  <p><u>Information. </u> When you use our Electronic Service, Rentivity Product and/or application, we may
                    collect and store information about your location by converting your IP address into a rough geo-location
                    or by accessing your mobile/electronic device's GPS coordinates or coarse location if you enable location
                    services on your device. We may ask you to manually provide precise location information on our Electronic
                    Service, Rentivity Product and/or application. We may use location information to improve and personalize
                    our Electronic Service and/or Rentivity Product for you. If you do not want us to collect location
                    information, you may disable that feature on your mobile/electronic device.</p>

                  <p><strong>Information that We Share with Third Parties</strong></p>
                  <p>We will not share any Personal Information that we have collected from or regarding you except as
                    described in this Privacy Policy:</p>

                  <p><u>Information Shared with Our Services Providers.</u> We may engage third-party services providers to
                    work with us to administer and provide the Electronic Service and/or Rentivity Product. for example
                    payment services providers. These third-party services providers have access to your Personal Information
                    only for the purpose of performing services you requested and/or agreed to.</p>

                  <p><u>Information Shared with Third Parties.</u> We may share aggregated information and non-identifying
                    information with third parties for industry research and analysis, demographic profiling and other similar
                    purposes.</p>

                  <p>We may share Personal Information you provide to us via the Electronic Service and/or Rentivity Product,
                    non-personal information and other information we have about you (not to include your Lease Payment
                    History) with third-parties and the third parties may personalize the Third-Party Service (as defined
                    below) or content that they then provide to you. Further, when you enter your credit/debit card and other
                    financial and payment information with our Third-Party Service provider and authorize payment(s), if you
                    are the winning bidder or agree to rent the property at the posted rental amount sought by the owner
                    and/or property manager, you will also authorize your screening report to be shared with the third party
                    responsible for leasing and/or managing the property.</p>
                  <p>We will comply with applicable legal requirements to the extent the requirements require us to obtain
                    your consent before sharing such information with third parties.</p>

                  <p><u>Information Disclosed in Connection with Business Transactions.</u> Information that we collect from
                    our Users, including Personal Information, is considered to be a business asset. Thus, if we are acquired
                    by a third party as a result of a transaction such as a merger, acquisition or asset sale or if our assets
                    are acquired by a third party in the event we go out of business or enter bankruptcy, some or all of our
                    assets, including your Personal Information, may be disclosed or transferred to a third party acquirer in
                    connection with the transaction.</p>

                  <p><u>Information Disclosed for Our Protection and the Protection of Others.</u> We cooperate with
                    government and law enforcement officials or private parties to enforce and comply with the law. We may
                    disclose any information about you to government or law enforcement officials or private parties as we, in
                    our sole discretion, believe necessary or appropriate:
                    <br />(i) to respond to claims, legal process (including
                    subpoenas).
                    <br />(ii) to protect our property, rights and safety and the property, rights and safety of a third
                    party or the public in general.
                    <br />(iii) to stop any activity that we consider illegal, unethical or
                    legally actionable activity.</p>

                  <p><strong>Your Choices</strong></p>
                  <p>We offer you choices regarding the collection, use and sharing of your Personal Information and we will
                    respect the choices you make. Please note that if you decide not to provide us with your Personal
                    Information that we request, you may not be able to access all of the features of the Electronic Service
                    and/or Rentivity Product.</p>

                  <p>Opt-Out. We may periodically send you free newsletters, e-mails and/or text messages that directly
                    promote our Electronic Service and/or Rentivity Product. When you receive such promotional Communications
                    from us, you will have the opportunity to “opt-out" (either through your Account or by following the
                    unsubscribe instructions provided in the e-mail you receive). We do need to send you certain
                    Communications regarding the Electronic Service and/or Rentivity Product and you will not be able to opt
                    out of those Communications including Communications regarding updates to our Terms and Conditions or this
                    Privacy Policy or information about billing.</p>

                  <p>Modifying/Deleting Your Information. You can access and modify your Personal Information associated with
                    your Account by updating your profile and payment information online. If you want us to delete your
                    Personal Information and your Account, please contact us at deletePI@rentivity.com with your request. We
                    will take steps to delete your information as soon we can, but some information may remain in
                    archived/backup copies for our records or as otherwise required by law.</p>


                  <p>Responding to Do Not Track Signals</p>
                  <p>Our Electronic Service and/or Rentivity Product does not have the capability to respond to “Do Not
                      Track" signals received from various web browsers.</p>

                  <p>The Security of Your Information</p>
                  <p>We take reasonable administrative, physical and electronic measures designed to protect the information
                    that we collect from or about you (including your Personal Information) from unauthorized access, use or
                    disclosure. When you enter sensitive information on our forms, Electronic Service and/or Rentivity
                    Product, we encrypt this data. Please be aware, however, that no method of transmitting information over
                    the Internet or storing information is completely secure. Accordingly, we cannot guarantee the absolute
                      security of any information.</p>

                  <p>Links to Other Sites</p>
                  <p>Our Electronic Service and/or Rentivity Product may contain links to websites and services that are
                    owned or operated by third parties, including renter insurance services, credit and background screening
                    services and payment processing (each, a “Third-Party Service"). Any information that you provide on or
                    to a Third-Party Service or that is collected by a Third-Party Service is provided directly to the owner
                    or operator of the Third-Party Service and is subject to the owner's or operator's privacy policy. We
                    are not responsible for the content, privacy or security practices and policies of any Third-Party
                    Service. To protect your information we recommend that you carefully review the privacy policies of all
                      Third-Party Services that you access.</p>

                  <p>International Transfer</p>
                  <p>Your Personal Information may be transferred to, and maintained on, computers located outside of your
                    state, province, country or other governmental jurisdiction where the privacy laws may not be as
                    protective as those in your jurisdiction. If you are located outside the United States and choose to
                    provide your Personal Information to us, we may transfer your Personal Information to the United States
                      and process it there.</p>

                  <p>Our Policy Toward Children</p>
                  <p>Our Electronic Service and/or Rentivity Product are not directed to children under 18 and we do not
                    knowingly collect Personal Information from children under 18 except if they are an occupant of the
                    rental then their names and ages are required by the owner and/or property manager. If we learn that we
                    have collected Personal Information of a child under 18 (except as stated in the prior sentence), we
                      will take steps to delete such information from our files as soon as possible.</p>

                  <p>Communications in languages other than English.</p>
                  <p>Please note, we may be unable to fulfill and service your rights pursuant to this Privacy Policy in a
                    language other than English. Future Communications may be in English only. If you are not fluent in
                    English, you should consider obtaining the services of an interpreter or taking other steps to ensure
                    you understand this Privacy Policy before agreeing to it and to explain any future Communications in
                      English.</p>

                  <p>Acceptance and Consent. By consenting to this Privacy Policy, you agree to the following statements:
                    (a) I have read, understand and agree to be bound by the terms and conditions described herein. (b) My
                    consent to this Privacy Policy does not automatically expire and is not limited as to duration. (c)
                    Withdrawing my consent does not change the legal effect, of validity or enforceability of my prior
                    consent. (c) Rentivity and/or its agents may revoke my participation in the Electronic Service and/or
                    Rentivity Product(s) and/or program(s) at any time at its and/or their discretion. (d) Any third party
                    and/or its agents providing products and/or services to me may revoke my participation in its products
                    and/or services at any time at its and/or their discretion. (e) Neither Rentivity, its agents nor any
                    third party and/or its agents shall be liable for any loss, liability, cost, expense, or claim for
                    acting upon this authorization or arising from my use of the Electronic Service and/or Rentivity Product
                    and/or services or other third party product and/or services provided pursuant to this Privacy Policy.
                    and (f) Documents that may be provided contain important information or disclosures concerning the
                      Privacy Policy and I agree to review such documents in a timely manner.</p>
                  <p>By clicking “I AGREE”, you acknowledge that you have read and agree to the terms set forth in this
                    Privacy Policy and that you give your affirmative consent for us to provide you with the Electronic
                    Service and/or Rentivity Product. You are also confirming that
                    <br />
                    (a) By your consent you confirm that you
                    are able to receive, access and view records and documents in PDF, HTML format and electronic records.
                      <br /> (b) You are able to print or save a copy of this Privacy Policy for your records.</p>
                  <p>You are also confirming that you are authorized to, and do, consent on behalf of all the other
                    authorized signers, authorized representatives, occupants, product users and/or service users identified
                      with the Electronic Service and/or any Rentivity Product.</p>
                  <p>Questions?</p>
                  <p>Please contact us at questions@rentivity.com if you have any questions about our Privacy Policy.</p>

                  <p>As of February 23, 2019</p>

                  <h3>TERMS AND CONDITIONS</h3>
                  <p>NOTICE: PLEASE READ THESE TERMS AND CONDITIONS (the “Terms and Conditions”) CAREFULLY. BY ACCESSING
                    THIS WEBSITE AND REGISTERING, YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS PROVIDED HEREIN. IF
                    YOU DO NOT AGREE TO THE TERMS AND CONDITIONS BELOW, YOU MAY NOT REGISTER WITH RENTIVITY.COM AND ARE NOT
                      AUTHORIZED TO ACCESS CERTAIN COMPONENTS OF THIS WEBSITE.</p>
                  <p>We welcome you to the Rentivity.com website ("Website") for Rental Technologies LLC d/b/a Rentivity and
                    each and every current and future affiliate of Rentivity (hereinafter, such entities are collectively
                    referred to as "Rentivity") and the Website, rental home marketing, bidding and management system (are
                    collectively referred to as the "System"). The Rentivity System includes various websites and web pages
                    operated or maintained by Rentivity and through which Rentivity provides a platform to showcase
                    properties, electronic pre-screening of potential renters, a rental bidding function, leasing
                    transactional platform and marketplace for hosting service providers and maintenance companies for use
                    by owners and/or property managers within a proprietary comprehensive cloud-based software and may
                    contain links to websites and services that are owned or operated by third parties, including renter
                    insurance services, credit and background screening services and payment processing (collectively
                      referred to as “Vendors”).</p>
                  <p>Rentivity provides open access to the Website however, certain components, functions, and elements of
                    the System pertain to those seeking to rent or have rented properties (collectively, “Renters”) from
                    owners or property managers that offer properties for rent utilizing the System. Owners, property
                    managers, Renters, and Vendors may collectively hereinafter be referred to as “Users”. The words
                    “include” and “including” herein when used at the beginning of a list of one or more items, indicates
                    the list contains examples – the list is not exclusive or exhaustive, and the items in the list are only
                    illustrations. They are not the only possible items that could appear in the list. Further, the words
                      “include” and "including" shall mean including without limitation.</p>
                  <p>The definition of a word or words in this Terms and Conditions shall not define the same word or words
                    in Rentivity’s ESIGN Consent or Privacy Policy, both of which have their own specific definitions and
                    are separate and distinct documents from this Terms and Conditions. Likewise, any word or words which
                    are not defined in this Terms and Conditions but are defined in Rentivity’s ESIGN Consent and/or Privacy
                    Policy shall not be so defined herein. Further, references to Rentivity’s ESIGN Consent and/or Privacy
                    Policy in this Terms and Conditions shall not be utilized to interpret any term or provision in either
                      the ESIGN Consent and/or the Privacy Policy.</p>
                  <p>Registration, Questionnaire and Application</p>
                  <p>All Users shall be required to register to use the System by providing their e-mail address and
                    password (“Registration”, “Registering” or “Register”). Renter by Registering provides the ability,
                      among other things, to create and save searches and receive notifications.</p>
                  <p>Any Renter seeking to become a participant in the System shall be required to complete a Renter
                    questionnaire (“Questionnaire”) and a TransUnion (defined below) application (“Application”) which will
                    require specific Renter information and information regarding Renter's qualifications and financial
                    information (which qualifications and financial information will be utilized by the third party credit
                    reporting company described in the next sentence). Renter acknowledges Rentivity within the System
                    utilizes the third party credit reporting company, TransUnion Rental Screening Solutions, Inc.
                    (“TransUnion”), to obtain and make available to an owner and/or property manager Renter’s credit
                    profile, background screening report and qualifications. Renter acknowledges Rentivity does not create,
                    modify, store or produce and is not responsible for the security and/or accuracy of Renter’s credit
                      profile, background screening report and/or qualifications.</p>
                  <p>Web Content and Materials</p>
                  <p>The information maintained on the Rentivity System is intended to provide Users with information about
                    properties and services offered by Users (other than Renters) utilizing the Rentivity System. The
                    information and material posted and presented on the Rentivity System is subject to change without
                    notice. The System is a platform that hosts the content supplied by Users. Not all of the properties or
                    services described on the Rentivity System are available in all geographic areas. No solicitation is
                    made by Rentivity to any person to use any information, materials, products or services in any
                    jurisdiction where the provision of such information, materials, products, and/or services is prohibited
                    by law. Rentivity disclaims any responsibility, ownership, or liability relating to the content,
                      properties, information, or anything whatsoever posted or presented to the System by Users.</p>
                  <p>Technological Issues</p>
                  <p>The Rentivity System may, from time to time, not operate as intended, at normal speed, with full
                    functionality, or at all. In Rentivity's sole discretion, the Rentivity System may cease to operate for
                    any amount of time. Doing so may limit or eliminate the functionality of the System and/or limit the use
                    of the System by Users. Any decision to cease the activity of the System is solely within Rentivity's
                    sole discretion and Rentivity shall have no liability or obligation to any User or any other party
                      resulting from such limiting or shutdown of the System.</p>
                  <p>No Unlawful or Prohibited Use</p>
                  <p>As a condition of User’s utilization of the Rentivity System, User represents and warrant to Rentivity
                    that they shall not use the Rentivity System for any purpose that is unlawful or prohibited by these
                    Terms and Conditions and/or any other applicable terms, conditions and notices. Any User participating
                    in the System shall do so in good faith such that any information input into the System shall be true
                    and correct. User shall not use the Rentivity System in any manner that could damage, disable,
                    overburden, compromise and/or impair the Rentivity System or interfere with Rentivity’s, or any other
                    User’s or party's use and enjoyment of the Rentivity System. User shall not obtain or attempt to obtain
                    any materials or information through any means not intentionally made available or provided for through
                    the Rentivity System. Rentivity reserves the right to terminate and/or remove any party, content,
                    property, or bar a User or prospective User from the System for acting in bad faith or contrary to these
                    Terms and Conditions, law, regulation, policy or otherwise, as determined in Rentivity's sole
                      discretion.</p>
                  <p>Contracts</p>
                  <p>Owners and/or property managers are required, upon listing of a property, to provide the lease document
                    that shall serve, upon execution by the required parties, as the binding instrument/contract between the
                    owner or property manager and the Renter (procured through owner’s and/or property managers use of the
                    System). Rentivity has not provided, offered, or participated in the preparation or drafting of any
                    lease or any revision, modification and/or amendment thereto. Renter shall be solely, fully responsible
                    for reviewing and confirming that Renter has read and accepted the terms of the lease, if Renter is the
                    successful bidder for the property or elects to utilize the “Rent it Now” button to rent the property
                    (which means the Renter agrees to pay the rental amount listed for the property). Upon submittal of a
                    bid or offer to rent the property utilizing "Rent it Now", Renter shall be deemed to have accepted the
                    lease terms. Once the System accepts a successful bid or a Renter has elected to rent the property
                    utilizing "Rent it Now", the lease form will be automatically populated with the foregoing party’s
                    Application information. Successful bidders or Renters electing to rent the property utilizing "Rent it
                    Now" shall have a defined period as set by an owner or property manager to electronically execute the
                    lease and submit all required payments and fees to the owner or property manager utilizing an automated
                    clearing house (“ACH”) payment service, third party payment processor provided by Rentivity or the owner
                    or property manager, check, debit card or credit card (which ever method(s) is/are required by the owner
                    or property manager). Listings posted by owners and/or property managers are not offers for contract
                    purposes. The bids submitted by Renters shall be deemed offers for contract purposes and shall be
                      subject only to final acceptance by owners and/or property managers.</p>
                  <p>User hereby releases and indemnifies Rentivity from
                    <br />(a) any claim, action, demand, charge, cost,
                    petition, or anything whatsoever that may result or arise from the relationship that may result between
                    Renters, customers, visitors, prospects, Vendors, owners, property managers, or any other person.
                    <br />
                    (b) any contract, lease, instrument, or other obligation generated by any such parties by, through or in
                    any way associated with the System, Rentivity.com and/or Rentivity. Each party to any contract, lease,
                      instrument, or other obligation shall be deemed to have had an opportunity for legal consultation.</p>
                  <p>Submissions</p>
                  <p>Rentivity does not claim ownership of the information and/or materials that Users provide, post,
                    upload, input, submit or showcase to Rentivity (including feedback and suggestions) (collectively,
                    "Submissions") within the System. However, by providing, posting, uploading, inputting, submitting or
                    showcasing User’s Submissions, User grants Rentivity (including without limitation its affiliates and
                    necessary sublicensees) permission and consent to use User’s Submissions in connection with the
                    operation of the System and to provide such Submissions (for the purposes intended by Rentivity) to its
                    Users, including the right to copy, distribute, post, publish, advertise, transmit, publicly display,
                    publicly perform, reproduce, edit, translate, sell, and reformat User’s Submissions. Please note that
                    specific provisions and requirements explained on the web page or pages describing a particular feature
                    or offer, may apply in lieu of this Submissions section. Any and all transactional data and records
                    relating to the transactions, rents, and/or performance of the properties by Renters shall belong to and
                    remain an asset of Rentivity including its right (and unfettered use) to create reports, publish,
                      monitor data, collect data, and/or sell data mine results.</p>
                  <p>Linked Site Statement</p>
                  <p>Links to websites other than the Rentivity System are provided solely as pointers and/or syndication to
                    information on topics that may be useful to Users of the Rentivity System and Rentivity has no control
                    over, and expressly disclaims any responsibility for the endorsement of, the content on or liability
                    arising from such websites. If User chooses to link to a website not controlled by Rentivity, Rentivity
                    makes no warranties, either express or implied, concerning the content of such site, including the
                    accuracy, completeness, reliability or suitability thereof for any particular purpose, nor does
                    Rentivity warrant that such website or content is free from any claims of copyright, trademark or other
                    infringement of the rights of third parties or that such site or content is devoid of viruses or other
                    contamination. Rentivity does not guarantee the authenticity of documents on the Internet. Links to
                    non-Rentivity systems do not imply, and Rentivity expressly disclaims, any endorsement of or
                    responsibility for the opinions, ideas, products, information, content or services offered at such
                    websites, or any representation regarding the content at such websites. Vendors or other providers for
                    products and/or services and links thereto on the Website are not affiliated with Rentivity and
                    Rentivity has no control relating to such Vendors’ or other providers’ services. Renter acknowledges
                    that such Vendors or other providers including insurance providers and other third parties may pay a
                      platform, technology and/or other fee for such link or subsequent transaction.</p>
                  <p>Advertising/Syndication</p>
                  <p>Renter acknowledges the System offers a showcase platform and bidding system programmed to connect
                    interested Renters to properties offered by owners and/or property managers. Rentivity will post
                    property information on the Website showcase pages and coordinate certain syndication to third party
                    platforms offering marketing, advertising, and/or showcase platforms on such third party websites.
                    Rentivity has no control or direction regarding the operation of or information on such third party
                    websites. Any indemnity, release, or acknowledgment provided by User herein, for the benefit of
                    Rentivity, shall benefit and inure to any third party website or platform to which Rentivity may
                      syndicate.</p>

                  <p>Professional Services</p>
                  <p>Rentivity is a platform made available to owners, property managers, Renters, prospective Renters, and
                    Vendors. Rentivity offers no services (including maintenance and/or repair services) other than the use
                    of the System and is not a real estate agent or broker or insurance company or insurance agency. Renter
                    acknowledges Rentivity is not providing real estate or insurance services. In limited circumstances,
                    Rentivity and/or its representatives may be agents for and/or coordinators of certain property
                    management services but Rentivity and/or its representatives remain as a facilitator and not the service
                    provider. User has the right and is encouraged to procure professional representation from legal counsel
                    and real estate professionals such as licensed agents/brokers in each state that such User shall be
                    interested in leasing properties. Renter, owner and/or property manager may be responsible for any
                    contract, agreement, or compensation due to such professionals. The Rentivity System is a showcase for
                    third parties and a fee is paid to Rentivity, based on its then applicable fee schedule, for utilization
                      of the System by each User.</p>
                  <p>User’s Account</p>
                  <p>If you use the Rentivity System, you are responsible for maintaining the confidentiality of your
                    account and any passwords you utilize. You must restrict access to your computer, your account, and your
                    passwords and agree to accept responsibility for all activities that occur related to and/or in
                    connection with your account and password. Any personal information submitted by a User for
                    determination of qualification for bidding and/or renting the properties presented by or to potential
                    Renters and/or their representatives shall be the User’s and User shall maintain it with appropriate
                    confidentiality. The assessment analysis shall be made through and by the System utilizing the data
                    supplied by User, and/or third party sources made available to User for credential validation, credit
                    reports and other reporting sources, which are third party sources made available to User for
                    self-certification or verification, independent of the System. The System will package and provide such
                    data as a pass through function, in a format designated by Rentivity, and shall only be available to
                    owners and/or property managers from whom Renter may rent property. Renters shall not be eligible to bid
                    on properties that do not meet the minimum Renter qualifications established by owners and/or property
                      managers.</p>
                  <p>Renter Certification</p>
                  <p>The System contains a Renter certification element as a functional component of the screening process
                    for prospective Renters. The owner and/or property manager enters its respective qualification criteria
                    with respect to each property it lists which the System displays. Renters can search for and view which
                    properties whose qualifications criteria they most likely meet and can submit bids to rent such
                      properties or “Rent it Now”.</p>
                  <p>Pursuant to the Questionnaire and Application, Renter is required to provide specific personal
                    information, financial data and other information and specifications which is only provided to an owner
                    and/or property manager if a Renter submits an acceptable highest bid on a property or utilizes “Rent it
                    Now” for a property. Owner and/or property manager utilize the foregoing information, financial data and
                    other information and specifications as a part of their determination whether Renter can become a tenant
                    for a particular property. Rentivity provides no service or participation in the provision of the
                    foregoing information, financial data and other information and specifications by Renter, as the
                    foregoing is the confidential and personal information of Renter provided by the individual applicant to
                      TransUnion’s third party certification database.</p>
                  <p>An element of the Renter certification shall include credit rating and tenant screening background
                    verification through TransUnion, a third party. Rentivity provides Renter a link to TransUnion and
                    Renter’s utilization of such link authorizes the provision of a “Tenant Screening Report” consisting of,
                    among other things, a credit report, background screening report, Renter’s qualifications and/or
                    certification which TransUnion independently and confidentially provides (after Renter submits an
                    acceptable highest bid on a property or utilizes “Rent it Now”) to owner and/or property manager for
                    inclusion and assessment by owner and/or property manager in qualifying Renter for a particular
                      property.</p>
                  <p>Owner and/or property manager shall set the Renter financial and credit requirements for each property
                    and establish its selected conditions, verification, and determination parameters. Any required
                    additional or specific Renter qualification, verification and/or inquiry by owner and/or property
                    manager is to be specifically set forth in the corresponding property advertising page and Renter
                    acknowledges such conditions and procedures prior to submitting a bid or utilizing “Rent it Now”. Owners
                    and/or property managers may request additional information from Renter during the review process and
                      retain the right to approve or deny, at their sole discretion, Renter as a tenant for the property.</p>
                  <p>Renter, owner and property manager release and discharge Rentivity from any claim, action, demand,
                    charge, cost, or anything whatsoever relating to the information on any Renter Questionnaire,
                    Application and/or Tenant Screening Report including breach or misuse of confidential financial
                      information of any Renter, owner and/or property manager.</p>
                  <p>Renter acknowledges and agrees its Questionnaire, Application and/or qualifications shall apply to any
                    and all properties and there is no requirement for a separate paid Application for each property.
                    However, Renter also acknowledges and agrees there is a limitation of only sharing the Application with
                    up to five properties per day for up to thirty days from the date of the Application, for a cumulative
                    maximum of 150 properties within a thirty day period. After 150 properties, Renter shall pay Rentivity a
                    fee for having TransUnion provide an updated Tenant Screening Report. Further, the owners and/or
                    property manager may require additional information or specific information prior to acceptance or
                      denial of a lease (with the acceptance or denial in their sole discretion).</p>
                  <p>Applicable Law</p>
                  <p>By using the Rentivity System, you agree that applicable federal law and the laws of the State of Florida,
                  without regard to Florida’s principles of conflict of laws, shall govern these Terms and Conditions and
                  any dispute of any sort that might arise between User and Rentivity. User agrees venue for any disputes
                  shall be located exclusively in Orange County, Florida and expressly waives any right to claim that such
                    forum is inconvenient.</p>
                  <p>Non-Waiver</p>
                  <p>Rentivity's failure to exercise or enforce any provision or right set forth in these Terms and Conditions
                    or any other terms and conditions does not constitute a waiver of that right or provision.</p>
                  <p>Assignment</p>
                  <p>Rentivity may assign these Terms and Conditions, in whole or in part, at any time with or without notice
                  to User. User is prohibited from assigning these Terms and Conditions without the prior written consent of
                    Rentivity, which may be withheld in its sole discretion.</p>
                  <p>No Warranty</p>
                  <p>The information and materials contained in the Rentivity System including all profiles, text, graphics,
                  links, photos or other items are provided "as is." Rentivity does not warrant the accuracy, adequacy or
                  completeness of this System, its information and/or materials and expressly disclaims liability for errors
                  or omissions in its System, information and/or materials. No warranty of any kind, implied, express or
                  statutory including the warranties of merchantability, title, non-infringement of intellectual property,
                  fitness for any particular purpose and freedom from computer virus, is given in conjunction with this
                    System, its information and/or materials.</p>
                  <p>Limitation of Liability</p>
                  <p>In no event shall Rentivity be liable for any direct, indirect, punitive, incidental, special and/or
                  consequential damages, losses and/or expenses (whether based in contract, tort, strict liability and/or
                  otherwise) to the User and/or any third party, arising in connection with the Website or use thereof or
                  the inability to use the Website by any party, or in connection with any failure of performance, error,
                  omission, interruption, defect, delay in operation or transmission, computer virus or line or system
                  failure, even if Rentivity and/or its representatives, are advised of the possibility of such damages,
                    losses or expenses.</p>
                  <p>Intellectual Property</p>
                  <p>Rentivity owns certain trademarks, trade names, trade secrets, copyright, data, information, facts, and
                  other intellectual property associated with the System, the properties and the transactions generated and
                  performed as a consequence of the System (collectively, "Intellectual Property"). User acknowledges this
                  ownership and agrees not to use, infringe, or contest such rights. User may not refer to, use, print,
                  publish, reference, or post Rentivity Intellectual Property in any way, without the prior written consent
                  of Rentivity. User agrees to comply with these Terms and Conditions, any other terms and conditions,
                  requirements, and rules promulgated by Rentivity and any applicable federal and/or state laws which relate
                    to the Intellectual Property.</p>
                  <p>ESIGN Consent</p>
                  <p>User acknowledges having read and agreed to the terms of the ESIGN Consent contained within the System.</p>
                  <p><strong>Privacy Policy</strong></p>
                  <p>User acknowledges having read and agreed to the terms of the Privacy Policy contained within the System.</p>
                  <p>Social Media or Third Party Websites</p>
                  <p>If the System offers a tool or service which User decides to utilize thereby allowing the System to access
                  or use any profile or other information about User that User has provided to Facebook or another third
                  party website (each a "Social Media Site"), User acknowledges and agrees:
                    <br />(a) The information or content that is a part of User's Social Media Site profile (which User has
                    designated as "public" or a similar designation) may be accessed and used by Rentivity in connection with
                    the System (with such information or content referred to herein as "Social Media Content").
                    <br />(b) The Social Media Content shall be considered content generated by User under these Terms and
                    Conditions and, except as set forth in (c) and (d) below, User and Rentivity shall have the same rights
                    and responsibilities with respect to this content as User and Rentivity have with respect to other user
                    generated content under these Terms and Conditions.
                    <br />(c) Notwithstanding anything to the contrary in these Terms and Conditions, in the event the Social Media
                    Content is for any reason misclassified with a public or similar designation, is otherwise inaccurate or
                    is such that User does not agree with any or all of the Social Media Content for any reason, User agrees
                    to work with the Social Media Site to make any changes or resolve any disputes and acknowledges Rentivity
                    shall not be liable to User for any type of damages or required to provide User with any recourse.
                    <br />(d) The operation of User's profile and account with and/or on the Social Media Site shall continue to be
                    governed by the terms and conditions, privacy policy and/or any other requirements, rules and/or
                    provisions of such Social Media Site.</p>
                  <p>Fees</p>
                  <p>User acknowledges Rentivity charges fees to User in association with use of the System. Additionally,
                  owners and/or property managers may charge individual and/or additional deposits and fees, which are
                  established by those owners and/or property managers and collected through System. Deposits and fees (as
                  well as fees payable to Rentivity) may include the following: Application fees, pet fees, pet deposits,
                  pet violation fees, security deposits, first and/or last month’s rent deposits, good faith deposits,
                  binder fees, administrative fees, payment processing fees, work order processing fees, early termination
                  fees, move out inspection fees, non-sufficient funds fees, transaction fees and other fees related to
                  inspections, trip charges, lost items such as remote controls and keys, leasing, lease renewals, and
                  management. Additionally, Renter may pay its rent, deposits, fees and/or other amounts through the System.
                    Stripe</p>
                  <p>Payment processing services for Rentivity on the System are provided by Stripe and are subject to the
                  Stripe Connected Account Agreement, which includes the Stripe Terms of Service (collectively, the “Stripe
                  Services Agreement”). By agreeing to these Terms and Conditions or continuing to operate as a User on the
                  System, User agrees to be bound by the Stripe Services Agreement, as the same may be modified by Stripe
                  from time to time. As a condition of Rentivity enabling payment processing services through Stripe, User
                  agrees to provide Rentivity accurate and complete information about User, and User authorizes Rentivity to
                  share it and transaction information related to your use of the payment processing services provided by
                    Stripe.</p>
                  <p>Agreeing to Terms and Conditions</p>
                  <p>By clicking “I AGREE”, User acknowledges having read and agreed to these Terms and Conditions.
                    As of February 23, 2019</p>
                </div>
              )
            }

          </BottomScrollListener>
          {this.state.disableAccept && <i className='fa fa-arrow-circle-down down-arrow' />}
        </ModalBody>
        <ModalFooter>
          <form className="float-right mb-0 form-check-label form-inline">
            <button
              type="button"
              className="btn btn-link"
              onClick={this.props.onLogout}
            >
              Sign me out
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={this.props.onSubmit}
              disabled={this.state.disableAccept}
              style={{ minWidth: '100px' }}
            >
              I Agree
            </button>
          </form>
        </ModalFooter>
      </Modal>
    );
  }
}

export default TermsAndConditions;

import React from 'react';


const TermsAndConditionsPage = () => {
  return (
    <div className='container mt-5'>
      <h1 className='text-center'>TERMS AND CONDITIONS</h1>
      <p>NOTICE: PLEASE READ THESE TERMS AND CONDITIONS (the “Terms and Conditions”) CAREFULLY. BY ACCESSING
        THIS WEBSITE AND REGISTERING, YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS PROVIDED HEREIN. IF
        YOU DO NOT AGREE TO THE TERMS AND CONDITIONS BELOW, YOU MAY NOT REGISTER WITH RENTIVITY.COM AND ARE NOT
        AUTHORIZED TO ACCESS CERTAIN COMPONENTS OF THIS WEBSITE.</p>
      <p>We welcome you to the Rentivity.com website ("Website") for Rental Technologies LLC d/b/a Rentivity and
        each and every current and future affiliate of Rentivity (hereinafter, such entities are collectively
        referred to as "Rentivity") and the Website, rental home marketing, bidding and management system (are
        collectively referred to as the "System"). The Rentivity System includes various websites and web pages
        operated or maintained by Rentivity and through which Rentivity provides a platform to showcase
        properties, electronic pre-screening of potential renters, a rental bidding function, leasing
        transactional platform and marketplace for hosting service providers and maintenance companies for use
        by owners and/or property managers within a proprietary comprehensive cloud-based software and may
        contain links to websites and services that are owned or operated by third parties, including renter
        insurance services, credit and background screening services and payment processing (collectively
        referred to as “Vendors”).</p>
      <p>Rentivity provides open access to the Website however, certain components, functions, and elements of
        the System pertain to those seeking to rent or have rented properties (collectively, “Renters”) from
        owners or property managers that offer properties for rent utilizing the System. Owners, property
        managers, Renters, and Vendors may collectively hereinafter be referred to as “Users”. The words
        “include” and “including” herein when used at the beginning of a list of one or more items, indicates
        the list contains examples – the list is not exclusive or exhaustive, and the items in the list are only
        illustrations. They are not the only possible items that could appear in the list. Further, the words
        “include” and "including" shall mean including without limitation.</p>
      <p>The definition of a word or words in this Terms and Conditions shall not define the same word or words
        in Rentivity’s ESIGN Consent or Privacy Policy, both of which have their own specific definitions and
        are separate and distinct documents from this Terms and Conditions. Likewise, any word or words which
        are not defined in this Terms and Conditions but are defined in Rentivity’s ESIGN Consent and/or Privacy
        Policy shall not be so defined herein. Further, references to Rentivity’s ESIGN Consent and/or Privacy
        Policy in this Terms and Conditions shall not be utilized to interpret any term or provision in either
        the ESIGN Consent and/or the Privacy Policy.</p>
      <p>Registration, Questionnaire and Application</p>
      <p>All Users shall be required to register to use the System by providing their e-mail address and
        password (“Registration”, “Registering” or “Register”). Renter by Registering provides the ability,
        among other things, to create and save searches and receive notifications.</p>
      <p>Any Renter seeking to become a participant in the System shall be required to complete a Renter
        questionnaire (“Questionnaire”) and a TransUnion (defined below) application (“Application”) which will
        require specific Renter information and information regarding Renter's qualifications and financial
        information (which qualifications and financial information will be utilized by the third party credit
        reporting company described in the next sentence). Renter acknowledges Rentivity within the System
        utilizes the third party credit reporting company, TransUnion Rental Screening Solutions, Inc.
        (“TransUnion”), to obtain and make available to an owner and/or property manager Renter’s credit
        profile, background screening report and qualifications. Renter acknowledges Rentivity does not create,
        modify, store or produce and is not responsible for the security and/or accuracy of Renter’s credit
        profile, background screening report and/or qualifications.</p>
      <p>Web Content and Materials</p>
      <p>The information maintained on the Rentivity System is intended to provide Users with information about
        properties and services offered by Users (other than Renters) utilizing the Rentivity System. The
        information and material posted and presented on the Rentivity System is subject to change without
        notice. The System is a platform that hosts the content supplied by Users. Not all of the properties or
        services described on the Rentivity System are available in all geographic areas. No solicitation is
        made by Rentivity to any person to use any information, materials, products or services in any
        jurisdiction where the provision of such information, materials, products, and/or services is prohibited
        by law. Rentivity disclaims any responsibility, ownership, or liability relating to the content,
        properties, information, or anything whatsoever posted or presented to the System by Users.</p>
      <p>Technological Issues</p>
      <p>The Rentivity System may, from time to time, not operate as intended, at normal speed, with full
        functionality, or at all. In Rentivity's sole discretion, the Rentivity System may cease to operate for
        any amount of time. Doing so may limit or eliminate the functionality of the System and/or limit the use
        of the System by Users. Any decision to cease the activity of the System is solely within Rentivity's
        sole discretion and Rentivity shall have no liability or obligation to any User or any other party
        resulting from such limiting or shutdown of the System.</p>
      <p>No Unlawful or Prohibited Use</p>
      <p>As a condition of User’s utilization of the Rentivity System, User represents and warrant to Rentivity
        that they shall not use the Rentivity System for any purpose that is unlawful or prohibited by these
        Terms and Conditions and/or any other applicable terms, conditions and notices. Any User participating
        in the System shall do so in good faith such that any information input into the System shall be true
        and correct. User shall not use the Rentivity System in any manner that could damage, disable,
        overburden, compromise and/or impair the Rentivity System or interfere with Rentivity’s, or any other
        User’s or party's use and enjoyment of the Rentivity System. User shall not obtain or attempt to obtain
        any materials or information through any means not intentionally made available or provided for through
        the Rentivity System. Rentivity reserves the right to terminate and/or remove any party, content,
        property, or bar a User or prospective User from the System for acting in bad faith or contrary to these
        Terms and Conditions, law, regulation, policy or otherwise, as determined in Rentivity's sole
        discretion.</p>
      <p>Contracts</p>
      <p>Owners and/or property managers are required, upon listing of a property, to provide the lease document
        that shall serve, upon execution by the required parties, as the binding instrument/contract between the
        owner or property manager and the Renter (procured through owner’s and/or property managers use of the
        System). Rentivity has not provided, offered, or participated in the preparation or drafting of any
        lease or any revision, modification and/or amendment thereto. Renter shall be solely, fully responsible
        for reviewing and confirming that Renter has read and accepted the terms of the lease, if Renter is the
        successful bidder for the property or elects to utilize the “Rent it Now” button to rent the property
        (which means the Renter agrees to pay the rental amount listed for the property). Upon submittal of a
        bid or offer to rent the property utilizing "Rent it Now", Renter shall be deemed to have accepted the
        lease terms. Once the System accepts a successful bid or a Renter has elected to rent the property
        utilizing "Rent it Now", the lease form will be automatically populated with the foregoing party’s
        Application information. Successful bidders or Renters electing to rent the property utilizing "Rent it
        Now" shall have a defined period as set by an owner or property manager to electronically execute the
        lease and submit all required payments and fees to the owner or property manager utilizing an automated
        clearing house (“ACH”) payment service, third party payment processor provided by Rentivity or the owner
        or property manager, check, debit card or credit card (which ever method(s) is/are required by the owner
        or property manager). Listings posted by owners and/or property managers are not offers for contract
        purposes. The bids submitted by Renters shall be deemed offers for contract purposes and shall be
        subject only to final acceptance by owners and/or property managers.</p>
      <p>User hereby releases and indemnifies Rentivity from
        <br />(a) any claim, action, demand, charge, cost,
        petition, or anything whatsoever that may result or arise from the relationship that may result between
        Renters, customers, visitors, prospects, Vendors, owners, property managers, or any other person.
        <br />(b) any contract, lease, instrument, or other obligation generated by any such parties by, through or in
        any way associated with the System, Rentivity.com and/or Rentivity. Each party to any contract, lease,
        instrument, or other obligation shall be deemed to have had an opportunity for legal consultation.</p>
      <p>Submissions</p>
      <p>Rentivity does not claim ownership of the information and/or materials that Users provide, post,
        upload, input, submit or showcase to Rentivity (including feedback and suggestions) (collectively,
        "Submissions") within the System. However, by providing, posting, uploading, inputting, submitting or
        showcasing User’s Submissions, User grants Rentivity (including without limitation its affiliates and
        necessary sublicensees) permission and consent to use User’s Submissions in connection with the
        operation of the System and to provide such Submissions (for the purposes intended by Rentivity) to its
        Users, including the right to copy, distribute, post, publish, advertise, transmit, publicly display,
        publicly perform, reproduce, edit, translate, sell, and reformat User’s Submissions. Please note that
        specific provisions and requirements explained on the web page or pages describing a particular feature
        or offer, may apply in lieu of this Submissions section. Any and all transactional data and records
        relating to the transactions, rents, and/or performance of the properties by Renters shall belong to and
        remain an asset of Rentivity including its right (and unfettered use) to create reports, publish,
        monitor data, collect data, and/or sell data mine results.</p>
      <p>Linked Site Statement</p>
      <p>Links to websites other than the Rentivity System are provided solely as pointers and/or syndication to
        information on topics that may be useful to Users of the Rentivity System and Rentivity has no control
        over, and expressly disclaims any responsibility for the endorsement of, the content on or liability
        arising from such websites. If User chooses to link to a website not controlled by Rentivity, Rentivity
        makes no warranties, either express or implied, concerning the content of such site, including the
        accuracy, completeness, reliability or suitability thereof for any particular purpose, nor does
        Rentivity warrant that such website or content is free from any claims of copyright, trademark or other
        infringement of the rights of third parties or that such site or content is devoid of viruses or other
        contamination. Rentivity does not guarantee the authenticity of documents on the Internet. Links to
        non-Rentivity systems do not imply, and Rentivity expressly disclaims, any endorsement of or
        responsibility for the opinions, ideas, products, information, content or services offered at such
        websites, or any representation regarding the content at such websites. Vendors or other providers for
        products and/or services and links thereto on the Website are not affiliated with Rentivity and
        Rentivity has no control relating to such Vendors’ or other providers’ services. Renter acknowledges
        that such Vendors or other providers including insurance providers and other third parties may pay a
        platform, technology and/or other fee for such link or subsequent transaction.</p>
      <p>Advertising/Syndication</p>
      <p>Renter acknowledges the System offers a showcase platform and bidding system programmed to connect
        interested Renters to properties offered by owners and/or property managers. Rentivity will post
        property information on the Website showcase pages and coordinate certain syndication to third party
        platforms offering marketing, advertising, and/or showcase platforms on such third party websites.
        Rentivity has no control or direction regarding the operation of or information on such third party
        websites. Any indemnity, release, or acknowledgment provided by User herein, for the benefit of
        Rentivity, shall benefit and inure to any third party website or platform to which Rentivity may
        syndicate.</p>

      <p>Professional Services</p>
      <p>Rentivity is a platform made available to owners, property managers, Renters, prospective Renters, and
        Vendors. Rentivity offers no services (including maintenance and/or repair services) other than the use
        of the System and is not a real estate agent or broker or insurance company or insurance agency. Renter
        acknowledges Rentivity is not providing real estate or insurance services. In limited circumstances,
        Rentivity and/or its representatives may be agents for and/or coordinators of certain property
        management services but Rentivity and/or its representatives remain as a facilitator and not the service
        provider. User has the right and is encouraged to procure professional representation from legal counsel
        and real estate professionals such as licensed agents/brokers in each state that such User shall be
        interested in leasing properties. Renter, owner and/or property manager may be responsible for any
        contract, agreement, or compensation due to such professionals. The Rentivity System is a showcase for
        third parties and a fee is paid to Rentivity, based on its then applicable fee schedule, for utilization
        of the System by each User.</p>
      <p>User’s Account</p>
      <p>If you use the Rentivity System, you are responsible for maintaining the confidentiality of your
        account and any passwords you utilize. You must restrict access to your computer, your account, and your
        passwords and agree to accept responsibility for all activities that occur related to and/or in
        connection with your account and password. Any personal information submitted by a User for
        determination of qualification for bidding and/or renting the properties presented by or to potential
        Renters and/or their representatives shall be the User’s and User shall maintain it with appropriate
        confidentiality. The assessment analysis shall be made through and by the System utilizing the data
        supplied by User, and/or third party sources made available to User for credential validation, credit
        reports and other reporting sources, which are third party sources made available to User for
        self-certification or verification, independent of the System. The System will package and provide such
        data as a pass through function, in a format designated by Rentivity, and shall only be available to
        owners and/or property managers from whom Renter may rent property. Renters shall not be eligible to bid
        on properties that do not meet the minimum Renter qualifications established by owners and/or property
        managers.</p>
      <p>Renter Certification</p>
      <p>The System contains a Renter certification element as a functional component of the screening process
        for prospective Renters. The owner and/or property manager enters its respective qualification criteria
        with respect to each property it lists which the System displays. Renters can search for and view which
        properties whose qualifications criteria they most likely meet and can submit bids to rent such
        properties or “Rent it Now”.</p>
      <p>Pursuant to the Questionnaire and Application, Renter is required to provide specific personal
        information, financial data and other information and specifications which is only provided to an owner
        and/or property manager if a Renter submits an acceptable highest bid on a property or utilizes “Rent it
        Now” for a property. Owner and/or property manager utilize the foregoing information, financial data and
        other information and specifications as a part of their determination whether Renter can become a tenant
        for a particular property. Rentivity provides no service or participation in the provision of the
        foregoing information, financial data and other information and specifications by Renter, as the
        foregoing is the confidential and personal information of Renter provided by the individual applicant to
        TransUnion’s third party certification database.</p>
      <p>An element of the Renter certification shall include credit rating and tenant screening background
        verification through TransUnion, a third party. Rentivity provides Renter a link to TransUnion and
        Renter’s utilization of such link authorizes the provision of a “Tenant Screening Report” consisting of,
        among other things, a credit report, background screening report, Renter’s qualifications and/or
        certification which TransUnion independently and confidentially provides (after Renter submits an
        acceptable highest bid on a property or utilizes “Rent it Now”) to owner and/or property manager for
        inclusion and assessment by owner and/or property manager in qualifying Renter for a particular
        property.</p>
      <p>Owner and/or property manager shall set the Renter financial and credit requirements for each property
        and establish its selected conditions, verification, and determination parameters. Any required
        additional or specific Renter qualification, verification and/or inquiry by owner and/or property
        manager is to be specifically set forth in the corresponding property advertising page and Renter
        acknowledges such conditions and procedures prior to submitting a bid or utilizing “Rent it Now”. Owners
        and/or property managers may request additional information from Renter during the review process and
        retain the right to approve or deny, at their sole discretion, Renter as a tenant for the property.</p>
      <p>Renter, owner and property manager release and discharge Rentivity from any claim, action, demand,
        charge, cost, or anything whatsoever relating to the information on any Renter Questionnaire,
        Application and/or Tenant Screening Report including breach or misuse of confidential financial
        information of any Renter, owner and/or property manager.</p>
      <p>Renter acknowledges and agrees its Questionnaire, Application and/or qualifications shall apply to any
        and all properties and there is no requirement for a separate paid Application for each property.
        However, Renter also acknowledges and agrees there is a limitation of only sharing the Application with
        up to five properties per day for up to thirty days from the date of the Application, for a cumulative
        maximum of 150 properties within a thirty day period. After 150 properties, Renter shall pay Rentivity a
        fee for having TransUnion provide an updated Tenant Screening Report. Further, the owners and/or
        property manager may require additional information or specific information prior to acceptance or
        denial of a lease (with the acceptance or denial in their sole discretion).</p>
      <p>Applicable Law</p>
      <p>By using the Rentivity System, you agree that applicable federal law and the laws of the State of Florida,
        without regard to Florida’s principles of conflict of laws, shall govern these Terms and Conditions and
        any dispute of any sort that might arise between User and Rentivity. User agrees venue for any disputes
        shall be located exclusively in Orange County, Florida and expressly waives any right to claim that such
        forum is inconvenient.</p>
      <p>Non-Waiver</p>
      <p>Rentivity's failure to exercise or enforce any provision or right set forth in these Terms and Conditions
        or any other terms and conditions does not constitute a waiver of that right or provision.</p>
      <p>Assignment</p>
      <p>Rentivity may assign these Terms and Conditions, in whole or in part, at any time with or without notice
        to User. User is prohibited from assigning these Terms and Conditions without the prior written consent of
        Rentivity, which may be withheld in its sole discretion.</p>
      <p>No Warranty</p>
      <p>The information and materials contained in the Rentivity System including all profiles, text, graphics,
        links, photos or other items are provided "as is." Rentivity does not warrant the accuracy, adequacy or
        completeness of this System, its information and/or materials and expressly disclaims liability for errors
        or omissions in its System, information and/or materials. No warranty of any kind, implied, express or
        statutory including the warranties of merchantability, title, non-infringement of intellectual property,
        fitness for any particular purpose and freedom from computer virus, is given in conjunction with this
        System, its information and/or materials.</p>
      <p>Limitation of Liability</p>
      <p>In no event shall Rentivity be liable for any direct, indirect, punitive, incidental, special and/or
        consequential damages, losses and/or expenses (whether based in contract, tort, strict liability and/or
        otherwise) to the User and/or any third party, arising in connection with the Website or use thereof or
        the inability to use the Website by any party, or in connection with any failure of performance, error,
        omission, interruption, defect, delay in operation or transmission, computer virus or line or system
        failure, even if Rentivity and/or its representatives, are advised of the possibility of such damages,
        losses or expenses.</p>
      <p>Intellectual Property</p>
      <p>Rentivity owns certain trademarks, trade names, trade secrets, copyright, data, information, facts, and
        other intellectual property associated with the System, the properties and the transactions generated and
        performed as a consequence of the System (collectively, "Intellectual Property"). User acknowledges this
        ownership and agrees not to use, infringe, or contest such rights. User may not refer to, use, print,
        publish, reference, or post Rentivity Intellectual Property in any way, without the prior written consent
        of Rentivity. User agrees to comply with these Terms and Conditions, any other terms and conditions,
        requirements, and rules promulgated by Rentivity and any applicable federal and/or state laws which relate
        to the Intellectual Property.</p>
      <p>ESIGN Consent</p>
      <p>User acknowledges having read and agreed to the terms of the ESIGN Consent contained within the System.</p>
      <p><strong>Privacy Policy</strong></p>
      <p>User acknowledges having read and agreed to the terms of the Privacy Policy contained within the System.</p>
      <p>Social Media or Third Party Websites</p>
      <p>If the System offers a tool or service which User decides to utilize thereby allowing the System to access
        or use any profile or other information about User that User has provided to Facebook or another third
        party website (each a "Social Media Site"), User acknowledges and agrees:
        <br />(a) The information or content that is a part of User's Social Media Site profile (which User has
        designated as "public" or a similar designation) may be accessed and used by Rentivity in connection with
        the System (with such information or content referred to herein as "Social Media Content").
        <br />(b) The Social Media Content shall be considered content generated by User under these Terms and
        Conditions and, except as set forth in (c) and (d) below, User and Rentivity shall have the same rights
        and responsibilities with respect to this content as User and Rentivity have with respect to other user
        generated content under these Terms and Conditions;
        <br />(c) Notwithstanding anything to the contrary in these Terms and Conditions, in the event the Social Media
        Content is for any reason misclassified with a public or similar designation, is otherwise inaccurate or
        is such that User does not agree with any or all of the Social Media Content for any reason, User agrees
        to work with the Social Media Site to make any changes or resolve any disputes and acknowledges Rentivity
        shall not be liable to User for any type of damages or required to provide User with any recourse.
        <br />(d) The operation of User's profile and account with and/or on the Social Media Site shall continue to be
        governed by the terms and conditions, privacy policy and/or any other requirements, rules and/or
        provisions of such Social Media Site.</p>
      <p>Fees</p>
      <p>User acknowledges Rentivity charges fees to User in association with use of the System. Additionally,
        owners and/or property managers may charge individual and/or additional deposits and fees, which are
        established by those owners and/or property managers and collected through System. Deposits and fees (as
        well as fees payable to Rentivity) may include the following: Application fees, pet fees, pet deposits,
        pet violation fees, security deposits, first and/or last month’s rent deposits, good faith deposits,
        binder fees, administrative fees, payment processing fees, work order processing fees, early termination
        fees, move out inspection fees, non-sufficient funds fees, transaction fees and other fees related to
        inspections, trip charges, lost items such as remote controls and keys, leasing, lease renewals, and
        management. Additionally, Renter may pay its rent, deposits, fees and/or other amounts through the System.
        Stripe</p>
      <p>Payment processing services for Rentivity on the System are provided by Stripe and are subject to the
        Stripe Connected Account Agreement, which includes the Stripe Terms of Service (collectively, the “Stripe
        Services Agreement”). By agreeing to these Terms and Conditions or continuing to operate as a User on the
        System, User agrees to be bound by the Stripe Services Agreement, as the same may be modified by Stripe
        from time to time. As a condition of Rentivity enabling payment processing services through Stripe, User
        agrees to provide Rentivity accurate and complete information about User, and User authorizes Rentivity to
        share it and transaction information related to your use of the payment processing services provided by
        Stripe.</p>
      <p>Agreeing to Terms and Conditions</p>
      <p>By clicking “I AGREE”, User acknowledges having read and agreed to these Terms and Conditions.
        As of February 23, 2019</p>
    </div>
  );
}

export default TermsAndConditionsPage;

import createSelector from 'selectorator';

export default createSelector({
  sessionPending: 'global.pending',
  sessionSuccess: 'global.success',
  isAuthenticated: 'global.isAuthenticated',
  session: 'global.session',
  user: 'global.login.user',
  loginError: 'login.error',
  loginPending: 'login.pending',
  loginSuccess: 'login.success',
});
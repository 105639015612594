import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from '../../../components/ProtectedRoute';
import CommonRegistration from './CommonRegistration';
import EmailVerification from './EmailVerification';
import PMRegistrationSteps from '../../Admin/PMRegistration';
import SetPasswordPage from './SetPassword';

import './style.scss';

export default (/* props */) => (
  <Switch>
    <Route
      path='/register'
      component={CommonRegistration}
      exact
    />
    <Route
      path='/register/:token/verify'
      component={EmailVerification}
      exact
    />
    <Route
      path='/register/:token/set-password'
      exact
      component={SetPasswordPage}
    />
    <ProtectedRoute
      path='/register/:token/steps'
      component={PMRegistrationSteps}
      exact
    />
  </Switch>
);

// /**
//  * Gets the repositories of the user from Github
//  */
import axios, { CancelToken } from 'axios'
import { fork, spawn, call, put, select, takeLatest, cancelled } from 'redux-saga/effects';
import qs from 'qs';
import _ from 'lodash';
import { applyFilters } from './utils';
import { mongoGeoToBounds, boundsToMongo } from '../utils';
const debug = require('debug')('property-search:sagas');




axios.defaults.paramsSerializer = (params) => qs.stringify(params);

export function request(config = {}) {
  return call(function* () {
    const source = CancelToken.source();
    try {
      return yield call(axios.request, { cancelToken: source.token, ...config });
    } finally {
      if (yield cancelled()) {
        source.cancel();
      }
    }
  });
}





function* watchSearchPropertySessions(arg) {
  try {
    const { payload: { query } } = arg;
    const { data: propertySessions } = yield request({
      method: 'GET',
      url: '/api/rentivity/property-session/search',
      params: {
        query,
        select: {}
      }
    });
    yield put({ type: 'maps/searchPropertySessionsSuccess', payload: propertySessions });
    // yield put({ type: 'maps/setBounds', payload: query.bounds });
  } catch (err) {
    yield put({ type: 'maps/searchPropertySessionsError', error: err });
  }
}

function* watchSetBounds(action) {
  const log = debug.extend('watchSetBounds');
  try {
    const state = yield select((state) => state.maps);

    log('curr bounds', state.bounds);
    const cBounds = (state.bounds[0][0] === 0) ? null : mongoGeoToBounds(state.bounds);

    if (!cBounds) {
      log('no cbounds')
      yield put({ type: 'maps/setBoundsPending', payload: action.payload });
    }

    else {
      const bounds = mongoGeoToBounds(action.payload);
      cBounds.union(bounds);

      const newBounds = boundsToMongo(cBounds);

      log('newbounds', newBounds);
      log('mapState', state);


      if (_.difference(state.bounds[0], newBounds[0]).length > 0 || _.difference(state.bounds[1], newBounds[1]).length > 0) {
        yield put({ type: 'maps/setBoundsPending', payload: newBounds });
      }
    }

  } catch (err) {

  }
}

function* watchSetBoundsPending(arg) {
  try {
    yield put({ type: 'maps/searchPropertySessionsPending', payload: {} });
    const { payload: bounds } = arg;
    const { data: propertySessions } = yield request({
      method: 'GET',
      url: '/api/rentivity/property-session/search',
      params: {
        query: { bounds },
        select: {
        }
      }
    });

    const searchFilter = yield select((state) => state.maps.searchFilter);
    const toHide = yield call(applyFilters, searchFilter, propertySessions);
    yield put({ type: 'maps/setSearchFilterSuccess', payload: toHide });
    yield put({ type: 'maps/setBoundsSuccess', payload: bounds });
    yield put({ type: 'maps/searchPropertySessionsSuccess', payload: propertySessions });
  } catch (err) {
    yield put({ type: 'maps/searchPropertySessionsError', error: err });
  }
}

function* watchSetSearchFilter(action) {
  try {
    yield put({ type: 'maps/setSearchFilterPending', payload: {} });

    const state = yield select((state) => state.maps);
    const toHide = yield call(applyFilters, action.payload, state.markers);


    yield put({ type: 'maps/setSearchFilterSuccess', payload: toHide });
  } catch (err) {
    yield put({ type: 'maps/setSearchFilterError', payload: err });
  }
}

const defFilters = { searchText: '', available: -1, beds: -1, baths: -1, floors: -1, creditScore: -1, propertyType: [], amenities: [], pets: -1, lotSize: [200], price: -1, eviction: -1, bankruptcy: -1, customPrice: -1, minimumResidentScore: -1, zoom: 10 };

function* watchResetSearchFilter(action) {
  try {
    yield put({ type: 'maps/setSearchFilterPending', payload: {} });
    const state = yield select((state) => state.maps);
    const toHide = yield call(applyFilters, defFilters, state.markers);
    yield put({ type: 'maps/setSearchFilterSuccess', payload: toHide });
  } catch (err) {
    yield put({ type: 'maps/setSearchFilterError', payload: err });
  }
}

function* watchSaveSearchFilter(action) {
  try {
    yield put({ type: 'maps/saveSearchFilterPending', payload: {} });
    const currSearchFilter = yield select((state) => state.maps.searchFilter);
    // const currBounds = yield select((state) => state.maps.bounds);
    const currSavedFilters = yield select((state) => state.maps.savedSearchFilters);
    console.log('currSaved', currSearchFilter);
    const { data: searchFilter } = yield request({
      method: 'POST',
      url: '/api/rentivity/search-filter',
      data: _.omit({
        ...currSearchFilter,
        ...action.payload,
      }, ['_id', 'id', '__v'])
    });

    yield put({ type: 'maps/saveSearchFilterSuccess', payload: _.uniqBy([searchFilter, ...currSavedFilters], '_id') });
    yield put({ type: 'maps/setSearchFilter', payload: searchFilter });
  } catch (err) {
    yield put({ type: 'maps/saveSearchFilterError', payload: err });
  }
}
function* watchDeleteSearchFilter(action) {
  try {
    yield put({ type: 'maps/deleteSearchFilterPending', payload: {} });
    const currSavedFilters = yield select((state) => state.maps.savedSearchFilters);
    const { data: searchFilter } = yield request({
      method: 'DELETE',
      url: `/api/rentivity/search-filter/${action.payload}`,
    });
    yield put({ type: 'maps/deleteSearchFilterSuccess', payload: _.filter(currSavedFilters, (el) => el._id !== action.payload) });
  } catch (err) {
    yield put({ type: 'maps/deleteSearchFilterError', payload: err });
  }
}


function* watchGetFavorites() {
  try {
    yield put({ type: 'maps/getFavoritesPending', payload: {} });
    const { data: favdetails } = yield request({
      method: 'GET',
      url: '/api/rentivity/favorite/details',
    });
    yield put({ type: 'maps/getFavoritesSuccess', payload: _.map(favdetails, 'propertySession') });
  } catch (err) {
    yield put({ type: 'maps/getFavoritesError', error: err });
  }
}



function* watchGetFeatures() {
  try {
    yield put({ type: 'maps/getFeaturesPending', payload: {} });
    const { data: filters } = yield request({
      method: 'GET',
      url: '/api/rentivity/feature',
    });
    yield put({ type: 'maps/getFeaturesSuccess', payload: filters });
  } catch (err) {
    yield put({ type: 'maps/getFeaturesError', error: err });
  }
}

function* watchGetSavedFilters() {
  try {
    yield put({ type: 'maps/getSavedFiltersPending', payload: {} });
    const { data: filters } = yield request({
      method: 'GET',
      url: '/api/rentivity/search-filter',
    });
    yield put({ type: 'maps/getSavedFiltersSuccess', payload: filters });
  } catch (err) {
    yield put({ type: 'maps/getSavedFiltersError', error: err });
  }
}



function* watchDeleteFavorite(action) {
  try {
    yield put({ type: 'maps/deleteFavoritePending', payload: {} });

    const { data: saved } = yield request({
      method: 'DELETE',
      url: `/api/rentivity/favorite/${action.payload}`,
    });

    const favorites = yield select((state) => state.maps.favorites);
    const markers = yield select((state) => state.maps.markers);
    const newMarkers = _.map(markers, (el) => {
      if (el._id === saved._id) {
        return {
          ...saved,
          lng: saved.property.location.coordinates[0],
          lat: saved.property.location.coordinates[1]
        };
      }
      return el;
    })
    yield put({
      type: 'maps/deleteFavoriteSuccess',
      payload: {
        markers: newMarkers,
        favorites: _.filter(favorites, (el) => el._id !== action.payload)
      }
    });
  } catch (err) {
    yield put({ type: 'maps/deleteFavoriteError', error: err });
  }
}

function* watchAddFavorite(action) {
  try {
    yield put({ type: 'maps/addFavoritePending', payload: {} });

    const { data: saved } = yield request({
      method: 'POST',
      url: `/api/rentivity/favorite/${action.payload}`,
    });

    const markers = yield select((state) => state.maps.markers);
    const favorites = yield select((state) => state.maps.favorites);
    const newMarkers = _.map(markers, (el) => {
      if (el._id === saved._id) {
        return {
          ...saved,
          lng: saved.property.location.coordinates[0],
          lat: saved.property.location.coordinates[1]
        };
      }
      return el;
    })
    yield put({
      type: 'maps/addFavoriteSuccess', payload: {
        markers: newMarkers,
        favorites: _.uniqBy([{
          ...saved,
          lng: saved.property.location.coordinates[0],
          lat: saved.property.location.coordinates[1]
        }, ...favorites], '_id')
      }
    });
  } catch (err) {
    yield put({ type: 'maps/addFavoriteError', error: err });
  }
}



/**
 * Root saga manages watcher lifecycle
 */
function* _rootSaga() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest('maps/searchPropertySessions', watchSearchPropertySessions);
  yield fork(function* () {
    yield takeLatest('maps/getSavedFilters', watchGetSavedFilters)
  });

  yield fork(function* () {
    yield takeLatest('maps/getFeatures', watchGetFeatures)
  });
  yield fork(function* () {
    yield takeLatest('maps/getFavorites', watchGetFavorites)
  });

  yield takeLatest('maps/setBounds', watchSetBounds);
  yield takeLatest('maps/setBoundsPending', watchSetBoundsPending);
  yield takeLatest('maps/setSearchFilter', watchSetSearchFilter);
  yield takeLatest('maps/resetSearchFilter', watchResetSearchFilter);
  yield takeLatest('maps/saveSearchFilter', watchSaveSearchFilter);
  yield takeLatest('maps/deleteSearchFilter', watchDeleteSearchFilter);

  yield takeLatest('maps/addFavorite', watchAddFavorite)
  yield takeLatest('maps/deleteFavorite', watchDeleteFavorite)
}

export default function* rootSaga() {
  yield spawn(_rootSaga)
}

import * as Yup from 'yup';


export default Yup.object().shape({
  grossIncomeToRentRatio: Yup
  .string().matches(/0\.5|^[1-9][0-9]{0,1}(\.[05]?)?$/, 'Values are allowed only in steps of 0.5 and between 0 to 100.')
  .required('Gross income to rent is a required field'),
  minimumResidentScore: Yup.number()
    .required('Minimum resident score is a required field'),
    rentersInsuranceRequired: Yup.boolean().required(),
    restrictedPetBreeds: Yup.array(Yup.string()),
    petPolicyDescription: Yup.string().nullable(),
  decline: Yup.object().shape({
    bankruptcy_discharged: Yup.object().shape({
      enforce: Yup.boolean()
        .required(),
      monthsWithin: Yup.number().when('enforce', {
        is: true,
        then: Yup.number().integer().typeError('Please enter X months value').min(1, 'Within X months must be greater than 0.'),        
        otherwise: Yup.number().nullable().transform(() => null)
      })
    }),

    bankruptcy_open: Yup.object().shape({
      enforce: Yup.boolean().required()
    }),
    bankruptcy_ch13: Yup.object().shape({
      enforce: Yup.boolean().required()
    }),

    foreclosure: Yup.object().shape({
      enforce: Yup.boolean().required(),
      monthsWithin: Yup.number().when('enforce', {
        is: true,
        then: Yup.number().integer().typeError('Please enter X months value').min(1, 'Within X months must be greater than 0.'),
        otherwise: Yup.number().nullable().transform(() => null)
      })
    }),

    collections: Yup.object().shape({
      enforce: Yup.boolean().required(),
      monthsWithin: Yup.number().when('enforce', {
        is: true,
        then: Yup.number().integer().typeError('Please enter X months value').min(1, 'Within X months must be greater than 0.'),
        otherwise: Yup.number().nullable().transform(() => null)
      })
    }),

    eviction: Yup.object().shape({
      enforce: Yup.boolean().required(),
      monthsWithin: Yup.number().when('enforce', {
        is: true,
        then: Yup.number().integer().typeError('Please enter X months value').min(1, 'Within X months must be greater than 0.'),
        otherwise: Yup.number().nullable().transform(() => null)
      })
    }),

    convictions: Yup.object().shape({
      sexualOffense: Yup.object().shape({
        enforce: Yup.boolean().required(),
        allTime: Yup.boolean(),
        monthsWithin: Yup.number().when('enforce', {
          is: true,
          then: Yup.number().integer().typeError('Please enter X months value').min(1, 'Within X months must be greater than 0.'),
          otherwise: Yup.number().nullable().transform(() => null)
        })
      }),
      convictedLast7Years: Yup.boolean().required(),
      convictedOfViolentOrDrugRelated: Yup.object().shape({
        value: Yup.string().required(),
        monthsWithin: Yup.number().when('value', {
          is: 'yes',
          then: Yup.number().integer().typeError('Please enter X months value').min(1, 'Within X months must be greater than 0.'),
          otherwise: Yup.number().nullable().transform(() => null)
        })
      }),
    })
  }),
  additionalInformation: Yup.object().shape({
    description: Yup.string().nullable()
  })
});



export const defaultValues = {
  grossIncomeToRentRatio: '',
  minimumResidentScore: 630,
  rentersInsuranceRequired: false,
  restrictedPetBreeds: [],
  petPolicyDescription: '',
  decline: {
    bankruptcy_discharged: {
      enforce: true,
      monthsWithin: 24
    },
    bankruptcy_open: {
      enforce: true
    },
    bankruptcy_ch13: {
      enforce: true
    },
    foreclosure: {
      enforce: true,
      monthsWithin: 24
    },
    collections: {
      enforce: true,
      monthsWithin: 24
    },
    eviction: {
      enforce: true,
      monthsWithin: 24
    },
    convictions: {
      sexualOffense: {
        enforce: true,
        monthsWithin: 24,
        allTime: false
      },
      convictedLast7Years: false,
      convictedOfViolentOrDrugRelated:  {
        value: 'no',
        monthsWithin: 24
      }
    }    
  },
  additionalInformation: {
    description: ''
  }
}

// /**
//  * Gets the repositories of the user from Github
//  */
import { spawn } from 'redux-saga/effects';
import { default as mapSagas } from '../components/GMaps/store/sagas';
// const debug = require('debug')('property-search:sagas');

// /**
//  * Root saga manages watcher lifecycle
//  */
export default function* propertySearchSaga() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  // yield takeLatest('GET_SEARCH_FILTERS', getSearchFilters);
  // yield takeLatest('SAVE_SEARCH_FILTER', saveSearchFilter);
  // yield takeLatest('DELETE_SEARCH_FILTER', deleteSearchFilter);

  // yield takeLatest('GET_FEATURES', getFeatures);
  // yield takeLatest('SEARCH_PROPERTY_SESSION', searchPropertySessions);
  // yield takeLatest('SEARCH_PROPERTY_SESSION_SUCCESS', getFeatures);

  // yield takeLatest('GET_FAVORITES', fetchFavorites);

  // yield takeLatest('ADD_FAVORITE', addFavorite);
  // yield takeLatest('DELETE_FAVORITE', deleteFavorite);
  // yield takeLatest('DELETE_FAVORITE_SUCCESS', fetchFavorites);
  // yield takeLatest('ADD_FAVORITE_SUCCESS', fetchFavorites);
  yield spawn(mapSagas)
}

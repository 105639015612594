import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { compose, applyMiddleware, createStore } from 'redux';
import { getDefaultMiddleware } from 'redux-starter-kit';
// import { configureStore, getDefaultMiddleware } from 'redux-starter-kit';
import { routerMiddleware } from 'connected-react-router';
import { createLogger as _createLogger } from 'redux-logger';
import _ from 'lodash';

import createReducer from './reducers';
import rootSaga from './sagas';


const sagaMiddleware = createSagaMiddleware();
// output plain js objects to console on action dispatch
const createLogger = _.memoize((config) => _createLogger(config));

const initLogger = (config = {}, loggerConfig) => (store) => (next) => (action) => {
  if (config.enabled === true) {
    return createLogger(loggerConfig)(store)(next)(action);
  }
  return next(action);
}

const logger = initLogger({
  enabled: localStorage.logger !== 'false'
}, {
  collapsed: (getState, action, logEntry) => !logEntry.error
});

const getWhiteList = (localStorageKey) => {
  try {
    const arr = _.filter(localStorage[localStorageKey].split(','), (el) => !_.isEmpty(el));
    return arr;
  } catch (err) {
    return [];
  }
}

export default function _configureStore(initialState = {}, history) {

  // create list of middlewares
  const middlewares = [thunk, sagaMiddleware, routerMiddleware(history), logger];
  if (process.env.NODE_ENV === 'test') {
    middlewares.pop();
  }
  const enhancers = [applyMiddleware(...middlewares)];

  /* eslint-disable */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
      typeof window === 'object' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const [, tthunk] = getDefaultMiddleware();




  const whiteList = getWhiteList('loggerWhitelist');
  const isDev = process.env.NODE_ENV !== 'production';
  const isProd = !isDev;

  // const store = configureStore({
  //   reducer: createReducer(),
  //   preloadedState: initialState,
  //   devTools: isDev,
  //   middleware: [
  //     tthunk,
  //     sagaMiddleware, 
  //     routerMiddleware(history),
  //     initLogger({ 
  //       enabled: localStorage.logger !== 'false' && !isProd
  //     }, {
  //       predicate: (getState, action) => {
  //         if (whiteList.length > 0){
  //           return _.findIndex(whiteList, (wl) => wl.includes(action.type)) > -1
  //         }
  //         return true;
  //       },
  //       collapsed: (getState, action, logEntry) => !logEntry.error
  //     })
  //   ]
  // })

  const store = createStore(createReducer(), initialState, composeEnhancers(...enhancers));
  /* eslint-enable */

  sagaMiddleware.run(rootSaga);

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}


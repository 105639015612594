import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import createSelector from 'selectorator';
import { createSlice } from 'redux-starter-kit';


const getInitialState = () => ({
  pending: false,
  success: false,
  error: false,
  user: null
});

const slice = createSlice({
  slice: 'email_verification',
  initialState: getInitialState(),
  reducers: {
    verifyEmail: (state) => ({ ...state, pending: false, success: false, user: null }),
    verifyEmailPending: (state) => ({ ...state, pending: true, user: null, success: false }),
    verifyEmailSuccess: (state, action) => ({ ...state, pending: false, success: true, user: action.payload }),
    verifyEmailError: (state, action) => ({ ...state, pending: false, success: false, error: action.payload }),
    resetVerifyEmailState: (state, action) => ({ ...state, ...getInitialState() }),
  }
})


// export reducer, actions, sagas
export const actions = slice.actions;
export const reducer = slice.reducer;
export { default as sagas } from './sagas';



// export mapStateToProps and mapDispatchToProps and composeContainer 
export const selectors = createSelector({
  verification: 'registration.steps.verification',
  setPasswordPending: 'registration.steps.verification.user.registration.setPasswordPending'
});
export const mapDispatchToProps = (dispatch) => bindActionCreators({ ...slice.actions, }, dispatch);
export const composeContainer = connect(selectors, mapDispatchToProps);
import React,{Component} from 'react'
import _ from 'lodash';
import {
  Row, Col,
  Card,
  Form,
  Button
} from 'react-bootstrap';
import { Formik, Field, FieldArray } from "formik";
import _map from 'lodash/map';
import _each from 'lodash/each';
import { FormikCustomField } from '../../../../components/Inputs';
import customFormikField from './../UploadDocuments/customField'
import {fetchPreShowingQuestions} from '../../../../utils/services';


class PreShowingQuestionarie extends Component {
constructor(props){
  super(props);
  this.state={
    preShowingQuestions:[]
  }
}
componentDidMount=async()=>{
  this.fetchPreShow()
}
 async fetchPreShow(){
    try {
      const { data } = await fetchPreShowingQuestions();
       this.setState({
        preShowingQuestions: data
      }); 
    } catch (err) {
      console.log(err);
    }
  
}
render(){
  return (
    <div>
      <Row>
        <Col>
          <Card>

            <Formik 
              enableReinitialize
              initialValues={{preShowingQuestions:this.state.preShowingQuestions,
               ...this.props.initialValues
              }}
              onSubmit={values => {
                this.props.onSubmit('pre-showing-questions', values.preShowingQuestions); 
              }}>
              {
                ({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldTouched,
                  setFieldValue,
                  isValid
                }) => (
                    <Form onSubmit={handleSubmit}>
                      <Card.Header>Pre-Showing Questionnaire</Card.Header>
                      <FieldArray
                      name="preShowingQuestions"
                      render={arrayHelpers => (
                      <div>
                        {
                          this.state.preShowingQuestions.map((pres, index) => (
                      <Card.Body key={index}>         
                      <Row form>
                      <Col>
                      <React.Fragment>
                      <div>
                      <Card>
                      <div className='subHeaderStyling'>
                        <Field
                          type='checkbox'
                          name={`preShowingQuestions.${index}`}
                          label={pres.value}
                          component={customFormikField}
                          onChange={(event) => {
                            const presCheck = event.target.checked ? (pres.checked=true) : (pres.checked=false)
                            setFieldValue(`preShowingQuestions.${index}`, pres)
                          }}
                        />
                        </div>
                        </Card>
                        </div>
                        </React.Fragment>
                      </Col>
                    
                    </Row>
                  </Card.Body>

                ))
                }
              </div> 
              )
             } 
             />
              <Card.Footer>
                <Button 
                  color='primary' 
                  className='mr-2' 
                  onClick={this.props.onPrevious}
                >
                  Previous
                </Button>
                <Button 
                  color='primary' 
                  type='submit'
                  disabled={!_.isEmpty(this.props.errors) || this.props.pending}
                >
                  Next
                </Button>
              </Card.Footer>
            
            </Form>
          )
      }
            </Formik>
          </Card>
        </Col>
      </Row>

    </div>
  )
            }
}

export default PreShowingQuestionarie 

import React from 'react';
import * as R from 'ramda';

/*
  Admin Pages
*/

const LeasePage = React.lazy(() => import('../views/Admin/Lease'));
const Dashboard = React.lazy(() => import('../views/Admin/Dashboard'));
const Properties = React.lazy(() => import('../views/Admin/Properties'));
const LeaseWrapupReview = React.lazy(() => import('../views/Admin/LeaseWrapup'));
const Clients = React.lazy(() => import('../views/Admin/Clients'));
const Partners = React.lazy(() => import('../views/Admin/Partners'));
const RenterPartners = React.lazy(() => import('../views/Renter/Partners'));
const Promotions = React.lazy(() => import('../views/Admin/Promotions'));
const Maintenance = React.lazy(() => import('../views/Admin/Maintenance'));
const Accounting = React.lazy(() => import('../views/Admin/Accounting'));
const Riders = React.lazy(() => import('../views/Admin/Riders'));
const MaintenanceDetails = React.lazy(() => import('../views/Admin/Maintenance/components/MaintenanceDetails'));
const Profile = React.lazy(() => import('../views/Admin/Profile'));
const Settings = React.lazy(() => import('../views/Admin/Settings'));
const Vendors = React.lazy(() => import('../views/Admin/Vendors'));
const Organization = React.lazy(() => import('../views/Admin/Organization'));
const UserManage = React.lazy(() => import('../views/Admin/UserManagement'));

const ManagePortfolios = React.lazy(() => import("views/Admin/Clients/components/ManagePortfolios"));
const PMNotification = React.lazy(() => import('../views/Admin/Notification'));
// const RequestResetPassword = React.lazy(() => import('../views/Common/ResetPassword/components/RequestResetPassword'));
const SetPassword = React.lazy(() => import('../views/Common/ResetPassword/components/SetPassword'));
const PropertyDetails = React.lazy(() => import('../views/Admin/Properties/components/PropertyDetails'));


// Report Pages
const Reports = React.lazy(() => import('../views/Admin/Reports'));
const RequestActivity = React.lazy(() => import('../views/Admin/Reports/components/RequestActivity'));
const ApplicationActivity = React.lazy(() => import('../views/Admin/Reports/components/ApplicationActivity'));
const ListingActivity = React.lazy(() => import('../views/Admin/Reports/components/ListingActivity'));
const PropertySearchSummary = React.lazy(() => import('../views/Admin/Reports/components/PropertySearchSummary'));
const SavedSearchesSummary = React.lazy(() => import('../views/Admin/Reports/components/SavedSearchesSummary'));
const RegistrationActivity = React.lazy(() => import('../views/Admin/Reports/components/RegistrationActivity'));
const ListingAnalysis = React.lazy(() => import('../views/Admin/Reports/components/ListingAnalysis'));

/*
   Renter Pages
 */
const Landing = React.lazy(() => import('../views/Renter/Landing'));
// const RenterDashboard = React.lazy(() => import('../views/Renter/Dashboard/index'));
const MyOffer = React.lazy(() => import('../views/Renter/MyOffer/index'));
const PropertyListing = React.lazy(() => import('../views/Renter/PropertyListing'));
const PropertySearch = React.lazy(() => import('../views/Renter/PropertySearch'));
const RenterProfile = React.lazy(() => import('../views/Renter/Profile'));
const RenterSettings = React.lazy(() => import('../views/Renter/Settings'));
const LeaseWrapup = React.lazy(() => import('../views/Renter/LeaseWrapup'));
const MyRental = React.lazy(() => import('../views/Renter/Dashboard/components/myRental'));
const RenterApplication = React.lazy(() => import('../views/Renter/Application'));
const ViewRenterApplication = React.lazy(() => import('../views/Renter/Application/components/ViewRenterApplication'));
const TransunionApplication = React.lazy(() => import('../views/Renter/Transunion'));
const RenterNotifications = React.lazy(() => import('../views/Renter/Notifications'));
const Favorites = React.lazy(() => import('../views/Renter/Dashboard/components/favorites'));
const Showing = React.lazy(() => import('../views/Renter/Dashboard/components/showings'));
const Invite = React.lazy(() => import('../views/Renter/Dashboard/components/invite'));
const SavedSearch = React.lazy(() => import('../views/Renter/Dashboard/components/saved'));
const MakePayment = React.lazy(() => import('../views/Renter/Dashboard/components/myRental/components/MakePayment'));
// const Application = React.lazy(() => import('../views/Renter/Dashboard/components/application'));


const adminConfig = {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-compass',
      userType: ['pm', 'client'],
      component: Dashboard
    },
    {
      name: 'Properties',
      url: '/properties',
      icon: 'icon-home',
      userType: ['pm', 'client'],
      component: Properties,
      exact: true
    },
    {
      name: 'Lease Wrapup Details',
      url: '/pm/property-session/:propertySessionId/lease-wrapups',
      userType: ['pm', 'client'],
      component: LeaseWrapupReview
    },
    {
      name: 'Clients',
      url: '/clients',
      icon: 'icon-people',
      userType: 'pm',
      component: Clients,
      exact: true
    },
    {
      name: 'Manage Portfolios',
      url: '/clients/:clientId/manage-portfolios',
      userType: 'pm',
      component: ManagePortfolios
    },
    {
      name: 'Market Place',
      url: '/partners',
      icon: 'icon-share',
      userType: 'pm',
      component: Partners
    },
    {
      name: 'Promotions',
      url: '/promotion',
      userType: 'pm',
      icon: 'icon-tag',
      component: Promotions
    },

    {
      name: 'Lease Templates',
      url: '/templates',
      userType: 'pm',
      icon: 'icon-docs',
      component: LeasePage
    },
    {
      name: 'Riders',
      url: '/riders',
      userType: 'pm',
      icon: 'icon-speedometer',
      component: Riders
    },
    {
      name: 'User Management',
      url: '/usermanagement',
      userType: 'pm',
      icon: 'icon-doc',
      component: UserManage,
      exact: true
    },
    {
      name: 'Reports',
      url: '/reports',
      userType: 'pm',
      icon: 'cui-graph',
      component: Reports,
      exact: true
    },
    {
      name: 'Maintenance',
      url: '/maintenance',
      icon: 'icon-wrench',
      userType: ['pm', 'client'],
      component: Maintenance,
      exact: true
    },
    {
      name: 'Maintenance Details',
      url: '/maintenance/:id',
      userType: ['pm', 'client'],
      component: MaintenanceDetails,
      exact: true
    },
    {
      name: 'Accounting',
      url: '/accounting',
      userType: ['pm', 'client'],
      icon: 'icon-calculator',
      component: Accounting
    },
    {
      name: 'Request Activity',
      url: '/reports/requestActivity',
      userType: 'pm',
      component: RequestActivity,
      exact: true
    },
    {
      name: 'Application Activity',
      url: '/reports/applicationActivity',
      userType: 'pm',
      component: ApplicationActivity,
      exact: true
    },
    {
      name: 'Listing Activity',
      url: '/reports/listingActivity',
      userType: 'pm',
      component: ListingActivity,
      exact: true
    },
    {
      name: 'Property Search Summary',
      url: '/reports/propertySearchSummary',
      userType: 'pm',
      component: PropertySearchSummary,
      exact: true
    },
    {
      name: 'Saved Searches Summary',
      url: '/reports/savedSearchesSummary',
      userType: 'pm',
      component: SavedSearchesSummary,
      exact: true
    },
    {
      name: 'Registration Activity',
      url: '/reports/registrationActivity',
      userType: 'pm',
      component: RegistrationActivity,
      exact: true
    },
    {
      name: 'Listing Analysis',
      url: '/reports/listingAnalysis',
      userType: 'pm',
      component: ListingAnalysis,
      exact: true
    },
    {
      name: 'Vendors',
      url: '/vendors',
      userType: 'pm',
      icon: 'icon-notebook',
      component: Vendors
    },

    {
      name: 'Organization',
      url: '/organization',
      userType: 'pm',
      icon: 'icon-notebook',
      component: Organization
    },

    {
      name: 'Profile',
      url: '/profile',
      userType: ['pm', 'client'],
      component: Profile,
      exact: true
    },

    {
      name: 'Settings',
      url: '/settings',
      component: Settings,
      userType: ['pm', 'client'],
      exact: true
    },
    {
      name: 'Notification',
      url: '/pm-notification',
      component: PMNotification,
      exact: true
    },
    {
      name: 'Change Password',
      url: '/change-password',
      component: SetPassword,
      public: true,
      exact: true
    },
    {
      name: 'Property Details',
      url: '/properties/:propertySessionId',
      component: PropertyDetails,
      exact: true
    }
  ]
};

const renterConfig = {
  items: [
    {
      name: 'Home',
      url: '/home',
      component: Landing,
      icon: 'icon-home',
      public: true,
      exact: true
    },
    {
      name: 'Make Payment',
      url: '/myrental/makepayment',
      component: MakePayment,
      icon: 'icon-home',
      public: true,
      exact: true
    },
    {
      name: 'Property Listing',
      url: '/listings/:id',
      component: PropertyListing,
      exact: true,
      public: true
    },
    {
      name: 'My Offers',
      url: '/myoffer',
      component: MyOffer,
      icon: 'icon-present',
      // public: true,
      exact: true
    },
    {
      name: 'My Rental',
      url: '/myrental',
      component: MyRental,
      icon: 'icon-home',
      // public: true,
      exact: true
    },
    {
      name: 'Favorites',
      url: '/favorites',
      component: Favorites,
      icon: 'icon-heart',
      // public: true,
      exact: true
    },
    {
      name: 'Saved Search',
      url: '/saved',
      component: SavedSearch,
      icon: 'icon-pin',
      // public: true,
      exact: true
    },
    {
      name: 'Showings',
      url: '/showing',
      component: Showing,
      icon: 'icon-eye',
      // public: true,
      exact: true
    },

    {
      name: 'Invite',
      url: '/invite',
      component: Invite,
      icon: 'icon-user-follow',
      // public: true,
      exact: true
    },
 
    {
      name: 'Property Search',
      url: '/search',
      component: PropertySearch,
      icon: 'icon-magnifier',
      exact: true,
      public: true
    },
    {
      name: 'View Renter Application',
      url: '/renter-application/view',
      component: ViewRenterApplication,
      icon: 'icon-docs',
      exact: true
    },
    {
      name: 'Renter Application',
      url: '/renter-application',
      component: RenterApplication,
      icon: 'icon-docs',
      exact: true
    },

    {
      name: 'Notifications',
      url: '/renter-notifications',
      icon: 'icon-bell',
      component: RenterNotifications,
      exact: true
    },
    {
      name: 'Profile',
      url: '/renter-profile',
      icon: 'icon-user',
      component: RenterProfile,
      exact: true
    },
    {
      name: 'Settings',
      url: '/renter-settings',
      component: RenterSettings,
      icon: 'icon-settings',
      exact: true,
      public: true
    },
    {
      name: 'LeaseWrapup',
      url: '/lease-wrapup/:leaseWrapupId',
      component: LeaseWrapup,
      public: false
    },
    {
      name: 'TransunionApplication',
      url: '/transunion-application',
      public: false,
      component: TransunionApplication,
      userType: ['renter']
    },
    {
      name: 'Market Place',
      url: '/renterPartners',
      component: RenterPartners,
      icon: 'icon-share',
      exact: true
    }
    
  ]
}

const mapNavConfig = R.omit(['component']);
const toNavConfigAdmin = R.pipe(
  R.prop('items'),
  R.map(mapNavConfig),
  R.filter(R.complement(R.propEq)('name', 'Home')),
  R.filter(R.complement(R.propEq)('name', 'Maintenance Details')),
  R.filter(R.complement(R.propEq)('name', 'Request Activity')),
  R.filter(R.complement(R.propEq)('name', 'Application Activity')),
  R.filter(R.complement(R.propEq)('name', 'Listing Activity')),
  R.filter(R.complement(R.propEq)('name', 'Property Search Summary')),
  R.filter(R.complement(R.propEq)('name', 'Saved Searches Summary')),
  R.filter(R.complement(R.propEq)('name', 'Registration Activity')),
  R.filter(R.complement(R.propEq)('name', 'Listing Analysis')),
  R.filter(R.complement(R.propEq)('name', 'Organization')),
  R.filter(R.complement(R.propEq)('name', 'Profile')),
  R.filter(R.complement(R.propEq)('name', 'Settings')),
  R.filter(R.complement(R.propEq)('name', 'Manage Portfolios')),
  R.filter(R.complement(R.propEq)('name', 'Lease Wrapup Details')),
  R.filter(R.complement(R.propEq)('name', 'Notification')),
  R.filter(R.complement(R.propEq)('name', 'Change Password')),
  R.filter(R.complement(R.propEq)('name', 'SetPassword')),
  R.filter(R.complement(R.propEq)('name', 'Property Details')),
  R.set(R.lensProp('items'), R.__, {}),                  // eslint-disable-line
);


const toNavConfigRenter = R.pipe(
  R.prop('items'),
  R.map(mapNavConfig),
  R.filter(R.complement(R.propEq)('name', 'Home')),
  // R.filter((el) => el.name !== 'Renter Profile'),
  R.filter((el) => el.name !== 'Property Listing'),
  R.filter((el) => el.name !== 'Make Payment'),
  R.filter((el) => el.name !== 'Property Search'),
  R.filter((el) => el.name !== 'Renter Application'),
  R.filter((el) => el.name !== 'View Renter Application'),
  R.filter((el) => el.name !== 'Renter Notifications'),
  R.filter((el) => el.name !== 'LeaseWrapup'),
  R.filter((el) => el.name !== 'TransunionApplication'),
  R.set(R.lensProp('items'), R.__, {})                  // eslint-disable-line
)


const mapRouteConfig = R.converge(R.merge, [
  R.pick(['name', 'component', 'exact', 'component', 'routes', 'public', 'userType']),
  R.applySpec({ path: R.prop('url') })
]);
const toRouteConfig = R.pipe(R.prop('items'), R.map(mapRouteConfig));



export const adminNavConfig = toNavConfigAdmin(adminConfig);
export const adminRouteConfig = toRouteConfig(adminConfig);

export const renterNavConfig = toNavConfigRenter(renterConfig);
export const renterRouteConfig = toRouteConfig(renterConfig);

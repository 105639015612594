import React, { Component } from 'react';
import {
  Container,
  Row,
  Col
} from 'reactstrap';
import * as R from 'ramda';
import _ from 'lodash';
import { withRouter } from 'react-router';


import PropTypes from 'prop-types';
import { Wizard, Steps, Step } from 'react-albus';

import logo from 'assets/img/brand/brand-logo-color.png';
import CompanyInfo from './CompanyInfo';
import composeComponent from './store';
import Banking from './Banking';
import RolesAndTeam from './RolesAndTeam';
import QualificationCriteria from './QualificationCriteria';

import OrderLeaseWrapUp from './OrderLeaseWrapUp';
import LegalEntity from './LegalEntity';

import FeesAndDeposits from './FeesAndDeposits';

import './style.scss';
import UploadDocument from './UploadDocuments';
import PreShowingQuestionarie from './PreShowingQuestionarie';


class PMRegistration extends Component {

  static propTypes = {
    submissions: PropTypes.object.isRequired,
    currentStep: PropTypes.number.isRequired,
    submitStep: PropTypes.func.isRequired
  }
  componentWillMount() {
    this.props.dispatch({ type: 'SET_LAYOUT', value: { dashboardType: 'admin' } })
  }

  componentDidMount() {
    this.props.getCurrentFormData();
    this.props.getRoles();
  }

  componentWillReceiveProps(nextProps) {
  }

  previousStep = (wizard) => () => {
    return this.props.submitStep({
      currentStep: Math.max(this.props.currentStep - 1, 0),
      submissions: {
        ...this.props.submissions,
      }
    })
  }

  submitStep = (wizard) => (stepName, payload) => {
    console.log('wizard', wizard);
    const currentStep = this.props.currentStep;
    const lastStep = parseInt(_.last(_.keys(wizard.steps)));


    this.props.submitStep({
      currentStep: Math.min(currentStep + 1, lastStep),
      submissions: {
        ...this.props.submissions,
        [stepName]: payload
      }
    });
  }

  createOrg = () => (stepName, payload) => {
    const currentStep = this.props.currentStep;
    this.props.createOrganization({
      currentStep: currentStep + 1,
      submissions: {
        ...this.props.submissions,
        [stepName]: payload
      }
    });
  }

  addBankAccount = (wizard) => (account) => {
    return this.props.submitStep({
      currentStep: this.props.currentStep,
      submissions: {
        ...this.props.submissions,
        'banking-info': [
          ...this.props.submissions['banking-info'],
          account
        ]
      }
    })
  }

  removeBankAccount = (wizard) => (i) => {
    const updatedAccounts = _.reduce(this.props.submissions['banking-info'], (acc, curr, index) => {
      if (index === i) {
        return acc;
      }
      return [...acc, curr];
    }, []);

    return this.props.submitStep({
      currentStep: this.props.currentStep,
      submissions: {
        ...this.props.submissions,
        'banking-info': updatedAccounts
      }
    })
  }



  render() {

    if (this.props.pending) {
      return <></>
    }

    return (
      <div className="flex-row align-items-center">
        <div className="onboardHeader">
          <Container>
            <img src={logo} alt="logo" height="40" />
          </Container>
        </div>
        <Container>
          <Row>
            <Col>
              <Wizard>
                <Steps step={{ id: this.props.currentStep.toString() }}>
                  <Step
                    id={'0'}
                    render={(wizard) => {
                      return (
                        <CompanyInfo
                          step={this.props.currentStep + 1}
                          initialValues={this.props.submissions['company-info']}
                          onSubmit={this.submitStep(wizard)}
                        />
                      )
                    }}
                  />
                  <Step
                    id={'1'}
                    render={(wizard) => {
                      return (
                        <RolesAndTeam
                          user={this.props.user}
                          step={this.props.currentStep + 1}
                          roles={this.props.roles}
                          initialValues={this.props.submissions['roles-and-team']}
                          addRole={this.props.addRole}
                          updateRole={this.props.updateRole}
                          deleteRole={this.props.deleteRole}
                          onSubmit={this.submitStep(wizard)}
                          onPrevious={this.previousStep(wizard)}
                        />
                      )
                    }}
                  />

                  <Step
                    id={'2'}
                    render={(wizard) => {
                      return (
                        <QualificationCriteria
                          user={this.props.user}
                          step={this.props.currentStep + 1}
                          initialValues={this.props.submissions['qualification-criteria']}
                          onSubmit={this.submitStep(wizard)}
                          onPrevious={this.previousStep(wizard)}
                        />
                      )
                    }}
                  />

                  <Step
                    id={'3'}
                    render={(wizard) => {
                      return (
                        <FeesAndDeposits
                          user={this.props.user}
                          step={this.props.currentStep + 1}
                          initialValues={this.props.submissions['fees-and-deposits']}
                          onSubmit={this.submitStep(wizard)}
                          onPrevious={this.previousStep(wizard)}
                        />
                      )
                    }}
                  />

                  {/* Lease wrapup orderer - START */}

                  <Step
                    id={'4'}
                    render={(wizard) => {
                      return (
                        <UploadDocument
                          user={this.props.user}
                          step={this.props.currentStep + 1}
                          initialValues={this.props.submissions['required-documents']}
                          onSubmit={this.submitStep(wizard)}
                          onPrevious={this.previousStep(wizard)}
                        />
                      )
                    }}
                  />

                  <Step
                    id={'5'}
                    render={(wizard) => {
                      return (
                        <OrderLeaseWrapUp
                          user={this.props.user}
                          step={this.props.currentStep + 1}
                          initialValues={this.props.submissions['lease-wrapup-steps']}
                          createOrg={this.submitCreateOrg}
                          onSubmit={this.submitStep(wizard)}
                          onPrevious={this.previousStep(wizard)}
                        />
                      )
                    }}
                  />

                  {/* Lease wrapup orderer - END */}

                  <Step
                    id={'6'}
                    render={(wizard) => {
                      return (
                        <Banking
                          user={this.props.user}
                          accounts={this.props.submissions['banking-info']}
                          addBankAccount={this.addBankAccount(wizard)}
                          removeBankAccount={this.removeBankAccount(wizard)}
                          step={this.props.currentStep + 1}
                          initialValues={this.props.submissions['banking']}
                          onSubmit={this.submitStep(wizard)}
                          onPrevious={this.previousStep(wizard)}
                        />
                      )
                    }}
                  />
                  
                  <Step
                    id={'7'}
                    render={(wizard) => {
                      return (
                        <PreShowingQuestionarie
                        user={this.props.user}
                        step={this.props.currentStep + 1}
                        initialValues={this.props.submissions['pre-showing-questions']}
                        onSubmit={this.submitStep(wizard)}
                        onPrevious={this.previousStep(wizard)}
                        pending={this.props.pending}
                        />
                      )
                    }}
                  />
                <Step
                    id={'8'}
                    render={(wizard) => {
                      return (
                        <LegalEntity
                          user={this.props.user}
                          step={this.props.currentStep + 1}
                          initialValues={this.props.submissions['legal-entity']}
                          onSubmit={this.createOrg(wizard)}
                          onPrevious={this.previousStep(wizard)}
                          pending={this.props.pending}
                        />
                      )
                    }}
                  />
                </Steps>
                
              </Wizard>

            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}




// http://bit.ly/2ZxmQSU
const composeContainer = R.compose(
  withRouter,
  composeComponent
);


export default composeContainer(PMRegistration)

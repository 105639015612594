import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import errorImg from '../../assets/img/images/something-went-wrong.png';
import brand from '../../assets/img/brand/brand.png';
import './style.scss';

class ErrorBoundary extends Component {
  state = {
    error: null,
    errorInfo: null
  }

  componentDidCatch = (error, info) => {

    this.setState({
      error: error,
      errorInfo: info
    })
  }

  componentDidMount() {
    this.props.history.listen((location, action) => {
      this.setState({
        error: null,
        errorInfo: null
      });
    });
  }

  render() {
    const { error, errorInfo } = this.state;
    const { children, history } = this.props;

    if (errorInfo) {
      return (
        <>
          <div className='errorBoundary-header'>
            <img src={brand} alt='Rentivity' />
          </div>
          <div className='errorBoundary-wrap'>
            <img src={errorImg} alt='Something went wrong' />
            <h1 className='text-center'>Something went wrong !</h1>
            <h4 className='text-center'>Please choose an option</h4>
            <div className='mt-3'>
              <Button onClick={() => window.location.reload()} color='success' size='lg' style={{ color: 'white' }} className='mr-2'>Try Again</Button>
              <Button onClick={() => history.goBack()} outline color='secondary' size='lg'>Go Back</Button>
            </div>
          </div>
        </>
      )
    } else {
      return children
    }
  }
}

export default withRouter(ErrorBoundary);
// /**
//  * Gets the repositories of the user from Github
//  */

import { call, put, takeLatest } from 'redux-saga/effects';
import * as services from '../../../../utils/services';
import _ from 'lodash';
import { toast } from "react-toastify";

const showSuccessToast = (msg = 'Success', opts = { position: toast.POSITION.TOP_RIGHT }) => {
  return toast.success(msg, { position: toast.POSITION.TOP_RIGHT, ...opts });
};

const showErrorToast = (message, opts = { position: toast.POSITION.TOP_RIGHT }) => {
    if (message instanceof Error) {
      let msg = _.get(message, 'response.data.message', message.message);
      if (msg === 'Not a valid phone number') {
        msg = 'Not a valid phone number in Company Information Page';
      }
      return toast.error(msg, { position: toast.POSITION.TOP_RIGHT, ...opts });
    }
    return toast.error(message, { position: toast.POSITION.TOP_RIGHT, ...opts });
};

function* getRoles(action) {
  try {
    const { data: values } = yield call(services.getOrgRegistrationRoles)
    yield put({ type: 'pm_registration/getRoles_success', payload: values });
  } catch (err) {
    yield put({ type: 'pm_registration/error', payload: action.error });
  }
}

function* getOrgFormData(action) {
  try {
    const { data: values } = yield call(services.getOrgCreateFormSession)
    yield put({ type: 'pm_registration/getCurrentFormData_success', payload: values });
  } catch (err) {
    yield put({ type: 'pm_registration/error', payload: action.error });
  }
}


function* updateOrgFormData(action) {
  try {
    yield put({ type: 'showSpinner', payload: { text: 'Loading...' } });
    const { data: values } = yield call(services.updateOrgCreateFormSession, action.payload)
    yield put({ type: 'pm_registration/submitStep_success', payload: values })
    yield put({ type: 'hideSpinner' });
  } catch (err) {
    yield put({ type: 'hideSpinner' });
    yield put({ type: 'pm_registration/error', payload: action.error });
  }
}

function* createOrganization(action) {
  try {
    // yield put({ type: 'pm_registration/createOrganization', payload: action.payload });
    yield put({ type: 'showSpinner', payload: {  text: 'Creating organization...' } });
    const { data: values } = yield call(services.createOrganization, action.payload.submissions)
    yield put({ type: 'hideSpinner', payload: {  } });
    yield put({ type: 'pm_registration/createOrganization_success', payload: values });
    showSuccessToast('Created Organization Successfully!')
    yield put({ type: 'GET_SESSION' });
  } catch (err) {
    yield put({ type: 'pm_registration/error', payload: err });
    showErrorToast(err);
    yield put({ type: 'hideSpinner', payload: {  } });
  }
}

export default function* leaseWrapupSaga() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest('pm_registration/getRoles', getRoles);
  yield takeLatest('pm_registration/submitStep', updateOrgFormData);
  yield takeLatest('pm_registration/createOrganization', createOrganization);
  yield takeLatest('pm_registration/getCurrentFormData', getOrgFormData);
}

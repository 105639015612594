
// if `spec` contains `action.type` property and its a function, then call it
const createReducer = (initialState, spec) => (state = initialState, action) => {

  if (spec[action.type] && typeof spec[action.type] === 'function') {
    const reducerRtn = spec[action.type](state, action);
    return {
      ...state,
      ...reducerRtn
    }
  }

  // return state as-is
  return state;
};

export default createReducer;

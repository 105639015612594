/* eslint arrow-body-style: [0] */
import React, { Component } from 'react';
import {
  Row, Col, Table,
  Button, Form,
  Card, CardBody, CardHeader, CardFooter
} from 'reactstrap';
import { Formik, Field, FieldArray } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import objectid from 'objectid';
import Roles from './Roles';
import { FormikNoLabelField } from '../../../../components/Inputs';
import { checkEmailAvailable } from '../../../../utils/services'


const schema = Yup.object().shape({
  members: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string()
        .min(2, 'Too Short')
        .required('First name is required'),
      lastName: Yup.string()
        .min(2, 'Too Short')
        .required('Last name is required'),
      email: Yup.string()
        .email()
        .test(
          'email-available',
          // eslint-disable-next-line
          'Email is already in use',
          async function (email) {
            if (email) {
              try {
                const { data } = await checkEmailAvailable(email)
                return data.valid;
              } catch (err) {
                return false;
              }
            }
          })
        .required('Email is required')
    })
  )
});

let sustainMembers = [];

class TeamsRolesBase extends Component {

  state = {
    team: {
      members: [{ email: '', firstName: '', lastName: '', role: '' }]
    },
    remoteRoles: [],
    roles: []
  }

  addRole = async (payload) => {
    return this.props.addRole({
      ...payload,
      _id: objectid()
    })
  }

  editRole = async (roleId, payload) => {
    return this.props.updateRole({
      _id: roleId,
      value: { ...payload, _id: roleId }
    })
  }

  deleteRole = async (roleId) => {
    return this.props.deleteRole({
      _id: roleId,
    })
  }

  handleSubmit = async (payload) => {
    await this.props.onSubmit('roles-and-team', { ...payload })
  }


  render() {

    return (
      <div className='animated fade-in'>
        <Row>
          <Col>
            <Formik
              initialValues={{
                ...this.props.initialValues
              }}
              validationSchema={schema}
              enableReinitialize
              onSubmit={this.handleSubmit}
            >
              {
                ({
                  values,
                  handleSubmit,
                  errors
                }) => (

                    <Form onSubmit={handleSubmit}>
                      <Card>

                        <CardHeader>Roles & Teams</CardHeader>

                        <CardBody>

                          <Roles
                            roles={[...values.roles]}
                            addRole={this.addRole}
                            editRole={this.editRole}
                            deleteRole={this.deleteRole}
                          />

                          <Card>
                            <FieldArray
                              name='members'
                              render={(arrayHelpers) => {
                                if (!_.isEmpty(arrayHelpers.form.values.members)) sustainMembers = arrayHelpers.form.values.members;

                                if (!_.isEmpty(sustainMembers) && _.isEmpty(arrayHelpers.form.values.members)) {
                                  _.map(sustainMembers, data => arrayHelpers.push(data))
                                }

                                return (
                                  <>
                                    <CardHeader>
                                      <h3>Team</h3>
                                      <div className="card-header-actions">
                                        <Button
                                          color='success'
                                          size='sm'
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            arrayHelpers.push({ name: '', email: '', role: _.head(values.roles)._id })
                                          }
                                          }
                                        >
                                          Add Member
                                              </Button>
                                      </div>
                                    </CardHeader>

                                    <CardBody>
                                      <Table
                                        hover
                                        responsive
                                        className="table-outline mb-0 d-none d-sm-table"
                                      >
                                        <thead className="thead-light">
                                          <tr>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Email</th>
                                            <th>Role</th>
                                            <th>Remove</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td><input disabled type="text" className="form-control" value={`${this.props.user.firstName}`} /></td>
                                            <td><input disabled type="text" className="form-control" value={`${this.props.user.lastName}`} /></td>
                                            <td><input disabled type="text" className="form-control" value={`${this.props.user.email}`} /></td>
                                            <td>
                                              <select label="Select Account Type" disabled value={(_.find(values.roles, { name: 'admin-role' }) || {})._id} className="form-control">
                                                {
                                                  _.map([...values.roles], (role, index) => {
                                                    return <option key={`role_${index}`} value={role._id}>{role.name}</option>
                                                  })
                                                }
                                              </select>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                              <Button
                                                className="mr-1"
                                                color="danger"
                                                disabled
                                              >
                                                <span className="fa fa-minus"></span>
                                              </Button>
                                            </td>
                                          </tr>
                                          {
                                            _.map(_.uniq(sustainMembers), (member, index) => (
                                              <tr key={`member_${index}`}>
                                                <td>
                                                  <Field
                                                    noLabel
                                                    name={`members[${index}].firstName`}
                                                    component={FormikNoLabelField}
                                                  />
                                                </td>
                                                <td>
                                                  <Field
                                                    noLabel
                                                    name={`members[${index}].lastName`}
                                                    component={FormikNoLabelField}
                                                  />
                                                </td>
                                                <td>
                                                  <Field
                                                    noLabel
                                                    name={`members[${index}].email`}
                                                    component={FormikNoLabelField}
                                                  />
                                                </td>
                                                <td>
                                                  <Field
                                                    noLabel
                                                    name={`members[${index}].role`}
                                                    type='select'
                                                    label='Select Account Type'
                                                    component={FormikNoLabelField}
                                                  >
                                                    {
                                                      _.map([...values.roles], (role, index) => {
                                                        return <option key={`role_${index}`} value={role._id}>{role.name}</option>
                                                      })
                                                    }
                                                  </Field>

                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                  <Button
                                                    color="danger"
                                                    className="mr-1"
                                                    onClick={() => {
                                                      arrayHelpers.remove(index);

                                                      if (sustainMembers.length === 1) sustainMembers = []
                                                      else if (sustainMembers.length > 1) _.remove(sustainMembers, memberIn => memberIn !== member)
                                                      this.forceUpdate();
                                                    }}
                                                  >
                                                    <span className="fa fa-minus"></span>
                                                  </Button>
                                                </td>
                                              </tr>
                                            ))
                                          }
                                        </tbody>
                                      </Table>
                                    </CardBody>
                                  </>
                                )
                              }}
                            />
                            {_.get(this.props, 'stepInfo.rolesAndTeam.error.message', '')}
                          </Card>
                        </CardBody>

                        <CardFooter>
                          <div className="card-footer-actions">
                            <Button
                              type='button'
                              color="primary"
                              className="mr-2"
                              onClick={this.props.onPrevious}
                            >
                              Previous
                          </Button>
                            <Button
                              type='submit'
                              color="primary"
                              disabled={!_.isEmpty(errors)}
                            >
                              Next
                          </Button>
                          </div>
                        </CardFooter>
                      </Card>
                    </Form>
                  )
              }
            </Formik>
          </Col>
        </Row>
      </div>
    );
  }
}

export default TeamsRolesBase;

